import { PropsWithChildren, HTMLAttributes } from 'react';

import { IUserRole } from 'src/types';

import { useAccess } from './effects';

export interface IAccessProps {
  roles?: IUserRole[];
}

export const Access = ({ roles, children }: PropsWithChildren<HTMLAttributes<HTMLElement>> & IAccessProps) => {
  const hasAccess = useAccess(...(roles ?? []));

  return <>{hasAccess ? children : null}</>;
};
