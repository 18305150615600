import { ThemedComponent } from '@mui/material/styles';

export const MuiChip: ThemedComponent<'MuiChip'> = {
  defaultProps: {
    size: 'small',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      border: 'none',
      alignItems: 'baseline',
      color: theme.palette.text.primary,
      borderRadius: theme.shape.borderRadius,
    }),
    label: {
      height: '100%',
    },
    sizeMedium: ({ theme }) => ({
      fontSize: 13,
      height: theme.spacing(2.5),
      minWidth: theme.spacing(5.5),
    }),
    sizeSmall: ({ theme }) => ({
      fontSize: 12,
      height: theme.spacing(2),
      minWidth: theme.spacing(4.5),
    }),
  },
};
