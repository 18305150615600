import { memo, useMemo } from 'react';

import { PieChartCard, IPieChartSegment, createChartColor } from 'src/components/shared/PieChart';
import numberFormatter from 'src/utils/numberFormatter';
import { IAnomalyTypeChartItem } from 'src/types';

export interface IAnomalyTypeChartProps {
  types: IAnomalyTypeChartItem[];
}

const formatNumber = numberFormatter();

const anomalyTypesToSegments = (types: IAnomalyTypeChartItem[]) =>
  types.map(({ name, value }, index) => ({
    name,
    value,
    color: createChartColor(index),
    view: formatNumber(value),
  })) as IPieChartSegment[];

const AnomalyTypeChart = ({ types }: IAnomalyTypeChartProps) => {
  const amountSegments = useMemo(() => anomalyTypesToSegments(types), [types]);

  return <PieChartCard title="ANOMALY TYPE (#)" segments={amountSegments} emptyMessage={'No Anomalies Detected'} />;
};

export default memo(AnomalyTypeChart);
