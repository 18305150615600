import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import MenuItem from '@mui/material/MenuItem';
import Button, { ButtonProps } from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { ILogisticsProvider } from 'src/types';
import { IWarehouseSchema, WarehouseSchema } from 'src/schemas';
import { FormAutocomplete, FormFields, FormInput, FormText } from 'src/components/shared/Form';

export interface IFormId {
  id: string;
}

export interface IWarehouseFormProps extends IFormId {
  logistics: ILogisticsProvider[];
  onSubmit: (warehouse: IWarehouseSchema) => void;
}

export const WarehouseSubmitButton = ({ id, ...props }: IFormId & ButtonProps) => (
  <Button {...props} form={id} type="submit" variant="contained">
    Save
  </Button>
);

export const WarehouseForm = ({ id, logistics, onSubmit }: IWarehouseFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IWarehouseSchema>({
    resolver: yupResolver(WarehouseSchema),
  });

  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <FormFields>
        <Typography sx={{ lineHeight: 1 }} variant="h2">
          General information
        </Typography>
        <FormInput>
          <FormText control={control} name="name" label="Warehouse Name" errorMessage={errors?.name?.message} />
        </FormInput>
        <FormInput>
          <FormText
            control={control}
            name="description"
            label="Warehouse Description"
            errorMessage={errors?.description?.message}
          />
        </FormInput>
        <FormInput>
          <FormAutocomplete
            control={control}
            label="Select 3PL"
            name="logistics_provider_id"
            options={logistics}
            errorMessage={errors.logistics_provider_id?.message}
            onChange={(logistic) => logistic?.id}
            isOptionsEqual={(prev, next) => prev.id === next.id}
            getLabel={(logistic) => (logistics?.length ? logistic.name : '')}
            getValue={(value) => logistics.find(({ id }) => id === value) ?? null}
            renderValue={(props, { id, name }) => (
              <MenuItem {...props} key={id}>
                {name}
              </MenuItem>
            )}
          />
        </FormInput>
      </FormFields>
      <FormFields>
        <Typography sx={{ lineHeight: 1 }} variant="h2">
          Address
        </Typography>
        <FormInput>
          <FormText control={control} name="country" label="Country" errorMessage={errors?.country?.message} />
        </FormInput>
        <FormInput>
          <FormText control={control} name="city" label="City" errorMessage={errors?.city?.message} />
        </FormInput>
        <FormInput>
          <FormText control={control} name="state" label="State" errorMessage={errors?.state?.message} />
        </FormInput>
        <FormInput>
          <FormText control={control} name="zip_code" label="Zip code" errorMessage={errors?.zip_code?.message} />
        </FormInput>
        <FormInput>
          <FormText
            control={control}
            name="address_line_1"
            label="Address line 1"
            errorMessage={errors?.address_line_1?.message}
          />
        </FormInput>
        <FormInput>
          <FormText
            control={control}
            name="address_line_2"
            label="Address line 2"
            errorMessage={errors?.address_line_2?.message}
          />
        </FormInput>
      </FormFields>
    </form>
  );
};
