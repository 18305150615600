import { Fragment, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';

import { usePrivateContext } from 'src/layouts/Private';
import shouldForwardProp from 'src/utils/shouldForwardProp';

const StyledPageTitle = styled.div`
  padding: ${({ theme }) => theme.spacing(2, 3)};

  &:empty {
    display: none;
  }
`;

const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export interface ITitleProps {
  title: string;
  description?: string | JSX.Element;
}

export const PageTitle = ({ title, description, children }: PropsWithChildren<ITitleProps>) => {
  const { titleRef } = usePrivateContext();

  if (!titleRef) return <Fragment />;

  return (
    <StyledPageTitle>
      {createPortal(
        <StyledTitle>
          <Typography variant="h1" sx={{ lineHeight: 0.9 }}>
            {title}
          </Typography>
          {description && <Typography variant="body1">{description}</Typography>}
        </StyledTitle>,
        titleRef,
      )}
      {children}
    </StyledPageTitle>
  );
};

PageTitle.displayName = 'PageTitle';

export interface ITitleButtonsProps {
  align: 'left' | 'right';
}

const StyledTitleButtons = styled('div', { shouldForwardProp })<{ $align: ITitleButtonsProps['align'] }>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ $align }) => ($align === 'left' ? 'start' : 'end')};
  gap: ${({ theme }) => theme.spacing(3)};
`;

export const TitleButtons = ({ align, children }: PropsWithChildren<ITitleButtonsProps>) => (
  <StyledTitleButtons $align={align}>{children}</StyledTitleButtons>
);

const StyledTitleButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(3)};
`;

export const TitleButtonGroup = ({ children }: PropsWithChildren) => (
  <StyledTitleButtonGroup>{children}</StyledTitleButtonGroup>
);
