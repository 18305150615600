import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';

import { CardFooter } from 'src/components/shared/Card';
import { InvoiceStatusChip } from 'src/components/Status';
import { linkToInvoiceDetails } from 'src/components/Invoice';
import { IInvoiceCardProps, InvoiceCard } from 'src/components/InvoiceDetails';

const InvoiceSummaryCard = ({ invoice }: IInvoiceCardProps) => (
  <InvoiceCard isAccent invoice={invoice}>
    <CardFooter>
      <Button variant="text" component={NavLink} to={linkToInvoiceDetails(invoice.name, invoice.services)}>
        View invoice
      </Button>
      <InvoiceStatusChip status={invoice.status} />
    </CardFooter>
  </InvoiceCard>
);

export default memo(InvoiceSummaryCard);
