import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';

import { Loading } from 'src/components/shared/Page';
import alertService from 'src/services/AlertService';
import makeRequest from 'src/effects/makeRequest';
import * as apiAlert from 'src/api/alert';

import { StyledBellIcon, StyledAlertPanel, StylesAlertTotal } from './styles';
import useAlertsControl from './useAlertsControl';
import useAlertAnimation from './useAlertAnimation';
import AlertDialog from './AlertDialog';

const useAlertsRequest = makeRequest(apiAlert.getAlerts);

const AlertPanel = () => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef<MaybeNull<HTMLDivElement>>(null);

  const animateAlert = useAlertAnimation(ref);
  const alertsControl = useAlertsControl();
  const alertsRequest = useAlertsRequest(null, true);
  const alertsUnread = useMemo(
    () =>
      alertsControl.totals.unread <= 0
        ? ''
        : alertsControl.totals.unread > 9
          ? '9+'
          : `${alertsControl.totals.unread} `,
    [alertsControl.totals.unread],
  );

  const hidePopper = useCallback(() => setOpen(false), []);
  const togglePopper = useCallback(() => setOpen(!isOpen), [isOpen]);

  useEffect(() => {
    alertsRequest.make();
    alertsRequest.onFulfill(alertsControl.initializeAlerts);
  }, []);

  useEffect(
    () =>
      alertService.subscribe((event) => {
        if (!alertsControl.initialized) return;

        switch (event.action) {
          case 'NEW': {
            alertsControl.addAlert(event.alert);
            animateAlert();

            break;
          }
          case 'UPDATE': {
            alertsControl.updateAlert(event.alert, { silent: true });
            break;
          }
          case 'DELETE': {
            alertsControl.removeAlert(event.alert);
            break;
          }
        }
      }),
    [alertsControl.initialized],
  );

  useEffect(() => alertsControl.observer.subscribe((alert) => alertService.update(alert)), []);

  return (
    <>
      <StyledAlertPanel ref={ref} onClick={togglePopper}>
        {!!alertsControl.totals.unread && (
          <Box>
            <StylesAlertTotal>{alertsUnread}</StylesAlertTotal>
          </Box>
        )}
        <Box>
          <StyledBellIcon $active={isOpen || !!alertsUnread} />
        </Box>
      </StyledAlertPanel>
      <Popper
        transition
        open={isOpen}
        anchorEl={ref.current}
        placement="bottom-end"
        sx={{ zIndex: 1, top: '8px!important' }}>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={hidePopper}>
            <Fade {...TransitionProps}>
              {alertsRequest.loading ? (
                <Loading />
              ) : (
                <AlertDialog alerts={alertsControl.alerts} onClose={hidePopper} onUpdate={alertsControl.updateAlert} />
              )}
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default memo(AlertPanel);
