import { memo, useMemo } from 'react';

import { IInvoiceOrderSortBy, IInvoiceOrders, ISort } from 'src/types';
import { Loading } from 'src/components/shared/Page';
import { TableBody } from 'src/components/shared/Table';
import Message from 'src/components/shared/Message';
import TruncatePopover from 'src/components/shared/TruncatePopover';

import { OrderListTotal } from './OrderListTotal';
import { IOrderHeadAction } from './OrderListHeader';
import { IOrderListRowProps } from './OrderListRow';

export type IOrderActionProps = Omit<IOrderListRowProps, 'renderAction'>;
export type IOrderRowAction = (props: IOrderActionProps) => JSX.Element;

export interface IOrderListProps {
  loading: boolean;
  payload: MaybeNull<IInvoiceOrders>;
  sorting: ISort<IInvoiceOrderSortBy>;
  onSortChange: (sort: ISort<IInvoiceOrderSortBy>) => void;
  rowAction: IOrderRowAction;
  headAction: IOrderHeadAction;
}

const OrderList = ({
  loading,
  payload,
  sorting,
  onSortChange,
  rowAction: RowAction,
  headAction: HeadAction,
}: IOrderListProps) => {
  const ordersNumbers = useMemo(() => payload?.data.map(({ order_number }) => order_number) ?? [], [payload?.data]);

  return (
    <>
      <TruncatePopover>
        <TableBody>
          <HeadAction sort={{ by: sorting.by, order: sorting.order }} onSort={onSortChange} />
          {!loading && payload && (
            <>
              {payload.data.map((order) => (
                <RowAction
                  key={`order-row-${order.order_number}-${order.error_code}`}
                  order={order}
                  ordersNumbers={ordersNumbers}
                />
              ))}
              <OrderListTotal total={payload.total} />
            </>
          )}
        </TableBody>
      </TruncatePopover>
      {loading ? <Loading /> : !payload?.data.length && <Message>No orders</Message>}
    </>
  );
};

export default memo(OrderList);
