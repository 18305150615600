import { memo } from 'react';

import { ISort, ISortDirection, IInvoiceErrorTotal, IInvoiceErrorSortBy } from 'src/types';
import Message from 'src/components/shared/Message';
import { Loading } from 'src/components/shared/Page';
import { TableBody } from 'src/components/shared/Table';
import TruncatePopover from 'src/components/shared/TruncatePopover';

import { ErrorListRow } from './ErrorListRow';
import { ErrorListHeader } from './ErrorListHeader';
import { ErrorListTotal } from './ErrorListTotal';

export type IErrorListSortParams = {
  by: IInvoiceErrorSortBy;
  order: ISortDirection;
};

export interface IErrorListProps {
  loading: boolean;
  sorting: IErrorListSortParams;
  payload: MaybeNull<IInvoiceErrorTotal>;
  changeSorting: (sort: ISort<IInvoiceErrorSortBy>) => void;
  onErrorTypeClick: (codes: string[]) => void;
}

const ErrorList = ({ payload, loading, sorting, changeSorting, onErrorTypeClick }: IErrorListProps) => (
  <>
    <TruncatePopover>
      <TableBody>
        <ErrorListHeader sort={{ by: sorting.by, order: sorting.order }} onSort={changeSorting} />
        {!loading && !!payload && (
          <>
            {payload.data
              .filter((r) => r.unfavorable_errors_count > 0)
              .map((item, index) => (
                <ErrorListRow key={`error-summary-row-${index}`} item={item} onErrorTypeClick={onErrorTypeClick} />
              ))}
            <ErrorListTotal total={payload.total} />
          </>
        )}
      </TableBody>
    </TruncatePopover>
    {loading ? <Loading /> : !payload?.data.length && <Message>No errors</Message>}
  </>
);

export default memo(ErrorList);
