import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import TextField, { BaseTextFieldProps } from '@mui/material/TextField';
import { InputProps } from '@mui/material/Input';

export interface IFormTextProps<S extends FieldValues> {
  control: Control<S>;
  name: FieldPath<S>;
  label?: string;
  placeholder?: string;
  errorMessage?: Maybe<string>;
  type?: BaseTextFieldProps['type'];
  onBlur?: BaseTextFieldProps['onBlur'];
  onFocus?: BaseTextFieldProps['onFocus'];
  endAdornment?: InputProps['endAdornment'];
  startAdornment?: InputProps['startAdornment'];
  autoComplete?: InputProps['autoComplete'];
}

export const FormText = <S extends FieldValues>(props: IFormTextProps<S>) => (
  <Controller
    control={props.control}
    name={props.name}
    render={({ field }) => (
      <TextField
        autoComplete={props.autoComplete ?? 'off'}
        variant="filled"
        id={props.name}
        name={props.name}
        type={props.type}
        label={props.label}
        placeholder={props.placeholder}
        value={field.value ?? ''}
        error={!!props.errorMessage}
        helperText={props.errorMessage}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        onChange={field.onChange}
        InputProps={{
          startAdornment: props.startAdornment,
          endAdornment: props.endAdornment,
        }}
      />
    )}
  />
);
