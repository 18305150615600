import { ThemedComponent } from '@mui/material/styles';

export const MuiTooltip: ThemedComponent<'MuiTooltip'> = {
  styleOverrides: {
    tooltip: ({ theme }) => ({
      color: theme.palette.text.secondary,
      fontSize: theme.spacing(1.5),
      fontWeight: theme.typography.fontWeightRegular,
      padding: theme.spacing(0.5, 2),
      boxShadow: theme.shadows[3],
      background: theme.palette.common.white,
    }),
  },
};
