import { memo } from 'react';
import Typography from '@mui/material/Typography';

import { IAnomalyTotal } from 'src/types';
import numberFormatter from 'src/utils/numberFormatter';
import { getAnomalyStatusLabel } from 'src/components/Status';

import { StyledAnomalyTotal } from './styles';

const formatNumber = numberFormatter();

export interface IAnomalyDetailsTotalProps {
  total: IAnomalyTotal;
}

const AnomalyDetailsTotal = ({ total }: IAnomalyDetailsTotalProps) => (
  <StyledAnomalyTotal>
    <span>
      <Typography variant="h4" component={'dt'}>
        Sku Count:
      </Typography>
      <Typography variant="body1" component={'dd'}>
        {formatNumber(total.sku_count)}
      </Typography>
    </span>
    <span>
      <Typography variant="h4" component={'dt'}>
        Anomaly Status:
      </Typography>
      <Typography variant="body1" component={'dd'}>
        {getAnomalyStatusLabel(total.anomaly_status)}
      </Typography>
    </span>
    <span>
      <Typography variant="h4" component={'dt'}>
        Carrier:
      </Typography>
      <Typography variant="body1" component={'dd'}>
        {total.carrier}
      </Typography>
    </span>
    <span>
      <Typography variant="h4" component={'dt'}>
        Service Level:
      </Typography>
      <Typography variant="body1" component={'dd'}>
        {total.service_level}
      </Typography>
    </span>
  </StyledAnomalyTotal>
);

export default memo(AnomalyDetailsTotal);
