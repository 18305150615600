import dateFormatter from 'src/utils/dateFormatter';
import numberFormatter from 'src/utils/numberFormatter';
import currencyFormatter from 'src/utils/currencyFormatter';
import { FilterType, IFilterType, IFiltersOptions, IFiltersValues } from 'src/types';

import { IFormatters } from './types';

export type IFormatter = (options: IFiltersOptions[IFilterType], value: IFiltersValues[IFilterType]) => string;

const formatDate = dateFormatter();
const formatNumber = numberFormatter();
const formatCurrency = currencyFormatter();

const singleSelectFormatter = (options: IFiltersOptions['single_select'], key?: IFiltersValues['single_select']) => {
  if (key == null) return '-';

  return options.values.find((value) => value.key === key)?.value ?? '-';
};

const multiSelectFormatter = (options: IFiltersOptions['multi_select'], keys?: IFiltersValues['multi_select']) => {
  if (keys == null) return '-';

  const values = options.values.filter((value) => keys.includes(value.key)).map(({ value }) => value);

  return values.length ? values.join(', ') : '-';
};

const criterionNumberFormatter = (
  options: IFiltersOptions['criterion_number'],
  value?: IFiltersValues['criterion_number'],
) => {
  if (value == null) return '-';

  const criterion = options.criterions.find(({ key }) => key === value.criterion)?.value;
  const number = options.format === 'currency' ? formatCurrency(value.number) : formatNumber(value.number);

  return [criterion, number].filter(Boolean).join(' ');
};

const dateRangeFormatter = (_options: IFiltersOptions['date_range'], dates?: IFiltersValues['date_range']) => {
  if (dates == null) return '-';

  return [formatDate(dates.from), formatDate(dates.to)].join(' - ');
};

const formatters = {
  [FilterType.SingleSelect]: singleSelectFormatter,
  [FilterType.MultiSelect]: multiSelectFormatter,
  [FilterType.CriterionNumber]: criterionNumberFormatter,
  [FilterType.DateRange]: dateRangeFormatter,
} as IFormatters;

export const getFormatter = (type: IFilterType) => formatters[type] as IFormatter;
