import { memo } from 'react';

import {
  ISort,
  ISortDirection,
  IInvoiceDetailDataSortBy,
  IInvoiceServiceDataItem,
  IInvoiceServiceTotalData,
} from 'src/types';
import { Loading } from 'src/components/shared/Page';
import { TableBody } from 'src/components/shared/Table';
import Message from 'src/components/shared/Message';

import { PickAndPackRow } from './PickAndPackRow';
import { PickAndPackHeaders } from './PickAndPackHeaders';
import { PickAndPackTotal } from './PickAndPackTotal';

export type IPickAndPackListParams = {
  by: IInvoiceDetailDataSortBy;
  order: ISortDirection;
};

export interface IPickAndPackListProps {
  loading: boolean;
  params: IPickAndPackListParams;
  changeSorting: (sort: ISort<IInvoiceDetailDataSortBy>) => void;
  data: Maybe<IInvoiceServiceDataItem[]>;
  total: Maybe<IInvoiceServiceTotalData>;
}

const PickAndPackList = ({ loading, params, changeSorting, data, total }: IPickAndPackListProps) =>
  loading ? (
    <Loading />
  ) : (
    <TableBody>
      <PickAndPackHeaders sort={{ by: params.by, order: params.order }} onSort={changeSorting} />
      {!loading && !!data ? (
        <>
          {data.map((item, index) => (
            <PickAndPackRow item={item} key={`pick-and-pack-row-${index}`} />
          ))}
          {total && <PickAndPackTotal total={total} />}
        </>
      ) : (
        <Message>No details for selected invoice</Message>
      )}
    </TableBody>
  );

export default memo(PickAndPackList);
