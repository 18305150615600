import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';

import { PageTitle, PageContainer, TitleButtons, Loading } from 'src/components/shared/Page';
import { ButtonLink } from 'src/components/shared/Buttons';
import { NewUserForm, NewUserSubmitButton } from 'src/components/UserForm';
import makeRequest from 'src/effects/makeRequest';
import * as apiBrand from 'src/api/brand';
import * as apiUser from 'src/api/user';
import { INewUser } from 'src/types';

const NEW_USER_FORM_ID = 'new-user-form';

const useBrandRequest = makeRequest(apiBrand.getBrands);
const useCreateUserRequest = makeRequest(apiUser.createUser);

export default function UserListPage() {
  const navigate = useNavigate();
  const brandRequest = useBrandRequest([], true);
  const createUser = useCreateUserRequest(null, false);

  const handleCreateUser = (user: INewUser) => {
    createUser.make(user);
    createUser.onFulfill(() => navigate('/system/users'));
  };

  useEffect(() => brandRequest.make(), []);

  return (
    <>
      <PageTitle title={'Create user'} description={'Simply create new user.'}>
        <TitleButtons align="left">
          <NewUserSubmitButton
            id={NEW_USER_FORM_ID}
            variant="contained"
            disabled={brandRequest.loading || createUser.loading}>
            Add user
          </NewUserSubmitButton>
          <ButtonLink title="Cancel" to={'/system/users'} variant="outlined" />
        </TitleButtons>
      </PageTitle>
      <PageContainer>
        <Divider />
        {brandRequest.loading ? (
          <Loading />
        ) : (
          <NewUserForm id={NEW_USER_FORM_ID} brands={brandRequest.data} onSubmit={handleCreateUser} />
        )}
      </PageContainer>
    </>
  );
}
