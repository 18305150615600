import { ChangeEvent, forwardRef, memo, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

import { CloseIcon } from 'src/components/Icons';
import { IAlertCommon } from 'src/types';

import { StyledAlertList, StyledCloseButton, StyledDialog, StyledDialogControls, StyledDialogHeader } from './styles';
import Alert from './Alert';

export interface IAlertDialogProps {
  onClose: () => void;
  onUpdate: (alert: IAlertCommon) => void;
  alerts: IAlertCommon[];
}

const AlertDialog = forwardRef<HTMLDivElement, IAlertDialogProps>(({ alerts, onUpdate, onClose }, ref) => {
  const [isUnread, setUnread] = useState(true);
  const [filteredAlerts, setFilteredAlerts] = useState<IAlertCommon[]>([]);

  const handleUnreadChange = ({ target }: ChangeEvent<HTMLInputElement>) => setUnread(target.checked);

  useEffect(() => {
    const filteredAlerts = alerts.filter((alert) => (isUnread ? !alert.acknowledged : true));

    setFilteredAlerts(filteredAlerts);
  }, [isUnread, alerts]);

  return (
    <StyledDialog ref={ref}>
      <StyledDialogHeader>
        <Typography variant="h3">Alerts</Typography>
        <StyledDialogControls>
          <FormControlLabel
            control={<Switch checked={isUnread} onChange={handleUnreadChange} />}
            label={isUnread ? 'Unread' : 'All'}
            labelPlacement="start"
          />
          <StyledCloseButton onClick={onClose}>
            <CloseIcon />
          </StyledCloseButton>
        </StyledDialogControls>
      </StyledDialogHeader>
      <StyledAlertList>
        {filteredAlerts.length ? (
          filteredAlerts.map((alert) => (
            <Alert key={`alert-${alert.id}`} alert={alert} onClose={onClose} onUpdate={onUpdate} />
          ))
        ) : (
          <Typography variant="body1">No alerts</Typography>
        )}
      </StyledAlertList>
    </StyledDialog>
  );
});

AlertDialog.displayName = 'AlertDialog';

export default memo(AlertDialog);
