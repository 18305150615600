import { memo } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import { UploadState } from 'src/effects/useFileUploadState';
import { DownloadState } from 'src/effects/useFileDownloadState';
import { DownloadIcon, TrashIcon } from 'src/components/Icons';

import { StyledContainer, StyledDescription, StyledActions, UploadProgress } from './styles';

export interface IDownloadingProps {
  downloadState: DownloadState;
  uploadState: UploadState;
  onDelete: () => void;
  onDownload: () => void;
}

const Downloading = ({ downloadState, uploadState, onDelete, onDownload }: IDownloadingProps) => {
  const isFailed = downloadState.status === 'failed';
  const isDownloading = downloadState.status === 'downloading';

  return (
    <StyledContainer $failed={isFailed}>
      <StyledDescription>
        <Typography variant="body1">{uploadState.name}</Typography>
        {isDownloading && <UploadProgress variant="determinate" value={downloadState.progress} />}
      </StyledDescription>
      {!isDownloading && (
        <StyledActions>
          <IconButton sx={{ padding: 0 }} onClick={onDelete}>
            <TrashIcon />
          </IconButton>
          <IconButton sx={{ padding: 0 }} onClick={onDownload}>
            <DownloadIcon />
          </IconButton>
        </StyledActions>
      )}
    </StyledContainer>
  );
};

export default memo(Downloading);
