import { PropsWithChildren, forwardRef, memo } from 'react';

import { IInvoice } from 'src/types';
import dateFormatter from 'src/utils/dateFormatter';
import numberFormatter from 'src/utils/numberFormatter';
import currencyFormatter from 'src/utils/currencyFormatter';
import { Card, CardHead, CardRow } from 'src/components/shared/Card';

export interface IInvoiceCardProps {
  invoice: IInvoice;
  isAccent?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const formatUSD = currencyFormatter();
const formatNumber = numberFormatter();
const formatDate = dateFormatter();

const InvoiceCard = forwardRef<HTMLDivElement, PropsWithChildren<IInvoiceCardProps>>(
  ({ invoice, isAccent, onClick, children }, ref) => (
    <Card ref={ref} isAccent={isAccent} isPointer={!!onClick} onClick={onClick}>
      <CardHead
        prefix={'Invoice'}
        name={invoice.name}
        value={`${formatDate(invoice.start_date)} - ${formatDate(invoice.end_date)}`}
      />
      <CardRow name={'3PL'} value={invoice.logistics_provider} />
      <CardRow name={'Warehouse'} value={invoice.warehouse} />
      <CardRow name={'Services'} value={invoice.services} />
      <CardRow name={'Charges'} value={formatUSD(invoice.logistics_charges)} />
      <CardRow name={'Errors (#)'} value={formatNumber(invoice.unfavorable_errors_count)} />
      <CardRow name={'Errors ($)'} value={formatUSD(invoice.unfavorable_errors_dollars)} />
      {children}
    </Card>
  ),
);

InvoiceCard.displayName = 'InvoiceCard';

export default memo(InvoiceCard);
