import styled from '@emotion/styled';

import shouldForwardProp from 'src/utils/shouldForwardProp';

export const StyledCheckbox = styled.span`
  display: inline-block;
  border-radius: 2px;
  width: ${({ theme }) => theme.spacing(2)};
  height: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.palette.text.secondary};
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const StyledTableCell = styled.div<{ $width?: number }>`
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  padding: ${({ theme }) => theme.spacing(1, 1.5)};

  ${({ $width }) =>
    $width != null &&
    `
max-width: 0;
width: ${$width}%;
& > div {
  max-width: 100%;
  display: inline-flex;

  & > * {
    display: inline;
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
`}
`;

export const StyledHeaderCell = styled.div<{ preWrap?: boolean }>`
  position: relative;
  display: table-cell;
  vertical-align: middle;
  white-space: ${({ preWrap }) => (preWrap ? 'pre-wrap' : 'nowrap')};
  padding: ${({ theme }) => theme.spacing(1, 1.5)};

  &::before {
    content: '';
    display: inline-block;
    width: 2px;
    border-radius: 2px;
    height: ${({ theme }) => theme.spacing(2)};
    background-color: ${({ theme }) => theme.palette.primary.main};
    position: absolute;
    left: 0;
    top: 50%;
    translate: 0 -50%;
  }
`;

export const StyledLeadCell = styled(StyledHeaderCell)`
  width: ${({ theme }) => theme.spacing(5.5)};
  min-width: ${({ theme }) => theme.spacing(5.5)};

  &::before {
    content: initial;
  }
`;

export const StyledFooterCell = styled(StyledTableCell, { shouldForwardProp })<{ $ignoreWidth?: boolean }>`
  ${({ $ignoreWidth }) =>
    $ignoreWidth &&
    `
width: 0;
max-width: 0;
pointer-events: none;
`}
`;

export const StyledTableCellAction = styled(StyledLeadCell)`
  width: ${({ theme }) => theme.spacing(5.5)};
  min-width: ${({ theme }) => theme.spacing(5.5)};
`;

export const StyledHeaderCellSort = styled(StyledHeaderCell)`
  cursor: pointer;

  & svg {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`;

export const StyledTableRow = styled.div`
  display: table-row;
  height: ${({ theme }) => theme.spacing(5)};
  border-radius: ${({ theme }) => theme.spacing(0.63)};
`;

export const StyledTableHeaderRow = styled('header', { shouldForwardProp })<{ $sticky?: boolean }>`
  height: ${({ theme }) => theme.spacing(5)};
  background: ${({ theme }) => theme.palette.common.white};
  display: table-row;
  ${({ $sticky: $sticky }) =>
    $sticky &&
    `
z-index: 2;
position: sticky;
top: 0;
`}
`;

export const StyledTableFooterRow = styled('footer', { shouldForwardProp })<{ $sticky?: boolean }>`
  height: ${({ theme }) => theme.spacing(5)};
  display: table-row;

  ${({ $sticky }) =>
    $sticky &&
    `
z-index: 2;
position: sticky;
bottom: 0;
`}
`;

export const StyledTable = styled.section`
  display: table;
  position: relative;

  & ${StyledTableRow} {
    z-index: 1;
    transform: scale(1);
    position: relative;
    transition: ${({ theme }) => theme.transitions.create(['box-shadow'])};
  }

  & ${StyledTableRow}:hover {
    z-index: 3;
    box-shadow: ${({ theme }) => theme.shadows[3]};
  }

  & [data-stripe] > *:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  & [data-stripe] > *:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  & [data-stripe]:nth-child(2n + 2) > * {
    background-color: ${({ theme }) => theme.palette.secondary.light};
  }

  & [data-stripe]:nth-child(2n + 1) > * {
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`;
