import { IAnalyticServiceType } from 'src/types';
import { PageTitle, TitleButtons } from 'src/components/shared/Page';
import { ServiceType, analyticsDetailsQuery } from 'src/components/AnalyticService';

import Service from './Service';

export default function AnalyticServicesPage() {
  const [{ type }, setQueryParams] = analyticsDetailsQuery.useParams();

  const handleTypeChange = (type: IAnalyticServiceType) => setQueryParams({ type });

  return (
    <>
      <PageTitle title={'Analytics'}>
        <TitleButtons align="left">
          <ServiceType type={type} onChange={handleTypeChange} />
        </TitleButtons>
      </PageTitle>
      <Service type={type} />
    </>
  );
}
