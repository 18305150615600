import { Publisher } from 'src/libs/Observer';
import { ALERT_CHANNEL } from 'src/config/channels';
import { IAlertAction, IAlertRaw } from 'src/types/alert';

class AlertService extends Publisher<IAlertAction> {
  #channel = new BroadcastChannel(ALERT_CHANNEL);

  constructor() {
    super();

    this.#channel.addEventListener('message', ({ data }: MessageEvent<IAlertAction>) => this.publish(data));
  }

  public update(alert: IAlertRaw) {
    this.#channel.postMessage({ action: 'UPDATE', alert });
  }
}

export default new AlertService();
