import { HTMLAttributes, PropsWithChildren, forwardRef } from 'react';
import Typography from '@mui/material/Typography';

import { StyledCardContent, StyledCard, StyledCardFooter, StyledCardHead, StyledCardRow } from './styles';

export interface ICardProps {
  isAccent?: boolean;
  isPointer?: boolean;
  aside?: JSX.Element;
}

export const Card = forwardRef<HTMLDivElement, PropsWithChildren<HTMLAttributes<HTMLElement>> & ICardProps>(
  ({ isAccent, isPointer, aside, children, ...props }, ref) => (
    <StyledCard {...props} ref={ref} variant={isAccent ? 'accent' : 'elevation'} $pointer={isPointer}>
      <StyledCardContent>{children}</StyledCardContent>
      {aside}
    </StyledCard>
  ),
);

Card.displayName = 'Card';

export interface ICardRowProps {
  prefix?: Maybe<string | number | JSX.Element>;
  name?: Maybe<string | number | JSX.Element>;
  value?: Maybe<string | number | JSX.Element>;
}

export const CardHead = ({ prefix, name, value }: ICardRowProps) => (
  <StyledCardHead>
    {name != null && (
      <Typography variant="h3" sx={{ textTransform: 'initial' }}>
        {prefix != null && <>{prefix}: </>}
        {name}
      </Typography>
    )}
    {value != null && <Typography variant="body1">{value}</Typography>}
  </StyledCardHead>
);

export const CardRow = ({ name, value }: ICardRowProps) => (
  <StyledCardRow>
    {name != null && <Typography variant="h4">{name}</Typography>}
    {value != null && <Typography variant="body1">{value}</Typography>}
  </StyledCardRow>
);

export const CardFooter = ({ children }: PropsWithChildren) => <StyledCardFooter>{children}</StyledCardFooter>;
