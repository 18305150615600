import { ThemedComponent } from '@mui/material/styles';
import { alpha } from '@mui/system/colorManipulator';

export const MuiButtonBase: ThemedComponent<'MuiButtonBase'> = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      textTransform: 'uppercase',
      '&.Mui-disabled': {
        opacity: 0.5,
      },
    },
  },
};

export const MuiButtonGroup: ThemedComponent<'MuiButtonGroup'> = {
  defaultProps: {
    size: 'large',
    disableRipple: true,
  },
  variants: [
    {
      props: { variant: 'popper' },
      style: ({ theme }) => ({
        color: theme.palette.text.secondary,
        background: theme.palette.secondary.light,
        '& > *:not(:first-child)': {
          borderLeft: `2px solid ${alpha(theme.palette.text.secondary, 0.2)}`,
        },
      }),
    },
  ],
};

export const MuiButton: ThemedComponent<'MuiButton'> = {
  defaultProps: {
    size: 'large',
    disableRipple: true,
    variant: 'outlined',
  },
  variants: [
    {
      props: { variant: 'popper' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.secondary.light,
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: theme.palette.secondary.light,
          boxShadow: 'none',
          color: theme.palette.primary.main,
        },
        '&:active': {
          backgroundColor: theme.palette.secondary.light,
          boxShadow: 'none',
        },
      }),
    },
    {
      props: { variant: 'text' },
      style: ({ theme }) => ({
        padding: 0,
        minWidth: 0,
        borderRadius: 0,
        height: 'auto',
        boxShadow: 'none',
        fontSize: 'inherit',
        lineHeight: 'initial',
        backgroundColor: 'transparent',
        '&:hover': {
          fontWeight: theme.typography.fontWeightBolder,
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
        '&:active': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }),
    },
    {
      props: { variant: 'contained' },
      style: ({ theme }) => ({
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
      }),
    },
    {
      props: { variant: 'outlined' },
      style: ({ theme }) => ({
        borderColor: theme.palette.primary.main,
      }),
    },
  ],
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: 14,
      lineHeight: 'normal',
      boxShadow: theme.shadows[3],
      fontWeight: theme.typography.fontWeightBold,
      transition: theme.transitions.create(['color', 'background-color', 'box-shadow']),
      '&:hover': {
        boxShadow: `inset ${theme.shadows[3]}`,
      },
      '&:active': {
        boxShadow: `inset ${theme.shadows[3]}`,
        color: theme.palette.text.primary,
      },
      '&.Mui-disabled': {
        opacity: 0.5,
      },
    }),
    sizeLarge: ({ theme }) => ({
      height: theme.spacing(5),
      minWidth: theme.spacing(22),
      padding: theme.spacing(1.5),
    }),
    sizeMedium: ({ theme }) => ({
      height: theme.spacing(4.5),
      minWidth: theme.spacing(19),
      padding: theme.spacing(1),
    }),
    sizeSmall: ({ theme }) => ({
      height: theme.spacing(4),
      minWidth: theme.spacing(16),
      padding: theme.spacing(0.5),
    }),
  },
};

export const MuiIconButton: ThemedComponent<'MuiIconButton'> = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      '.Mui-focused &': {
        color: 'inherit',
      },
    },
  },
};

export const MuiToggleButton: ThemedComponent<'MuiToggleButton'> = {
  defaultProps: {
    size: 'large',
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: 14,
      lineHeight: 'normal',
      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightBold,
      '&:hover': {
        fontWeight: theme.typography.fontWeightBold,
      },
      '&:active': {
        fontWeight: theme.typography.fontWeightBold,
      },
      '&.Mui-disabled': {
        opacity: 0.5,
      },
    }),
    sizeLarge: ({ theme }) => ({
      height: theme.spacing(5),
      padding: theme.spacing(1.5),
    }),
    sizeMedium: ({ theme }) => ({
      height: theme.spacing(4.5),
      padding: theme.spacing(1),
    }),
    sizeSmall: ({ theme }) => ({
      height: theme.spacing(4),
      padding: theme.spacing(0.5),
    }),
  },
};

export const MuiToggleButtonGroup: ThemedComponent<'MuiToggleButtonGroup'> = {
  defaultProps: {
    size: 'large',
  },
};
