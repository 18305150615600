import { NavLink } from 'react-router-dom';
import Link from '@mui/material/Link';

import { IBrandListItem } from 'src/types';
import { TableRow, TableCell, TableLeadCell } from 'src/components/shared/Table';
import { BrandStatus } from 'src/components/BrandDetails';
import dateFormatter from 'src/utils/dateFormatter';

const formatDate = dateFormatter();

export interface IBrandRowProps {
  brand: IBrandListItem;
}

export const BrandRow = ({ brand }: IBrandRowProps) => (
  <TableRow>
    <TableLeadCell />
    <TableCell>{brand.id}</TableCell>
    <TableCell>{formatDate(brand.created_at)}</TableCell>
    <TableCell>
      <Link component={NavLink} to={`/system/company/${brand.id}`}>
        {brand.name}
      </Link>
    </TableCell>
    <TableCell>{brand.email}</TableCell>
    <TableCell>
      <BrandStatus status={brand.status} />
    </TableCell>
  </TableRow>
);
