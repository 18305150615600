import { ID, IFilter } from './common';

export const AnomalyStatus = {
  UNREVIEWED: 'UNREVIEWED' as const,
  ACCEPTED: 'ACCEPTED' as const,
  REJECTED: 'REJECTED' as const,
} as const;

export type IAnomalyStatus = ValueOf<typeof AnomalyStatus>;

export const AnomalyType = {
  ACTUAL_WEIGHT: 'ACTUAL_WEIGHT' as const,
  DIM_WEIGHT: 'DIM_WEIGHT' as const,
  ZONE: 'ZONE' as const,
  TOTAL_COST: 'TOTAL_COST' as const,
  TOTAL_ACCESSORIALS_COST: 'TOTAL_ACCESSORIALS_COST' as const,
} as const;

export type IAnomalyType = ValueOf<typeof AnomalyType>;

export type IAnomalyOrder = {
  anomaly_id: ID;
  order_number: string;
  anomaly_status: IAnomalyStatus;
  ship_date: string;
  invoice_name: string;
  anomaly_name: string;
};

export const AnomalyOrderSortBy = {
  ORDER_NUMBER: 'ORDER_NUMBER' as const,
  ANOMALY_STATUS: 'ANOMALY_STATUS' as const,
  SHIP_DATE: 'SHIP_DATE' as const,
  INVOICE_NAME: 'INVOICE_NAME' as const,
  ANOMALY_TYPE: 'ANOMALY_TYPE' as const,
} as const;

export type IAnomalyOrderSortBy = ValueOf<typeof AnomalyOrderSortBy>;

export type IAnomalyOrdersFiltersConfig = {
  [K in keyof IAnomalyOrdersFilters]: Omit<IAnomalyOrdersFilters[K], 'options'>;
};

export type IAnomalyOrdersFilters = {
  ANOMALY_STATUS: IFilter<'multi_select'>;
  INVOICE_NAME: IFilter<'single_select'>;
  ANOMALY_TYPE: IFilter<'single_select'>;
};

export const AnomalyOrdersFiltersConfig: IAnomalyOrdersFiltersConfig = {
  ANOMALY_STATUS: { type: 'multi_select' },
  INVOICE_NAME: { type: 'single_select' },
  ANOMALY_TYPE: { type: 'single_select' },
};

export type IAnomaly = {
  anomaly_id: ID;
  order_number: string;
  actual_weight: number;
  dim_weight: number;
  zone: number;
  total_accessorials_cost: number;
  total_cost: number;
  distance: number;
  actual_weight_unit: string;
  dim_weight_unit: string;
  distance_unit: string;
  anomaly_type: IAnomalyType;
};

export type IAnomalyComparable = Omit<IAnomaly, 'anomaly_type' | 'anomaly_id'>;

export type IAnomalyTotal = {
  sku_count: number;
  anomaly_status: IAnomalyStatus;
  carrier: string;
  service_level: string;
};

export type IAnomalyDetails = {
  anomaly: IAnomaly;
  comparison: IAnomalyComparable[];
  total: IAnomalyTotal;
};

export type IAnomalyTypeChartItem = {
  value: number;
  name: string;
};

export type IAnomalyStatusCount = {
  status: IAnomalyStatus;
  value: number;
};

export type IAnomalyStatusTotal = {
  statuses: IAnomalyStatusCount[];
  total: number;
};

export type IAnomalyCountChartItem = {
  date: string;
  value: number;
};

export type IAnomaliesAggregation = {
  total_type_chart: IAnomalyTypeChartItem[];
  total_count_chart: IAnomalyCountChartItem[];
  total_status: IAnomalyStatusTotal;
};
