import { ID } from './common';

export const RateCardStatus = {
  APPROVED: 'APPROVED' as const,
  PENDING: 'PENDING' as const,
  IN_USE: 'IN USE' as const,
  EXPIRED: 'EXPIRED' as const,
} as const;

export type IRateCardStatus = ValueOf<typeof RateCardStatus>;

export const RateCardOrder = {
  B2B: 'B2B' as const,
  B2C: 'B2C' as const,
} as const;

export type IRateCardOrder = ValueOf<typeof RateCardOrder>;

export type IRateCardListItem = {
  id: number;
  name: string;
  effective_from: string;
  effective_to: string;
  logistics_provider: {
    id: ID;
    name: string;
  };
  logistics_node: {
    id: ID;
    name: string;
  };
  status: IRateCardStatus;
  created_at: string;
};

export type IRateCard = {
  id: number;
  name: string;
  status: IRateCardStatus;
  warehouse_id: number;
  logistics_provider_id: number;
  file_upload_id?: number;
  order_type: IRateCardOrder;
  rates: IPickAndPackRate[];
};

export type IRateCardDetails = {
  name: string;
  status: IRateCardStatus;
  brand: string;
  file_upload_id?: {
    id: number;
    name: string;
  };
  warehouse_id: {
    id: number;
    name: string;
  };
  logistics_provider_id: {
    id: number;
    name: string;
  };
  order_type: IRateCardOrder;
  rates: IPickAndPackRate[];
};

export type IRateCardEditable = {
  name: string;
  warehouse_id: number;
  logistics_provider_id: number;
  file_upload_id?: number;
  order_type: IRateCardOrder;
  rates: IPickAndPackRate[];
};

export type IRateCardConfirmationStatus = {
  rate_card_id: number;
  status: IRateCardStatus;
};

export type IPickAndPackRate = {
  effective_from: string;
  effective_to: string;
  rate_type: string;
  rate_details: IPickAndPackRateFee[];
};

export type IPickAndPackRateFee = {
  description: string;
  data_type: string;
  value: number;
};

export const RateCardSortBy = {
  CONTRACT_NAME: 'contract_name' as const,
  STATUS_NAME: 'status_name' as const,
} as const;

export type IRateCardSortBy = ValueOf<typeof RateCardSortBy>;

export const RateCardFileStatus = {
  FAILED: 'FAILED' as const,
  LANDING: 'LANDING' as const,
  INGESTING: 'INGESTING' as const,
  COMPLETE: 'COMPLETE' as const,
} as const;

export type IRateCardFileStatus = ValueOf<typeof RateCardFileStatus>;

export type IRateCardFile = {
  id: number;
  filename: string;
  delete_enabled: boolean;
  status: IRateCardFileStatus;
};
