import { StyledSvgIcon } from './styles';
import withIconRef from './withIconRef';

export const DashboardIcon = withIconRef((props, ref) => (
  <StyledSvgIcon {...props} ref={ref} width="24" height="24" viewBox="0 0 24 24">
    <path d="m23 0h-9c-0.5523 0-1 0.44772-1 1v3c0 0.55228 0.4477 1 1 1h9c0.5523 0 1-0.44772 1-1v-3c0-0.55228-0.4477-1-1-1z" />
    <path d="m10 19h-9c-0.55228 0-1 0.4477-1 1v3c0 0.5523 0.44772 1 1 1h9c0.5523 0 1-0.4477 1-1v-3c0-0.5523-0.4477-1-1-1z" />
    <path d="m10 0h-9c-0.55228 0-1 0.44772-1 1v15c0 0.5523 0.44772 1 1 1h9c0.5523 0 1-0.4477 1-1v-15c0-0.55228-0.4477-1-1-1z" />
    <path d="m23 7h-9c-0.5523 0-1 0.44772-1 1v15c0 0.5523 0.4477 1 1 1h9c0.5523 0 1-0.4477 1-1v-15c0-0.55228-0.4477-1-1-1z" />
  </StyledSvgIcon>
));
