import { useEffect, useMemo, useState } from 'react';

import {
  ID,
  ISort,
  IFilter,
  IInvoiceErrorSortBy,
  IInvoiceErrorsFilters,
  IInvoiceErrorsExportFilters,
  IInvoiceErrorTotal,
} from 'src/types';
import pipe from 'src/libs/pipe';
import { sortData } from 'src/effects/useDataSorting';
import { filterData } from 'src/effects/useDataFiltering';
import { IQueryParams } from 'src/components/shared/QueryFilter';
import { errorsFiltersQuery, errorsSortingQuery } from 'src/components/InvoiceReconcile';

export const useErrorsSorting = () => {
  const [params, changeSorting] = errorsSortingQuery.useParams();

  return {
    params,
    changeSorting,
  };
};

export const useErrorsFilters = (errors: MaybeNull<IInvoiceErrorTotal>) => {
  const filterConfig = useMemo(() => makeErrorsFilterConfig(errors), [errors]);
  const filterQuery = errorsFiltersQuery.useFilter(filterConfig);

  return filterQuery;
};

export interface IErrorsDataProcessorParams {
  data: Maybe<IInvoiceErrorTotal>;
  filterConfig: Maybe<IInvoiceErrorsFilters>;
  filterParams: Maybe<IQueryParams<IInvoiceErrorsFilters>>;
  sortParams: ISort<IInvoiceErrorSortBy>;
}

export const useErrorsDataProcessor = ({
  data,
  filterConfig,
  filterParams,
  sortParams,
}: IErrorsDataProcessorParams) => {
  const [processedData, setProcessedData] = useState<MaybeNull<IInvoiceErrorTotal>>(null);

  useEffect(() => {
    if (!data) return setProcessedData(null);

    const processedData = pipe(data.data)
      .chain((data) => filterData(data, filterConfig, filterParams))
      .chain((data) => sortData(data, sortParams))
      .value();

    if (!processedData) return setProcessedData(null);

    setProcessedData({
      ...data,
      data: processedData,
    });
  }, [data, filterConfig, filterParams, sortParams]);

  return processedData;
};

export const makeErrorTypeFilterConfig = (
  errorTotal: MaybeNull<IInvoiceErrorTotal>,
): MaybeNull<IFilter<'multi_select'>> => {
  if (!errorTotal) return null;

  const values = [
    ...errorTotal.data.reduce((table, { error_code, error_name }) => {
      error_code.forEach((code, index) => table.set(code, error_name[index]));

      return table;
    }, new Map<ID, string>()),
  ].map(([key, value]) => ({ key, value }));

  return {
    type: 'multi_select',
    value: null,
    options: {
      label: 'Error type',
      values,
    },
  };
};

export const makeErrorsFilterConfig = (errorTotal: MaybeNull<IInvoiceErrorTotal>): MaybeNull<IInvoiceErrorsFilters> => {
  if (!errorTotal) return null;

  const services = [...errorTotal.data.reduce((set, { service }) => set.add(service), new Set<string>())];
  const servicesValues = services.map((service) => ({ key: service, value: service }));

  const errorValues = [
    ...errorTotal.data.reduce((table, { error_code, error_name }) => {
      error_code.forEach((code, index) => table.set(code, error_name[index]));

      return table;
    }, new Map<ID, string>()),
  ].map(([key, value]) => ({ key, value }));

  return {
    service: {
      type: 'single_select',
      value: null,
      options: {
        label: 'Service',
        values: servicesValues,
      },
    },
    error_code: {
      type: 'multi_select',
      value: null,
      options: {
        label: 'Error type',
        values: errorValues,
      },
    },
    unfavorable_errors_count: {
      type: 'criterion_number',
      options: {
        label: 'Errors (#)',
        format: 'number',
        placeholder: '',
        criterions: [
          {
            key: 'gt',
            value: 'Greater than',
          },
          {
            key: 'lt',
            value: 'Less than',
          },
          {
            key: 'eq',
            value: 'Equal',
          },
        ],
      },
      value: null,
    },
    unfavorable_errors_dollars: {
      type: 'criterion_number',
      options: {
        label: 'Errors ($)',
        format: 'currency',
        placeholder: 'Dollars $',
        criterions: [
          {
            key: 'gt',
            value: 'greater than',
          },
          {
            key: 'lt',
            value: 'less than',
          },
          {
            key: 'eq',
            value: 'equal',
          },
        ],
      },
      value: null,
    },
  };
};

const ERRORS_FILTERS_MAPPING = new Map<keyof IInvoiceErrorsFilters, keyof IInvoiceErrorsExportFilters>([
  ['error_code', 'ERROR_CODE'],
  ['service', 'SERVICE'],
  ['unfavorable_errors_count', 'UNFAVORABLE_COUNT'],
  ['unfavorable_errors_dollars', 'UNFAVORABLE_DOLLARS'],
]);

export const mapErrorsFiltersToExport = (filters: IQueryParams<IInvoiceErrorsFilters>) => {
  const filterEntries = Object.entries(filters).map(([key, value]) => [
    ERRORS_FILTERS_MAPPING.get(key as keyof IInvoiceErrorsFilters),
    value,
  ]);

  return Object.fromEntries(filterEntries);
};
