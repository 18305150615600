import { ThemedComponent } from '@mui/material/styles';
import { alpha } from '@mui/system/colorManipulator';

export const MuiDivider: ThemedComponent<'MuiDivider'> = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderBottom: `2px solid ${alpha(theme.palette.text.secondary, 0.2)}`,
    }),
  },
};
