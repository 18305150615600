import request, { serializeQueryJson } from './requestPrivate';

export const getInvoiceReportId = (
  {
    type,
    name,
    format,
    filters,
  }: {
    type: 'orders' | 'error';
    name: string;
    format?: 'xlsx' | 'csv';
    filters?: object;
  },
  useAlerts = true,
) => {
  const filtersQuery = JSON.stringify(filters, serializeQueryJson);

  return request.post<string>('/export/', null, {
    page_type: type,
    invoice_id: name,
    export_format: format,
    filtering_query_json: filtersQuery,
    use_alerts: useAlerts,
  });
};
