import {
  BrandSortBy,
  ID,
  IRateCardConfirmationStatus,
  IRateCardDetails,
  IRateCardEditable,
  IRateCardFile,
  IRateCardListItem,
  IRateCardSortBy,
  ISortDirection,
  SortDirection,
} from 'src/types';

import request from './requestPrivate';

export const getRateCards = (params?: { order?: ISortDirection; filter?: MaybeNull<string>; by?: IRateCardSortBy }) => {
  const requestParams = {
    sort_by: params?.by ?? BrandSortBy.BRAND_NAME,
    sort: params?.order ?? SortDirection.DESC,
    name_filter: params?.filter,
  };

  return request.get<IRateCardListItem[]>('/rate-card', requestParams);
};

export const createRateCard = (rateCard: IRateCardEditable) =>
  request.post<number, IRateCardEditable>('/rate-card', rateCard);

export const updateRateCard = (rateCardId: ID, rateCard: IRateCardEditable) =>
  request.put<null, IRateCardEditable>(`/rate-card/${rateCardId}`, rateCard);

export const approveRateCard = (rateCardId: ID) =>
  request.put<IRateCardConfirmationStatus>(`/rate-card/approve/${rateCardId}`);

export const approveRateCards = (params?: { filter?: MaybeNull<string> }) => {
  const requestParams = {
    name_filter: params?.filter,
  };

  return request.put<boolean>('/rate-card/approve', requestParams);
};

export const getRateCard = (rateCardId: ID) => request.get<IRateCardDetails>(`/rate-card/${rateCardId}`);

export const getRateCardFiles = (id: ID) => request.get<IRateCardFile[]>(`/upload/${id}`);
