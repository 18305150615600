import { ICredit } from 'src/types';
import { TableRow, TableCell, IRenderAction, TableCellAction } from 'src/components/shared/Table';
import { CreditRequestStatusChip } from 'src/components/Status';
import currencyFormatter from 'src/utils/currencyFormatter';
import numberFormatter from 'src/utils/numberFormatter';
import dateFormatter from 'src/utils/dateFormatter';

const formatDate = dateFormatter();
const formatUSD = currencyFormatter();
const formatNumber = numberFormatter();

export type ICreditRequestsRowActionProps = Omit<ICreditRequestsRowProps, 'renderAction' | 'renderLink'>;
export type ICreditRequestsLink = () => JSX.Element;

export interface ICreditRequestsRowProps {
  credit: ICredit;
  renderAction: IRenderAction;
  renderLink: ICreditRequestsLink;
}

export const CreditRequestsRow = ({ credit, renderAction, renderLink }: ICreditRequestsRowProps) => (
  <TableRow>
    <TableCellAction renderAction={renderAction} />
    <TableCell>{credit.credit_request_number}</TableCell>
    <TableCell>{credit.invoice_number}</TableCell>
    <TableCell>
      <CreditRequestStatusChip status={credit.status} />
    </TableCell>
    <TableCell>{formatDate(credit.submit_date)}</TableCell>
    <TableCell>{formatNumber(credit.errors_count)}</TableCell>
    <TableCell>{formatUSD(credit.errors_dollars)}</TableCell>
    <TableCell>{renderLink()}</TableCell>
  </TableRow>
);
