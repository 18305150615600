import { ThemedComponent } from '@mui/material/styles';

export const MuiTypography: ThemedComponent<'MuiTypography'> = {
  variants: [
    {
      props: { variant: 'filled' },
      style: ({ theme }) => ({
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'transparent',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.secondary.light,
        boxShadow: theme.shadows[3],
        color: theme.palette.text.secondary,
        '&:hover': {
          backgroundColor: theme.palette.secondary.light,
          boxShadow: `inset ${theme.shadows[3]}`,
        },
        '&.Mui-focused': {
          borderColor: theme.palette.primary.main,
          boxShadow: `inset ${theme.shadows[3]}`,
        },
      }),
    },
  ],
};
