import { PropsWithChildren } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import { ConfirmResetPasswordSchema, IConfirmResetPasswordSchema } from 'src/schemas';
import { FormFields, FormInput, FormPassword, FormText } from 'src/components/shared/Form';

import { StyledAuthError, StyledForm, StyledFormFooter } from './styles';

export interface IConfirmResetPasswordProps {
  error?: Maybe<string>;
  loading: boolean;
  onSubmit: (auth: IConfirmResetPasswordSchema) => void;
  onResend: () => void;
}

export const ResetPasswordForm = ({
  error,
  loading,
  onSubmit,
  onResend,
}: PropsWithChildren<IConfirmResetPasswordProps>) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IConfirmResetPasswordSchema>({
    resolver: yupResolver(ConfirmResetPasswordSchema),
  });

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <FormFields>
        <FormInput>
          <FormText
            name="confirmation_code"
            autoComplete="one-time-code"
            label="Code *"
            placeholder="Enter code"
            control={control}
            errorMessage={errors?.confirmation_code?.message}
          />
        </FormInput>
        <FormInput>
          <FormPassword
            name="password"
            autoComplete="new-password"
            label="New password *"
            placeholder="Enter new password"
            control={control}
            errorMessage={errors?.password?.message}
          />
        </FormInput>
        <FormInput>
          <FormPassword
            name="confirm_password"
            autoComplete="new-password"
            label="Confirm password *"
            placeholder="Confirm new password"
            control={control}
            errorMessage={errors?.confirm_password?.message}
          />
        </FormInput>
      </FormFields>
      <StyledFormFooter>
        {!!error && <StyledAuthError>{error}</StyledAuthError>}
        <Button variant="contained" type="submit" disabled={loading}>
          Submit
        </Button>
        <Button component={Link} onClick={onResend} disabled={loading}>
          Resend Code
        </Button>
      </StyledFormFooter>
    </StyledForm>
  );
};
