import { Fragment, memo, useMemo } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import shouldForwardProp from 'src/utils/shouldForwardProp';
import { UserActiveIconIcon, UserInactiveIconIcon } from 'src/components/Icons';

export interface IUserStatusProps {
  status: boolean;
}

const StyledUserStatus = styled('span', { shouldForwardProp })<{ $color: string }>`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  color: ${({ $color }) => $color};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

const UserStatus = memo(({ status }: IUserStatusProps) => {
  const { palette } = useTheme();

  const labels = useMemo(
    () => ({
      true: 'Active',
      false: 'Inactive',
    }),
    [],
  );

  const icons = useMemo(
    () => ({
      true: UserActiveIconIcon,
      false: UserInactiveIconIcon,
    }),
    [],
  );

  const colors = useMemo(
    () => ({
      true: palette.success.main,
      false: palette.text.secondary,
    }),
    [],
  );

  const label = labels[`${status}`] ?? 'Unknown';
  const color = colors[`${status}`] ?? palette.text.disabled;
  const Icon = icons[`${status}`] ?? Fragment;

  return (
    <StyledUserStatus $color={color}>
      <Icon /> {label}
    </StyledUserStatus>
  );
});

UserStatus.displayName = 'UserStatus';

export default UserStatus;
