import { PropsWithChildren } from 'react';
import { IntercomProvider as IntercomAppProvider } from 'react-use-intercom';

import { INTERCOM_APP_ID } from 'src/config/env';

const IntercomProvider = ({ children }: PropsWithChildren) => (
  <IntercomAppProvider appId={INTERCOM_APP_ID}>{children}</IntercomAppProvider>
);

export default IntercomProvider;
