import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

import { Loading } from 'src/components/shared/Page';
import { MessageAuthorized } from 'src/components/Auth';
import { authService } from 'src/services/AuthService';
import { AuthChallengeType, IAuthChallenge } from 'src/types';
import useAuthenticator from 'src/effects/useAuthenticator';

import Login from './Step/Login';
import VerifyEmail from './Step/VerifyEmail';
import ResetPassword from './Step/ResetPassword';
import ForgotPassword from './Step/ForgotPassword';

export default function LoginPage() {
  const navigate = useNavigate();
  const { isAuthenticated, signOut, signIn } = useAuthenticator();
  const [challenge, setChallenge] = useState<IAuthChallenge>({
    type: 'LOGIN',
    payload: null,
  });

  useEffect(() => {
    if (challenge.type === AuthChallengeType.SUCCESS) {
      signIn(
        {
          id: challenge.payload.id_token,
          refresh: challenge.payload.refresh_token,
        },
        () => navigate('/'),
      );
    }
  }, [challenge.type]);

  useEffect(
    () =>
      authService.subscribe((action) => {
        if (action === 'update') navigate('/');
      }),
    [],
  );

  if (isAuthenticated) {
    return (
      <MessageAuthorized>
        <Button variant="contained" onClick={signOut}>
          Log Out
        </Button>
      </MessageAuthorized>
    );
  }

  switch (challenge.type) {
    case AuthChallengeType.LOGIN:
      return <Login challenge={challenge} onChallenge={setChallenge} />;
    case AuthChallengeType.VERIFY_EMAIL:
      return <VerifyEmail challenge={challenge} onChallenge={setChallenge} />;
    case AuthChallengeType.RESET_PASSWORD:
      return <ResetPassword challenge={challenge} onChallenge={setChallenge} />;
    case AuthChallengeType.FORGOT_PASSWORD:
      return <ForgotPassword challenge={challenge} onChallenge={setChallenge} />;
    default:
      return <Loading />;
  }
}
