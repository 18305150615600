import {
  IBrand,
  IBrandSortBy,
  IBrandStatus,
  INewBrand,
  IReportType,
  ISortDirection,
  ReportType,
  BrandSortBy,
  SortDirection,
} from 'src/types';

import request, { IRequest } from './requestPrivate';

export const getBrands = (params?: {
  statuses?: IBrandStatus[];
  sort?: ISortDirection;
  filter?: MaybeNull<string>;
  by?: IBrandSortBy;
}): IRequest<IBrand[]> => {
  const requestParams = {
    status: params?.statuses?.join(',') ?? [],
    sort_by: params?.by ?? BrandSortBy.BRAND_NAME,
    sort: params?.sort ?? SortDirection.DESC,
    name_hint: params?.filter,
  };

  return request.get<IBrand[]>('/brand', requestParams);
};

export const createBrand = (brand: INewBrand) => request.post<number>('/brand', brand);

export const getBrand = (brandId: number) => request.get<IBrand>(`/brand/${brandId}`);

export const getReportToken = (report: IReportType) =>
  request.get<string>(`/brand/reporttoken`, { report_key: report });

export const getTokenHome = () => getReportToken(ReportType.HOME);

export const getTokenOther = () => getReportToken(ReportType.OTHER);

export const getTokenSummary = () => getReportToken(ReportType.SUMMARY);

export const getTokenPerformance = () => getReportToken(ReportType.PERFORMANCE_MANAGEMENT);

export const getTokenPickAndPack = () => getReportToken(ReportType.PICK_AND_PACK);
