import { memo } from 'react';
import Typography from '@mui/material/Typography';

import { IAnomalyStatusTotal } from 'src/types';
import numberFormatter from 'src/utils/numberFormatter';
import { getAnomalyStatusLabel } from 'src/components/Status';

import { StyledTotalStatus, StyledTotalStatusValue, StyledTotalStatuses } from './styles';

const formatNumber = numberFormatter();

export interface IAnomalyStatusTotalsProps {
  totals: IAnomalyStatusTotal;
}

const AnomalyStatusTotals = ({ totals }: IAnomalyStatusTotalsProps) => (
  <StyledTotalStatuses>
    {totals.statuses.map((item) => (
      <StyledTotalStatus key={`anomaly-total-status-${item.status}`} variant="inset">
        <Typography variant="h4">{getAnomalyStatusLabel(item.status)}</Typography>
        <StyledTotalStatusValue>{formatNumber(item.value)}</StyledTotalStatusValue>
      </StyledTotalStatus>
    ))}
    <StyledTotalStatus variant="inset">
      <Typography variant="h4">Total</Typography>
      <StyledTotalStatusValue>{totals.total}</StyledTotalStatusValue>
    </StyledTotalStatus>
  </StyledTotalStatuses>
);

export default memo(AnomalyStatusTotals);
