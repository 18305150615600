import Button from '@mui/material/Button';

import { CreditStep, CreditStepActions, CreditStepAction } from 'src/components/InvoiceReconcile';

import { useCreditStepsContext } from './CreditStepsContext';

const TagsStep = () => {
  const { stepControl } = useCreditStepsContext();

  const handlePrevious = () => stepControl.goToErrors();
  const handleNext = () => stepControl.goToEmail();

  return (
    <CreditStep>
      <div>Coming soon</div>
      <CreditStepActions>
        <CreditStepAction>
          <Button variant="outlined" onClick={handlePrevious}>
            Previous
          </Button>
        </CreditStepAction>
        <CreditStepAction>
          <Button variant="contained" onClick={handleNext}>
            Next
          </Button>
        </CreditStepAction>
      </CreditStepActions>
    </CreditStep>
  );
};

export default TagsStep;
