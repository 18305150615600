import { memo } from 'react';

import { makeErrorNote, makeInfoNote } from 'src/components/InvoiceReconcile/Export';
import ExportButton from 'src/components/shared/Buttons/ExportButton';
import notification from 'src/services/NotificationService';
import makeRequest from 'src/effects/makeRequest';
import * as apiRoot from 'src/api/root';

import { useReconciliationContext } from './ReconciliationContext';

const useOrdersReportRequest = makeRequest(apiRoot.getInvoiceReportId);

const InvoiceOrdersExport = () => {
  const { invoiceName, ordersFilters } = useReconciliationContext();
  const ordersReportRequest = useOrdersReportRequest(null);

  const handleOrderExport = () => {
    ordersReportRequest.make({ name: invoiceName, type: 'orders', filters: ordersFilters.params });
    ordersReportRequest.onFulfill(() => {
      const infoNote = makeInfoNote();

      notification.publish(infoNote);
    });
    ordersReportRequest.onReject(() => {
      const errorNote = makeErrorNote();

      notification.publish(errorNote);
    });
  };

  return <ExportButton isBusy={ordersReportRequest.loading} onClick={handleOrderExport} />;
};

export default memo(InvoiceOrdersExport);
