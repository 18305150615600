import { memo } from 'react';

import { IRateCardListItem, IRateCardSortBy, ISort } from 'src/types';
import { Loading } from 'src/components/shared/Page';
import { TableBody } from 'src/components/shared/Table';
import Message from 'src/components/shared/Message';
import ListFilter from 'src/components/shared/ListFilter';

import { IRateCardHeadAction } from './RateCardHeaders';
import { IRateCardRowAction } from './RateCardRow';

export type IRateCardListParams = {
  filter: MaybeNull<string>;
  by: ISort<IRateCardSortBy>['by'];
  order: ISort<IRateCardSortBy>['order'];
};

export type IRateCardListActions = {
  changeSorting: (sort: ISort<IRateCardSortBy>) => void;
  changeFilter: (filter: MaybeNull<string>) => void;
};

export interface IRateCardListProps {
  loading: boolean;
  params: IRateCardListParams;
  actions: IRateCardListActions;
  payload: IRateCardListItem[];
  rowAction: IRateCardRowAction;
  headAction: IRateCardHeadAction;
}

const RateCardList = ({
  payload,
  loading,
  params,
  actions,
  rowAction: RowAction,
  headAction: HeadAction,
}: IRateCardListProps) => (
  <>
    <ListFilter placeholder="Filter By Name" filter={params.filter} onFilterChange={actions.changeFilter} />
    <TableBody>
      <HeadAction sort={{ order: params.order, by: params.by }} onSort={actions.changeSorting} />
      {!loading && payload && (
        <>
          {payload.map((rateCard) => (
            <RowAction key={`rate-card-row-${rateCard.id}`} rateCard={rateCard} />
          ))}
        </>
      )}
    </TableBody>
    {loading ? <Loading /> : !payload?.length && <Message>No rate cards</Message>}
  </>
);

export default memo(RateCardList);
