/* eslint-disable @typescript-eslint/no-explicit-any */
// INFO: upcast to any is needed for internal reasons, public interface is strict

import { useCallback, useEffect, useState } from 'react';

import { abortPromise, IAbortPromise } from 'src/utils/promiseUtils';
import notification from 'src/services/NotificationService';
import { IRequest } from 'src/libs/Request';

type IMakeRequest = <
  CB extends (...args: any[]) => IRequest<any, any>,
  CE extends Parameters<NonNullable<Parameters<ReturnType<ReturnType<CB>['make']>['catch']>[0]>>[0],
  EF extends (
    data: Awaited<ReturnType<ReturnType<CB>['make']>>['data'],
  ) => Awaited<ReturnType<ReturnType<CB>['make']>>['data'],
>(
  callback: CB,
  options?: { effect?: EF; onError?: <T = unknown>(error: T) => void; cancellable?: boolean; silent?: boolean },
) => <
  TData extends MaybeNull<Awaited<ReturnType<ReturnType<CB>['make']>>['data']>,
  CData = null extends TData
    ? MaybeNull<Awaited<ReturnType<ReturnType<CB>['make']>>['data']>
    : Awaited<ReturnType<ReturnType<CB>['make']>>['data'],
>(
  data: TData,
  loading?: boolean,
) => {
  data: CData;
  loading: boolean;
  error: MaybeNull<CE>;
  cleanup: () => void;
  make: (...args: Parameters<CB>) => void;
  onFinally: (callback: () => void) => void;
  onReject: (callback: (error: CE) => void) => void;
  onFulfill: (callback: (data: Awaited<ReturnType<ReturnType<CB>['make']>>['data']) => void) => void;
};

export const makeRequestBase: IMakeRequest =
  (callback, options) =>
  (initialData, initialLoading = false) => {
    const [data, setData] = useState<any>(initialData);
    const [loading, setLoading] = useState(initialLoading);
    const [error, setError] = useState<MaybeNull<any>>(null);

    const [payload, setPayload] = useState<MaybeNull<IAbortPromise<any>>>(null);

    const [handleFulfill, setFulfill] = useState<MaybeNull<(data: any) => void>>(null);
    const onFulfill = useCallback((fulfillCallback: (data: any) => void) => setFulfill(() => fulfillCallback), []);

    const [handleReject, setReject] = useState<MaybeNull<(error: any) => void>>(null);
    const onReject = useCallback((rejectCallback: (error: any) => void) => setReject(() => rejectCallback), []);

    const [handleFinally, setFinally] = useState<MaybeNull<() => void>>(null);
    const onFinally = useCallback((finallyCallback: (error: any) => void) => setFinally(() => finallyCallback), []);

    const make = useCallback(
      (...args: Parameters<typeof callback>) => {
        const request = callback(...args);
        const promise = abortPromise(
          request.make().then((response) => ({ ...response, data: options?.effect?.(response.data) ?? response.data })),
          () => request.controller.abort(),
        );

        setLoading(true);
        setPayload((prev) => (prev?.abort(), promise));
      },
      [callback],
    );

    const cleanup = useCallback(() => {
      setPayload((prev) => (prev?.abort(), null));
      setLoading(initialLoading);
      setData(initialData);
      setError(null);
    }, [initialLoading, initialData]);

    useEffect(() => {
      if (!payload?.promise) return;

      payload?.promise
        .then(({ data }) => setData(data))
        .catch(setError)
        .finally(() => setLoading(false));

      return () => {
        if (options?.cancellable ?? true) payload.abort();
      };
    }, [payload]);

    useEffect(() => {
      payload?.promise?.then(({ data }) => handleFulfill?.(data));
    }, [payload, handleFulfill]);

    useEffect(() => {
      payload?.promise?.catch((error) => {
        if (!options?.silent) options?.onError?.(error);

        handleReject?.(error);
      });
    }, [payload, handleReject]);

    useEffect(() => {
      payload?.promise?.finally(() => handleFinally?.());
    }, [payload, handleFinally]);

    return {
      loading,
      error,
      data,
      make,
      cleanup,
      onFulfill,
      onReject,
      onFinally,
    };
  };

const makeRequest = (callback: Parameters<IMakeRequest>[0], options?: Parameters<IMakeRequest>[1]) => {
  const onError = options?.onError ?? (() => notification.publishError('Something went wrong'));

  return makeRequestBase(callback, { ...options, onError });
};

export default makeRequest as IMakeRequest;
