import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

export const StyledDialogPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(3, 2)};
  min-width: 960px;
`;

export const StyledCreditHeader = styled.header`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(1.5)};

  & > hgroup {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1.5)};
  }
`;

export const StyledStep = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
`;

export const StyledStepActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const StyledStepAction = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const StyledDescription = styled(Typography)`
  white-space: pre;
  line-height: 18px;
  margin-top: ${({ theme }) => theme.spacing(-1)};
`;

export const StyledCard = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(3, 2)};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  overflow-x: auto;
`;

export const StyledInfo = styled.div`
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const StyledInfoLine = styled(StyledInfo)`
  flex-direction: row;

  & > span {
    display: flex;
    gap: ${({ theme }) => theme.spacing(0.5)};

    & > * {
      white-space: pre-wrap;
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    }
  }
`;

export const StyledActions = styled.div`
  align-self: self-start;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const StyledCloseButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 0;

  & > svg {
    width: ${({ theme }) => theme.spacing(3)};
  }
`;

export const StyledDownloadProgress = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
`;
