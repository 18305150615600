import { Fragment, useRef, useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';

import { StyledCheckbox, StyledTableCellAction } from './styles';

export interface IRowActionControl {
  togglePopper: () => void;
  showPopper: () => void;
  hidePopper: () => void;
}

export type IRenderAction = (control: IRowActionControl) => MaybeNull<JSX.Element>;

export interface ITableCellActionProps {
  renderAction: IRenderAction;
}

export const TableCellAction = ({ renderAction }: ITableCellActionProps) => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef<MaybeNull<HTMLButtonElement>>(null);

  const showPopper = () => setOpen(false);
  const hidePopper = () => setOpen(false);
  const togglePopper = () => setOpen(!isOpen);

  const element = renderAction({ showPopper, hidePopper, togglePopper });

  if (!element) return <Fragment />;

  return (
    <StyledTableCellAction>
      <ClickAwayListener onClickAway={hidePopper}>
        <IconButton size="small" onClick={togglePopper} ref={ref} sx={{ padding: 0 }} disableRipple>
          <StyledCheckbox />
          <Popper
            transition
            open={isOpen}
            anchorEl={ref.current}
            placement="bottom-start"
            sx={(theme) => ({ zIndex: theme.zIndex.tooltip, top: '8px!important' })}>
            {({ TransitionProps }) => <Fade {...TransitionProps}>{element}</Fade>}
          </Popper>
        </IconButton>
      </ClickAwayListener>
    </StyledTableCellAction>
  );
};
