import { Fragment, memo, useCallback, useMemo, useState } from 'react';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import { AddIcon } from 'src/components/Icons';

import { StyledFilterValueContainer, StyledFilterValue, StyledMenuItem } from './styles';
import { IFilterOptions, IQueryFilter } from './useQueryFilter';

export interface IAddFilterProps<T extends IFilterOptions> {
  config: NonNullable<IQueryFilter<T>['options']>;
  order: IQueryFilter<T>['order'];
  onChange: (key: (keyof T)[]) => void;
}

const FilterValue = () => (
  <StyledFilterValueContainer>
    <StyledFilterValue>
      <Typography variant="body1">Add Filter</Typography>
    </StyledFilterValue>
  </StyledFilterValueContainer>
);

const AddFilter = <T extends IFilterOptions>({ config, order, onChange }: IAddFilterProps<T>) => {
  const [isOpen, setOpen] = useState(false);
  const options = useMemo(() => Object.keys(config) as (keyof T)[], [config]);

  const getOptionLabel = useCallback((option: keyof T) => config[option].options.label, [config]);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const handleChange = (value: (keyof T)[]) => {
    handleClose();
    onChange(value);
  };

  if (!options.length) return <Fragment />;

  return (
    <Select
      value={order}
      multiple
      displayEmpty
      variant="filled"
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      renderValue={() => <FilterValue />}
      onChange={({ target }) => handleChange(target.value as (keyof T)[])}
      IconComponent={AddIcon}
      MenuProps={{
        TransitionProps: {
          onExiting: () => (document.activeElement as Maybe<HTMLElement>)?.blur(),
        },
        MenuListProps: {
          disablePadding: true,
        },
        PaperProps: {
          sx: {
            overflow: 'visible',
          },
        },
      }}>
      {options.map((option) => (
        <StyledMenuItem key={`option-${option.toString()}`} value={option.toString()}>
          {getOptionLabel(option)}
        </StyledMenuItem>
      ))}
    </Select>
  );
};

export default memo(AddFilter) as typeof AddFilter;
