import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';

import shouldForwardProp from 'src/utils/shouldForwardProp';

export const HiddenInput = styled.input`
  display: none;
`;

export const UploadProgress = styled(LinearProgress)`
  border-radius: 4px;
  height: 4px;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
`;

export const StyledUploadLabel = styled.label`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const StyledDescription = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  position: relative;
  height: 100%;
  gap: 8px;
  align-items: center;
  min-width: 0;
`;

export const StyledActions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.5)};
  color: ${({ theme }) => theme.palette.primary.main};

  & > * {
    padding: 0;
    transition: ${({ theme }) => theme.transitions.create(['color'])};
  }

  & > *:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const StyledContainer = styled(Paper, { shouldForwardProp })<{ $failed?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${({ theme }) => theme.spacing(5)};
  padding: ${({ theme }) => theme.spacing(1, 1.5)};
  border: 1px dashed ${({ $failed, theme }) => ($failed ? theme.palette.error.main : theme.palette.text.secondary)};
  background-color: ${({ $failed, theme }) => ($failed ? theme.palette.error.light : theme.palette.secondary.light)};

  transition: ${({ theme }) => theme.transitions.create(['color', 'border-color'])};

  &:hover {
    box-shadow: ${({ theme }) => `inset ${theme.shadows[3]}`};
  }

  & ${StyledUploadLabel} {
    opacity: 0.5;
    transition: ${({ theme }) => theme.transitions.create(['opacity'])};
  }

  &:hover ${StyledUploadLabel} {
    opacity: 1;
  }
`;
