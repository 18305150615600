import { useEffect, useState } from 'react';

import { IFiltersValues } from 'src/types';
import { IFilterOptions, IQueryParams } from 'src/components/shared/QueryFilter';

import filterExpressions, { IFilterExpression } from './expressions';

export const filterData = <D extends object, T extends IFilterOptions>(
  data: Maybe<D[]>,
  config: Maybe<T>,
  params: Maybe<IQueryParams<T>>,
) => {
  if (!data || !config || !params) return null;

  const expressions = (Object.entries(params) as [keyof D, ValueOf<IFiltersValues>][])
    .filter(([, value]) => value != null)
    .map(([key, value]) => {
      const type = config[key]['type'];
      const callback = filterExpressions[type] as IFilterExpression<D>;

      return callback(key, value);
    });

  return expressions.reduce((data, expression) => expression(data), data);
};

export const useFilteredData = <D extends object, T extends IFilterOptions>(
  data: Maybe<D[]>,
  config: Maybe<T>,
  params: Maybe<IQueryParams<T>>,
) => {
  const [filteredData, setFilteredData] = useState<MaybeNull<D[]>>(() =>
    data ? filterData(data, config, params) : null,
  );

  useEffect(() => {
    const filteredData = filterData(data, config, params);

    setFilteredData(filteredData);
  }, [data, params, config]);

  return filteredData;
};
