import { IFilter } from './common';
import { IOrderOperator } from './order';

export type IInvoiceChartWaterfallColor = 'BOUND' | 'POSITIVE' | 'NEGATIVE' | 'POSITIVE_TOTAL' | 'NEGATIVE_TOTAL';

export type IInvoiceChartWaterfallGroup = {
  label: string;
  start: number;
  end: number;
  value: number;
  color_label: IInvoiceChartWaterfallColor;
};

export type IInvoiceChartStackItem = {
  date: string;
  value: number;
};

export type IInvoiceChartStackGroup = {
  data: IInvoiceChartStackItem[];
  name: string;
};

export type IInvoiceChart = {
  stack_charts_data: IInvoiceChartStackGroup[];
  waterfall_chart_data: IInvoiceChartWaterfallGroup[];
};

export type IInvoice = {
  name: string;
  logistics_provider: string;
  brand: string;
  warehouse: string;
  services: string;
  logistics_charges: number;
  favorable_errors_count: number;
  favorable_errors_dollars: number;
  unfavorable_errors_count: number;
  unfavorable_errors_dollars: number;
  start_date: string;
  end_date: string;
  status: IInvoiceStatus;
};

export const InvoiceSortBy = {
  INVOICE_NAME: 'INVOICE_NAME' as const,
  LOGISTICS_PROVIDER_NAME: 'LOGISTICS_PROVIDER_NAME' as const,
  LOGISTICS_NODE_NAME: 'LOGISTICS_NODE_NAME' as const,
  INVOICE_TYPE: 'INVOICE_TYPE' as const,
  INVOICE_START_DATE: 'INVOICE_START_DATE' as const,
  INVOICE_END_DATE: 'INVOICE_END_DATE' as const,
  AUDIT_DEPTH: 'AUDIT_DEPTH' as const,
  SERVICES_CHARGES: 'SERVICES_CHARGES' as const,
  LOGISTICS_CHARGES: 'LOGISTICS_CHARGES' as const,
  FAVORABLE_DOLLARS: 'FAVORABLE_DOLLARS' as const,
  FAVORABLE_COUNT: 'FAVORABLE_COUNT' as const,
  UNFAVORABLE_DOLLARS: 'UNFAVORABLE_DOLLARS' as const,
  UNFAVORABLE_COUNT: 'UNFAVORABLE_COUNT' as const,
  TOTAL_COUNT: 'TOTAL_COUNT' as const,
} as const;

export type IInvoiceSortBy = ValueOf<typeof InvoiceSortBy>;

export type ILatestInvoice = {
  invoice_id: string;
};

export type IInvoicesFilters = {
  LOGISTICS_PROVIDER_NAME: IFilter<'single_select'>;
  WAREHOUSE: IFilter<'single_select'>;
  STATUS: IFilter<'single_select'>;
  UNFAVORABLE_COUNT: IFilter<'criterion_number'>;
  UNFAVORABLE_DOLLARS: IFilter<'criterion_number'>;
  LOGISTICS_SERVICE: IFilter<'single_select'>;
  LOGISTICS_CHARGES: IFilter<'criterion_number'>;
};

export type IInvoicesFiltersConfig = {
  [K in keyof IInvoicesFilters]: Omit<IInvoicesFilters[K], 'options'>;
};

export const InvoicesFiltersConfig: IInvoicesFiltersConfig = {
  LOGISTICS_PROVIDER_NAME: { type: 'single_select' },
  WAREHOUSE: { type: 'single_select' },
  STATUS: { type: 'single_select' },
  UNFAVORABLE_COUNT: { type: 'criterion_number' },
  UNFAVORABLE_DOLLARS: { type: 'criterion_number' },
  LOGISTICS_SERVICE: { type: 'single_select' },
  LOGISTICS_CHARGES: { type: 'criterion_number' },
};

export const InvoiceDataType = {
  SUMMARY_SERVICE: 'SERVICES' as const,
  PICK_AND_PACK: 'PICK_AND_PACK' as const,
  FREIGHT: 'FREIGHT' as const,
} as const;

export type IInvoiceDataType = ValueOf<typeof InvoiceDataType>;

export type IInvoiceServiceDataItem = {
  audit_depth: string;
  service_name: string;
  logistics_charges: number;
  unfavorable_errors_count: number;
  unfavorable_errors_dollars: number;
};

export type IInvoiceServiceTotalData = {
  logistics_charges: number;
  unfavorable_errors_count: number;
  unfavorable_errors_dollars: number;
};

export type IInvoiceService = {
  data: IInvoiceServiceDataItem[];
  total: IInvoiceServiceTotalData;
};

export type IInvoiceServices = {
  summary_services: IInvoiceService;
  pnp_services: IInvoiceService;
};

export type IInvoiceDetailDataSortBy = Extract<
  keyof IInvoiceServiceDataItem,
  'audit_depth' | 'logistics_charges' | 'unfavorable_errors_count' | 'unfavorable_errors_dollars' | 'service_name'
>;

export const InvoiceDetailDataSortBy: { readonly [K in IInvoiceDetailDataSortBy]: K } = {
  audit_depth: 'audit_depth',
  logistics_charges: 'logistics_charges',
  unfavorable_errors_count: 'unfavorable_errors_count',
  unfavorable_errors_dollars: 'unfavorable_errors_dollars',
  service_name: 'service_name',
} as const;

export type IInvoiceSummary = {
  summary: IInvoice;
  unfavorable_errors_amount: IInvoiceSummaryErrors;
  unfavorable_errors_cost: IInvoiceSummaryErrors;
};

export type IInvoiceSummaryErrors = {
  period: IInvoiceSummaryErrorPeriod;
  data: IInvoiceSummaryError[];
  total: number;
};

export type IInvoiceSummaryError = {
  value: number;
  name: string;
  type: string;
};

export type IInvoiceSummaryErrorPeriod = {
  start_date: string;
  end_date: string;
};

export type IInvoiceError = {
  error_name: string[];
  error_code: string[];
  service: string;
  unfavorable_errors_count: number;
  unfavorable_errors_dollars: number;
};

export type IInvoiceErrorTotal = {
  data: IInvoiceError[];
  total: IInvoiceTotalError;
};

export type IInvoiceTotalError = {
  unfavorable_errors_count: number;
  unfavorable_errors_dollars: number;
  favorable_errors_count: number;
  favorable_errors_dollars: number;
};

export const InvoiceErrorSortBy: { readonly [K in IInvoiceErrorSortBy]: K } = {
  service: 'service',
  error_name: 'error_name',
  unfavorable_errors_count: 'unfavorable_errors_count',
  unfavorable_errors_dollars: 'unfavorable_errors_dollars',
} as const;

export type IInvoiceErrorSortBy = Extract<
  keyof IInvoiceError,
  'service' | 'error_name' | 'unfavorable_errors_dollars' | 'unfavorable_errors_count'
>;

export const ReconcileDataType = {
  ERROR_SUMMARY: 'ERROR_SUMMARY' as const,
  ORDERS: 'ORDERS' as const,
  TAGGED_ORDERS: 'TAGGED_ORDERS' as const,
} as const;

export type IReconcileDataType = ValueOf<typeof ReconcileDataType>;

export type IInvoiceOrder = {
  order_number: string;
  invoice_name: string;
  status: ISalesOrderStatus;
  error_code: string[];
  error_name: string[];
  fulfill_date: string;
  expected_rate: number;
  operator: IOrderOperator;
  invoiced_rate: number;
  unfavorable_dollars?: number;
  display_error_dollars?: number;
};

export type IInvoiceOrders = {
  data: IInvoiceOrder[];
  total: IInvoiceTotalOrder;
  page_info: IInvoiceOrderPagination;
};

export type IInvoiceOrdersFiltersConfig = {
  [K in keyof IInvoiceOrdersFilters]: Omit<IInvoiceOrdersFilters[K], 'options'>;
};

export type IInvoiceOrdersFilters = {
  STATUS: IFilter<'single_select'>;
  FULFILL_DATE: IFilter<'date_range'>;
  ERROR_CODE: IFilter<'multi_select'>;
  SERVICE_CHARGES: IFilter<'criterion_number'>;
  LOGISTICS_CHARGES: IFilter<'criterion_number'>;
};

export const InvoiceOrdersFiltersConfig: IInvoiceOrdersFiltersConfig = {
  STATUS: { type: 'single_select' },
  FULFILL_DATE: { type: 'date_range' },
  ERROR_CODE: { type: 'multi_select' },
  SERVICE_CHARGES: { type: 'criterion_number' },
  LOGISTICS_CHARGES: { type: 'criterion_number' },
};

export type IInvoiceErrorsFilters = {
  service: IFilter<'single_select'>;
  error_code: IFilter<'multi_select'>;
  unfavorable_errors_count: IFilter<'criterion_number'>;
  unfavorable_errors_dollars: IFilter<'criterion_number'>;
};

export type IInvoiceErrorsFiltersConfig = {
  [K in keyof IInvoiceErrorsFilters]: Omit<IInvoiceErrorsFilters[K], 'options'>;
};

export const InvoiceErrorsFiltersConfig: IInvoiceErrorsFiltersConfig = {
  service: { type: 'single_select' },
  error_code: { type: 'multi_select' },
  unfavorable_errors_count: { type: 'criterion_number' },
  unfavorable_errors_dollars: { type: 'criterion_number' },
};

export type IInvoiceErrorsExportFilters = {
  SERVICE: IFilter<'single_select'>;
  ERROR_CODE: IFilter<'single_select'>;
  UNFAVORABLE_COUNT: IFilter<'criterion_number'>;
  UNFAVORABLE_DOLLARS: IFilter<'criterion_number'>;
};

export type IInvoiceTotalOrder = {
  operator: IOrderOperator;
  expected_rate: number;
  invoiced_rate: number;
};

export type IInvoiceOrderPagination = {
  page_size: number;
  page_count: number;
  page_num: number;
};

export const InvoiceOrderSortBy = {
  SALES_ORDER_NAME: 'SALES_ORDER_NAME' as const,
  STATUS: 'STATUS' as const,
  UNFAVORABLE_DOLLARS: 'UNFAVORABLE_DOLLARS' as const,
  ERROR_CODE: 'ERROR_CODE' as const,
  FULFILL_DATE: 'FULFILL_DATE' as const,
  INVOICE_NAME: 'INVOICE_NAME' as const,
  LOGISTICS_CHARGES: 'LOGISTICS_CHARGES' as const,
  SERVICE_CHARGES: 'SERVICE_CHARGES' as const,
} as const;

export type IInvoiceOrderSortBy = ValueOf<typeof InvoiceOrderSortBy>;

export const InvoiceStatus = {
  REVIEWED: 'REVIEWED' as const,
  UNREVIEWED: 'UNREVIEWED' as const,
  NO_REVIEW_REQUIRED: 'NO_REVIEW_REQUIRED' as const,
  UNDER_REVIEW: 'UNDER_REVIEW' as const,
  PROCESSING: 'PROCESSING' as const,
  RECONCILED: 'RECONCILED' as const,
  LOAD_ERROR: 'LOAD ERROR' as const,
} as const;

export type IInvoiceStatus = ValueOf<typeof InvoiceStatus>;

export const SalesOrderStatus = {
  UNREVIEWED: 'UNREVIEWED' as const,
  ACCEPT: 'ACCEPT' as const,
  REJECT: 'REJECT' as const,
  NO_REVIEW_REQUIRED: 'NO_REVIEW_REQUIRED' as const,
} as const;

export type ISalesOrderStatus = ValueOf<typeof SalesOrderStatus>;
