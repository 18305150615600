import { DownloadState } from 'src/effects/useFileDownloadState';
import { UploadState } from 'src/effects/useFileUploadState';
import { ID } from 'src/types';

export interface IFileControllerProps {
  handleFileDownload: (id: ID) => void;
  handleFileUpload: (file: File) => void;
  handleFileDelete: () => void;
  downloadState: DownloadState;
  uploadState: UploadState;
}

export const useFileController = ({
  handleFileDownload,
  handleFileUpload,
  handleFileDelete,
  downloadState,
  uploadState,
}: IFileControllerProps) => ({
  uploadState: uploadState,
  downloadState: downloadState,
  onFileDelete: handleFileDelete,
  onFileUpload: handleFileUpload,
  onFileDownload: handleFileDownload,
});

export type IUseFileController = ReturnType<typeof useFileController>;
