import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';

import * as apiBrand from 'src/api/brand';
import makeRequest from 'src/effects/makeRequest';
import { ButtonLink } from 'src/components/shared/Buttons';
import { BrandForm, BrandSubmitButton } from 'src/components/BrandForm';
import { PageTitle, TitleButtons, PageContainer, Loading } from 'src/components/shared/Page';
import { IBrandSchema } from 'src/schemas';

const BRAND_FORM_ID = 'brand-form';
const useBrandRequest = makeRequest(apiBrand.createBrand);

export default function BrandNewPage() {
  const brandRequest = useBrandRequest(null);
  const navigate = useNavigate();

  const handleSubmit = (brand: IBrandSchema) => {
    brandRequest.make({
      status: brand.status,
      name: brand.name,
      email: brand.email,
    });
    brandRequest.onFulfill((id) => navigate(`/system/company/${id}`));
    brandRequest.onReject(() => navigate('/error'));
  };

  return (
    <>
      <PageTitle title={'Create company'} description={'Pretty simple, fill up form to create company.'}>
        <TitleButtons align="left">
          <BrandSubmitButton id={BRAND_FORM_ID} disabled={brandRequest.loading} />
          <ButtonLink title="Cancel" to={'/system/companies'} variant="outlined" />
        </TitleButtons>
      </PageTitle>
      <PageContainer>
        {brandRequest.loading ? (
          <Loading />
        ) : (
          <>
            <Divider />
            <BrandForm id={BRAND_FORM_ID} onSubmit={handleSubmit} />
          </>
        )}
      </PageContainer>
    </>
  );
}
