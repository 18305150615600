import { memo } from 'react';
import Checkbox from '@mui/material/Checkbox';

import { TableRow, TableCell, TableLeadCell } from 'src/components/shared/Table';
import currencyFormatter from 'src/utils/currencyFormatter';
import numberFormatter from 'src/utils/numberFormatter';
import { ICreditError } from 'src/types';

const formatUSD = currencyFormatter();
const formatNumber = numberFormatter();

export interface IErrorTypesRowProps {
  item: ICreditError;
  selected: boolean;
}

const ErrorTypesRow = ({ item, selected }: IErrorTypesRowProps) => (
  <TableRow>
    <TableLeadCell>
      <Checkbox id={item.error_code} checked={selected} disableRipple />
    </TableLeadCell>
    <TableCell>{item.error_name}</TableCell>
    <TableCell>{item.service}</TableCell>
    <TableCell>{formatNumber(item.unfavorable_errors_count)}</TableCell>
    <TableCell>{formatUSD(item.unfavorable_errors_dollars)}</TableCell>
  </TableRow>
);

export default memo(ErrorTypesRow);
