import { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';

import { IBrandStatus, BrandStatus, BrandSortBy, SortDirection, ISort, IBrandSortBy } from 'src/types';
import { PageTitle, TitleButtons, PageContainer, Loading } from 'src/components/shared/Page';
import { BrandList } from 'src/components/BrandList';
import { ButtonLink } from 'src/components/shared/Buttons';
import makeRequest from 'src/effects/makeRequest';
import * as apiBrand from 'src/api/brand';

const useBrandRequest = makeRequest(apiBrand.getBrands);

export default function BrandListPage() {
  const brandsRequest = useBrandRequest([]);

  const [statuses] = useState<IBrandStatus[]>(Object.values<IBrandStatus>(BrandStatus));
  const [sort, setSort] = useState<ISort<IBrandSortBy>>({
    order: SortDirection.DESC,
    by: BrandSortBy.BRAND_NAME,
  });

  useEffect(() => {
    brandsRequest.cleanup();
    brandsRequest.make({
      statuses,
      by: sort.by,
      sort: sort.order,
    });
  }, [sort, statuses]);

  return (
    <>
      <PageTitle title={'Companies'} description={'Find a company or create new one.'}>
        <TitleButtons align="left">
          <ButtonLink title="Create company" to={'/system/company'} variant="contained"></ButtonLink>
        </TitleButtons>
      </PageTitle>
      <PageContainer>
        {brandsRequest.loading ? (
          <Loading />
        ) : (
          <>
            <Divider />
            <BrandList brands={brandsRequest.data} sort={sort} onSort={setSort} />
          </>
        )}
      </PageContainer>
    </>
  );
}
