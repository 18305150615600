import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import { OverridableComponent, OverridableTypeMap } from '@mui/material/OverridableComponent';

export const StyledForm = styled.form`
  align-self: self-start;
  width: 300px;
  min-width: 300px;

  & > * {
    width: 100%;
  }

  & [type='search'] {
    padding-left: 0;
    padding-right: 0;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }

  & svg {
    transition: ${({ theme }) => theme.transitions.create(['color'])};
  }

  & button:not([disabled]) svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const StyledTooltip = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(0.5, 1.5)};
`;

export const StyledPopper = styled(Popper)`
  z-index: 1;
  margin-top: ${({ theme }) => theme.spacing(1)} !important;
`;

export const StyledMenuItem = styled<OverridableComponent<OverridableTypeMap>>(MenuItem)`
  justify-content: flex-start;
  text-transform: initial;

  & > :first-child {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  & > :last-child {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;
