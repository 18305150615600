import { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import {
  IOrderPickAndPackTotal,
  IOrderPickAndPackError,
  IOrderPickAndPackLine,
  IOrderPickAndPackErrorStatus,
  OrderPickAndPackErrorStatus,
} from 'src/types';
import numberFormatter from 'src/utils/numberFormatter';
import currencyFormatter from 'src/utils/currencyFormatter';
import OrderOperator from 'src/components/shared/OrderOperator';

import {
  StyledPickAndPackHead,
  StyledPickAndPackList,
  StyledPickAndPackRecord,
  StyledPickAndPackRecords,
  StyledPickAndPackErrorList,
  StyledPickAndPackErrorRecord,
  StyledPickAndPackTotal,
  StyledPickAndPackTitle,
  StyledPickAndPackTotalRecord,
} from './styles';

const formatNumber = numberFormatter();
const formatCurrency = currencyFormatter();

export interface IPickAndPackTitleProps {
  title: string;
}

export const PickAndPackTitle = ({ title }: IPickAndPackTitleProps) => (
  <StyledPickAndPackTitle>
    <Typography variant="h3">{title}</Typography>
    <Chip label="Expected" />
    <span />
    <Chip label="Invoiced" />
  </StyledPickAndPackTitle>
);

export interface IPickAndPackListProps {
  details: IOrderPickAndPackLine[];
  orderFees: MaybeNull<IOrderPickAndPackLine[]>;
  totalPnp: IOrderPickAndPackTotal;
  totalPnpFee: IOrderPickAndPackTotal;
  totalOrderFee: MaybeNull<IOrderPickAndPackTotal>;
}

export const PickAndPackList = ({
  details,
  orderFees,
  totalPnpFee,
  totalPnp,
  totalOrderFee,
}: IPickAndPackListProps) => (
  <StyledPickAndPackList>
    <StyledPickAndPackHead>
      <Typography variant="h4">Item</Typography>
      <Typography variant="h4">Bundle Components</Typography>
      <Typography variant="h4">Charge type</Typography>
      <Typography variant="h4">Qty</Typography>
      <Typography variant="h4">Rate</Typography>
      <Typography variant="h4">charge</Typography>
      <Typography variant="h4" sx={(theme) => ({ padding: theme.spacing(0, 1.5) })}>
        VS
      </Typography>
      <Typography variant="h4">Fee</Typography>
      <Typography variant="h4">Qty</Typography>
      <Typography variant="h4">Rate</Typography>
    </StyledPickAndPackHead>
    <StyledPickAndPackRecords>
      {details.map((item, index) => (
        <StyledPickAndPackRecord key={`order-pick-and-pack-${index}`}>
          <Typography variant="body1" sx={{ color: 'text.primary' }}>
            {item.item}
          </Typography>
          <Typography variant="body1">Coming soon</Typography>
          <Typography variant="body1">{item.service}</Typography>
          <Typography variant="body1">{formatNumber(item.expected_quantity)}</Typography>
          <Typography variant="body1">{formatCurrency(item.expected_rate)}</Typography>
          <Typography variant="body1">{formatCurrency(item.expected_total_charges)}</Typography>
          <span>
            <OrderOperator operator={item.operator} />
          </span>
          <Typography variant="body1">{formatCurrency(item.invoiced_total_charges)}</Typography>
          <Typography variant="body1">{formatNumber(item.invoiced_quantity)}</Typography>
          <Typography variant="body1">{formatCurrency(item.invoiced_rate)}</Typography>
        </StyledPickAndPackRecord>
      ))}
      <StyledPickAndPackRecord>
        <Typography variant="h4">Total pick fees</Typography>
        <span />
        <span />
        <Typography variant="h4">{formatNumber(totalPnpFee.expected_quantity)}</Typography>
        <span />
        <Typography variant="h4">{formatCurrency(totalPnpFee.expected_rate)}</Typography>
        <span>
          <OrderOperator operator={totalPnpFee.operator} />
        </span>
        <Typography variant="h4">{formatCurrency(totalPnpFee.invoiced_rate)}</Typography>
        <Typography variant="h4">{formatNumber(totalPnpFee.invoiced_quantity)}</Typography>
        <span />
      </StyledPickAndPackRecord>
    </StyledPickAndPackRecords>
    <StyledPickAndPackTotal>
      {!!orderFees && !!totalOrderFee && (
        <>
          <Divider />
          <StyledPickAndPackRecords>
            {orderFees.map((item, index) => (
              <StyledPickAndPackRecord key={`order-pick-and-pack-${index}`}>
                <Typography variant="body1" sx={{ color: 'text.primary' }}>
                  {item.service}
                </Typography>
                <span />
                <span />
                <Typography variant="body1">{formatNumber(item.expected_quantity)}</Typography>
                <Typography variant="body1">{formatCurrency(item.expected_rate)}</Typography>
                <Typography variant="body1">{formatCurrency(item.expected_total_charges)}</Typography>
                <span>
                  <OrderOperator operator={item.operator} />
                </span>
                <Typography variant="body1">{formatCurrency(item.invoiced_total_charges)}</Typography>
                <Typography variant="body1">{formatNumber(item.invoiced_quantity)}</Typography>
                <Typography variant="body1">{formatCurrency(item.invoiced_rate)}</Typography>
              </StyledPickAndPackRecord>
            ))}
            <StyledPickAndPackTotalRecord>
              <Typography variant="h4" sx={{ color: 'text.primary' }}>
                Total Order-level Fees
              </Typography>
              <Typography variant="h4">{formatNumber(totalOrderFee.expected_quantity)}</Typography>
              <span />
              <Typography variant="h4">{formatCurrency(totalOrderFee.expected_rate)}</Typography>
              <span>
                <OrderOperator operator={totalOrderFee.operator} />
              </span>
              <Typography variant="h4">{formatCurrency(totalOrderFee.invoiced_rate)}</Typography>
              <Typography variant="h4">{formatNumber(totalOrderFee.invoiced_quantity)}</Typography>
              <span />
            </StyledPickAndPackTotalRecord>
          </StyledPickAndPackRecords>
        </>
      )}
      <Divider />
      <StyledPickAndPackTotalRecord>
        <Typography variant="h4">Total Pick & Pack</Typography>
        <Typography variant="h4">{formatNumber(totalPnp.expected_quantity)}</Typography>
        <span />
        <Typography variant="h4">{formatCurrency(totalPnp.expected_rate)}</Typography>
        <span>
          <OrderOperator operator={totalPnp.operator} />
        </span>
        <Typography variant="h4">{formatCurrency(totalPnp.invoiced_rate)}</Typography>
        <Typography variant="h4">{formatNumber(totalPnp.invoiced_quantity)}</Typography>
        <span />
      </StyledPickAndPackTotalRecord>
    </StyledPickAndPackTotal>
  </StyledPickAndPackList>
);

const PickAndPackErrorStatus = ({ status }: { status: IOrderPickAndPackErrorStatus }) => {
  const { palette } = useTheme();

  const labels = useMemo(
    () => ({
      [OrderPickAndPackErrorStatus.PASS]: 'Pass',
      [OrderPickAndPackErrorStatus.FAIL]: 'Fail',
    }),
    [],
  );

  const colors = useMemo(
    () => ({
      [OrderPickAndPackErrorStatus.PASS]: palette.success.light,
      [OrderPickAndPackErrorStatus.FAIL]: palette.error.light,
    }),
    [],
  );

  const label = labels[status] ?? 'Unknown';
  const color = colors[status] ?? palette.text.disabled;

  return (
    <Chip label={label} sx={(theme) => ({ backgroundColor: color, minWidth: theme.spacing(6.5) })} component={'span'} />
  );
};

export interface IPickAndPackErrorsProps {
  errors: IOrderPickAndPackError[];
}

export const PickAndPackErrors = ({ errors }: IPickAndPackErrorsProps) => (
  <StyledPickAndPackErrorList>
    {errors.map((error) => (
      <StyledPickAndPackErrorRecord key={`order-pick-and-pack-error-${error.error_name}-${error.status}`}>
        <Typography variant="h4">{error.error_name}</Typography>
        <PickAndPackErrorStatus status={error.status} />
      </StyledPickAndPackErrorRecord>
    ))}
  </StyledPickAndPackErrorList>
);
