import { memo, useCallback } from 'react';

import { TableHeaderRow, TableHeaderCellSort, TableLeadCell } from 'src/components/shared/Table';
import { InvoiceSortBy, SortDirection, ISortProps, IInvoiceSortBy } from 'src/types';

const InvoiceHead = ({ sort, onSort }: ISortProps<IInvoiceSortBy>) => {
  const handleSortChange = useCallback(
    (fieldName: IInvoiceSortBy) => {
      if (sort.by !== fieldName) {
        onSort({
          by: fieldName,
          order: SortDirection.DESC,
        });
      } else {
        onSort({
          ...sort,
          order: sort.order === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
        });
      }
    },
    [sort, onSort],
  );

  return (
    <TableHeaderRow isSticky>
      <TableLeadCell />
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === InvoiceSortBy.INVOICE_NAME}
        onClick={() => handleSortChange(InvoiceSortBy.INVOICE_NAME)}>
        Invoice #
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === InvoiceSortBy.INVOICE_END_DATE}
        onClick={() => handleSortChange(InvoiceSortBy.INVOICE_END_DATE)}>
        Service period
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === InvoiceSortBy.LOGISTICS_PROVIDER_NAME}
        onClick={() => handleSortChange(InvoiceSortBy.LOGISTICS_PROVIDER_NAME)}>
        3PL
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === InvoiceSortBy.LOGISTICS_NODE_NAME}
        onClick={() => handleSortChange(InvoiceSortBy.LOGISTICS_NODE_NAME)}>
        Warehouse
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === InvoiceSortBy.INVOICE_TYPE}
        onClick={() => handleSortChange(InvoiceSortBy.INVOICE_TYPE)}>
        Services
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === InvoiceSortBy.LOGISTICS_CHARGES}
        onClick={() => handleSortChange(InvoiceSortBy.LOGISTICS_CHARGES)}>
        Charges
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === InvoiceSortBy.UNFAVORABLE_COUNT}
        onClick={() => handleSortChange(InvoiceSortBy.UNFAVORABLE_COUNT)}>
        Errors (#)
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === InvoiceSortBy.UNFAVORABLE_DOLLARS}
        onClick={() => handleSortChange(InvoiceSortBy.UNFAVORABLE_DOLLARS)}>
        Errors ($)
      </TableHeaderCellSort>
    </TableHeaderRow>
  );
};

export default memo(InvoiceHead);
