import { IInvoiceDataType, InvoiceDataType } from 'src/types';
import { invoiceDetailsQuery } from 'src/components/Invoice';
import combineSearchStrings from 'src/utils/combineSearchStrings';

const SERVICE_TYPE: Record<string, IInvoiceDataType> = {
  'Pick and Pack': InvoiceDataType.PICK_AND_PACK,
};

const DEFAULT_SERVICE_TYPE = InvoiceDataType.SUMMARY_SERVICE;

const getType = (service: string) => SERVICE_TYPE[service] ?? DEFAULT_SERVICE_TYPE;

export const linkToInvoiceDetails = (invoiceName: string, invoiceService: string, searchString?: string) => {
  const type = getType(invoiceService);

  const detailsString = invoiceDetailsQuery.getSearchString({
    name: invoiceName,
    type: type,
  });

  return `/invoices/details?${combineSearchStrings(detailsString, searchString)}`;
};
