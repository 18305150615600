import { memo } from 'react';

import { IAnomaliesAggregation } from 'src/types';

import { IAnomalyTab } from './types';
import AnomalyStatusTotals from './AnomalyStatusTotals';
import AnomalyCountChart from './AnomalyCountChart';

export interface IAnomalyTabComponentProps {
  tab: IAnomalyTab;
  payload: IAnomaliesAggregation;
}

const AnomalyTabComponent = ({ tab, payload }: IAnomalyTabComponentProps): JSX.Element => {
  switch (tab) {
    case 'status':
      return <AnomalyStatusTotals totals={payload.total_status} />;
    case 'count':
      return <AnomalyCountChart data={payload.total_count_chart} />;
    default:
      return <>Coming soon</>;
  }
};

export default memo(AnomalyTabComponent);
