import styled from '@emotion/styled';

export const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const StyledTitle = styled.span`
  font-size: 36px;
  font-weight: ${({ theme }) => theme.typography.fontWeightThin};
`;

export const StyledDescription = styled.span`
  font-size: 16px;
`;
