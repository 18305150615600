import { Dayjs } from 'dayjs';
import styled from '@emotion/styled';
import { alpha } from '@mui/system/colorManipulator';
import Button from '@mui/material/Button';
import FilledInput from '@mui/material/FilledInput';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

import shouldForwardProp from 'src/utils/shouldForwardProp';

export const StyledPickersDay = styled(PickersDay<Dayjs>, { shouldForwardProp })<{
  $selected?: boolean;
  $inRange?: boolean;
  $first?: boolean;
  $last?: boolean;
  $highlight?: boolean;
}>`
  margin: 0;
  border-radius: 0;
  position: relative;
  border: none !important;

  &:hover,
  &:focus {
    background-color: initial !important;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &::before {
    border-top-left-radius: ${({ $first }) => ($first ? '50%' : '0')};
    border-bottom-left-radius: ${({ $first }) => ($first ? '50%' : '0')};
    border-top-right-radius: ${({ $last }) => ($last ? '50%' : '0')};
    border-bottom-right-radius: ${({ $last }) => ($last ? '50%' : '0')};

    background-color: ${({ $inRange, theme }) => ($inRange ? theme.palette.success.light : 'initial')};
  }

  &::after {
    border-radius: 50%;

    background-color: ${({ $highlight, theme }) => ($highlight ? theme.palette.success.main : 'initial')};
  }

  &.MuiPickersDay-today::after {
    border-radius: 50%;

    border: 1px solid ${({ theme }) => theme.palette.success.main};
`;

export const StyledDateCalendarHeader = styled('div', { shouldForwardProp })`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(2)};

  & h4 {
    text-align: center;
    min-width: ${({ theme }) => theme.spacing(14.5)};
  }

  & button {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const StyledDateCalendar = styled(DateCalendar<Dayjs>)`
  max-height: initial;
  height: 274px;
  min-height: 274px;
  max-height: 274px;
  width: 242px;
  min-width: 242px;
  max-width: 242px;
  padding: 8px;
  margin: 0;
  overflow: hidden;

  & .MuiDayCalendar-weekDayLabel,
  & .MuiDayCalendar-weekContainer {
    margin: 0;
  }

  & .MuiDayCalendar-monthContainer {
    z-index: 0;
  }

  & .MuiPickersDay-root,
  & .MuiDayCalendar-weekDayLabel {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
  }
`;

export const StyledPicker = styled.section`
  display: flex;
  flex-direction: row;
`;

export const StyledPickerAside = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(1.5, 0, 1.5, 1.5)};
  border-right: 2px solid ${({ theme }) => alpha(theme.palette.text.secondary, 0.2)};
`;

export const StyledPickerAsideBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};
`;

export const StyledPickerCalendar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledPickerCalendarDates = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  gap: ${({ theme }) => theme.spacing(1.5)};
  padding: ${({ theme }) => theme.spacing(1.5)};
  border-top: 2px solid ${({ theme }) => alpha(theme.palette.text.secondary, 0.2)};

  & > * {
    width: 102px;
  }
`;

export const StyledPickerButton = styled(Button, { shouldForwardProp })<{ $warn?: boolean }>`
  height: 30px;
  justify-content: flex-start;

  ${({ $warn, theme }) => $warn && `color: ${theme.palette.error.main};`}
`;

export const StyledPickerInput = styled(FilledInput, { shouldForwardProp })<{ $active?: boolean }>`
  &,
  & * {
    cursor: pointer;
  }

  & input {
    padding-left: 0;
    padding-right: 0;
    max-width: 120px;
    min-width: 120px;
    pointer-events: none;
  }

  & svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
