import { useEffect } from 'react';
import { Control, FieldErrors, UseFormWatch } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';

import { ILogisticsProvider, IRateCardOrder, IWarehouse, RateCardOrder } from 'src/types';
import { FormAutocomplete, FormFields, FormInput, FormSelect } from 'src/components/shared/Form';
import { IRateCardSchema } from 'src/schemas';

import { IFormBlockErrorProps } from './RateCardBlock';

export interface IRateCardRulesProps {
  logistics: MaybeNull<ILogisticsProvider[]>;
  warehouses: MaybeNull<IWarehouse[]>;
  errors: FieldErrors<IRateCardSchema>;
  control: Control<IRateCardSchema>;
  watch: UseFormWatch<IRateCardSchema>;
}

export const RateCardRules = ({
  logistics,
  warehouses,
  control,
  watch,
  errors,
  onError,
}: IRateCardRulesProps & IFormBlockErrorProps) => {
  const hasErrors = !!(errors.logisticsId || errors.warehouseId || errors.orderType || errors.rates);

  const logisticsId = watch('logisticsId');
  const warehouseId = watch('warehouseId');

  useEffect(() => onError(hasErrors), [onError, hasErrors]);

  return (
    <FormFields>
      <FormInput>
        <FormAutocomplete
          control={control}
          label="Select 3PL"
          name="logisticsId"
          options={logistics}
          errorMessage={errors.logisticsId?.message}
          onChange={(logistic) => logistic?.id}
          isOptionsEqual={(prev, next) => prev.id === next.id}
          getLabel={(logistic) => (logistics?.length ? logistic.name : '')}
          getValue={(value) => logistics?.find(({ id }) => id === value) ?? null}
          renderValue={(props, { id, name }) => (
            <MenuItem {...props} selected={logisticsId === id} key={id}>
              {name}
            </MenuItem>
          )}
        />
      </FormInput>
      <FormInput>
        <FormAutocomplete
          control={control}
          label="Select Warehouse"
          name="warehouseId"
          options={warehouses}
          errorMessage={errors.warehouseId?.message}
          onChange={(warehouse) => warehouse?.id}
          isOptionsEqual={(prev, next) => prev.id === next.id}
          getLabel={(warehouse) => warehouse.name}
          getValue={(value) => warehouses?.find(({ id }) => id === value) ?? null}
          renderValue={(props, { id, name }) => (
            <MenuItem {...props} selected={warehouseId === id} key={id}>
              {name}
            </MenuItem>
          )}
        />
      </FormInput>
      <FormInput>
        <FormSelect
          label="Select order type"
          name="orderType"
          control={control}
          errorMessage={errors.orderType?.message}
          options={Object.values<IRateCardOrder>(RateCardOrder)}
        />
      </FormInput>
    </FormFields>
  );
};
