import { memo, useCallback } from 'react';

import {
  TableHeaderRow,
  TableHeaderCell,
  IRenderAction,
  TableHeaderCellSort,
  TableCellAction,
} from 'src/components/shared/Table';
import { AnomalyOrderSortBy, IAnomalyOrderSortBy, ISortProps, SortDirection } from 'src/types';

export type IAnomalyOrderHeadActionProps = Omit<IAnomalyOrderHeadProps, 'renderAction'>;
export type IAnomalyOrderHeadAction = (props: IAnomalyOrderHeadActionProps) => JSX.Element;

export interface IAnomalyOrderHeadProps extends ISortProps<IAnomalyOrderSortBy> {
  renderAction: IRenderAction;
}

const AnomalyOrderHead = ({ sort, onSort, renderAction }: IAnomalyOrderHeadProps) => {
  const handleSortChange = useCallback(
    (fieldName: IAnomalyOrderSortBy) => {
      if (sort.by !== fieldName) {
        onSort({
          by: fieldName,
          order: SortDirection.DESC,
        });
      } else {
        onSort({
          ...sort,
          order: sort.order === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
        });
      }
    },
    [sort, onSort],
  );

  return (
    <TableHeaderRow isSticky>
      <TableCellAction renderAction={renderAction} />
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === AnomalyOrderSortBy.ORDER_NUMBER}
        onClick={() => handleSortChange(AnomalyOrderSortBy.ORDER_NUMBER)}>
        Order number
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === AnomalyOrderSortBy.ANOMALY_STATUS}
        onClick={() => handleSortChange(AnomalyOrderSortBy.ANOMALY_STATUS)}>
        Anomaly Status
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === AnomalyOrderSortBy.SHIP_DATE}
        onClick={() => handleSortChange(AnomalyOrderSortBy.SHIP_DATE)}>
        Ship Date
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === AnomalyOrderSortBy.INVOICE_NAME}
        onClick={() => handleSortChange(AnomalyOrderSortBy.INVOICE_NAME)}>
        Invoice #
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === AnomalyOrderSortBy.ANOMALY_TYPE}
        onClick={() => handleSortChange(AnomalyOrderSortBy.ANOMALY_TYPE)}>
        Anomaly Type
      </TableHeaderCellSort>
      <TableHeaderCell>Comparable Orders</TableHeaderCell>
    </TableHeaderRow>
  );
};

export default memo(AnomalyOrderHead);
