import { memo } from 'react';

import { OrderList } from 'src/components/InvoiceReconcile/Order';
import { QueryFilter } from 'src/components/shared/QueryFilter';
import ActionPanel from 'src/components/shared/ActionPanel';

import OrderRowAction from './InvoiceOrdersRowAction';
import OrderHeadAction from './InvoiceOrdersHeadAction';
import InvoiceOrdersExport from './InvoiceOrdersExport';
import { useReconciliationContext } from './ReconciliationContext';

const InvoiceOrders = () => {
  const { ordersRequest, ordersFilters, ordersSorting } = useReconciliationContext();

  return (
    <>
      <ActionPanel noAlign>
        <QueryFilter control={ordersFilters} />
        <InvoiceOrdersExport />
      </ActionPanel>
      <OrderList
        rowAction={OrderRowAction}
        headAction={OrderHeadAction}
        payload={ordersRequest.data}
        loading={ordersRequest.loading}
        sorting={ordersSorting.params}
        onSortChange={ordersSorting.setSorting}
      />
    </>
  );
};

export default memo(InvoiceOrders);
