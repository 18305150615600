import { useState } from 'react';

import { ID } from 'src/types';

export const useDialogControl = () => {
  const [id, setId] = useState<MaybeNull<ID>>(null);

  const showDialog = (id: ID) => setId(id);
  const hideDialog = () => setId(null);

  return { id, hideDialog, showDialog };
};
