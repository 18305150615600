import styled from '@emotion/styled';
import { alpha } from '@mui/system/colorManipulator';

import shouldForwardProp from 'src/utils/shouldForwardProp';

export const StyledStepDot = styled('div', { shouldForwardProp })<{ $color: string }>`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  &::before {
    content: '';
    display: block;
    border-radius: 100%;
    width: ${({ theme }) => theme.spacing(2)};
    height: ${({ theme }) => theme.spacing(2)};
    background-color: ${({ $color }) => $color};
  }

  &::after {
    height: 2px;
    flex: 1;
    background-color: ${({ theme }) => alpha(theme.palette.text.secondary, 0.2)};
  }
`;

export const StyledStep = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const StyledStepper = styled.div`
  display: flex;
  flex-direction: row;

  & ${StyledStep}:not(:last-child) {
    flex: 1;

    & ${StyledStepDot}::after {
      content: '';
    }
  }
`;
