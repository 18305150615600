import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import Link from '@mui/material/Link';

import { IRateCardListItem } from 'src/types';
import dateFormatter from 'src/utils/dateFormatter';
import { RateCardStatus } from 'src/components/RateCardDetails';
import { TableRow, TableCell, TableCellAction, IRenderAction } from 'src/components/shared/Table';

const formatDate = dateFormatter();

export type IRateCardRowActionProps = Omit<IRateCardRowProps, 'renderAction'>;
export type IRateCardRowAction = (props: IRateCardRowActionProps) => JSX.Element;

export interface IRateCardRowProps {
  rateCard: IRateCardListItem;
  renderAction: IRenderAction;
}

const RateCardRow = ({ rateCard, renderAction }: IRateCardRowProps) => (
  <TableRow>
    <TableCellAction renderAction={renderAction} />
    <TableCell>
      {formatDate(rateCard.effective_from)} - {formatDate(rateCard.effective_to)}
    </TableCell>
    <TableCell>
      <Link id={`rate-card-name-${rateCard.id}`} component={NavLink} to={`/settings/ratecard/${rateCard.id}`}>
        {rateCard.name}
      </Link>
    </TableCell>
    <TableCell>{rateCard.logistics_provider.name}</TableCell>
    <TableCell>{rateCard.logistics_node.name}</TableCell>
    <TableCell>
      <RateCardStatus status={rateCard.status} />
    </TableCell>
  </TableRow>
);

export default memo(RateCardRow);
