import styled from '@emotion/styled';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ActiveElement, Chart } from 'chart.js';

const CHART_CONFIG = {
  options: {
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    cutout: '80%',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: false,
      },
    },
  },
};

const getChartData = (segments: IPieChartSegment[]) => ({
  segments,
  labels: segments.map(({ name }) => name),
  datasets: [
    {
      data: segments.map(({ value }) => value),
      backgroundColor: segments.map(({ color }) => color),
    },
  ],
});

const StyledContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLabel = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.main};

  & > * {
    margin: 0;
  }
`;

const StyledCanvas = styled.canvas`
  width: 100%;
  position: relative;
`;

export interface IPieChartSegment {
  value: number;
  view: string;
  name: string;
  color: string;
}

export interface IPieChartProps {
  size: number;
  segments: IPieChartSegment[];
}

const PieChart = ({ size, segments }: IPieChartProps) => {
  const ref = useRef<MaybeNull<HTMLCanvasElement>>(null);
  const data = useMemo(() => getChartData(segments), [segments]);
  const [activeSegment, setActiveSegment] = useState<MaybeNull<IPieChartSegment>>(null);

  useEffect(() => {
    const context = ref.current?.getContext('2d');

    const handleChartEvent = (_: never, elements: ActiveElement[]) => {
      const [element] = elements;

      setActiveSegment(element ? data.segments[element.index] : null);
    };

    if (!context) return;

    const chart = new Chart(context, {
      ...CHART_CONFIG,
      type: 'doughnut',
      data: data,
      options: {
        ...CHART_CONFIG.options,
        events: ['mousemove', 'click'],
        onClick: handleChartEvent,
        onHover: handleChartEvent,
      },
    });

    return () => chart.destroy();
  }, [data]);

  return (
    <StyledContainer>
      {activeSegment && <StyledLabel>{activeSegment.view}</StyledLabel>}
      <StyledCanvas ref={ref} width={size} height={size} />
    </StyledContainer>
  );
};

export default PieChart;
