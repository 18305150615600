import { PropsWithChildren } from 'react';
import Typography from '@mui/material/Typography';

import TruncatePopover from 'src/components/shared/TruncatePopover';

import {
  StyledCard,
  StyledCardHeader,
  StyledCardContent,
  StyledSection,
  StyledSectionAside,
  StyledSectionCards,
} from './styles';

export interface ISectionCardProps {
  title: JSX.Element | string;
  main: JSX.Element;
  aside?: JSX.Element;
}

export const SectionCardTitle = ({ children }: PropsWithChildren) => (
  <Typography variant="h2" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
    {children}
  </Typography>
);

export const SectionCard = ({ title, main, aside }: ISectionCardProps) => (
  <StyledSection>
    <SectionCardTitle>{title}</SectionCardTitle>
    <StyledSectionCards>{main}</StyledSectionCards>
    <StyledSectionAside>{aside}</StyledSectionAside>
  </StyledSection>
);

export interface ISectionItemTitleProps {
  title: JSX.Element | string;
}

export const SectionItemTitle = ({ title, children }: PropsWithChildren<ISectionItemTitleProps>) => (
  <StyledCardHeader>
    <Typography variant="h3">{title}</Typography>
    {children}
  </StyledCardHeader>
);

export interface ISectionItemProps {
  header: JSX.Element;
}

export const SectionItem = ({ header, children }: PropsWithChildren<ISectionItemProps>) => (
  <StyledCard>
    {header}
    <TruncatePopover>
      <StyledCardContent>{children}</StyledCardContent>
    </TruncatePopover>
  </StyledCard>
);
