import { useMemo, useState } from 'react';
import Button from '@mui/material/Button';

import * as apiInvoice from 'src/api/invoice';
import makeRequest from 'src/effects/makeRequest';
import { ICreditEmailSchema } from 'src/schemas';
import { Loading } from 'src/components/shared/Page';
import { CreditStep, CreditStepActions, CreditStepAction, EmailForm } from 'src/components/InvoiceReconcile';

import { useCreditRequestContext } from './CreditRequestContext';
import { useCreditStepsContext } from './CreditStepsContext';
import { createEmailTemplate, makeErrorFilters } from './creditRequestControl';

const FORM_ID = 'credit-email';
const useCreateCreditRequest = makeRequest(apiInvoice.createCredit);

const EmailStep = () => {
  const { stepControl, creditErrorsTotal, creditErrorsSelection, creditSummaryRequest, creditRecipientRequest } =
    useCreditStepsContext();
  const createCreditRequest = useCreateCreditRequest(null, false);
  const { invoiceName, dialogControl } = useCreditRequestContext();
  const [isTest, setIsTest] = useState(false);

  const defaultValues = useMemo<NestedPartial<ICreditEmailSchema>>(() => {
    if (!creditRecipientRequest.data || !creditSummaryRequest.data) return {};

    const { logistics_provider_email, logistics_provider_name, sender_name, subject } = creditRecipientRequest.data;
    const { invoice_name, start_date, end_date } = creditSummaryRequest.data;
    const emailTemplate = createEmailTemplate({
      invoiceName: invoice_name,
      periodStart: start_date,
      periodEnd: end_date,
      senderName: sender_name,
      recipientName: logistics_provider_name,
      errorsDollars: creditErrorsTotal.unfavorable_errors_dollars,
    });

    return {
      email: logistics_provider_email,
      name: logistics_provider_name,
      subject: subject,
      message: emailTemplate,
    };
  }, [creditErrorsTotal.unfavorable_errors_dollars, creditRecipientRequest.data, creditSummaryRequest.data]);

  const handlePrevious = () => stepControl.goToErrors();

  const handleSubmit = (creditEmail: ICreditEmailSchema) => {
    const payload = {
      recipient_email: creditEmail.email,
      email_subject: creditEmail.subject,
      email_text: creditEmail.message,
      invoice_id: invoiceName,
      is_test: isTest,
    };

    const filters = makeErrorFilters([...creditErrorsSelection.selection]);

    createCreditRequest.make(payload, filters);
    createCreditRequest.onFulfill(() => {
      if (!isTest) dialogControl.hideDialog();
    });
  };

  return (
    <CreditStep>
      {creditRecipientRequest.loading || creditSummaryRequest.loading ? (
        <Loading />
      ) : (
        <>
          <EmailForm id={FORM_ID} defaultValues={defaultValues} onSubmit={handleSubmit} />
          <CreditStepActions>
            <CreditStepAction>
              <Button variant="text" onClick={handlePrevious}>
                Previous
              </Button>
            </CreditStepAction>
            <CreditStepAction>
              <Button
                form={FORM_ID}
                type="submit"
                variant="outlined"
                disabled={createCreditRequest.loading}
                onClick={() => setIsTest(true)}>
                Send test
              </Button>
              <Button
                form={FORM_ID}
                type="submit"
                variant="contained"
                disabled={createCreditRequest.loading}
                onClick={() => setIsTest(false)}>
                Send
              </Button>
            </CreditStepAction>
          </CreditStepActions>
        </>
      )}
    </CreditStep>
  );
};

export default EmailStep;
