export const abortPromise = <T>(origin: Promise<T>, onAbort?: () => void) => {
  const pending = Promise.race.bind(Promise, []) as () => Promise<T>;
  let abort: () => void = () => ({});
  let aborted = false;

  const promise = new Promise<T>((resolve, reject) => {
    abort = () => {
      resolve(pending());
      onAbort?.();
      aborted = true;
    };

    origin.then(resolve, reject);
  });

  return { abort, aborted, promise };
};

export type IAbortPromise<T = unknown> = ReturnType<typeof abortPromise<T>>;

export const PROMISE_STATE = {
  PENDING: 'pending',
  REJECTED: 'rejected',
  FULFILLED: 'fulfilled',
} as const;

const PENDING_STATE = Symbol();

export const getPromiseState = (promise: Promise<unknown>): Promise<ValueOf<typeof PROMISE_STATE>> =>
  Promise.race([promise, Promise.resolve(PENDING_STATE)]).then(
    (value) => (value === PENDING_STATE ? PROMISE_STATE.PENDING : PROMISE_STATE.FULFILLED),
    () => PROMISE_STATE.REJECTED,
  );
