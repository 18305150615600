import { useMemo } from 'react';

import { IReconcileDataType } from 'src/types';
import { reconcileTypeQuery } from 'src/components/InvoiceReconcile';

export const useReconcileType = () => {
  const [queryParams, setQueryParams] = reconcileTypeQuery.useParams();

  const type = useMemo<IReconcileDataType>(() => queryParams.type, [queryParams.type]);
  const setType = (type: IReconcileDataType) => setQueryParams({ type });

  return { type, setType };
};
