import { PropsWithChildren } from 'react';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import dateFormatter from 'src/utils/dateFormatter';
import currencyFormatter from 'src/utils/currencyFormatter';
import OrderOperator from 'src/components/shared/OrderOperator';
import { IOrderFulfillmentTotal, IOrderCostSummaryTotal, IOrderFulfillmentDetail, IOrderTimeline } from 'src/types';

import {
  StyledTimeline,
  StyledTimelineNode,
  StyledTimelineDot,
  StyledFulfillmentDetails,
  StyledFulfillmentDetail,
  StyledTotalCostList,
  StyledTotalCostHead,
  StyledTotalCostRecords,
  StyledTotalCostRecord,
  StyledTotal,
} from './styles';

const formatUSD = currencyFormatter();
const formatDate = dateFormatter();

export const Timeline = ({ children }: PropsWithChildren) => <StyledTimeline>{children}</StyledTimeline>;

export interface ITimelineNodeProps {
  node: IOrderTimeline;
}

const getTimelineNodeTitle = (node: IOrderTimeline) => {
  switch (node.event) {
    case 'Order Date':
      return `${node.event} (t=0)`;
    default:
      return `${node.event} (${node.days_from_order} ${node.days_from_order > 1 ? 'days' : 'day'})`;
  }
};

export const TimelineNode = ({ node }: ITimelineNodeProps) => (
  <StyledTimelineNode>
    <Typography variant="h4">{formatDate(node.date)}</Typography>
    <StyledTimelineDot />
    <Typography variant="body1">{getTimelineNodeTitle(node)}</Typography>
  </StyledTimelineNode>
);

export interface IFulfillmentDetailProps {
  details: IOrderFulfillmentDetail;
}

export const FulfillmentDetails = ({ details }: IFulfillmentDetailProps) => (
  <StyledFulfillmentDetails>
    <StyledFulfillmentDetail>
      <Typography variant="h4">3PL</Typography>
      <Typography variant="body1">{details.logistics_provider}</Typography>
    </StyledFulfillmentDetail>
    <StyledFulfillmentDetail>
      <Typography variant="h4">Warehouse</Typography>
      <Typography variant="body1">{details.warehouse}</Typography>
    </StyledFulfillmentDetail>
    <StyledFulfillmentDetail>
      <Typography variant="h4">Rate card</Typography>
      <Typography variant="body1">{details.rate_card}</Typography>
    </StyledFulfillmentDetail>
    <Divider />
    <StyledFulfillmentDetail>
      <Typography variant="h4">Fulfillment Status</Typography>
      <Typography variant="body1">Coming soon</Typography>
    </StyledFulfillmentDetail>
    <StyledFulfillmentDetail>
      <Typography variant="h4">Shipping Status</Typography>
      <Typography variant="body1">Coming soon</Typography>
    </StyledFulfillmentDetail>
    <StyledFulfillmentDetail>
      <Typography variant="h4">Carrier</Typography>
      <Typography variant="body1">Coming soon</Typography>
    </StyledFulfillmentDetail>
    <StyledFulfillmentDetail>
      <Typography variant="h4">Service Level</Typography>
      <Typography variant="body1">Coming soon</Typography>
    </StyledFulfillmentDetail>
    <StyledFulfillmentDetail>
      <Typography variant="h4">Zone</Typography>
      <Typography variant="body1">Coming soon</Typography>
    </StyledFulfillmentDetail>
    <StyledFulfillmentDetail>
      <Typography variant="h4">Distance Travelled (mi)</Typography>
      <Typography variant="body1">Coming soon</Typography>
    </StyledFulfillmentDetail>
    <Divider />
    <StyledFulfillmentDetail>
      <Typography variant="h4">Address Type</Typography>
      <Typography variant="body1">Coming soon</Typography>
    </StyledFulfillmentDetail>
    <StyledFulfillmentDetail>
      <Typography variant="h4">Split Ship?</Typography>
      <Typography variant="body1">Coming soon</Typography>
    </StyledFulfillmentDetail>
    <StyledFulfillmentDetail>
      <Typography variant="h4">Order Weight (expected) (lbS.)</Typography>
      <Typography variant="body1">Coming soon</Typography>
    </StyledFulfillmentDetail>
  </StyledFulfillmentDetails>
);

export interface ITotalCostProps {
  totalFulfillment: IOrderFulfillmentTotal;
  totalErrors: number;
  total: IOrderCostSummaryTotal;
}

export const TotalCostList = ({ total, totalFulfillment, totalErrors }: ITotalCostProps) => (
  <StyledTotalCostList>
    <StyledTotalCostHead>
      <span></span>
      <Typography variant="h4">Expected</Typography>
      <Typography variant="h4" sx={(theme) => ({ padding: theme.spacing(0, 1.5) })}>
        VS
      </Typography>
      <Typography variant="h4">Invoiced</Typography>
      <Typography variant="h4">Error type</Typography>
    </StyledTotalCostHead>
    <StyledTotalCostRecords>
      <StyledTotalCostRecord>
        <Typography variant="h4" sx={{ color: 'text.primary' }}>
          {totalFulfillment.name}
        </Typography>
        <Typography variant="h4">{formatUSD(totalFulfillment.expected_rate)}</Typography>
        <span>
          <OrderOperator operator={totalFulfillment.operator} />
        </span>
        <Typography variant="h4">{formatUSD(totalFulfillment.invoiced_rate)}</Typography>
        <Typography variant="body1">{totalFulfillment.error_types}</Typography>
      </StyledTotalCostRecord>
    </StyledTotalCostRecords>
    <StyledTotal>
      <Divider />
      <StyledTotalCostRecord>
        <Typography variant="h4">Total</Typography>
        <Typography variant="h4">{formatUSD(total.expected_rate)}</Typography>
        <span>
          <OrderOperator operator={total.operator} />
        </span>
        <Typography variant="h4">{formatUSD(total.invoiced_rate)}</Typography>
        <span />
      </StyledTotalCostRecord>
      <Divider />
      <StyledTotalCostRecord $accent>
        <Typography variant="h4">Errors total</Typography>
        <span />
        <Typography variant="h4">{formatUSD(totalErrors)}</Typography>
        <span />
        <span />
      </StyledTotalCostRecord>
    </StyledTotal>
  </StyledTotalCostList>
);
