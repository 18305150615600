import dayjs from 'dayjs';
import { DateParam, StringParam, createEnumParam, withDefault } from 'use-query-params';

import {
  SortDirection,
  InvoiceSortBy,
  InvoiceDataType,
  IInvoicesFilters,
  InvoiceDetailDataSortBy,
  InvoicesFiltersConfig,
} from 'src/types';
import { makeQueryFilter } from 'src/components/shared/QueryFilter';
import { makeNamedQueryParams } from 'src/effects/useNamedQueryParams';

const InvoiceTypeParam = withDefault(createEnumParam(Object.values(InvoiceDataType)), InvoiceDataType.PICK_AND_PACK);
const SortDirectionParam = withDefault(createEnumParam(Object.values(SortDirection)), SortDirection.DESC);
const InvoiceSortParam = withDefault(createEnumParam(Object.values(InvoiceSortBy)), InvoiceSortBy.INVOICE_END_DATE);
const InvoiceDetailsSortParam = withDefault(
  createEnumParam(Object.values(InvoiceDetailDataSortBy)),
  InvoiceDetailDataSortBy.unfavorable_errors_dollars,
);
const InvoiceDateFormParam = withDefault(DateParam, dayjs().subtract(3, 'month').toDate());
const InvoiceDateToParam = withDefault(DateParam, dayjs().toDate());

export const invoicesFilterQuery = makeQueryFilter<IInvoicesFilters>('if', InvoicesFiltersConfig);

export const invoicesSortQuery = makeNamedQueryParams('is', {
  by: InvoiceSortParam,
  order: SortDirectionParam,
});

export const invoicesDateRangeQuery = makeNamedQueryParams('ir', {
  from: InvoiceDateFormParam,
  to: InvoiceDateToParam,
});

export const invoiceDetailsQuery = makeNamedQueryParams('id', {
  type: InvoiceTypeParam,
  name: StringParam,
});

export const pickAndPackQuery = makeNamedQueryParams('pp', {
  by: InvoiceDetailsSortParam,
  order: SortDirectionParam,
});

export const servicesQuery = makeNamedQueryParams('ss', {
  by: InvoiceDetailsSortParam,
  order: SortDirectionParam,
});
