import dayjs, { Dayjs, ManipulateType } from 'dayjs';
import { memo, useMemo } from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

import { IDateRange } from './DateRangeCalendar';

export type IDateRangePeriod = {
  label: string;
  offset?: {
    value: number;
    type: ManipulateType;
  };
};

export interface IPickerPeriodProps {
  isRange: boolean;
  range: Maybe<IDateRange>;
  periods: IDateRangePeriod[];
  onPeriodChange: (range: Maybe<IDateRange>) => void;
}

export const DEFAULT_PERIODS: IDateRangePeriod[] = [
  {
    label: 'Last 7 days',
    offset: {
      value: 7,
      type: 'day',
    },
  },
  {
    label: 'Last 15 days',
    offset: {
      value: 15,
      type: 'day',
    },
  },
  {
    label: 'Last 30 days',
    offset: {
      value: 30,
      type: 'day',
    },
  },
  {
    label: 'Last year',
    offset: {
      value: 1,
      type: 'year',
    },
  },
  /*{
    label: 'All time',
  },*/
];

const makePeriodControl = (today: Dayjs, offset: IDateRangePeriod['offset']) => {
  const range: Maybe<IDateRange> = offset
    ? {
        from: today.subtract(offset.value, offset.type),
        to: today,
      }
    : null;

  const isSame = (comparisonRange: Maybe<IDateRange>) => {
    if (!comparisonRange && !range) return true;

    if ((!!comparisonRange && !range) || (!comparisonRange && !!range)) return false;

    return comparisonRange?.from.isSame(range?.from, 'day') && comparisonRange?.to.isSame(range?.to, 'day');
  };

  return {
    range,
    isSame,
  };
};

const makePeriodsControl = (today: Dayjs, periods: IDateRangePeriod[]) =>
  periods.map((period) => ({
    ...period,
    ...makePeriodControl(today, period.offset),
  }));

const PickerPeriod = ({ range, isRange, periods, onPeriodChange }: IPickerPeriodProps) => {
  const today = useMemo(() => dayjs(), []);
  const controls = useMemo(() => makePeriodsControl(today, periods), [periods]);

  return (
    <>
      {controls.map((control) => (
        <FormControlLabel
          slotProps={{
            typography: {
              variant: 'body2',
            },
          }}
          key={control.label}
          value={control.label}
          label={control.label}
          control={<Radio checked={isRange && control.isSame(range)} />}
          onClick={() => onPeriodChange(control.range)}
        />
      ))}
    </>
  );
};

export default memo(PickerPeriod);
