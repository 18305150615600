import { PropsWithChildren, createContext, useContext } from 'react';

import { IAnomaliesAggregation, IAnomalyDetails, IAnomalyOrder, IAnomalyOrdersFilters } from 'src/types';
import makeRequest from 'src/effects/makeRequest';
import * as apiAnomaly from 'src/api/anomaly';

import { useAnomaliesFilters, useAnomaliesSorting, useDialogControl } from './freightControl';

export interface IFreightContext {
  anomaliesRequest: ReturnType<typeof useAnomaliesRequest<MaybeNull<IAnomaliesAggregation>>>;
  anomalyOrdersRequest: ReturnType<typeof useAnomalyOrdersRequest<MaybeNull<IAnomalyOrder[]>>>;
  anomaliesFiltersRequest: ReturnType<typeof useAnomaliesFiltersRequest<MaybeNull<IAnomalyOrdersFilters>>>;
  anomalyDetailsRequest: ReturnType<typeof useAnomalyDetailsRequest<MaybeNull<IAnomalyDetails>>>;

  anomaliesSorting: ReturnType<typeof useAnomaliesSorting>;
  anomaliesFilters: ReturnType<typeof useAnomaliesFilters>;

  dialogControl: ReturnType<typeof useDialogControl>;
}

const useAnomaliesRequest = makeRequest(apiAnomaly.getAnomalies);
const useAnomalyOrdersRequest = makeRequest(apiAnomaly.getAnomalyOrders);
const useAnomaliesFiltersRequest = makeRequest(apiAnomaly.getAnomaliesFilters);
const useAnomalyDetailsRequest = makeRequest(apiAnomaly.getAnomalyDetails);

export const FreightContext = createContext<IFreightContext>({} as IFreightContext);

export const FreightProvider = ({ children }: PropsWithChildren) => {
  const anomaliesRequest = useAnomaliesRequest(null);
  const anomalyOrdersRequest = useAnomalyOrdersRequest([], true);
  const anomaliesFiltersRequest = useAnomaliesFiltersRequest(null);
  const anomalyDetailsRequest = useAnomalyDetailsRequest(null, true);

  const anomaliesSorting = useAnomaliesSorting();
  const anomaliesFilters = useAnomaliesFilters(anomaliesFiltersRequest.data);

  const dialogControl = useDialogControl();

  return (
    <FreightContext.Provider
      value={{
        anomaliesRequest: anomaliesRequest,
        anomalyOrdersRequest: anomalyOrdersRequest,
        anomaliesFiltersRequest: anomaliesFiltersRequest,
        anomalyDetailsRequest: anomalyDetailsRequest,

        anomaliesSorting: anomaliesSorting,
        anomaliesFilters: anomaliesFilters,

        dialogControl: dialogControl,
      }}>
      {children}
    </FreightContext.Provider>
  );
};

export const useFreightContext = () => useContext(FreightContext);
