import { memo, useEffect } from 'react';
import { FullStory as FS } from '@fullstory/browser';

import { FULLSTORY_ORG_ID } from 'src/config/env';
import { authService } from 'src/services/AuthService';

const FullStory = () => {
  if (!FULLSTORY_ORG_ID) return null;

  useEffect(() => {
    FS('setIdentity', {
      uid: `${authService.user.id}`,
      properties: {
        displayName: `${authService.user.first_name} ${authService.user.last_name}`,
        email: `${authService.user.email}`,
      },
    });

    FS('start');

    return () => {
      FS('shutdown');
    };
  }, []);

  return null;
};

export default memo(FullStory);
