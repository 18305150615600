import { TableHeaderRow, TableHeaderCell, TableLeadCell } from 'src/components/shared/Table';

export const UserHeaders = () => (
  <TableHeaderRow>
    <TableLeadCell />
    <TableHeaderCell>User name</TableHeaderCell>
    <TableHeaderCell>Company name</TableHeaderCell>
    <TableHeaderCell>Email</TableHeaderCell>
    <TableHeaderCell>Created at</TableHeaderCell>
    <TableHeaderCell>Role</TableHeaderCell>
    <TableHeaderCell>Status</TableHeaderCell>
  </TableHeaderRow>
);
