import { PropsWithChildren } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import { ForgotPasswordSchema, IForgotPasswordSchema } from 'src/schemas';
import { FormFields, FormInput, FormText } from 'src/components/shared/Form';

import { StyledAuthError, StyledForm, StyledFormFooter } from './styles';

export interface IForgotPasswordFormProps {
  error?: Maybe<string>;
  loading: boolean;
  onSubmit: (auth: IForgotPasswordSchema) => void;
  onCancel: () => void;
}

export const ForgotPasswordForm = ({
  error,
  loading,
  onSubmit,
  onCancel,
}: PropsWithChildren<IForgotPasswordFormProps>) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IForgotPasswordSchema>({
    resolver: yupResolver(ForgotPasswordSchema),
  });

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <FormFields>
        <FormInput>
          <FormText
            name="username"
            autoComplete="username"
            label="Email *"
            placeholder="Enter your email"
            control={control}
            errorMessage={errors?.username?.message}
          />
        </FormInput>
      </FormFields>
      <StyledFormFooter>
        {!!error && <StyledAuthError>{error}</StyledAuthError>}
        <Button variant="contained" type="submit" disabled={loading}>
          Send code
        </Button>
        <Button component={Link} onClick={onCancel} disabled={loading}>
          Back to Sign In
        </Button>
      </StyledFormFooter>
    </StyledForm>
  );
};
