import Typography from '@mui/material/Typography';

import { AnalyticServiceType } from 'src/types';
import { analyticsDetailsQuery } from 'src/components/AnalyticService';

import SubmenuLink from './SubmenuLink';
import { StyledSubmenuContainer, StyledSubmenuSegment, StyledSubmenuGroup, StyledSubmenuNav } from './styles';

export const ReportingSubmenu = () => (
  <StyledSubmenuContainer>
    <StyledSubmenuSegment>
      <StyledSubmenuGroup>
        <Typography variant="h3">Analytics</Typography>
        <StyledSubmenuNav>
          <SubmenuLink
            to={`/analytic/services?${analyticsDetailsQuery.getSearchString({
              type: AnalyticServiceType.SUMMARY,
            })}`}>
            Summary
          </SubmenuLink>
          <SubmenuLink
            to={`/analytic/services?${analyticsDetailsQuery.getSearchString({
              type: AnalyticServiceType.PICK_AND_PACK,
            })}`}>
            Pick and Pack
          </SubmenuLink>
          <SubmenuLink disabled to={'/'}>
            Freight
          </SubmenuLink>
          <SubmenuLink
            to={`/analytic/services?${analyticsDetailsQuery.getSearchString({
              type: AnalyticServiceType.OTHER,
            })}`}>
            Other
          </SubmenuLink>
        </StyledSubmenuNav>
      </StyledSubmenuGroup>
      <StyledSubmenuGroup>
        <Typography variant="h3">Reporting</Typography>
        <StyledSubmenuNav>
          <SubmenuLink to={'/analytic/reports'}>All Reports</SubmenuLink>
        </StyledSubmenuNav>
      </StyledSubmenuGroup>
    </StyledSubmenuSegment>
  </StyledSubmenuContainer>
);
