import { memo, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';

import { StyledNavigation, StyledNavigationContent, StyledLeftIcon, StyledRightIcon } from './styles';

type LinkId = string | number;

export interface ILinkNavigationProps {
  linkId: LinkId;
  linkIds: LinkId[];
  makeLink: (id: LinkId) => string;
  makeSearch?: (id: LinkId, search: string) => string;
  title: (params: {
    prev: MaybeNull<LinkId>;
    next: MaybeNull<LinkId>;
    linkId: LinkId;
    linkIds: LinkId[];
  }) => JSX.Element;
}

const LinkNavigation = ({ linkId, linkIds, title, makeLink, makeSearch }: ILinkNavigationProps) => {
  const { search, state } = useLocation();
  const { prev, next } = useMemo<{ prev: MaybeNull<LinkId>; next: MaybeNull<LinkId> }>(() => {
    const currentId = linkIds.indexOf(linkId);

    return {
      prev: linkIds[currentId - 1] ?? null,
      next: linkIds[currentId + 1] ?? null,
    };
  }, [linkId, linkIds]);

  const buildLink = (param: MaybeNull<LinkId>) =>
    param ? `${makeLink(param)}${makeSearch?.(param, search) ?? search}` : '';

  return (
    <StyledNavigation>
      <Button component={NavLink} variant="text" disabled={!prev} replace to={buildLink(prev)} state={state}>
        <StyledLeftIcon />
      </Button>
      <StyledNavigationContent>{title({ prev, next, linkId, linkIds })}</StyledNavigationContent>
      <Button component={NavLink} variant="text" disabled={!next} replace to={buildLink(next)} state={state}>
        <StyledRightIcon />
      </Button>
    </StyledNavigation>
  );
};

export default memo(LinkNavigation) as typeof LinkNavigation;
