import { AnalyticReportList, analyticsReportsSortQuery, REPORTS_DATA } from 'src/components/AnalyticReport';
import { PageTitle, PageContainer } from 'src/components/shared/Page';
import useSortedData from 'src/effects/useDataSorting';

export default function AnalyticReportsPage() {
  const [sortParams, setSortParams] = analyticsReportsSortQuery.useParams();
  const sortedReports = useSortedData(REPORTS_DATA, sortParams);

  return (
    <>
      <PageTitle title={'Reporting'}></PageTitle>
      <PageContainer>
        <AnalyticReportList reports={sortedReports} sort={sortParams} onSort={setSortParams} />
      </PageContainer>
    </>
  );
}
