import { useCallback } from 'react';

import { TableHeaderRow, TableHeaderCellSort, TableLeadCell } from 'src/components/shared/Table';
import { IInvoiceDetailDataSortBy, ISortProps, InvoiceDetailDataSortBy, SortDirection } from 'src/types';

export const PickAndPackHeaders = ({ sort, onSort }: ISortProps<IInvoiceDetailDataSortBy>) => {
  const handleSortChange = useCallback(
    (fieldName: IInvoiceDetailDataSortBy) => {
      if (sort.by !== fieldName) {
        onSort({
          by: fieldName,
          order: SortDirection.DESC,
        });
      } else {
        onSort({
          ...sort,
          order: sort.order === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
        });
      }
    },
    [sort, onSort],
  );

  return (
    <TableHeaderRow>
      <TableLeadCell />
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === InvoiceDetailDataSortBy.service_name}
        onClick={() => handleSortChange(InvoiceDetailDataSortBy.service_name)}>
        Invoice Lines
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === InvoiceDetailDataSortBy.audit_depth}
        onClick={() => handleSortChange(InvoiceDetailDataSortBy.audit_depth)}>
        Audit Depth
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === InvoiceDetailDataSortBy.logistics_charges}
        onClick={() => handleSortChange(InvoiceDetailDataSortBy.logistics_charges)}>
        Charges
      </TableHeaderCellSort>
      {/*<TableHeaderCellSort*/}
      {/*  order={sort.order}*/}
      {/*  active={sort.by === InvoiceDetailDataSortBy.unfavorable_errors_count}*/}
      {/*  onClick={() => handleSortChange(InvoiceDetailDataSortBy.unfavorable_errors_count)}>*/}
      {/*  Errors (#)*/}
      {/*</TableHeaderCellSort>*/}
      {/*<TableHeaderCellSort*/}
      {/*  order={sort.order}*/}
      {/*  active={sort.by === InvoiceDetailDataSortBy.unfavorable_errors_dollars}*/}
      {/*  onClick={() => handleSortChange(InvoiceDetailDataSortBy.unfavorable_errors_dollars)}>*/}
      {/*  Errors $*/}
      {/*</TableHeaderCellSort>*/}
    </TableHeaderRow>
  );
};
