import { IBrandListItem, IBrandSortBy, ISortProps } from 'src/types';
import { TableBody } from 'src/components/shared/Table';

import { BrandRow } from './BrandRow';
import { BrandHeaders } from './BrandHeaders';

const NoBrands = () => <>You don&apos;t have any confirmed companies.</>;

export interface IBrandListProps extends ISortProps<IBrandSortBy> {
  brands: IBrandListItem[];
}

export const BrandList = ({ brands, sort, onSort }: IBrandListProps) => {
  if (!brands.length) return <NoBrands />;

  return (
    <TableBody>
      <BrandHeaders sort={sort} onSort={onSort} />
      <>
        {brands.map((brand) => (
          <BrandRow brand={brand} key={`brand-row-${brand.id}`} />
        ))}
      </>
    </TableBody>
  );
};
