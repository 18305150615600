import { createTheme } from '@mui/material/styles';

import shape from './shape';
import shadows from './shadows';
import palette from './palette';
import typography from './typography';
import components from './components';

export const theme = createTheme({
  shape,
  shadows,
  palette,
  typography,
  components,
});
