import { memo } from 'react';

import { AuthContent, LoginForm, MessageGreeting } from 'src/components/Auth';
import { AuthChallengeType, IAuthChallengeLogin } from 'src/types';
import { ILoginSchema } from 'src/schemas';
import makeRequest from 'src/effects/makeRequest';
import * as apiAuth from 'src/api/auth';

import { IStepComponent } from './types';

const useLoginRequest = makeRequest(apiAuth.login, { silent: true });

const Login: IStepComponent<IAuthChallengeLogin> = ({ onChallenge }) => {
  const loginRequest = useLoginRequest(null, false);

  const handleSubmit = (auth: ILoginSchema) => {
    loginRequest.make(auth);
    loginRequest.onFulfill(onChallenge);
  };
  const handleForgotPassword = () =>
    onChallenge({
      type: AuthChallengeType.FORGOT_PASSWORD,
      payload: null,
    });

  return (
    <AuthContent>
      <MessageGreeting title="Sign in" description="Good to see you back!" />
      <LoginForm
        error={loginRequest.error?.detail}
        loading={loginRequest.loading}
        onSubmit={handleSubmit}
        onForgotPassword={handleForgotPassword}
      />
    </AuthContent>
  );
};

export default memo(Login) as typeof Login;
