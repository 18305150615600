import * as yup from 'yup';

import { IBrandStatus, BrandStatus } from 'src/types';

export const BrandSchema = yup.object().shape({
  status: yup.string().required().oneOf(Object.values<IBrandStatus>(BrandStatus)).label('Company status'),
  name: yup.string().required().label('Company name'),
  email: yup.string().required().email().label('Company email'),
});

export type IBrandSchema = yup.InferType<typeof BrandSchema>;
