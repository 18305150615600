import { memo } from 'react';
import { useTheme } from '@emotion/react';
import Typography from '@mui/material/Typography';

import { StyledStepper, StyledStepDot, StyledStep } from './styles';

const useStepColor = ({ steps, step }: IStepperProps) => {
  const { palette } = useTheme();
  const stepIndex = steps.indexOf(step);

  return (index: number) => {
    const isCompleted = index < stepIndex;
    const isActive = index === stepIndex;

    switch (true) {
      case isCompleted:
        return palette.success.main;
      case isActive:
        return palette.primary.main;
      default:
        return palette.text.secondary;
    }
  };
};

export interface IStepperProps {
  steps: string[];
  step: string;
}

const Stepper = ({ steps, step }: IStepperProps) => {
  const getStepColor = useStepColor({ steps, step });

  return (
    <StyledStepper>
      {steps.map((step, index) => (
        <StyledStep key={step}>
          <Typography variant="h4">{index + 1}</Typography>
          <StyledStepDot $color={getStepColor(index)} />
          <Typography variant="body1">{step}</Typography>
        </StyledStep>
      ))}
    </StyledStepper>
  );
};

export default memo(Stepper);
