import { Fragment } from 'react';
import Typography from '@mui/material/Typography';

import { IPickAndPackRate, IRateCardDetails } from 'src/types';
import dateFormatter from 'src/utils/dateFormatter';

import RateCardStatus from './RateCardStatus';
import { StyledContainer, StyledBlock, StyledFieldName, StyledFieldValue, StyledGroup } from './styles';

interface IRateRule {
  description: string;
  normal: number | string;
  peak: number | string;
}

const formatDate = dateFormatter();

const getRules = (rates: IPickAndPackRate[]): IRateRule[] => {
  const [normal, peak] = rates;
  const { rate_details: normalDetails } = normal;
  const { rate_details: peakDetails } = peak;

  const dateRules: IRateRule[] = [
    { description: 'Start on', normal: formatDate(normal.effective_from), peak: formatDate(peak.effective_from) },
    { description: 'End on', normal: formatDate(normal.effective_to), peak: formatDate(peak.effective_to) },
  ];

  const rateRules = normalDetails.map<IRateRule>((normalDetail, index) => ({
    description: normalDetail.description,
    normal: normalDetail.value,
    peak: peakDetails[index].value,
  }));

  return [...dateRules, ...rateRules];
};

export default function RateCardDetails({ rateCard }: { rateCard: IRateCardDetails }) {
  const rules = getRules(rateCard.rates);

  return (
    <StyledContainer>
      <Typography variant="h2">General Information</Typography>
      <StyledBlock>
        <StyledFieldName>Status:</StyledFieldName>
        <StyledFieldValue>
          <RateCardStatus status={rateCard.status} />
        </StyledFieldValue>
        <StyledFieldName>Name:</StyledFieldName>
        <StyledFieldValue>{rateCard.name}</StyledFieldValue>
        <StyledFieldName>3Pl:</StyledFieldName>
        <StyledFieldValue>{rateCard.logistics_provider_id.name}</StyledFieldValue>
        <StyledFieldName>Warehouse:</StyledFieldName>
        <StyledFieldValue>{rateCard.warehouse_id.name}</StyledFieldValue>
        <StyledFieldName>Company:</StyledFieldName>
        <StyledFieldValue>{rateCard.brand}</StyledFieldValue>
        <StyledFieldName>Order Type:</StyledFieldName>
        <StyledFieldValue>{rateCard.order_type}</StyledFieldValue>
      </StyledBlock>
      <Typography variant="h2">Rate Card Rules</Typography>
      <StyledBlock>
        <StyledFieldName>
          <Typography variant="h4">Line item</Typography>
        </StyledFieldName>
        <StyledGroup>
          <Typography variant="h4">Normal</Typography>
          <Typography variant="h4">Peak</Typography>
        </StyledGroup>
        {rules.map((rule) => (
          <Fragment key={rule.description}>
            <StyledFieldName>{rule.description}:</StyledFieldName>
            <StyledGroup>
              <StyledFieldValue>{rule.normal}</StyledFieldValue>
              <StyledFieldValue>{rule.peak}</StyledFieldValue>
            </StyledGroup>
          </Fragment>
        ))}
      </StyledBlock>
    </StyledContainer>
  );
}
