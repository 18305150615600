import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageTitle, PageContainer, Loading } from 'src/components/shared/Page';
import makeRequest from 'src/effects/makeRequest';
import * as apiOrder from 'src/api/order';

const useLatestOrderRequest = makeRequest(apiOrder.getLatestOrder);

export default function LatestOrderPage() {
  const latestOrderRequest = useLatestOrderRequest(null, true);
  const navigate = useNavigate();

  useEffect(() => {
    latestOrderRequest.make();
    latestOrderRequest.onFulfill(({ invoice_id, order_number }) =>
      navigate(`/invoice/${encodeURIComponent(invoice_id)}/order/${encodeURIComponent(order_number)}`, {
        replace: true,
      }),
    );
    latestOrderRequest.onReject(() => navigate('/error'));
  }, []);

  return (
    <>
      <PageTitle title={'Order details'} />
      <PageContainer>
        <Loading />
      </PageContainer>
    </>
  );
}
