import { memo, useEffect } from 'react';

import { Loading } from 'src/components/shared/Page';
import Message from 'src/components/shared/Message';
import { ServicesList } from 'src/components/InvoiceServices';
import { PickAndPackList } from 'src/components/InvoicePickAndPack';
import { IInvoiceDataType, InvoiceDataType } from 'src/types';

import { useInvoiceDetailsContext } from './InvoiceDetailsContext';

const NAV_MAP: Record<string, IInvoiceDataType> = {
  'Pick and pack': InvoiceDataType.PICK_AND_PACK,
  Freight: InvoiceDataType.FREIGHT,
};

const NAV_SERVICES = Object.keys(NAV_MAP) as string[];

const InvoiceDetails = () => {
  const {
    invoiceDetailsControl,
    pickAndPackControl,
    servicesControl,
    summaryData,
    pickAndPackData,
    invoiceServicesRequest,
  } = useInvoiceDetailsContext();

  const handleServiceTypeClick = (name: string) => {
    const dataType = NAV_MAP[name];

    if (dataType) invoiceDetailsControl.setType(dataType);
  };

  useEffect(() => {
    if (!invoiceDetailsControl.name) invoiceServicesRequest.cleanup();
    else invoiceServicesRequest.make(invoiceDetailsControl.name);
  }, [invoiceDetailsControl.name]);

  if (!invoiceDetailsControl.name) return <Message>Select invoice to see details</Message>;

  if (invoiceDetailsControl.type === InvoiceDataType.PICK_AND_PACK) {
    return (
      <PickAndPackList
        loading={invoiceServicesRequest.loading}
        params={pickAndPackControl.params}
        changeSorting={pickAndPackControl.changeSorting}
        data={pickAndPackData}
        total={invoiceServicesRequest.data?.pnp_services.total}
      />
    );
  }

  if (invoiceDetailsControl.type === InvoiceDataType.SUMMARY_SERVICE) {
    return (
      <ServicesList
        navServices={NAV_SERVICES}
        onServiceTypeClick={handleServiceTypeClick}
        loading={invoiceServicesRequest.loading}
        params={servicesControl.params}
        changeSorting={servicesControl.changeSorting}
        data={summaryData}
        total={invoiceServicesRequest.data?.summary_services.total}
      />
    );
  }

  return <Loading />;
};

export default memo(InvoiceDetails);
