import { useEffect } from 'react';
import { Control, FieldErrors } from 'react-hook-form';

import { FormText, FormFile, IFileController, FormFields, FormInput } from 'src/components/shared/Form';
import { IRateCardSchema } from 'src/schemas';

import { IFormBlockErrorProps } from './RateCardBlock';

export interface IRateCardGeneralProps {
  errors: FieldErrors<IRateCardSchema>;
  control: Control<IRateCardSchema>;
  fileController: IFileController;
}

export const RateCardGeneral = ({
  fileController,
  control,
  errors,
  onError,
}: IRateCardGeneralProps & IFormBlockErrorProps) => {
  const hasErrors = !!(errors.name || errors.fileUploadId);

  useEffect(() => onError(hasErrors), [onError, hasErrors]);

  return (
    <FormFields>
      <FormInput>
        <FormText control={control} label="Nickname" name="name" errorMessage={errors.name?.message} />
      </FormInput>
      <FormInput>
        <FormFile
          label="Upload PDF"
          control={control}
          name="fileUploadId"
          controller={fileController}
          errorMessage={errors.fileUploadId?.message}
        />
      </FormInput>
    </FormFields>
  );
};
