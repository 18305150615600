import { IS_EXPERIMENTAL } from 'src/config/env';
import { IRoutes } from 'src/components/Router';
import PrivateLayout from 'src/layouts/Private';
import PublicLayout from 'src/layouts/Public';
import EntryPage from 'src/pages/Entry';
import LoginPage from 'src/pages/Login';
import DashboardPage from 'src/pages/Dashboard';
import InvoicesAllPage from 'src/pages/Invoices/InvoicesAll';
import InvoicesDetailsPage from 'src/pages/Invoices/InvoicesDetails';
import ReconciliationPage from 'src/pages/Invoices/Reconciliation';
import ReconciliationLatestPage from 'src/pages/Invoices/ReconciliationLatest';
import OrderPage from 'src/pages/Invoices/Order';
import OrderLatestPage from 'src/pages/Invoices/OrderLatest';
import CreditTrackerPage from 'src/pages/Invoices/CreditTracker';
import BrandDetailsPage from 'src/pages/Admin/BrandDetails';
import BrandListPage from 'src/pages/Admin/BrandList';
import BrandNewPage from 'src/pages/Admin/BrandNew';
import ErrorPage from 'src/pages/Error';
import NotFoundPage from 'src/pages/NotFound';
import RateCardDetailsPage from 'src/pages/Settings/RateCardDetails';
import RateCardNewPage from 'src/pages/Settings/RateCardNew';
import RateCardEditPage from 'src/pages/Settings/RateCardEdit';
import RateCardListPage from 'src/pages/Settings/RateCardList';
import LogisticsListPage from 'src/pages/Settings/LogisticsList';
import WarehousePage from 'src/pages/Settings/Warehouse';
import ThreePLPage from 'src/pages/Settings/3PL';
import UserListPage from 'src/pages/Admin/UserList';
import CreateUserPage from 'src/pages/Admin/CreateUser';
import AnalyticServicesPage from 'src/pages/Analytic/Services';
import AnalyticReportsPage from 'src/pages/Analytic/Reports';
import AnalyticReportPage from 'src/pages/Analytic/Report';
import PerformancePage from 'src/pages/Performance';
import AnomalyFreightPage from 'src/pages/Anomaly/AnomalyFreightPage';
import { WithRoles } from 'src/access';
import { UserRole } from 'src/types';

export const ROUTES_CONFIG: IRoutes = {
  '/': {
    element: PrivateLayout,
    routes: {
      '': {
        element: EntryPage,
      },
      '/dashboard': {
        element: WithRoles(DashboardPage, UserRole.CUSTOMER_ADMIN, UserRole.CUSTOMER_USER),
      },
      '/invoices': {
        element: WithRoles(InvoicesAllPage, UserRole.CUSTOMER_ADMIN, UserRole.CUSTOMER_USER),
      },
      '/invoices/details': {
        element: WithRoles(InvoicesDetailsPage, UserRole.CUSTOMER_ADMIN, UserRole.CUSTOMER_USER),
      },
      '/invoice/latest/reconcile': {
        element: WithRoles(ReconciliationLatestPage, UserRole.CUSTOMER_ADMIN, UserRole.CUSTOMER_USER),
      },
      '/invoice/:name/reconcile': {
        element: WithRoles(ReconciliationPage, UserRole.CUSTOMER_ADMIN, UserRole.CUSTOMER_USER),
      },
      '/invoice/latest/order': {
        element: WithRoles(OrderLatestPage, UserRole.CUSTOMER_ADMIN, UserRole.CUSTOMER_USER),
      },
      '/invoice/:name/order/:id': {
        element: WithRoles(OrderPage, UserRole.CUSTOMER_ADMIN, UserRole.CUSTOMER_USER),
      },
      '/invoice/credits/tracker': {
        element: WithRoles(CreditTrackerPage, UserRole.CUSTOMER_ADMIN, UserRole.CUSTOMER_USER),
      },
      '/settings/ratecard': {
        element: WithRoles(RateCardNewPage, UserRole.CUSTOMER_ADMIN),
      },
      '/settings/ratecard/:id': {
        element: WithRoles(RateCardDetailsPage, UserRole.CUSTOMER_ADMIN, UserRole.CUSTOMER_USER),
      },
      '/settings/ratecard/:id/edit': {
        element: WithRoles(RateCardEditPage, UserRole.CUSTOMER_ADMIN),
      },
      '/settings/ratecards': {
        element: WithRoles(RateCardListPage, UserRole.CUSTOMER_ADMIN, UserRole.CUSTOMER_USER),
      },
      '/settings/users': {
        element: WithRoles(UserListPage, UserRole.CUSTOMER_ADMIN, UserRole.CUSTOMER_USER),
      },
      '/settings/logistics': {
        element: WithRoles(LogisticsListPage, UserRole.CUSTOMER_ADMIN, UserRole.CUSTOMER_USER),
      },
      '/settings/logistics/3pl': {
        element: WithRoles(ThreePLPage, UserRole.CUSTOMER_ADMIN),
      },
      '/settings/logistics/warehouse': {
        element: WithRoles(WarehousePage, UserRole.CUSTOMER_ADMIN),
      },
      '/system/companies': {
        element: WithRoles(BrandListPage, UserRole.IMPLENTIO_ADMIN),
      },
      '/system/company': {
        element: WithRoles(BrandNewPage, UserRole.IMPLENTIO_ADMIN),
      },
      '/system/company/:id': {
        element: WithRoles(BrandDetailsPage, UserRole.IMPLENTIO_ADMIN),
      },
      '/system/users': {
        element: WithRoles(UserListPage, UserRole.IMPLENTIO_ADMIN),
      },
      '/system/user': {
        element: WithRoles(CreateUserPage, UserRole.IMPLENTIO_ADMIN),
      },
      '/analytic/services': {
        element: WithRoles(AnalyticServicesPage, UserRole.CUSTOMER_ADMIN, UserRole.CUSTOMER_USER),
      },
      '/analytic/reports': {
        element: WithRoles(AnalyticReportsPage, UserRole.CUSTOMER_ADMIN, UserRole.CUSTOMER_USER),
      },
      '/analytic/report/:type': {
        element: WithRoles(AnalyticReportPage, UserRole.CUSTOMER_ADMIN, UserRole.CUSTOMER_USER),
      },
      '/performance': {
        element: WithRoles(PerformancePage, UserRole.CUSTOMER_ADMIN, UserRole.CUSTOMER_USER),
      },
      ...(IS_EXPERIMENTAL
        ? {
            '/anomaly/freight': {
              element: WithRoles(AnomalyFreightPage, UserRole.CUSTOMER_ADMIN, UserRole.CUSTOMER_USER),
            },
          }
        : {}),
    },
  },
  '/login': {
    element: PublicLayout,
    routes: {
      '': {
        element: LoginPage,
      },
    },
  },
  '/error': {
    element: ErrorPage,
  },
  '*': {
    element: NotFoundPage,
  },
};
