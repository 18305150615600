import { ID, IFilter } from './common';
import { IInvoiceStatus, ISalesOrderStatus } from './invoice';

export type ICreditStepType = ValueOf<typeof CreditStepType>;

export const CreditStepType = {
  Errors: 'Error types',
  Email: 'Email',
  Tags: 'Tags',
} as const;

export type ICreditError = {
  service: string;
  error_name: string;
  error_code: string;
  unfavorable_errors_count: number;
  unfavorable_errors_dollars: number;
};

export const CreditErrorSortBy: { readonly [K in ICreditErrorSortBy]: K } = {
  service: 'service',
  error_name: 'error_name',
  unfavorable_errors_count: 'unfavorable_errors_count',
  unfavorable_errors_dollars: 'unfavorable_errors_dollars',
} as const;

export type ICreditErrorSortBy = Extract<
  keyof ICreditError,
  'service' | 'error_name' | 'unfavorable_errors_dollars' | 'unfavorable_errors_count'
>;

export interface ICreditSummaryCount {
  status: ISalesOrderStatus;
  count: number;
}

export interface ICreditSummary {
  status: IInvoiceStatus;
  end_date: string;
  start_date: string;
  invoice_name: string;
  total_orders: number;
  total_charges: number;
  orders_error_count: ICreditSummaryCount[];
}

export interface ICreditRecipient {
  logistics_provider_email: string;
  logistics_provider_name: string;
  sender_name: string;
  subject: string;
}

export interface ICreditRequest {
  is_test: boolean;
  invoice_id: string;
  email_text: string;
  email_subject: string;
  recipient_email: string;
}

export const CreditStatus = {
  SENT: 'SENT',
  DENIED: 'DENIED',
  RECEIVED: 'RECEIVED',
  CANCELLED: 'CANCELLED',
  PARTIALLY_RECEIVED: 'PARTIALLY_RECEIVED',
};

export type ICreditStatus = ValueOf<typeof CreditStatus>;

export interface ICredit {
  id: ID;
  invoice_number: string;
  errors_dollars: number;
  errors_count: number;
  submit_date: string;
  status: ICreditStatus;
  credit_request_number: string;
}

export type ICreditSortBy = Extract<
  keyof ICredit,
  'id' | 'invoice_number' | 'errors_dollars' | 'errors_count' | 'submit_date' | 'status'
>;

export const CreditSortBy: { readonly [K in ICreditSortBy]: K } = {
  id: 'id',
  invoice_number: 'invoice_number',
  errors_dollars: 'errors_dollars',
  errors_count: 'errors_count',
  submit_date: 'submit_date',
  status: 'status',
} as const;

export type ICreditFilters = {
  credit_request_number: IFilter<'single_select'>;
  invoice_number: IFilter<'multi_select'>;
  errors_dollars: IFilter<'criterion_number'>;
  errors_count: IFilter<'criterion_number'>;
  submit_date: IFilter<'date_range'>;
  status: IFilter<'single_select'>;
};

export type ICreditFiltersConfig = {
  [K in keyof ICreditFilters]: Omit<ICreditFilters[K], 'options'>;
};

export const CreditFiltersConfig: ICreditFiltersConfig = {
  credit_request_number: { type: 'single_select' },
  invoice_number: { type: 'multi_select' },
  errors_dollars: { type: 'criterion_number' },
  errors_count: { type: 'criterion_number' },
  submit_date: { type: 'date_range' },
  status: { type: 'single_select' },
};
