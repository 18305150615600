import { MouseEvent, useEffect } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { IS_EXPERIMENTAL } from 'src/config/env';
import {
  SectionCard,
  SectionItem,
  Timeline,
  TimelineNode,
  PickAndPackTitle,
  PickAndPackList,
  PickAndPackErrors,
  FulfillmentDetails,
  TotalCostList,
  InfoRecord,
  InfoRecords,
  SectionItemTitle,
  FreightErrors,
  AnomalyDetails,
  AnomalyDetailsTitle,
} from 'src/components/InvoiceOrder';
import { PageTitle, TitleButtonGroup, TitleButtons, PageContainer, Loading } from 'src/components/shared/Page';
import AnomalyAction from 'src/pages/Anomaly/AnomalyFreightPage/AnomalyAction';
import LinkNavigation from 'src/components/shared/LinkNavigation';
import { ButtonLink } from 'src/components/shared/Buttons';
import makeRequest from 'src/effects/makeRequest';
import * as apiAnomaly from 'src/api/anomaly';
import * as apiOrder from 'src/api/order';

interface ILocationState {
  ordersNumbers?: Maybe<string[]>;
  goBack?: boolean;
}

const useOrderRequest = makeRequest(apiOrder.getOrderDetails);
const useAnomalyDetailsRequest = makeRequest(apiAnomaly.getAnomalyDetails);

export default function OrderPage() {
  const { name: invoiceName, id: orderId } = useParams<{ name: string; id: string }>();
  const { state: locationState }: { state: MaybeNull<ILocationState> } = useLocation();
  const orderRequest = useOrderRequest(null, true);
  const anomalyDetailsRequest = useAnomalyDetailsRequest(null, true);
  const navigate = useNavigate();

  const handleNavigateBack = (event: MouseEvent<HTMLElement>) => {
    if (!locationState?.goBack) return;

    event.preventDefault();
    navigate(-1);
  };

  useEffect(() => {
    if (!invoiceName || !orderId) navigate('/error');
    else orderRequest.make(invoiceName, orderId);
  }, [invoiceName, orderId]);

  useEffect(() => {
    if (orderId) anomalyDetailsRequest.make(orderId);
  }, [orderId]);

  useEffect(() => {
    if (orderRequest.error) navigate('/error');
  }, [orderRequest.error]);

  if (!invoiceName || !orderId) return <Navigate to={'/error'} />;

  return (
    <>
      <PageTitle title={'Order details'}>
        <TitleButtonGroup>
          <TitleButtons align="left">
            <ButtonLink title="Go back" to={`/invoice/${invoiceName}/reconcile`} onClick={handleNavigateBack} />
            {!orderRequest.loading && !!orderRequest.data && (
              <InfoRecords>
                <InfoRecord name={'Order #'} value={orderRequest.data.order_number} />
                <InfoRecord name={'Invoice #'} value={orderRequest.data.invoice_name} />
                <InfoRecord name={'Type'} value={orderRequest.data.type} />
              </InfoRecords>
            )}
          </TitleButtons>
          <TitleButtons align="right">
            {locationState?.ordersNumbers && (
              <LinkNavigation
                linkId={orderId}
                linkIds={locationState.ordersNumbers}
                makeLink={(id) => `/invoice/${encodeURIComponent(invoiceName)}/order/${encodeURIComponent(id)}`}
                title={({ linkIds, linkId }) => (
                  <>
                    <Typography variant="h4" sx={{ textTransform: 'initial' }}>
                      Orders:
                    </Typography>
                    <Typography variant="body1">{`${linkIds.indexOf(linkId) + 1} of ${linkIds.length}`}</Typography>
                  </>
                )}
              />
            )}
          </TitleButtons>
        </TitleButtonGroup>
      </PageTitle>
      <PageContainer>
        {orderRequest.loading || !orderRequest.data ? (
          <Loading />
        ) : (
          <>
            <Divider />
            <SectionCard
              title={'Order overview'}
              main={
                <>
                  <SectionItem header={<SectionItemTitle title="Total cost summary" />}>
                    <TotalCostList
                      total={orderRequest.data.total_cost_summary.total}
                      totalErrors={orderRequest.data.total_cost_summary.errors_total}
                      totalFulfillment={orderRequest.data.total_cost_summary.fulfillment_total}
                    />
                  </SectionItem>
                  <SectionItem header={<SectionItemTitle title="Order Timeline" />}>
                    <Timeline>
                      {orderRequest.data.order_timeline.map((node) => (
                        <TimelineNode key={`timeline-node-${node.date}-${node.event}`} node={node} />
                      ))}
                    </Timeline>
                  </SectionItem>
                </>
              }
              aside={
                <SectionItem header={<SectionItemTitle title="Order fulfillment info" />}>
                  <FulfillmentDetails details={orderRequest.data.order_fulfillment_detail} />
                </SectionItem>
              }
            />
            <Divider />
            <SectionCard
              title={'Pick and pack'}
              main={
                <SectionItem header={<PickAndPackTitle title="Line item details" />}>
                  <PickAndPackList
                    details={orderRequest.data.pick_and_pack.line_item_details}
                    orderFees={orderRequest.data.pick_and_pack.order_inserts_fees}
                    totalPnp={orderRequest.data.pick_and_pack.total_pick_and_pack}
                    totalPnpFee={orderRequest.data.pick_and_pack.total_pick_fees}
                    totalOrderFee={orderRequest.data.pick_and_pack.total_order_level_fees}
                  />
                </SectionItem>
              }
              aside={
                <SectionItem header={<SectionItemTitle title="Error checks" />}>
                  <PickAndPackErrors errors={orderRequest.data.pick_and_pack.error_checks} />
                </SectionItem>
              }
            />
            <Divider />
            <SectionCard
              title={'Freight'}
              main={
                <>
                  <SectionItem header={<SectionItemTitle title="Line item details: Freight" />}>
                    Coming soon
                  </SectionItem>
                  <SectionItem header={<SectionItemTitle title="Accessorials" />}>Coming soon</SectionItem>
                </>
              }
              aside={
                <SectionItem header={<SectionItemTitle title="Error checks" />}>
                  <FreightErrors />
                </SectionItem>
              }
            />
            {IS_EXPERIMENTAL && (
              <>
                <Divider />
                <SectionCard
                  title={'Implentio AI'}
                  main={
                    <SectionItem header={<SectionItemTitle title={<AnomalyDetailsTitle />} />}>
                      <AnomalyDetails
                        loading={anomalyDetailsRequest.loading}
                        details={anomalyDetailsRequest.data}
                        action={AnomalyAction}
                      />
                    </SectionItem>
                  }
                />
              </>
            )}
          </>
        )}
      </PageContainer>
    </>
  );
}
