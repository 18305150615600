import { PropsWithChildren, createContext, useCallback, useEffect, useState } from 'react';

import { Loading } from 'src/components/shared/Page';
import { authService } from 'src/services/AuthService';
import makeRequest from 'src/effects/makeRequest';
import * as apiAuth from 'src/api/auth';
import { IAuthToken } from 'src/types';

export interface IAuthContext {
  isAuthenticated: boolean;
  signOut: () => void;
  signIn: (token: IAuthToken, onComplete: () => void) => void;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

const useVerifyTokenRequest = makeRequest(apiAuth.verifyToken, { silent: true });
const useInvalidateTokenRequest = makeRequest(apiAuth.invalidateToken, { silent: true });

const AuthProvider = ({ children }: PropsWithChildren) => {
  const [isPending, setPending] = useState(true);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const verifyTokenRequest = useVerifyTokenRequest(null, false);
  const invalidateTokenRequest = useInvalidateTokenRequest(null, false);

  const signOut = useCallback(() => {
    if (!authService.hasToken) {
      setAuthenticated(false);
      authService.cleanup();

      return;
    }

    setAuthenticated(false);
    invalidateTokenRequest.make(authService.token.refresh);
    authService.cleanup();
  }, []);

  const signIn = useCallback((token: IAuthToken, onComplete: () => void) => {
    authService.init(token).then(
      () => {
        setAuthenticated(true);
        setPending(false);
        onComplete();
      },
      () => {
        setAuthenticated(false);
        setPending(false);
      },
    );
  }, []);

  useEffect(() => {
    if (!authService.hasToken) {
      authService.cleanup();
      setAuthenticated(false);
      setPending(false);

      return;
    }

    if (verifyTokenRequest.loading) return;

    verifyTokenRequest.make(authService.token.id);
    verifyTokenRequest.onFulfill(({ valid }) => {
      const promise = valid ? authService.init(authService.token) : authService.refresh();

      promise.then(
        () => {
          setAuthenticated(true);
          setPending(false);
        },
        () => {
          setAuthenticated(false);
          setPending(false);
        },
      );
    });
    verifyTokenRequest.onReject(() => {
      setAuthenticated(false);
      setPending(false);
    });
  }, []);

  useEffect(
    () =>
      authService.subscribe((action) => {
        if (action === 'cleanup') setAuthenticated(false);
        if (action === 'update') setAuthenticated(true);
      }),
    [],
  );

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        signOut,
        signIn,
      }}>
      {isPending ? <Loading /> : children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
