import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';

import { ArrowLeftFilledIcon, ArrowRightFilledIcon } from 'src/components/Icons';

export const StyledNavigation = styled(Paper)`
  display: flex;
  padding: ${({ theme }) => theme.spacing(1, 1.5)};
  min-height: ${({ theme }) => theme.spacing(5)};
  background-color: ${({ theme }) => theme.palette.secondary.light};
`;

export const StyledNavigationContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(0, 1.5)};
`;

export const StyledLeftIcon = styled(ArrowLeftFilledIcon)`
  color: inherit;
`;

export const StyledRightIcon = styled(ArrowRightFilledIcon)`
  color: inherit;
`;
