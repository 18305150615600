import { memo, useCallback, useMemo, useRef, useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';

import { authService } from 'src/services/AuthService';

import {
  StyledDropdown,
  StyledTopLabel,
  StyledUserInfo,
  StyledUserLabel,
  StyledUserMail,
  StyledUserName,
} from './styles';
import UserActions from './UserActions';

interface IUserInfo {
  firstName: string;
  lastName: string;
  email: string;
}

export const UserInfo = memo(() => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef<MaybeNull<HTMLElement>>(null);

  const [userInfo, setUserInfo] = useState<IUserInfo>(() => ({
    firstName: authService.user.first_name,
    lastName: authService.user.last_name,
    email: authService.user.email,
  }));

  useCallback(
    () =>
      authService.subscribe((action) => {
        if (action !== 'update') return;

        setUserInfo({
          firstName: authService.user.first_name,
          lastName: authService.user.last_name,
          email: authService.user.email,
        });
      }),
    [],
  );

  const userAbbr = useMemo(() => `${userInfo.firstName[0]}${userInfo.lastName[0]}`, [userInfo]);

  const hidePopper = () => setOpen(false);
  const togglePopper = () => setOpen(!isOpen);

  return (
    <>
      <StyledTopLabel variant="filled" onClick={togglePopper} className={isOpen ? 'Mui-focused' : ''} ref={ref}>
        {userAbbr}
      </StyledTopLabel>
      <Popper
        transition
        open={isOpen}
        anchorEl={ref.current}
        placement="bottom-end"
        sx={{ zIndex: 1, top: '8px!important' }}>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={hidePopper}>
            <Fade {...TransitionProps}>
              <StyledDropdown>
                <StyledUserInfo>
                  <StyledUserLabel variant="filled">{userAbbr}</StyledUserLabel>
                  <StyledUserName variant="h4">{`${userInfo.firstName} ${userInfo.lastName}`}</StyledUserName>
                  <StyledUserMail variant="body1">{userInfo.email}</StyledUserMail>
                </StyledUserInfo>
                <UserActions />
              </StyledDropdown>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
});

UserInfo.displayName = 'UserInfo';
