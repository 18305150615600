import { memo } from 'react';
import Typography from '@mui/material/Typography';

import { IInvoiceStatus, ISalesOrderStatus } from 'src/types';
import dateFormatter from 'src/utils/dateFormatter';
import numberFormatter from 'src/utils/numberFormatter';
import currencyFormatter from 'src/utils/currencyFormatter';
import { getInvoiceStatusLabel, getOrderStatusLabel } from 'src/components/Status';

import { StyledInfo, StyledInfoLine } from './styles';

export interface ICreditInfoProps {
  invoiceName: string;
  invoiceStatus: IInvoiceStatus;
  periodStart: string;
  periodEnd: string;
  totalCharges: number;
  totalOrders: number;
  ordersStatus: { status: ISalesOrderStatus; count: number }[];
}

const formatDate = dateFormatter();
const formatUSD = currencyFormatter();
const formatNumber = numberFormatter();

const CreditInfo = ({
  invoiceName,
  invoiceStatus,
  periodStart,
  periodEnd,
  totalCharges,
  totalOrders,
  ordersStatus,
}: ICreditInfoProps) => (
  <StyledInfo>
    <StyledInfoLine as="dl">
      <span>
        <Typography variant="h4" component={'dt'}>
          Invoice #:
        </Typography>
        <Typography variant="body1" component={'dd'}>
          {invoiceName}
        </Typography>
      </span>
      <span>
        <Typography variant="h4" component={'dt'}>
          Service Period:
        </Typography>
        <Typography variant="body1" component={'dd'}>
          {formatDate(periodStart)} - {formatDate(periodEnd)}
        </Typography>
      </span>
      <span>
        <Typography variant="h4" component={'dt'}>
          Total Charges:
        </Typography>
        <Typography variant="body1" component={'dd'}>
          {formatUSD(totalCharges)}
        </Typography>
      </span>
      <span>
        <Typography variant="h4" component={'dt'}>
          Total Orders:
        </Typography>
        <Typography variant="body1" component={'dd'}>
          {formatNumber(totalOrders)}
        </Typography>
      </span>
    </StyledInfoLine>
    <StyledInfoLine as="dl">
      <span>
        <Typography variant="h4" component={'dt'}>
          Invoice Status:
        </Typography>
        <Typography variant="body1" component={'dd'}>
          {getInvoiceStatusLabel(invoiceStatus).toLocaleUpperCase()}
        </Typography>
      </span>
      <span>
        <Typography variant="h4" component={'dt'}>
          Order Error Status:
        </Typography>
        <Typography variant="body1" component={'dd'}>
          {ordersStatus
            .map((orders) => `${formatNumber(orders.count)} ${getOrderStatusLabel(orders.status).toLocaleUpperCase()}`)
            .join('  ')}
        </Typography>
      </span>
    </StyledInfoLine>
  </StyledInfo>
);

export default memo(CreditInfo);
