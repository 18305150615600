import * as yup from 'yup';

import { IUserRole, UserRole } from 'src/types';

const PasswordSchema = yup
  .string()
  .required()
  .minOfNum(1)
  .minOfLower(1)
  .minOfUpper(1)
  .minOfSpecial(1)
  .min(8)
  .label('Password') as yup.StringSchema<string>;

export const LoginSchema = yup.object().shape({
  username: yup.string().required().email().label('Email'),
  password: PasswordSchema,
});

export type ILoginSchema = yup.InferType<typeof LoginSchema>;

export const ForgotPasswordSchema = yup.object().shape({
  username: yup.string().required().email().label('Email'),
});

export type IForgotPasswordSchema = yup.InferType<typeof ForgotPasswordSchema>;

export const ConfirmResetPasswordSchema = yup.object().shape({
  confirmation_code: yup.string().required().label('Code'),
  password: PasswordSchema,
  confirm_password: yup
    .string()
    .required()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .label('Confirm password'),
});

export type IConfirmResetPasswordSchema = yup.InferType<typeof ConfirmResetPasswordSchema>;

export const VerifyUserSchema = yup.object().shape({
  confirmation_code: yup.string().required().label('Code'),
});

export type IVerifyUserSchema = yup.InferType<typeof VerifyUserSchema>;

export const NewUserSchema = yup.object().shape({
  name: yup.string().required().label('Name'),
  family_name: yup.string().required().label('Family name'),
  username: yup.string().required().email().label('Email'),
  role: yup.string().required().oneOf(Object.values<IUserRole>(UserRole)).label('Role'),
  company_id: yup.number().required().label('Company'),
  password: PasswordSchema,
});

export type INewUserSchema = yup.InferType<typeof NewUserSchema>;
