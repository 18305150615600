import { PropsWithChildren, memo } from 'react';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { IconButtonProps } from '@mui/material/IconButton';

import { CloseIcon } from 'src/components/Icons';
import percentFormatter from 'src/utils/percentFormatter';

import { StyledActions, StyledCloseButton, StyledDownloadProgress } from './styles';

export const CloseButton = (props: IconButtonProps) => (
  <StyledCloseButton {...props}>
    <CloseIcon />
  </StyledCloseButton>
);

const formatPercents = percentFormatter({ fractionDigits: 0 });

export const DownloadProgress = ({ progress }: { progress: number }) => (
  <StyledDownloadProgress>
    <Typography variant="h4">Download:</Typography>
    <Typography variant="h4" sx={(theme) => ({ color: theme.palette.primary.main, minWidth: theme.spacing(4.5) })}>
      {formatPercents(progress / 100)}
    </Typography>
    <LinearProgress value={progress} sx={({ spacing }) => ({ minWidth: spacing(32) })} />
  </StyledDownloadProgress>
);

const CreditActions = ({ children }: PropsWithChildren) => <StyledActions>{children}</StyledActions>;

export default memo(CreditActions);
