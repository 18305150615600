import { useCallback } from 'react';

import { TableHeaderRow, TableHeaderCellSort, TableLeadCell } from 'src/components/shared/Table';
import { IAnalyticReportsSortBy, AnalyticReportsSortBy, SortDirection, ISortProps } from 'src/types';

export const AnalyticReportHeaders = ({ sort, onSort }: ISortProps<IAnalyticReportsSortBy>) => {
  const handleSortChange = useCallback(
    (fieldName: IAnalyticReportsSortBy) => {
      if (sort.by !== fieldName) {
        onSort({
          by: fieldName,
          order: SortDirection.DESC,
        });
      } else {
        onSort({
          ...sort,
          order: sort.order === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
        });
      }
    },
    [sort, onSort],
  );

  return (
    <TableHeaderRow>
      <TableLeadCell />
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === AnalyticReportsSortBy.name}
        onClick={() => handleSortChange(AnalyticReportsSortBy.name)}>
        Report name
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === AnalyticReportsSortBy.description}
        onClick={() => handleSortChange(AnalyticReportsSortBy.description)}>
        Description
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === AnalyticReportsSortBy.type}
        onClick={() => handleSortChange(AnalyticReportsSortBy.type)}>
        Type
      </TableHeaderCellSort>
    </TableHeaderRow>
  );
};
