import { IUserRole } from 'src/types';
import NotFound from 'src/pages/NotFound';

import { useRole } from './effects';

export const WithRoles = (Page: () => JSX.Element, ...roles: IUserRole[]) => {
  const WithRolesWrapper = (props: object) => {
    const role = useRole();

    return roles.includes(role) ? <Page {...props} /> : <NotFound {...props} />;
  };

  return WithRolesWrapper;
};
