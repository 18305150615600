import { useLocation } from 'react-router-dom';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

import { StyledBody, StyledTitle, StyledDescription } from './styles';

const getErrorText = (errorCode?: string) => {
  if (!errorCode) return 'Something went wrong.';
  else if (errorCode === '404') return "The page you were looking for doesn't exist.";
  else if (errorCode >= '500') return 'Something went wrong on our end.\nPlease try again later.';

  return 'Something unusual happened.';
};

export const Error = () => {
  const location = useLocation();

  const errorCode = location.state?.errorCode;

  return (
    <StyledBody>
      <WarningRoundedIcon sx={(theme) => ({ fontSize: 70, color: theme.palette.error.main })} />
      <StyledTitle>Error</StyledTitle>
      <StyledDescription>{getErrorText(errorCode)}</StyledDescription>
    </StyledBody>
  );
};
