import { memo, useEffect, useRef } from 'react';
import { useTheme } from '@emotion/react';
import { Chart } from 'chart.js';
import dayjs from 'dayjs';

import { IInvoiceChartStackGroup } from 'src/types';
import currencyFormatter from 'src/utils/currencyFormatter';

import { StyledCanvas, StyledCanvasContainer } from './styles';

const COLORS = ['#D58484', '#90C69D', '#FBCFB6', '#00B2FF', '#6C6B89', '#F66B1C'];

const formatCurrency = currencyFormatter();

const getMaxValue = (stackData: IInvoiceChartStackGroup[]) => {
  const values = stackData.map(({ data }) => data.map(({ value }) => value));
  const valuesSize = Math.max(...values.map((item) => item.length));
  const aggregation: number[] = [];

  for (let cursor = 0; cursor < valuesSize; cursor += 1) {
    values.forEach((value) => {
      aggregation[cursor] = (aggregation[cursor] ?? 0) + (value.shift() ?? 0);
    });
  }

  return Math.max(...aggregation);
};

const makeBackgroundColor = (size: number) => {
  const colors = [];

  for (let cursor = 0; cursor < size; cursor += 1) colors[cursor] = COLORS[cursor % COLORS.length];

  return colors;
};

const makeChartData = (stackData: IInvoiceChartStackGroup[]) => {
  const labels = stackData[0].data.map(({ date }) => dayjs(date).format('MMM YY'));
  const backgroundColor = makeBackgroundColor(stackData.length);
  const datasets = stackData.map((dataset, index) => ({
    borderRadius: 5,
    label: dataset.name,
    backgroundColor: backgroundColor[index],
    data: dataset.data.map((item) => item.value),
  }));
  const maxValue = getMaxValue(stackData);

  return {
    labels,
    datasets,
    maxValue,
    backgroundColor,
  };
};

export interface IChartStackProps {
  data: IInvoiceChartStackGroup[];
}

const ChartStack = ({ data }: IChartStackProps) => {
  const ref = useRef<MaybeNull<HTMLCanvasElement>>(null);
  const { palette, typography } = useTheme();

  useEffect(() => {
    const context = ref.current?.getContext('2d');

    if (!context) return;

    const chartData = makeChartData(data);

    const chart = new Chart(context, {
      type: 'bar',
      data: chartData,
      options: {
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              title: ([{ dataset }]) => dataset.label,
              label: (item) => formatCurrency(item.raw as number),
            },
            backgroundColor: palette.secondary.main,
            titleColor: palette.secondary.light,
            bodyColor: palette.secondary.light,
            usePointStyle: true,
            boxPadding: 4,
            padding: 8,
            bodyFont: {
              family: typography.fontFamily,
              weight: typography.fontWeightRegular as number,
              size: 12,
            },
            titleFont: {
              family: typography.fontFamily,
              weight: typography.fontWeightMedium as number,
              size: 12,
            },
          },
          datalabels: {
            display: false,
          },
          legend: {
            position: 'top',
            align: 'start',
            labels: {
              padding: 20,
              pointStyle: 'circle',
              usePointStyle: true,
              font: {
                family: typography.fontFamily,
                weight: typography.fontWeightRegular as number,
                size: 12,
              },
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
            },
            ticks: {
              color: palette.text.primary,
              font: {
                weight: typography.fontWeightRegular as number,
                family: typography.fontFamily,
                size: 12,
              },
            },
          },
          y: {
            stacked: true,
            border: {
              display: false,
            },
            ticks: {
              display: false,
              stepSize: chartData.maxValue / 4,
            },
            min: 0,
            max: chartData.maxValue,
            grid: {
              lineWidth: 2,
            },
          },
        },
      },
    });

    return () => chart.destroy();
  }, [data, palette, typography]);

  return (
    <StyledCanvasContainer>
      <StyledCanvas ref={ref} width="640" height="90" />
    </StyledCanvasContainer>
  );
};

export default memo(ChartStack);
