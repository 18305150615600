import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { Loading } from 'src/components/shared/Page';
import {
  CreditStep,
  CreditStepActions,
  CreditStepAction,
  ErrorTypesList,
  ErrorTypesCard,
  ErrorTypesHeader,
  ErrorTypesTotal,
  ErrorTypesDescription,
} from 'src/components/InvoiceReconcile';

import { useCreditRequestContext } from './CreditRequestContext';
import { useCreditStepsContext } from './CreditStepsContext';
const ErrorsStep = () => {
  const { dialogControl } = useCreditRequestContext();
  const {
    stepControl,
    creditErrorsRequest,
    creditErrorsSorting,
    creditErrorsTotal,
    creditErrorsSorted,
    creditErrorsSelection,
  } = useCreditStepsContext();

  const handleCancel = () => dialogControl.hideDialog();
  const handleNext = () => stepControl.goToEmail();

  return (
    <CreditStep>
      {creditErrorsRequest.loading ? (
        <Loading />
      ) : (
        <>
          <ErrorTypesCard>
            <ErrorTypesHeader>
              <Typography variant="h3">Error Types</Typography>
              <ErrorTypesTotal
                totalCount={creditErrorsTotal.unfavorable_errors_count}
                totalDollars={creditErrorsTotal.unfavorable_errors_dollars}
              />
            </ErrorTypesHeader>
            <ErrorTypesDescription
              description={'Select the error types for which you would \nlike to request a credit from your 3PL'}
            />
            <ErrorTypesList
              selectId={'error_code'}
              payload={creditErrorsSorted}
              sorting={creditErrorsSorting.params}
              changeSorting={creditErrorsSorting.setParams}
              selection={creditErrorsSelection.selection}
              changeSelection={creditErrorsSelection.setSelection}
            />
          </ErrorTypesCard>
          <CreditStepActions>
            <CreditStepAction>
              <Button variant="outlined" onClick={handleCancel}>
                Cancel
              </Button>
            </CreditStepAction>
            <CreditStepAction>
              <Button variant="contained" disabled={!creditErrorsSelection.selection.size} onClick={handleNext}>
                Next
              </Button>
            </CreditStepAction>
          </CreditStepActions>
        </>
      )}
    </CreditStep>
  );
};

export default ErrorsStep;
