import { memo, useEffect, useRef } from 'react';

import { ISort, ISortDirection, ICreditErrorSortBy, ICreditError } from 'src/types';
import { TableBody } from 'src/components/shared/Table';
import Message from 'src/components/shared/Message';

import ErrorTypesRow from './ErrorTypesRow';
import ErrorTypesHeader from './ErrorTypesHeader';

export type IErrorTypesSortParams = {
  by: ICreditErrorSortBy;
  order: ISortDirection;
};

export interface IErrorTypesListProps {
  selectId: keyof ICreditError;
  sorting: IErrorTypesSortParams;
  payload: ICreditError[];
  selection: Set<string>;
  changeSelection: (selection: Set<string>) => void;
  changeSorting: (sort: ISort<ICreditErrorSortBy>) => void;
}

const ErrorTypesList = ({
  selectId,
  payload,
  sorting,
  selection,
  changeSorting,
  changeSelection,
}: IErrorTypesListProps) => {
  const ref = useRef<MaybeNull<HTMLElement>>(null);

  useEffect(() => {
    const handleCHange = (event: Event) => {
      const element = (event.target as Maybe<Element>)?.closest<HTMLInputElement>('input[type="checkbox"]');

      if (!element) return;

      if (element.id === 'ALL') {
        const newSelection = new Set(
          selection.size === 0 || selection.size < payload.length
            ? payload.map((item) => item[selectId] as string)
            : [],
        );

        return changeSelection(newSelection);
      }

      const newSelection = new Set(selection);

      if (element.checked) newSelection.delete(element.id);
      else newSelection.add(element.id);

      changeSelection(newSelection);
    };

    ref.current?.addEventListener('change', handleCHange);

    return () => ref.current?.removeEventListener('change', handleCHange);
  }, [payload, selection]);

  if (!payload.length) return <Message>No errors</Message>;

  return (
    <TableBody ref={ref}>
      <ErrorTypesHeader
        sort={{ by: sorting.by, order: sorting.order }}
        onSort={changeSorting}
        selected={selection.size === payload.length}
      />
      {payload.map((item, index) => (
        <ErrorTypesRow key={`error-type-row-${index}`} item={item} selected={selection.has(item[selectId] as string)} />
      ))}
    </TableBody>
  );
};

export default memo(ErrorTypesList);
