import { ChangeEvent, FormEvent, memo, useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { ArrowLeftFilledIcon, ArrowRightFilledIcon } from 'src/components/Icons';
import numberFormatter from 'src/utils/numberFormatter';

import { StyledButtons, StyledForm, StyledInput, StyledPages, StyledPagination } from './styles';

export interface IPaginationProps {
  page: number;
  loading: boolean;
  count: Maybe<number>;
  onChange: (page: number) => void;
}

const formatNumber = numberFormatter();
const pageRegex = /^([1-9]\d*)$/;

const Pagination = ({ page, count, loading, onChange }: IPaginationProps) => {
  const pageCount = useMemo(() => count ?? 0, [count]);
  const [currentPage, setCurrentPage] = useState<string>(`${page}`);

  const handlePageChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const isValidPage = target.value === '' || (pageRegex.test(target.value) && +target.value <= pageCount);

    if (isValidPage) setCurrentPage(target.value);
  };

  const handlePageSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isValidPage = pageRegex.test(currentPage) && +currentPage <= pageCount;

    if (isValidPage && +currentPage != page) onChange(+currentPage);
  };

  const handlePageBlur = () => {
    const isValidPage = pageRegex.test(currentPage) && +currentPage <= pageCount;

    if (isValidPage && +currentPage != page) onChange(+currentPage);
  };

  useEffect(() => setCurrentPage(`${page}`), [page]);

  return (
    <StyledPagination>
      <StyledForm $disabled={loading} onSubmit={handlePageSubmit}>
        <StyledInput
          name="page"
          disabled={loading}
          value={currentPage}
          onChange={handlePageChange}
          onBlur={handlePageBlur}
        />
        <StyledPages>
          <Typography variant="body1">{count != null ? `${formatNumber(pageCount)} pages` : 'loading'}</Typography>
        </StyledPages>
        <StyledButtons>
          <Button variant="text" disabled={loading || page <= 1} onClick={() => onChange(page - 1)}>
            <ArrowLeftFilledIcon />
          </Button>
          <Button variant="text" disabled={loading || page >= pageCount} onClick={() => onChange(page + 1)}>
            <ArrowRightFilledIcon />
          </Button>
        </StyledButtons>
      </StyledForm>
    </StyledPagination>
  );
};

export default memo(Pagination);
