import { useEffect } from 'react';

import { PageTitle, PageContainer } from 'src/components/shared/Page';
import { CreditRequestsList } from 'src/components/CreditRequests';
import { QueryFilter } from 'src/components/shared/QueryFilter';

import CreditTrackerRowAction from './CreditTrackerRowAction';
import { CreditTrackerProvider, useCreditTrackerContext } from './CreditTrackerContext';

const CreditTracker = () => {
  const { creditsRequest, creditsFilters, creditsSorting, creditsData } = useCreditTrackerContext();

  useEffect(() => creditsRequest.make(), []);

  return (
    <>
      <PageTitle title={'Credit request tracker'}>
        <QueryFilter control={creditsFilters} />
      </PageTitle>
      <PageContainer>
        <CreditRequestsList
          data={creditsData}
          params={creditsSorting.params}
          loading={creditsRequest.loading}
          changeSorting={creditsSorting.changeSorting}
          rowAction={CreditTrackerRowAction}
        />
      </PageContainer>
    </>
  );
};

export default function CreditTrackerPage() {
  return (
    <CreditTrackerProvider>
      <CreditTracker />
    </CreditTrackerProvider>
  );
}
