import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { BrandDetails } from 'src/components/BrandDetails';
import { ButtonLink } from 'src/components/shared/Buttons';
import { PageTitle, TitleButtons, Loading, PageContainer } from 'src/components/shared/Page';
import NotAvailableText from 'src/components/shared/NotAvailableText';
import makeRequest from 'src/effects/makeRequest';
import * as apiBrand from 'src/api/brand';

const useBrandRequest = makeRequest(apiBrand.getBrand);

export default function BrandDetailsPage() {
  const brandRequest = useBrandRequest(null, true);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id == null) navigate('/error');
    else {
      const brandId = Number.parseInt(id);

      brandRequest.cleanup();
      brandRequest.make(brandId);
    }
  }, [id]);

  useEffect(() => {
    if (brandRequest.error) navigate('/error');
  }, [brandRequest.error]);

  return (
    <>
      <PageTitle title={`${brandRequest.data?.name ?? ''} company`}>
        <TitleButtons align="left">
          <ButtonLink title="Create company" to={'/system/company'} variant="contained" />
          <ButtonLink title="Back" to={'/system/companies'} variant="outlined" />
        </TitleButtons>
      </PageTitle>
      <PageContainer>
        {brandRequest.loading ? (
          <Loading />
        ) : brandRequest.data ? (
          <BrandDetails brand={brandRequest.data} />
        ) : (
          <NotAvailableText />
        )}
      </PageContainer>
    </>
  );
}
