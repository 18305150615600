import { IReportType } from './report';

export const AnalyticServiceType = {
  SUMMARY: 'SUMMARY' as const,
  PICK_AND_PACK: 'PICK_AND_PACK' as const,
  FREIGHT: 'FREIGHT' as const,
  OTHER: 'OTHER' as const,
} as const;

export type IAnalyticServiceType = ValueOf<typeof AnalyticServiceType>;

export type IAnalyticReport = {
  type: string;
  name: string;
  description: string;
  reportType: IReportType;
};

export type IAnalyticReportsSortBy = Extract<keyof IAnalyticReport, 'name' | 'description' | 'type'>;

export const AnalyticReportsSortBy: { readonly [K in IAnalyticReportsSortBy]: K } = {
  type: 'type',
  name: 'name',
  description: 'description',
} as const;
