import { PropsWithChildren, createContext, useContext, useState } from 'react';

export type ISubmenuComponent = MaybeNull<() => JSX.Element>;

export interface ISubmenuController {
  isVisible: boolean;
  Component: ISubmenuComponent;
  showSubmenu: (component: ISubmenuComponent) => void;
  hideSubmenu: (props?: { hard?: boolean }) => void;
}

export interface ISidebarContext {
  controller: ISubmenuController;
}

export const SidebarContext = createContext<ISidebarContext>({} as ISidebarContext);

export const SidebarProvider = ({ children }: PropsWithChildren) => {
  const [isVisible, setVisibility] = useState<boolean>(false);
  const [Component, setComponent] = useState<ISubmenuComponent>(null);

  const hideSubmenu = ({ hard }: { hard?: boolean } = {}) => {
    if (hard) setComponent(null);
    setVisibility(false);
  };
  const showSubmenu = (component: ISubmenuComponent) => {
    setComponent(() => component);
    setVisibility(true);
  };

  const controller = {
    isVisible,
    Component,
    showSubmenu,
    hideSubmenu,
  };

  return <SidebarContext.Provider value={{ controller }}>{children}</SidebarContext.Provider>;
};

export const useController = () => useContext(SidebarContext).controller;
