import { ThemedComponent } from '@mui/material/styles';

export const MuiCheckbox: ThemedComponent<'MuiCheckbox'> = {
  defaultProps: {
    size: 'small',
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      padding: ownerState.disableRipple ? 0 : theme.spacing(1),
    }),
  },
};
