import { memo } from 'react';

import { IAnalyticServiceType, AnalyticServiceType } from 'src/types';
import { SlideTabs, SlideTab } from 'src/components/shared/SlideTabs';

export interface IServiceTypeProps {
  type: IAnalyticServiceType;
  onChange: (type: IAnalyticServiceType) => void;
}

const ServiceType = ({ type, onChange }: IServiceTypeProps) => (
  <SlideTabs value={type} onChange={onChange}>
    <SlideTab value={AnalyticServiceType.SUMMARY}>Summary</SlideTab>
    <SlideTab value={AnalyticServiceType.PICK_AND_PACK}>Pick and Pack</SlideTab>
    <SlideTab disabled value={AnalyticServiceType.FREIGHT}>
      Freight
    </SlideTab>
    <SlideTab value={AnalyticServiceType.OTHER}>Other</SlideTab>
  </SlideTabs>
);

export default memo(ServiceType);
