/* eslint-disable max-lines */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { alpha } from '@mui/system/colorManipulator';
import Paper from '@mui/material/Paper';

import shouldForwardProp from 'src/utils/shouldForwardProp';

/* Section */

export const StyledSectionCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
  flex: 1;
`;

export const StyledSectionAside = styled.aside`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
  flex: 0;
  min-width: 500px;
`;

export const StyledSection = styled.section`
  display: grid;
  grid-template-columns: minmax(auto, 2.1fr) minmax(auto, 0.9fr);
  grid-template-rows: repeat(2, auto);
  grid-gap: ${({ theme }) => theme.spacing(3)};

  & ${StyledSectionCards} {
    grid-area: 2 / 1 / 6 / 2;
    min-width: 400px;
  }

  & ${StyledSectionAside} {
    grid-area: 2 / 2 / 6 / 3;
    min-width: 400px;
  }
`;

/* Card */

export const StyledCard = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(3, 2)};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  overflow-x: auto;
`;

export const StyledCardHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const StyledCardContent = styled.div``;

/* Timeline */

export const StyledTimelineNode = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const StyledTimelineDot = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 2px;

  &::before {
    content: '';
    display: block;
    width: ${({ theme }) => theme.spacing(2)};
    height: ${({ theme }) => theme.spacing(2)};
    border-radius: 100%;
    background-image: radial-gradient(${({ theme }) => theme.palette.secondary.main} 30%, transparent 30%);
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }

  &::after {
    height: 2px;
    flex: 1;
    background-image: linear-gradient(
      90deg,
      ${({ theme }) => alpha(theme.palette.secondary.main, 0.2)},
      ${({ theme }) => alpha(theme.palette.secondary.main, 0.2)} 50%,
      transparent 50%,
      transparent 100%
    );
    background-size: 12px 100%;
  }
`;

export const StyledTimeline = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;

  & ${StyledTimelineNode}:not(:last-child) {
    flex: 1;

    & ${StyledTimelineDot}::after {
      content: '';
    }
  }
`;

/* Pick and pack */

const StyledPickAndPackTitleGrid = css`
  display: grid;
  align-items: center;
  grid-template-columns: 5.9fr 3fr 0.8fr 3fr;
`;

const StyledPickAndPackGrid = css`
  display: grid;
  align-items: center;
  grid-template-columns: 1.8fr 2.1fr 2fr 1fr 1fr 1fr 0.8fr 1fr 1fr 1fr;
`;

const StyledPickAndPackTotalGrid = css`
  display: grid;
  align-items: center;
  grid-template-columns: 5.9fr 1fr 1fr 1fr 0.8fr 1fr 1fr 1fr;
`;

export const StyledPickAndPackTitle = styled.div`
  ${StyledPickAndPackTitleGrid}

  margin-bottom: ${({ theme }) => theme.spacing(1)};
  padding-right: ${({ theme }) => theme.spacing(1)};
  min-width: 950px;
`;

export const StyledPickAndPackList = styled.div`
  flex: 1;
  min-width: 950px;
`;

export const StyledPickAndPackHead = styled.div`
  padding: ${({ theme }) => theme.spacing(1)};

  ${StyledPickAndPackGrid}

  & > * {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const StyledPickAndPackRecord = styled.div`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: ${({ theme }) => theme.spacing(1)};
  height: ${({ theme }) => theme.spacing(5)};

  ${StyledPickAndPackGrid}

  & > * {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const StyledPickAndPackTotalRecord = styled(StyledPickAndPackRecord)`
  ${StyledPickAndPackTotalGrid}
`;

export const StyledPickAndPackTotal = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(2, 0)};
`;

export const StyledPickAndPackRecords = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  & ${StyledPickAndPackRecord}:nth-child(2n + 2) {
    background-color: ${({ theme }) => theme.palette.secondary.light};
  }
`;

/* Pick and pack error */

export const StyledPickAndPackErrorList = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledPickAndPackErrorRecord = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

/*  Order fulfillment details */

export const StyledFulfillmentDetails = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledFulfillmentDetail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

/*  Total cost */

const StyledTotalCostGrid = css`
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 1fr 0.5fr 1fr 3fr;
`;

export const StyledTotalCostList = styled.div`
  flex: 1;
  min-width: 700px;
`;

export const StyledTotalCostHead = styled.div`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: ${({ theme }) => theme.spacing(1)};
  height: ${({ theme }) => theme.spacing(5)};

  ${StyledTotalCostGrid}

  & > * {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const StyledTotalCostRecord = styled('div', { shouldForwardProp })<{ $accent?: boolean }>`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: ${({ theme }) => theme.spacing(1)};
  height: ${({ theme }) => theme.spacing(5)};

  ${StyledTotalCostGrid}

  ${({ $accent, theme }) => $accent && `background-color: ${theme.palette.secondary.light};`}

  & > * {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const StyledTotalCostRecords = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: ${({ theme }) => theme.spacing(2)};

  & ${StyledTotalCostRecord}:nth-child(2n + 2) {
    background-color: ${({ theme }) => theme.palette.secondary.light};
  }
`;

export const StyledTotal = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(2, 0)};
`;

/* Info  */

export const StyledInfoRecords = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(0, 1)};
`;

export const StyledInfoRecord = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(0.5)};
`;

/* Freight error */

export const StyledFreightErrorList = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledFreightErrorRecord = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

/* Anomaly details */

export const StyledAnomalyDetailsTitle = styled.span`
  display: inline-flex;
  align-items: baseline;
  gap: ${({ theme }) => theme.spacing(1)};

  & > svg {
    width: 16px;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
