/* eslint-disable brace-style */

import { TOKEN_CHANNEL } from 'src/config/channels';
import { IAuthToken, IUserInfo, IUserRole } from 'src/types';
import { Publisher } from 'src/libs/Observer';
import * as apiAuth from 'src/api/auth';

export type IAuthAction = 'update' | 'cleanup';

type ISession = {
  subId: string;
  token: IAuthToken;
};

export type IAuthData = {
  session: ISession;
  user: Maybe<IUserInfo>;
};

const getSubId = (idToken: string): string => {
  const [, payload] = idToken.split('.');
  const subId = JSON.parse(atob(payload)).sub as string;

  return subId;
};

const ID_TOKEN_NAME = 'token.id';
const REFRESH_TOKEN_NAME = 'token.refresh';

export default class AuthService extends Publisher<IAuthAction> {
  #subId: Maybe<string> = null;
  #user: Maybe<IUserInfo> = null;
  #role: Maybe<IUserRole> = null;

  #initialized = false;
  #channel = new BroadcastChannel(TOKEN_CHANNEL);

  constructor() {
    super();

    this.#channel.addEventListener('message', this.#onAuthBroadcast);
  }

  get user() {
    if (this.#user == null) throw new Error('User is not defined');

    return this.#user;
  }

  get role() {
    if (this.#role == null) throw new Error('User role is not defined');

    return this.#role;
  }

  get token(): IAuthToken {
    if (!this.hasToken) throw new Error('There are no tokens');

    return {
      id: localStorage.getItem(ID_TOKEN_NAME) as string,
      refresh: localStorage.getItem(REFRESH_TOKEN_NAME) as string,
    };
  }

  get hasToken(): boolean {
    const idToken = localStorage.getItem(ID_TOKEN_NAME);
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_NAME);

    return !!idToken && !!refreshToken;
  }

  #onAuthBroadcast = ({ data }: MessageEvent<Maybe<IAuthData>>) => {
    // logout on another tab
    if (!data) {
      if (!this.#initialized) return;

      this.#cleanup();
      this.publish('cleanup');
    }
    // login on another tab
    else if (!this.#initialized) {
      this.#setAuth(data.session, data.user);
      this.publish('update');
    }
    // login as different user on another tab
    else if (this.#subId !== data.session.subId) {
      this.#setAuth(data.session, data.user);
      this.publish('update');
    }
    // login as same user on another tab
    else this.#setAuth(data.session);
  };

  #setAuth(session: IAuthData['session'], user?: Maybe<IAuthData['user']>) {
    localStorage.setItem(ID_TOKEN_NAME, session.token.id);
    localStorage.setItem(REFRESH_TOKEN_NAME, session.token.refresh);

    this.#subId = session.subId;
    this.#user = user ?? this.#user;
    this.#role = (user ?? this.#user)?.role.name ?? this.#role;

    this.#initialized = true;
  }

  #cleanup() {
    this.#subId = null;
    this.#user = null;
    this.#role = null;

    this.#initialized = false;

    localStorage.removeItem(ID_TOKEN_NAME);
    localStorage.removeItem(REFRESH_TOKEN_NAME);
  }

  async init(token?: MaybeNull<IAuthToken>): Promise<void> {
    if (!token) {
      this.cleanup();

      return;
    }

    try {
      const subId = getSubId(token.id);
      const isNewUser = this.#subId !== subId;

      let user: Maybe<IUserInfo>;

      if (isNewUser) {
        const request = apiAuth.getUserInfo(token.id);
        const payload = await request.make();

        user = payload.data;
      }

      const session = { subId, token };

      this.#setAuth(session, user);
      this.#channel.postMessage({ session, user });
      if (isNewUser) this.publish('update');
    } catch (error) {
      this.publish('cleanup');
      this.#channel.postMessage(null);
      this.#cleanup();

      throw error;
    }
  }

  async refresh(): Promise<void> {
    try {
      const { data } = await apiAuth.refreshToken(this.token.refresh).make();

      await this.init({ id: data.id_token, refresh: this.token.refresh });
    } catch (error) {
      this.cleanup();
      throw error;
    }
  }

  cleanup() {
    this.#cleanup();
    this.publish('cleanup');
    this.#channel.postMessage(null);
  }
}

export const authService = new AuthService();
