import { memo } from 'react';
import Typography from '@mui/material/Typography';

import { TabbedCardTabs, TabbedCardTab } from 'src/components/shared/TabbedCard';

import { IChartTab } from './types';

export interface ITabPanelProps {
  tab: IChartTab;
  onTabChange: (tab: IChartTab) => void;
}

const TabPanel = ({ tab, onTabChange }: ITabPanelProps) => (
  <TabbedCardTabs>
    <TabbedCardTab $active={tab === 'stack'} onClick={() => onTabChange('stack')}>
      <Typography variant="h3">Monthly Charges</Typography>
    </TabbedCardTab>
    <TabbedCardTab $active={tab === 'waterfall'} onClick={() => onTabChange('waterfall')}>
      <Typography variant="h3">Month Over Month</Typography>
    </TabbedCardTab>
  </TabbedCardTabs>
);

export default memo(TabPanel);
