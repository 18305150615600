import { StyledSvgIcon } from './styles';
import withIconRef from './withIconRef';

export const ArrowsRotateCircle = withIconRef((props, ref) => (
  <StyledSvgIcon {...props} ref={ref} width="16" height="16" viewBox="0 0 16 16">
    <path d="m8 0c-4.4124 0-8 3.5876-8 8 0 4.4124 3.5876 8 8 8 4.4124 0 8-3.5876 8-8 0-4.4124-3.5876-8-8-8zm0 1c3.8719 0 7 3.1281 7 7 0 3.8719-3.1281 7-7 7-3.8719 0-7-3.1281-7-7 0-3.8719 3.1281-7 7-7z" />
    <path d="m12.688 6.9063c0 0.25977-0.20898 0.46875-0.46875 0.46875h-2.8125c-0.25977 0-0.46875-0.20898-0.46875-0.46875s0.20898-0.46875 0.46875-0.46875h1.6816l-1.0176-1.0176c-0.55078-0.54883-1.2949-0.85742-2.0703-0.85742-1.4199 0-2.6406 0.86133-3.1641 2.0918-0.10156 0.23828-0.37696 0.34961-0.61524 0.24804-0.23828-0.10156-0.34961-0.37695-0.24804-0.61523 0.66601-1.5644 2.2188-2.6621 4.0273-2.6621 1.0254 0 2.0078 0.40625 2.7324 1.1309l1.0176 1.0195v-1.6816c0-0.25977 0.20898-0.46875 0.46875-0.46875s0.46875 0.20898 0.46875 0.46875zm-8.9063 1.7188h2.1875c0.25977 0 0.46875 0.20898 0.46875 0.46875s-0.20898 0.46875-0.46875 0.46875h-1.0566l1.0176 1.0176c0.55078 0.54883 1.2949 0.85742 2.0703 0.85742 1.418 0 2.6367-0.85742 3.1621-2.0859 0.10156-0.23828 0.37695-0.34765 0.61523-0.24609s0.34766 0.37695 0.2461 0.61523c-0.66797 1.5605-2.2168 2.6543-4.0234 2.6543-1.0254 0-2.0078-0.40625-2.7324-1.1309l-1.0176-1.0195v1.0566c0 0.25977-0.20898 0.46875-0.46875 0.46875-0.25977 0-0.46875-0.20898-0.46875-0.46875v-2.1875c0-0.25977 0.20898-0.46875 0.46875-0.46875z" />
  </StyledSvgIcon>
));

export const ExclamationCircle = withIconRef((props, ref) => (
  <StyledSvgIcon {...props} ref={ref} width="16" height="16" viewBox="0 0 16 16">
    <path d="m8 0c-4.4124 0-8 3.5876-8 8 0 4.4124 3.5876 8 8 8 4.4124 0 8-3.5876 8-8 0-4.4124-3.5876-8-8-8zm0 1c3.8719 0 7 3.1281 7 7 0 3.8719-3.1281 7-7 7-3.8719 0-7-3.1281-7-7 0-3.8719 3.1281-7 7-7z" />
    <path d="m7 2.0439v8h2v-8z" />
    <path d="m7 12.956c0 0.554 0.446 1 1 1 0.554 0 1-0.446 1-1s-0.446-1-1-1c-0.554 0-1 0.446-1 1z" />
  </StyledSvgIcon>
));

export const EyeCircle = withIconRef((props, ref) => (
  <StyledSvgIcon {...props} ref={ref} width="16" height="16" viewBox="0 0 16 16">
    <path d="m8 0c-4.4124 0-8 3.5876-8 8 0 4.4124 3.5876 8 8 8 4.4124 0 8-3.5876 8-8 0-4.4124-3.5876-8-8-8zm0 1c3.8719 0 7 3.1281 7 7 0 3.8719-3.1281 7-7 7-3.8719 0-7-3.1281-7-7 0-3.8719 3.1281-7 7-7z" />
    <path d="m7.9992 4.5c-1.4028 0-2.526 0.575-3.3438 1.2594-0.8125 0.67813-1.3559 1.4906-1.6129 2.0484-0.057292 0.12344-0.057292 0.26094 0 0.38438 0.25694 0.55781 0.80035 1.3703 1.6129 2.0484 0.81771 0.68438 1.941 1.2594 3.3438 1.2594s2.526-0.575 3.3438-1.2594c0.8125-0.67968 1.3559-1.4906 1.6146-2.0484 0.05725-0.12344 0.05725-0.26094 0-0.38438-0.25868-0.55781-0.80208-1.3703-1.6146-2.0484-0.81771-0.68437-1.941-1.2594-3.3438-1.2594zm-2.5 3.5c0-0.59674 0.26339-1.169 0.73223-1.591s1.1047-0.65901 1.7678-0.65901 1.2989 0.23705 1.7678 0.65901c0.46885 0.42196 0.73224 0.99425 0.73224 1.591s-0.26339 1.169-0.73224 1.591c-0.46884 0.42196-1.1047 0.65901-1.7678 0.65901s-1.2989-0.23705-1.7678-0.65901-0.73223-0.99425-0.73223-1.591zm2.5-1c0 0.55156-0.49827 1-1.1111 1-0.12327 0-0.24132-0.01875-0.35243-0.05156-0.09549-0.02813-0.2066 0.025-0.20313 0.11562 0.00521 0.10782 0.02257 0.21563 0.05556 0.32344 0.23784 0.8 1.1528 1.275 2.0417 1.0609s1.4167-1.0375 1.1788-1.8375c-0.19271-0.64844-0.82986-1.0844-1.5382-1.1109-0.1007-0.00312-0.15972 0.09531-0.12847 0.18281 0.03645 0.1 0.05729 0.20625 0.05729 0.31719z" />
  </StyledSvgIcon>
));

export const DashCircle = withIconRef((props, ref) => (
  <StyledSvgIcon {...props} ref={ref} width="16" height="16" viewBox="0 0 16 16">
    <path d="m8 0c-4.4124 0-8 3.5876-8 8 0 4.4124 3.5876 8 8 8 4.4124 0 8-3.5876 8-8 0-4.4124-3.5876-8-8-8zm0 1c3.8719 0 7 3.1281 7 7 0 3.8719-3.1281 7-7 7-3.8719 0-7-3.1281-7-7 0-3.8719 3.1281-7 7-7z" />
    <path d="m4 7h8c0.554 0 1 0.446 1 1s-0.446 1-1 1h-8c-0.554 0-1-0.446-1-1s0.446-1 1-1z" />
  </StyledSvgIcon>
));
