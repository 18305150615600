import { ThemedComponent } from '@mui/material/styles';

export const MuiSlider: ThemedComponent<'MuiSlider'> = {
  styleOverrides: {
    thumb: ({ theme }) => ({
      background: theme.palette.common.white,
      border: `1px solid ${theme.palette.primary.main}`,
      width: theme.spacing(2),
      height: theme.spacing(2),
    }),
  },
};
