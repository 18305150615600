import { memo, useMemo } from 'react';

import { IUserRole, UserRole } from 'src/types';

export interface IUserRoleLabelProps {
  role: IUserRole;
}

const UserRoleLabel = memo(({ role }: IUserRoleLabelProps) => {
  const labels = useMemo(
    () => ({
      [UserRole.IMPLENTIO_ADMIN]: 'Implentio admin',
      [UserRole.CUSTOMER_ADMIN]: 'Customer admin',
      [UserRole.CUSTOMER_USER]: 'Customer user',
    }),
    [],
  );

  return <>{labels[role] ?? 'Unknown'}</>;
});

UserRoleLabel.displayName = 'UserRoleLabel';

export default UserRoleLabel;
