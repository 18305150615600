import { ThemedComponent } from '@mui/material/styles';

export const MuiLinearProgress: ThemedComponent<'MuiLinearProgress'> = {
  defaultProps: {
    variant: 'determinate',
  },
  variants: [
    {
      props: { variant: 'determinate' },
      style: ({ theme }) => ({
        height: theme.spacing(1.25),
        borderRadius: theme.spacing(1.25 / 2),
        backgroundColor: theme.palette.common.white,
        boxShadow: `inset ${theme.shadows[3]}`,
        '& .MuiLinearProgress-bar': {
          height: theme.spacing(1.25),
          borderRadius: theme.spacing(1.25 / 2),
          backgroundColor: theme.palette.primary.main,
        },
      }),
    },
  ],
};
