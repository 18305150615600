import { memo, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { Palette } from '@mui/material/styles';

import { ICreditStatus, CreditStatus } from 'src/types';
import StatusChip from 'src/components/shared/StatusChip';

export interface ICreditRequestStatusChipProps {
  status: MaybeNull<ICreditStatus>;
}

export const getCreditRequestStatusLabel = (status: Maybe<ICreditStatus>) => {
  const labels = {
    [CreditStatus.SENT]: 'Sent',
    [CreditStatus.RECEIVED]: 'Received',
    [CreditStatus.PARTIALLY_RECEIVED]: 'Partially Received',
    [CreditStatus.DENIED]: 'Denied',
    [CreditStatus.CANCELLED]: 'Cancelled',
  };

  return status ? labels[status] ?? 'Unknown' : 'Unknown';
};

export const getCreditRequestStatusColor = (palette: Palette) => palette.text.secondary;

const CreditRequestStatusChip = ({ status }: ICreditRequestStatusChipProps) => {
  const { palette } = useTheme();

  const color = useMemo(() => getCreditRequestStatusColor(palette), [palette]);
  const label = useMemo(() => getCreditRequestStatusLabel(status), [status]);

  return <StatusChip label={label} color={color} />;
};

export default memo(CreditRequestStatusChip);
