import { memo, useCallback, useState } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import { CreditRequestsRow, ICreditRequestsRowActionProps } from 'src/components/CreditRequests/CreditRequestsRow';
import { makeErrorNote, makeInfoNote } from 'src/components/CreditRequests';
import ExportButton from 'src/components/shared/Buttons/ExportButton';
import { IRowActionControl } from 'src/components/shared/Table';
import { ErrorButton } from 'src/components/shared/Buttons';
import notification from 'src/services/NotificationService';
import { CreditStatus, ICreditStatus } from 'src/types';
import makeRequest from 'src/effects/makeRequest';
import * as apiCreditRequest from 'src/api/creditRequest';

const useChangeCreditStatusRequest = makeRequest(apiCreditRequest.changeCreditStatus);
const useCreditReportRequest = makeRequest(apiCreditRequest.getCreditReportId);

const CreditRequestRowAction = ({ credit, ...creditRestProps }: ICreditRequestsRowActionProps) => {
  const [creditData, updateCreditData] = useState(credit);
  const changeCreditStatusRequest = useChangeCreditStatusRequest(null);
  const creditReportRequest = useCreditReportRequest(null);

  const handleReportDownload = useCallback(() => {
    creditReportRequest.make(credit.id);
    creditReportRequest.onFulfill(() => {
      const infoNote = makeInfoNote();

      notification.publish(infoNote);
    });
    creditReportRequest.onReject(() => {
      const errorNote = makeErrorNote();

      notification.publish(errorNote);
    });
  }, [credit.id]);

  const renderAction = useCallback(
    ({ hidePopper }: IRowActionControl) => {
      const isDisabled = changeCreditStatusRequest.loading;

      const processStatusUpdate = (status: ICreditStatus) => {
        changeCreditStatusRequest.make(creditData.id, status);
        changeCreditStatusRequest.onFulfill(() => updateCreditData({ ...creditData, status }));
        hidePopper();
      };

      return (
        <ButtonGroup component={Paper} variant="popper">
          {CreditStatus.SENT !== creditData.status && (
            <Button onClick={() => processStatusUpdate(CreditStatus.SENT)} disabled={isDisabled}>
              Mark as Sent
            </Button>
          )}
          {CreditStatus.RECEIVED !== creditData.status && (
            <Button onClick={() => processStatusUpdate(CreditStatus.RECEIVED)} disabled={isDisabled}>
              Mark as Received
            </Button>
          )}
          {CreditStatus.PARTIALLY_RECEIVED !== creditData.status && (
            <Button onClick={() => processStatusUpdate(CreditStatus.PARTIALLY_RECEIVED)} disabled={isDisabled}>
              Mark as Partially Received
            </Button>
          )}
          {CreditStatus.DENIED !== creditData.status && (
            <Button onClick={() => processStatusUpdate(CreditStatus.DENIED)} disabled={isDisabled}>
              Mark as Denied
            </Button>
          )}
          {CreditStatus.CANCELLED !== creditData.status && (
            <Button onClick={() => processStatusUpdate(CreditStatus.CANCELLED)} disabled={isDisabled}>
              Mark as Cancelled
            </Button>
          )}
          <ErrorButton title="Cancel" onClick={hidePopper} />
        </ButtonGroup>
      );
    },
    [creditData, changeCreditStatusRequest.loading],
  );

  const renderLink = useCallback(
    () => (
      <ExportButton
        message="Download"
        busyMessage="Downloading"
        isBusy={creditReportRequest.loading}
        onClick={handleReportDownload}
        sx={({ spacing }) => ({ minWidth: spacing(16), justifyContent: 'start' })}
      />
    ),
    [handleReportDownload, creditReportRequest.loading],
  );

  return (
    <CreditRequestsRow {...creditRestProps} credit={creditData} renderAction={renderAction} renderLink={renderLink} />
  );
};

export default memo(CreditRequestRowAction) as typeof CreditRequestRowAction;
