import { memo } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import { UserRole, useAccess } from 'src/access';
import { ErrorButton } from 'src/components/shared/Buttons';
import { IRowActionControl } from 'src/components/shared/Table';
import { IRateCardHeadActionProps, RateCardHead } from 'src/components/RateCardList';

import { useRateCardContext } from './RateCardContext';

const RateCardHeadAction = (props: IRateCardHeadActionProps) => {
  const isActionAvailable = useAccess(UserRole.CUSTOMER_ADMIN);
  const { rateCardControl, rateCardRequest, approveAllRequest } = useRateCardContext();

  const confirmRateCards = () => {
    approveAllRequest.make(rateCardControl.params);
    approveAllRequest.onFulfill(() => rateCardRequest.make(rateCardControl.params));
  };

  const renderAction = ({ hidePopper }: IRowActionControl) => {
    const handleConfirm = () => {
      confirmRateCards();
      hidePopper();
    };

    if (!isActionAvailable) return null;

    return (
      <ButtonGroup component={Paper} variant="popper">
        <Button onClick={handleConfirm} disabled={approveAllRequest.loading || rateCardRequest.loading}>
          Confirm all
        </Button>
        <ErrorButton title="Cancel" onClick={hidePopper} />
      </ButtonGroup>
    );
  };

  return <RateCardHead {...props} renderAction={renderAction} />;
};

export default memo(RateCardHeadAction) as typeof RateCardHeadAction;
