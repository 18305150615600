import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import TextField, { BaseTextFieldProps } from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { InputProps } from '@mui/material/Input';
import { useState } from 'react';

import { VisibilityIcon, VisibilityOffIcon } from 'src/components/Icons';

export interface IFormPasswordProps<S extends FieldValues> {
  control: Control<S>;
  name: FieldPath<S>;
  label?: string;
  placeholder?: string;
  errorMessage?: Maybe<string>;
  onBlur?: BaseTextFieldProps['onBlur'];
  onFocus?: BaseTextFieldProps['onFocus'];
  autoComplete?: InputProps['autoComplete'];
}

export const FormPassword = <S extends FieldValues>(props: IFormPasswordProps<S>) => {
  const [isVisible, setVisibility] = useState(false);

  const EndAdornment = (
    <IconButton edge="end" onClick={() => setVisibility(!isVisible)}>
      {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
    </IconButton>
  );

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field }) => (
        <TextField
          autoComplete={props.autoComplete ?? 'off'}
          variant="filled"
          id={props.name}
          name={props.name}
          type={isVisible ? 'text' : 'password'}
          label={props.label}
          placeholder={props.placeholder}
          value={field.value ?? ''}
          error={!!props.errorMessage}
          helperText={props.errorMessage}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          onChange={field.onChange}
          InputProps={{
            endAdornment: EndAdornment,
          }}
        />
      )}
    />
  );
};
