import { HTMLAttributes, PropsWithChildren, forwardRef } from 'react';
import Typography from '@mui/material/Typography';

import {
  StyledFooterCell,
  StyledHeaderCell,
  StyledHeaderCellSort,
  StyledLeadCell,
  StyledTable,
  StyledTableCell,
  StyledTableFooterRow,
  StyledTableHeaderRow,
  StyledTableRow,
} from './styles';
import { ISortIcon, SortIcon } from './SortIcon';

export const TableBody = forwardRef<HTMLElement, PropsWithChildren>(({ children }, ref) => (
  <StyledTable ref={ref}>{children}</StyledTable>
));
TableBody.displayName = 'TableBody';

export const TableRow = ({ children }: PropsWithChildren) => <StyledTableRow data-stripe>{children}</StyledTableRow>;

export const TableCell = ({ size, children }: PropsWithChildren<{ size?: number }>) => (
  <StyledTableCell $width={size}>
    <Typography variant="body1" component={'div'}>
      {children}
    </Typography>
  </StyledTableCell>
);

export const TableLeadCell = ({ children }: PropsWithChildren) => <StyledLeadCell>{children}</StyledLeadCell>;

export const TableHeaderRow = ({ isSticky, children }: PropsWithChildren<{ isSticky?: boolean }>) => (
  <StyledTableHeaderRow $sticky={isSticky}>{children}</StyledTableHeaderRow>
);

export const TableHeaderCell = ({ preWrap, children }: PropsWithChildren<{ preWrap?: boolean }>) => (
  <StyledHeaderCell preWrap={preWrap}>
    <Typography variant="h4">{children}</Typography>
  </StyledHeaderCell>
);

export const TableHeaderCellSort = ({
  order,
  active,
  onClick,
  children,
}: PropsWithChildren<HTMLAttributes<HTMLElement>> & ISortIcon) => (
  <StyledHeaderCellSort onClick={onClick}>
    <Typography variant="h4" sx={{ display: 'inline', whiteSpace: 'nowrap', userSelect: 'none' }}>
      {children}
    </Typography>
    <SortIcon order={order} active={active} />
  </StyledHeaderCellSort>
);

export const TableFooterRow = ({ isSticky, children }: PropsWithChildren<{ isSticky?: boolean }>) => (
  <StyledTableFooterRow data-stripe $sticky={isSticky}>
    {children}
  </StyledTableFooterRow>
);

export const TableFooterCell = ({ ignoreWidth, children }: PropsWithChildren<{ ignoreWidth?: boolean }>) => (
  <StyledFooterCell $ignoreWidth={ignoreWidth}>
    <Typography variant="h4">{children}</Typography>
  </StyledFooterCell>
);
