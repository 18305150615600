import { useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';

import ActionPanel from 'src/components/shared/ActionPanel';
import { QueryFilter } from 'src/components/shared/QueryFilter';
import { PageTitle, PageContainer, Loading } from 'src/components/shared/Page';
import { invoicesSortQuery, invoicesFilterQuery, invoicesDateRangeQuery } from 'src/components/Invoice';
import InvoiceList from 'src/components/InvoiceList';
import InvoiceDateRange from 'src/components/InvoiceDateRange';
import { InvoiceCharts } from 'src/components/InvoiceCharts';
import * as apiInvoice from 'src/api/invoice';
import makeRequest from 'src/effects/makeRequest';

const useInvoicesRequest = makeRequest(apiInvoice.getInvoices);
const useInvoicesFiltersRequest = makeRequest(apiInvoice.getInvoicesFilters);
const useInvoicesChartsRequest = makeRequest(apiInvoice.getInvoicesCharts);

export default function AllInvoicesPage() {
  const invoicesRequest = useInvoicesRequest([], true);
  const invoicesFiltersRequest = useInvoicesFiltersRequest(null);
  const invoicesChartsRequest = useInvoicesChartsRequest(null);

  const invoicesFilters = invoicesFilterQuery.useFilter(invoicesFiltersRequest.data);
  const [invoicesSorting, changeInvoicesSorting] = invoicesSortQuery.useParams();
  const [dateRangeParams, setDateRangeParams] = invoicesDateRangeQuery.useParams();

  const filtersString = useMemo(
    () => invoicesFilterQuery.getSearchString(invoicesFilters.allParams),
    [invoicesFilters.params],
  );
  const periodString = useMemo(() => invoicesDateRangeQuery.getSearchString(dateRangeParams), [dateRangeParams]);
  const searchString = useMemo(
    () => [filtersString, periodString].filter(({ length }) => !!length).join('&'),
    [filtersString, periodString],
  );

  useEffect(() => invoicesFiltersRequest.make(), []);
  useEffect(() => invoicesChartsRequest.make(), []);

  useEffect(() => {
    if (!invoicesFilters.isReady) return;
    invoicesRequest.make(
      invoicesFilters.params,
      invoicesDateRangeQuery.getEncodedParams(dateRangeParams),
      invoicesSorting,
    );
  }, [invoicesFilters.isReady, invoicesFilters.params, dateRangeParams, invoicesSorting]);

  return (
    <>
      <PageTitle title={'All Invoices'}>
        <ActionPanel>
          <Box sx={{ marginRight: 'auto' }} />
          <InvoiceDateRange range={dateRangeParams} onChange={setDateRangeParams} />
        </ActionPanel>
      </PageTitle>
      <PageContainer>
        {invoicesRequest.loading && invoicesChartsRequest.loading ? (
          <Loading />
        ) : (
          <>
            <InvoiceCharts loading={invoicesChartsRequest.loading} charts={invoicesChartsRequest.data} />
            <QueryFilter control={invoicesFilters} />
            <InvoiceList
              loading={invoicesRequest.loading}
              payload={invoicesRequest.data}
              searchString={searchString}
              sorting={invoicesSorting}
              onSortChange={changeInvoicesSorting}
            />
          </>
        )}
      </PageContainer>
    </>
  );
}
