import { PropsWithChildren, createContext, useContext, useMemo } from 'react';

import { ICreditSummary, ICreditError, ICreditErrorSortBy, ICreditRecipient } from 'src/types';
import * as apiInvoice from 'src/api/invoice';
import makeRequest from 'src/effects/makeRequest';
import useSortedData, { useSortParams } from 'src/effects/useDataSorting';

import {
  IStepControl,
  useStepControl,
  ICreditErrorSelection,
  useCreditErrorSelection,
  useCreditErrorsTotal,
} from './creditRequestControl';

export interface ICreditStepsContext {
  stepControl: IStepControl;
  creditRecipientRequest: ReturnType<typeof useCreditRecipientRequest<MaybeNull<ICreditRecipient>>>;
  creditSummaryRequest: ReturnType<typeof useCreditSummaryRequest<MaybeNull<ICreditSummary>>>;
  creditErrorsRequest: ReturnType<typeof useCreditErrorsRequest<ICreditError[]>>;
  creditErrorsSorting: ReturnType<typeof useSortParams<ICreditErrorSortBy>>;
  creditErrorsTotal: ReturnType<typeof useCreditErrorsTotal>;
  creditErrorsSelection: ICreditErrorSelection;
  creditErrorsSorted: ICreditError[];
}

export interface ICreditStepsProviderProps {
  name: string;
  active: boolean;
}

export const CreditStepsContext = createContext<ICreditStepsContext>({} as ICreditStepsContext);

const useCreditRecipientRequest = makeRequest(apiInvoice.getCreditRecipient);
const useCreditSummaryRequest = makeRequest(apiInvoice.getCreditSummary);
const useCreditErrorsRequest = makeRequest(apiInvoice.getCreditErrors);

export const CreditStepsProvider = ({ children }: PropsWithChildren) => {
  const stepControl = useStepControl();

  const creditRecipientRequest = useCreditRecipientRequest(null, true);
  const creditSummaryRequest = useCreditSummaryRequest(null, true);
  const creditErrorsRequest = useCreditErrorsRequest([], true);
  const creditErrorsSorting = useSortParams<ICreditErrorSortBy>(
    useMemo(() => ({ by: 'unfavorable_errors_dollars', order: 'desc' }), []),
  );
  const creditErrorsSorted = useSortedData(creditErrorsRequest.data, creditErrorsSorting.params);
  const creditErrorsSelection = useCreditErrorSelection();
  const creditErrorsTotal = useCreditErrorsTotal(
    'error_code',
    creditErrorsSelection.selection,
    creditErrorsRequest.data,
  );

  return (
    <CreditStepsContext.Provider
      value={{
        stepControl,
        creditRecipientRequest,
        creditSummaryRequest,
        creditErrorsSelection,
        creditErrorsRequest,
        creditErrorsSorting,
        creditErrorsSorted,
        creditErrorsTotal,
      }}>
      {children}
    </CreditStepsContext.Provider>
  );
};

export const useCreditStepsContext = () => useContext(CreditStepsContext);
