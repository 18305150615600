import { StringParam, createEnumParam, withDefault } from 'use-query-params';

import { ISort, IRateCardSortBy, SortDirection, RateCardSortBy } from 'src/types';
import useNamedQueryParams from 'src/effects/useNamedQueryParams';

const SortDirectionParam = withDefault(createEnumParam(Object.values(SortDirection)), SortDirection.DESC);

const RateCardSortParam = withDefault(createEnumParam(Object.values(RateCardSortBy)), RateCardSortBy.CONTRACT_NAME);

export const useRateCardControl = (name: string) => {
  const [params, setParams] = useNamedQueryParams(name, {
    by: RateCardSortParam,
    order: SortDirectionParam,
    filter: StringParam,
  });

  const changeSorting = (sort: ISort<IRateCardSortBy>) => setParams({ by: sort.by, order: sort.order });
  const changeFilter = (filter: MaybeNull<string>) => setParams({ filter });

  const actions = {
    changeSorting,
    changeFilter,
  };

  return {
    params,
    actions,
  };
};
