import { memo, useEffect, useState } from 'react';

import { AnalyticServiceType, IAnalyticServiceType, ReportType } from 'src/types';
import { Loading } from 'src/components/shared/Page';
import ReportFrame from 'src/components/ReportFrame';
import makeRequest from 'src/effects/makeRequest';
import * as apiBrand from 'src/api/brand';

const usReportTokenRequest = makeRequest(apiBrand.getReportToken);

export interface IReportProps {
  type: IAnalyticServiceType;
}

const Report = ({ type }: IReportProps) => {
  const [token, setToken] = useState<MaybeNull<string>>(null);
  const reportTokenRequest = usReportTokenRequest(null, true);

  useEffect(() => {
    reportTokenRequest.cleanup();
    setToken(null);

    switch (type) {
      case AnalyticServiceType.SUMMARY: {
        reportTokenRequest.make(ReportType.SUMMARY);
        break;
      }
      case AnalyticServiceType.PICK_AND_PACK: {
        reportTokenRequest.make(ReportType.PICK_AND_PACK);
        break;
      }
      case AnalyticServiceType.OTHER: {
        reportTokenRequest.make(ReportType.OTHER);
        break;
      }
    }

    reportTokenRequest.onFulfill(setToken);
  }, [type]);

  if (!token) return <Loading />;

  return <ReportFrame token={token} />;
};

export default memo(Report) as typeof Report;
