import { forwardRef, memo, useEffect, useState } from 'react';
import MenuList from '@mui/material/MenuList';
import TextField from '@mui/material/TextField';

import { IFilterCriterionNumberValue } from 'src/types';

import { IFilterComponentProps } from '../types';
import { StyledDropdownContainer, StyledMenuItem } from '../styles';

const isNumber = (value: unknown) => value != null && !!`${value}`.length && !isNaN(Number(value));

const CriterionNumber = forwardRef<HTMLDivElement, IFilterComponentProps<'criterion_number'>>(
  ({ params, options, onChange }, ref) => {
    const [criterion, setCriterion] = useState<MaybeNull<IFilterCriterionNumberValue['criterion']>>(
      params?.criterion ?? null,
    );
    const [value, setValue] = useState<MaybeNull<string>>(params ? `${params.number}` : null);

    useEffect(() => {
      if (criterion == null || !isNumber(value)) return;

      onChange({
        number: Number(value),
        criterion: criterion,
      });
    }, [criterion, value]);

    return (
      <StyledDropdownContainer ref={ref}>
        <MenuList disablePadding>
          {options.criterions.map(({ key, value }) => (
            <StyledMenuItem key={key} selected={key === criterion} onClick={() => setCriterion(key)}>
              {value}
            </StyledMenuItem>
          ))}
        </MenuList>
        <TextField
          type="number"
          variant="filled"
          autoComplete="off"
          value={value ?? ''}
          placeholder={options.placeholder ?? ''}
          onChange={({ target }) => setValue(target.value)}
        />
      </StyledDropdownContainer>
    );
  },
);

CriterionNumber.displayName = 'CriterionNumber';

export default memo(CriterionNumber);
