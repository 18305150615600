import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryParam, createEnumParam, withDefault } from 'use-query-params';

import { PageTitle, PageContainer, Loading } from 'src/components/shared/Page';
import { ReconcileDataType } from 'src/types';
import makeRequest from 'src/effects/makeRequest';
import * as apiInvoice from 'src/api/invoice';
import { reconcileTypeQuery } from 'src/components/InvoiceReconcile';

const useLatestInvoiceRequest = makeRequest(apiInvoice.getLatestInvoice);

const TypeParam = withDefault(createEnumParam(Object.values(ReconcileDataType)), ReconcileDataType.ERROR_SUMMARY);

export default function ReconciliationLatestPage() {
  const latestInvoiceRequest = useLatestInvoiceRequest(null, true);
  const [invoiceType] = useQueryParam('type', TypeParam);
  const navigate = useNavigate();

  useEffect(() => {
    latestInvoiceRequest.make();
    latestInvoiceRequest.onFulfill(({ invoice_id }) => {
      const url = `/invoice/${encodeURIComponent(invoice_id)}/reconcile`;
      const searchString = reconcileTypeQuery.getSearchString({ type: invoiceType });

      navigate(`${url}?${searchString}`, { replace: true });
    });
    latestInvoiceRequest.onReject(() => navigate('/error'));
  }, []);

  return (
    <>
      <PageTitle title={'Reconciliation'} />
      <PageContainer>
        <Loading />
      </PageContainer>
    </>
  );
}
