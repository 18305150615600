import styled from '@emotion/styled';

import { ISortDirection, SortDirection } from 'src/types';
import shouldForwardProp from 'src/utils/shouldForwardProp';
import { ArrowBottomFilledIcon, ArrowTopFilledIcon } from 'src/components/Icons';

const StyledIcon = styled('span', { shouldForwardProp })<{ $active: boolean }>`
  display: inline-flex;
  align-items: center;
  color: ${({ theme, $active }) => ($active ? theme.palette.primary.main : theme.palette.text.secondary)};
`;

export interface ISortIcon {
  order: ISortDirection;
  active: boolean;
}

export const SortIcon = ({ order, active }: ISortIcon) => {
  const ActiveIcon = order === SortDirection.ASC ? ArrowTopFilledIcon : ArrowBottomFilledIcon;
  const InactiveIcon = ArrowBottomFilledIcon;
  const Icon = active ? ActiveIcon : InactiveIcon;

  return (
    <StyledIcon $active={active}>
      <Icon />
    </StyledIcon>
  );
};
