import { memo } from 'react';
import styled from '@emotion/styled';

import shouldForwardProp from 'src/utils/shouldForwardProp';

const StyledContainer = styled('span', { shouldForwardProp })<{ $color: string }>`
  color: ${({ $color }) => $color};

  & strong {
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  }
`;

export interface IStatusChipProps {
  color: string;
  label: string;
}

const StatusChip = ({ color, label }: IStatusChipProps) => (
  <StyledContainer $color={color}>
    <strong>{label}</strong>
  </StyledContainer>
);

export default memo(StatusChip);
