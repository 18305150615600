import { memo } from 'react';

import { TableCell, TableLeadCell, TableRow } from 'src/components/shared/Table';
import currencyFormatter from 'src/utils/currencyFormatter';
import { IAnomalyComparable } from 'src/types';

export interface IAnomalyComparableRowProps {
  anomaly: IAnomalyComparable;
}

const formatCurrency = currencyFormatter();

const AnomalyComparableRow = ({ anomaly }: IAnomalyComparableRowProps) => (
  <TableRow>
    <TableLeadCell />
    <TableCell>{anomaly.order_number}</TableCell>
    <TableCell>
      {anomaly.actual_weight} {anomaly.actual_weight_unit}
    </TableCell>
    <TableCell>
      {anomaly.dim_weight} {anomaly.dim_weight_unit}
    </TableCell>
    <TableCell>{anomaly.zone}</TableCell>
    <TableCell>
      {anomaly.distance} {anomaly.distance_unit}
    </TableCell>
    <TableCell>{formatCurrency(anomaly.total_accessorials_cost)}</TableCell>
    <TableCell>{formatCurrency(anomaly.total_cost)}</TableCell>
  </TableRow>
);

export default memo(AnomalyComparableRow);
