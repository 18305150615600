import { memo, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { Palette } from '@mui/material/styles';

import { ISalesOrderStatus, SalesOrderStatus } from 'src/types';
import StatusChip from 'src/components/shared/StatusChip';

export interface IOrderStatusChipProps {
  status: MaybeNull<ISalesOrderStatus>;
}

export const getOrderStatusLabel = (status: Maybe<ISalesOrderStatus>) => {
  const labels = {
    [SalesOrderStatus.ACCEPT]: 'Accepted',
    [SalesOrderStatus.UNREVIEWED]: 'Unreviewed',
    [SalesOrderStatus.REJECT]: 'Rejected',
    [SalesOrderStatus.NO_REVIEW_REQUIRED]: 'No review required',
  };

  return status ? labels[status] ?? 'Unknown' : 'Unknown';
};

export const getOrderStatusColor = (status: Maybe<ISalesOrderStatus>, palette: Palette) => {
  const colors = {
    [SalesOrderStatus.ACCEPT]: palette.success.main,
    [SalesOrderStatus.UNREVIEWED]: palette.text.secondary,
    [SalesOrderStatus.REJECT]: palette.error.main,
    [SalesOrderStatus.NO_REVIEW_REQUIRED]: palette.text.disabled,
  };

  return status ? colors[status] ?? palette.text.disabled : palette.text.disabled;
};

const OrderStatusChip = ({ status }: IOrderStatusChipProps) => {
  const { palette } = useTheme();

  const color = useMemo(() => getOrderStatusColor(status, palette), [status, palette]);
  const label = useMemo(() => getOrderStatusLabel(status), [status]);

  return <StatusChip label={label} color={color} />;
};

export default memo(OrderStatusChip);
