import { PropsWithChildren, useEffect, useRef, useState, useTransition } from 'react';

import { StyledBlank, StyledSlideTabs } from './styles';

type IBlank = { width: number; left: number };

export interface ISlideTabsProps<TValue extends string = string> {
  value: TValue;
  onChange: (value: TValue) => void;
}

const getTargetNode = (target: EventTarget): Maybe<HTMLButtonElement> => {
  if (!target || (target as HTMLElement).nodeName !== 'BUTTON') return null;
  if (!(target as HTMLButtonElement).hasAttribute('value') || (target as HTMLButtonElement).hasAttribute('disabled'))
    return null;

  return target as HTMLButtonElement;
};

export const SlideTabs = <TValue extends string = string>({
  value,
  onChange,
  children,
}: PropsWithChildren<ISlideTabsProps<TValue>>) => {
  const [, startTransition] = useTransition();
  const [blank, setBlank] = useState<MaybeNull<IBlank>>();
  const ref = useRef<MaybeNull<HTMLDivElement>>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = getTargetNode(event.target);

    if (!target) return;

    startTransition(() => onChange(target.value as TValue));
  };

  useEffect(() => {
    const node = ref.current;

    if (!node) return;

    const target = node.querySelector(`[value="${value}"]:not([disabled])`);

    node.querySelectorAll(`[value]`).forEach((node) => node.removeAttribute('data-active'));

    if (!target) return setBlank(null);

    target.setAttribute('data-active', '');

    const nodeBox = node.getBoundingClientRect();
    const targetBox = target.getBoundingClientRect();

    setBlank({
      left: targetBox.x - nodeBox.x,
      width: targetBox.width,
    });
  }, [value]);

  return (
    <StyledSlideTabs ref={ref} onClick={handleClick}>
      {blank && <StyledBlank style={{ left: blank.left, width: blank.width }} />}
      {children}
    </StyledSlideTabs>
  );
};
