import { memo, useCallback } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import { ISalesOrderStatus, SalesOrderStatus } from 'src/types';
import { ErrorButton } from 'src/components/shared/Buttons';
import { IRowActionControl } from 'src/components/shared/Table';
import { IOrderListHeaderActionProps, OrderListHeader } from 'src/components/InvoiceReconcile/Order';
import makeRequest from 'src/effects/makeRequest';
import * as apiInvoice from 'src/api/invoice';

import { useReconciliationContext } from './ReconciliationContext';

const useChangeOrdersStatusRequest = makeRequest(apiInvoice.changeOrdersStatus);

const InvoiceOrdersHeadAction = (props: IOrderListHeaderActionProps) => {
  const { invoiceName, ordersFilters, ordersPagination, ordersSorting, ordersRequest } = useReconciliationContext();
  const changeOrdersStatusRequest = useChangeOrdersStatusRequest(null);

  const renderAction = useCallback(
    ({ hidePopper }: IRowActionControl) => {
      const isDisabled = changeOrdersStatusRequest.loading;

      const processStatusUpdate = (status: ISalesOrderStatus) => {
        changeOrdersStatusRequest.make(invoiceName, ordersFilters.params, status);
        changeOrdersStatusRequest.onFulfill(() =>
          ordersRequest.make(invoiceName, ordersPagination.page, ordersSorting.params, ordersFilters.params),
        );
        hidePopper();
      };

      return (
        <ButtonGroup component={Paper} variant="popper">
          <Button onClick={() => processStatusUpdate(SalesOrderStatus.ACCEPT)} disabled={isDisabled}>
            Accept All
          </Button>
          <Button onClick={() => processStatusUpdate(SalesOrderStatus.REJECT)} disabled={isDisabled}>
            Reject All
          </Button>
          <Button onClick={() => processStatusUpdate(SalesOrderStatus.UNREVIEWED)} disabled={isDisabled}>
            Mark All as Unreviewed
          </Button>
          <ErrorButton title="Cancel" onClick={hidePopper} />
        </ButtonGroup>
      );
    },
    [invoiceName, ordersPagination.page, ordersFilters.params, ordersSorting.params, changeOrdersStatusRequest.loading],
  );

  return <OrderListHeader {...props} renderAction={renderAction} />;
};

export default memo(InvoiceOrdersHeadAction) as typeof InvoiceOrdersHeadAction;
