import { TableFooterCell, TableFooterRow } from 'src/components/shared/Table';
import currencyFormatter from 'src/utils/currencyFormatter';
import { IInvoiceTotalError } from 'src/types';
import numberFormatter from 'src/utils/numberFormatter';

const formatUSD = currencyFormatter();
const formatNumber = numberFormatter();

export interface IErrorSummaryTotalProps {
  total: IInvoiceTotalError;
}

export const ErrorListTotal = ({ total }: IErrorSummaryTotalProps) => (
  <>
    <TableFooterRow>
      <TableFooterCell ignoreWidth>Total</TableFooterCell>
      <TableFooterCell />
      <TableFooterCell />
      <TableFooterCell>{formatNumber(total.unfavorable_errors_count)}</TableFooterCell>
      <TableFooterCell>{formatUSD(total.unfavorable_errors_dollars)}</TableFooterCell>
    </TableFooterRow>
    <TableFooterRow>
      <TableFooterCell ignoreWidth>Note: Errors in your favor (excl. above)</TableFooterCell>
      <TableFooterCell />
      <TableFooterCell />
      <TableFooterCell>{formatNumber(total.favorable_errors_count)}</TableFooterCell>
      <TableFooterCell>{formatUSD(total.favorable_errors_dollars)}</TableFooterCell>
    </TableFooterRow>
  </>
);
