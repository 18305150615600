import { useCallback, useReducer } from 'react';

import { ID } from 'src/types';

export type DownloadingStatus = 'downloaded' | 'downloading' | 'failed' | 'initial';
export type DownloadFileState = {
  id: ID;
  status: DownloadingStatus;
  progress: number;
};

export type DownloadState = {
  [key: ID]: DownloadFileState;
};

export const useFilesDownloadState = () => {
  const [state, dispatch] = useReducer((state: DownloadState, update: MaybeNull<DownloadFileState>) => {
    if (!update) return {};

    if (update.status === 'downloaded') delete state[update.id];
    else state[update.id] = { ...update };

    return { ...state };
  }, {});

  const cleanup = useCallback(() => dispatch(null), [state]);

  const start = useCallback((id: ID) => dispatch({ id, status: 'downloading', progress: 0 }), [state]);

  const updateStatus = useCallback(
    (id: ID, status: DownloadingStatus) => {
      const fileState = state[id] ?? {
        progress: 0,
        status,
        id,
      };

      dispatch({ ...fileState, status });
    },
    [state],
  );

  const updateProgress = useCallback(
    (id: ID, event: ProgressEvent) => {
      const fileState = state[id];

      if (fileState) {
        const progress = (event.loaded * 100) / event.total;

        dispatch({ ...fileState, progress });
      }
    },
    [state],
  );

  return {
    state,
    start,
    cleanup,
    updateStatus,
    updateProgress,
  };
};

export type IUseFilesDownloadState = typeof useFilesDownloadState;
