import './init';
import './index.css';

import { createRoot } from 'react-dom/client';

import App from './App';
import { MOCK_ENABLED, NODE_ENV } from './config/env';

/*
 * Given we support a local workflow where api requests can be intercepted and responses can be mocked we need to check at the root before importing any service-worker plugins. **/
async function shouldEnableHttpMocking() {
  // Be cautious to only enable the mocking in development mode
  // We don't want to ship distributions of the build with mocked data, may be for demo purposes in preview environments.
  if (MOCK_ENABLED && NODE_ENV === 'development') {
    const { setupBrowserHttpMockWorker } = await import('./http-mocks/browser')

    return setupBrowserHttpMockWorker()
  }
}

shouldEnableHttpMocking().then(() => {
  const root = createRoot(document.getElementById('root') as HTMLElement);
  const app = App();

  root.render(app);
})

