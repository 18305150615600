import { memo } from 'react';

import { IAnomaly } from 'src/types';
import { TableBody } from 'src/components/shared/Table';

import { IAnomalyDetailRowAction } from './AnomalyDetailRow';
import AnomalyDetailHead from './AnomalyDetailHead';

export interface IAnomalyDetailListProps {
  anomaly: IAnomaly;
  anomalyAction: IAnomalyDetailRowAction;
}

const AnomalyDetailList = ({ anomaly, anomalyAction: AnomalyAction }: IAnomalyDetailListProps) => (
  <TableBody>
    <AnomalyDetailHead />
    <AnomalyAction anomaly={anomaly} />
  </TableBody>
);

export default memo(AnomalyDetailList);
