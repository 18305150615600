export const saveAsUrl = (url: string, name?: Maybe<string>) => {
  const node = document.createElement('a');

  node.href = url;
  if (name) node.download = name;

  document.body.appendChild(node);
  node.click();
  node.remove();
};

export const saveAsBlob = (blob: Blob, name?: Maybe<string>) => {
  const url = URL.createObjectURL(blob);

  saveAsUrl(url, name);
  URL.revokeObjectURL(url);
};
