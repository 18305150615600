import { memo, useEffect, useRef } from 'react';
import { useTheme } from '@emotion/react';
import { Chart } from 'chart.js';
import dayjs from 'dayjs';

import { IAnomalyCountChartItem } from 'src/types';
import numberFormatter from 'src/utils/numberFormatter';

import { StyledCanvasContainer } from './styles';

const COLOR = '#FBCFB6';

const formatNumber = numberFormatter();

const makeChartData = (stackData: IAnomalyCountChartItem[]) => {
  const labels = stackData.map(({ date }) => dayjs(date).format('MMM YY'));
  const backgroundColor = new Array(labels.length).fill(COLOR);
  const data = stackData.map(({ value }) => value);
  const maxValue = Math.max(...data);

  const datasets = [
    {
      data,
      backgroundColor,
      borderRadius: 5,
    },
  ];

  return {
    labels,
    datasets,
    maxValue,
  };
};

export interface IAnomalyCountChartProps {
  data: IAnomalyCountChartItem[];
}

const AnomalyCountChart = ({ data }: IAnomalyCountChartProps) => {
  const ref = useRef<MaybeNull<HTMLCanvasElement>>(null);
  const { palette, typography } = useTheme();

  useEffect(() => {
    const context = ref.current?.getContext('2d');

    if (!context) return;

    const chartData = makeChartData(data);

    const chart = new Chart(context, {
      type: 'bar',
      data: chartData,
      options: {
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              title: ([{ dataset }]) => dataset.label,
              label: (item) => formatNumber(item.raw as number),
            },
            backgroundColor: palette.secondary.main,
            titleColor: palette.secondary.light,
            bodyColor: palette.secondary.light,
            usePointStyle: true,
            boxPadding: 4,
            padding: 8,
            bodyFont: {
              family: typography.fontFamily,
              weight: typography.fontWeightRegular as number,
              size: 12,
            },
            titleFont: {
              family: typography.fontFamily,
              weight: typography.fontWeightMedium as number,
              size: 12,
            },
          },
          datalabels: {
            clamp: true,
            anchor: 'end',
            align: 'end',
            labels: {
              value: {
                color: palette.text.primary,
              },
            },
            font: {
              weight: 600,
              size: 12,
            },
            formatter: (value) => formatNumber(value),
          },
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              color: palette.text.primary,
              font: {
                weight: typography.fontWeightRegular as number,
                family: typography.fontFamily,
                size: 12,
              },
            },
          },
          y: {
            beginAtZero: true,
            border: {
              display: false,
            },
            ticks: {
              display: false,
              stepSize: chartData.maxValue / 5,
            },
            min: 0,
            max: chartData.maxValue,
            grid: {
              lineWidth: 2,
            },
          },
        },
        layout: {
          padding: {
            left: 0,
            top: 26,
          },
        },
      },
    });

    return () => chart.destroy();
  }, [data, palette, typography]);

  return (
    <StyledCanvasContainer>
      <canvas ref={ref} width="640" height="120" />
    </StyledCanvasContainer>
  );
};

export default memo(AnomalyCountChart);
