import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';

export const StyledFilterValueContainer = styled.span`
  max-width: 300px;
  min-width: 0;
  display: flex;
  padding-right: ${({ theme }) => theme.spacing(0.5)};
`;

export const StyledFilterValue = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & > * {
    display: inline;
    text-transform: none;
  }
`;

export const StyledIconButton = styled(IconButton)`
  transition: ${({ theme }) => theme.transitions.create(['color'])};

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  & > svg {
    width: ${({ theme }) => theme.spacing(2)};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  text-transform: none;
`;

export const StyledMenuList = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(3)};

  & > * {
    flex: 0;
  }
`;

export const StyledDropdownContainer = styled.div<{ $centered?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: ${({ $centered }) => ($centered ? 'center' : 'initial')};
  gap: ${({ theme }) => theme.spacing(1)};

  & > * {
    flex: 1;
  }
`;
