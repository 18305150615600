import { memo, useCallback } from 'react';

import {
  TableHeaderRow,
  TableHeaderCell,
  TableHeaderCellSort,
  TableCellAction,
  IRenderAction,
} from 'src/components/shared/Table';
import { IInvoiceOrderSortBy, ISortProps, InvoiceOrderSortBy, SortDirection } from 'src/types';

export type IOrderListHeaderActionProps = Omit<IOrderListHeaderProps, 'renderAction'>;
export type IOrderHeadAction = (props: IOrderListHeaderActionProps) => JSX.Element;

export interface IOrderListHeaderProps extends ISortProps<IInvoiceOrderSortBy> {
  renderAction: IRenderAction;
}

const OrderListHeader = ({ sort, onSort, renderAction }: IOrderListHeaderProps) => {
  const handleSortChange = useCallback(
    (fieldName: IInvoiceOrderSortBy) => {
      if (sort.by !== fieldName) {
        onSort({
          by: fieldName,
          order: SortDirection.DESC,
        });
      } else {
        onSort({
          ...sort,
          order: sort.order === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
        });
      }
    },
    [sort, onSort],
  );

  return (
    <TableHeaderRow isSticky>
      <TableCellAction renderAction={renderAction} />
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === InvoiceOrderSortBy.SALES_ORDER_NAME}
        onClick={() => handleSortChange(InvoiceOrderSortBy.SALES_ORDER_NAME)}>
        Order number
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === InvoiceOrderSortBy.STATUS}
        onClick={() => handleSortChange(InvoiceOrderSortBy.STATUS)}>
        Status
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === InvoiceOrderSortBy.ERROR_CODE}
        onClick={() => handleSortChange(InvoiceOrderSortBy.ERROR_CODE)}>
        Error type
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === InvoiceOrderSortBy.FULFILL_DATE}
        onClick={() => handleSortChange(InvoiceOrderSortBy.FULFILL_DATE)}>
        Fulfill date
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === InvoiceOrderSortBy.SERVICE_CHARGES}
        onClick={() => handleSortChange(InvoiceOrderSortBy.SERVICE_CHARGES)}>
        Expected fee
      </TableHeaderCellSort>
      <TableHeaderCell>VS</TableHeaderCell>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === InvoiceOrderSortBy.LOGISTICS_CHARGES}
        onClick={() => handleSortChange(InvoiceOrderSortBy.LOGISTICS_CHARGES)}>
        Invoiced fee
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === InvoiceOrderSortBy.UNFAVORABLE_DOLLARS}
        onClick={() => handleSortChange(InvoiceOrderSortBy.UNFAVORABLE_DOLLARS)}>
        Errors ($)
      </TableHeaderCellSort>
    </TableHeaderRow>
  );
};

export default memo(OrderListHeader);
