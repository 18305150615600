/* eslint-disable @typescript-eslint/no-explicit-any */

import { useCallback, useMemo } from 'react';
import queryString from 'query-string';
import {
  useQueryParams,
  encodeQueryParams,
  EncodedQuery,
  DecodedValueMap,
  QueryParamConfigMap,
} from 'use-query-params';

const getAllowedParams = <T extends object>(params: T, allowedKeys: string[]) =>
  Object.fromEntries(Object.entries(params).filter(([key]) => allowedKeys.includes(key))) as T;

const objectToSearchString = (name: string, allowedKeys: string[]) => (encodedParams: EncodedQuery) => {
  const namedEncodedParams = Object.fromEntries(
    Object.entries(encodedParams).map(([key, value]) => [allowedKeys.includes(key) ? `${name}-${key}` : key, value]),
  );

  return queryString.stringify(namedEncodedParams, {
    skipEmptyString: true,
    skipNull: true,
  });
};

const searchStringToObject = (name: string) => (searchString: string) => {
  const result = queryString.parse(searchString);

  return Object.fromEntries(
    Object.entries(result).map(([key, value]) => [
      key.startsWith(`${name}-`) ? key.slice(name.length + 1) : key,
      value,
    ]),
  );
};

export type IQueryParams<T> = {
  [K in keyof T]?: Maybe<T[K]>;
};

export interface INamedQueryUtils<QPCMap extends QueryParamConfigMap = QueryParamConfigMap> {
  getEncodedParams: (
    params: IQueryParams<DecodedValueMap<QPCMap>>,
  ) => Partial<{ [K in keyof QPCMap]: MaybeNull<string> }>;
  getSearchString: (params: IQueryParams<DecodedValueMap<QPCMap>>) => string;
}

export const makeNamedQueryUtils = <QPCMap extends QueryParamConfigMap = QueryParamConfigMap>(
  name: string,
  config: QPCMap,
): INamedQueryUtils<QPCMap> => {
  const allowedKeys = Object.keys(config);
  const paramsToSearchString = objectToSearchString(name, allowedKeys);

  const getEncodedParams = (params: IQueryParams<DecodedValueMap<QPCMap>>) =>
    encodeQueryParams(config, params as Partial<DecodedValueMap<QPCMap>>) as Partial<{
      [K in keyof QPCMap]: MaybeNull<string>;
    }>;

  const getSearchString = (params: IQueryParams<DecodedValueMap<QPCMap>>) => {
    const encodedParams = encodeQueryParams(config, params as Partial<DecodedValueMap<QPCMap>>);

    return paramsToSearchString(encodedParams);
  };

  return {
    getEncodedParams,
    getSearchString,
  };
};

const useNamedQueryParams = <QPCMap extends QueryParamConfigMap = QueryParamConfigMap>(
  name: string,
  config: QPCMap,
): [
  { [P in keyof QPCMap]: NonUndefined<ReturnType<QPCMap[P]['decode']>> },
  (params: IQueryParams<DecodedValueMap<QPCMap>>) => void,
] => {
  const allowedKeys = useMemo(() => Object.keys(config), [config]);
  const [rawParams, setRawParams] = useQueryParams(config, {
    objectToSearchString: objectToSearchString(name, allowedKeys),
    searchStringToObject: searchStringToObject(name),
  });

  const params = useMemo(
    () => Object.fromEntries(Object.entries(rawParams).map(([key, value]) => [key, value ?? null])) as any,
    [rawParams],
  );

  const setParams = useCallback(
    (newParams: IQueryParams<DecodedValueMap<QPCMap>>) => {
      const allowedParams = getAllowedParams(newParams, allowedKeys);

      if (Object.keys(allowedParams).length)
        setRawParams(allowedParams as Partial<DecodedValueMap<QPCMap>>, 'replaceIn');
    },
    [allowedKeys, setRawParams],
  );

  return [params, setParams];
};

export default useNamedQueryParams;

export const makeNamedQueryParams = <QPCMap extends QueryParamConfigMap = QueryParamConfigMap>(
  name: string,
  config: QPCMap,
) => {
  const utils = makeNamedQueryUtils(name, config);
  const useParams = () => useNamedQueryParams(name, config);

  return {
    ...utils,
    useParams,
  };
};
