import { memo, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { useIntercom } from 'react-use-intercom';

import { INTERCOM_APP_ID } from 'src/config/env';
import { authService } from 'src/services/AuthService';

const IntercomChat = () => {
  if (!INTERCOM_APP_ID) return null;

  const theme = useTheme();
  const { boot, shutdown } = useIntercom();

  useEffect(() => {
    boot({
      userId: `${authService.user.id}`,
      userHash: authService.user.intercom_hash,
      name: `${authService.user.first_name} ${authService.user.last_name}`,
      email: authService.user.email,
      company: {
        companyId: `${authService.user.brand.id}`,
        name: authService.user.brand.name,
      },

      actionColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
    });

    return () => shutdown();
  }, []);

  return null;
};

export default memo(IntercomChat);
