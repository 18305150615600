export interface INumberFormatterConfig {
  locale?: string;
  placeholder?: string;
  notation?: 'standard' | 'scientific' | 'engineering' | 'compact';
}

const DEFAULT_CONFIG: INumberFormatterConfig = {
  locale: 'en-US',
  placeholder: 'N/A',
  notation: 'standard',
};

const numberFormatter = (config?: INumberFormatterConfig) => {
  const locale = config?.locale ?? DEFAULT_CONFIG.locale;
  const placeholder = config?.placeholder ?? DEFAULT_CONFIG.placeholder;
  const notation = config?.notation ?? DEFAULT_CONFIG.notation;

  const formatter = new Intl.NumberFormat(locale, { notation });

  return (value: Maybe<number>) => (value != null ? formatter.format(value) : placeholder);
};

export default numberFormatter;
