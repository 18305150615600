import { useEffect } from 'react';
import { Control, FieldErrors, UseFormWatch } from 'react-hook-form';
import Typography from '@mui/material/Typography';

import { FormText, FormDate } from 'src/components/shared/Form';
import { IRateCardSchema } from 'src/schemas';

import { StyledContainer, StyledInputGroup, StyledHeaderName, StyledName } from './styles';
import { IFormBlockErrorProps } from './RateCardBlock';

export interface IRateCardRatesProps {
  errors: FieldErrors<IRateCardSchema>;
  control: Control<IRateCardSchema>;
  watch: UseFormWatch<IRateCardSchema>;
}

export const RateCardRates = ({ errors, control, watch, onError }: IRateCardRatesProps & IFormBlockErrorProps) => {
  const hasErrors = !!errors.rates;

  useEffect(() => onError(hasErrors), [onError, hasErrors]);

  return (
    <StyledContainer>
      <StyledHeaderName>
        <Typography variant="h4">Line item</Typography>
      </StyledHeaderName>
      <StyledInputGroup>
        <Typography variant="h4">Normal</Typography>
        <Typography variant="h4">Peak</Typography>
      </StyledInputGroup>
      <StyledName>
        <Typography variant="h4">Start on</Typography>
      </StyledName>
      <StyledInputGroup>
        <FormDate
          format="M/D/YY"
          maxDate={watch('rates.0.effectiveTo')}
          control={control}
          name="rates.0.effectiveFrom"
          errorMessage={errors.rates?.[0]?.effectiveFrom?.message}
        />
        <FormDate
          format="M/D/YY"
          maxDate={watch('rates.1.effectiveTo')}
          control={control}
          name="rates.1.effectiveFrom"
          errorMessage={errors.rates?.[1]?.effectiveFrom?.message}
        />
      </StyledInputGroup>
      <StyledName>
        <Typography variant="h4">End on</Typography>
      </StyledName>
      <StyledInputGroup>
        <FormDate
          format="M/D/YY"
          minDate={watch('rates.0.effectiveFrom')}
          control={control}
          name="rates.0.effectiveTo"
          errorMessage={errors.rates?.[0]?.effectiveTo?.message}
        />
        <FormDate
          format="M/D/YY"
          minDate={watch('rates.1.effectiveFrom')}
          control={control}
          name="rates.1.effectiveTo"
          errorMessage={errors.rates?.[1]?.effectiveTo?.message}
        />
      </StyledInputGroup>
      <StyledName>
        <Typography variant="h4">{watch('rates.0.details.0.description')}</Typography>
      </StyledName>
      <StyledInputGroup>
        <FormText
          control={control}
          name="rates.0.details.0.value"
          errorMessage={errors.rates?.[0]?.details?.[0]?.value?.message}
        />
        <FormText
          control={control}
          name="rates.1.details.0.value"
          errorMessage={errors.rates?.[1]?.details?.[0]?.value?.message}
        />
      </StyledInputGroup>
      <StyledName>
        <Typography variant="h4">{watch('rates.0.details.1.description')}</Typography>
      </StyledName>
      <StyledInputGroup>
        <FormText
          control={control}
          name="rates.0.details.1.value"
          errorMessage={errors.rates?.[0]?.details?.[1]?.value?.message}
        />
        <FormText
          control={control}
          name="rates.1.details.1.value"
          errorMessage={errors.rates?.[1]?.details?.[1]?.value?.message}
        />
      </StyledInputGroup>
      <StyledName>
        <Typography variant="h4">{watch('rates.0.details.2.description')}</Typography>
      </StyledName>
      <StyledInputGroup>
        <FormText
          control={control}
          name="rates.0.details.2.value"
          errorMessage={errors.rates?.[0]?.details?.[2]?.value?.message}
        />
        <FormText
          control={control}
          name="rates.1.details.2.value"
          errorMessage={errors.rates?.[1]?.details?.[2]?.value?.message}
        />
      </StyledInputGroup>
    </StyledContainer>
  );
};
