import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const Loading = () => (
  <StyledContainer>
    <CircularProgress />
  </StyledContainer>
);
