import { memo } from 'react';
import Typography from '@mui/material/Typography';

import numberFormatter from 'src/utils/numberFormatter';
import currencyFormatter from 'src/utils/currencyFormatter';

import { StyledInfoLine } from '../CreditDialog/styles';

export interface IErrorTypesTotalProps {
  totalCount: number;
  totalDollars: number;
}

const formatUSD = currencyFormatter();
const formatNumber = numberFormatter();

const ErrorTypesTotal = ({ totalCount, totalDollars }: IErrorTypesTotalProps) => (
  <StyledInfoLine>
    <span>
      <Typography variant="h4" component={'dt'}>
        Total Errors:
      </Typography>
      <Typography variant="body1" component={'dd'}>
        {formatNumber(totalCount)}
      </Typography>
    </span>
    <span>
      <Typography variant="h4" component={'dt'}>
        Total Errors:
      </Typography>
      <Typography variant="body1" component={'dd'}>
        {formatUSD(totalDollars)}
      </Typography>
    </span>
  </StyledInfoLine>
);

export default memo(ErrorTypesTotal);
