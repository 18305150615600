import { PropsWithChildren } from 'react';
import { NavLinkProps } from 'react-router-dom';

import { ListItemIcon } from 'src/components/Icons';

import { StyledSubmenuLink } from './styles';

export interface ISubmenuLinkProps extends NavLinkProps {
  disabled?: boolean;
}

const SubmenuLink = ({ children, disabled, ...props }: PropsWithChildren<ISubmenuLinkProps>) => (
  <StyledSubmenuLink {...props} $disabled={disabled}>
    <ListItemIcon />
    {children}
  </StyledSubmenuLink>
);

export default SubmenuLink;
