import { memo, useCallback } from 'react';

import {
  TableHeaderRow,
  TableHeaderCell,
  TableHeaderCellSort,
  TableCellAction,
  IRenderAction,
} from 'src/components/shared/Table';
import { IRateCardSortBy, RateCardSortBy, SortDirection, ISortProps } from 'src/types';

export type IRateCardHeadActionProps = Omit<IRateCardHeadProps, 'renderAction'>;
export type IRateCardHeadAction = (props: IRateCardHeadActionProps) => JSX.Element;

export interface IRateCardHeadProps extends ISortProps<IRateCardSortBy> {
  renderAction: IRenderAction;
}

const RateCardHead = ({ sort, onSort, renderAction }: IRateCardHeadProps) => {
  const handleSortChange = useCallback(
    (fieldName: IRateCardSortBy) => {
      if (sort.by !== fieldName) {
        onSort({
          by: fieldName,
          order: SortDirection.DESC,
        });
      } else {
        onSort({
          ...sort,
          order: sort.order === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
        });
      }
    },
    [sort, onSort],
  );

  return (
    <TableHeaderRow>
      <TableCellAction renderAction={renderAction} />
      <TableHeaderCell>Period</TableHeaderCell>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === RateCardSortBy.CONTRACT_NAME}
        onClick={() => handleSortChange(RateCardSortBy.CONTRACT_NAME)}>
        Name
      </TableHeaderCellSort>
      <TableHeaderCell>3PL Provider</TableHeaderCell>
      <TableHeaderCell>Warehouse Location</TableHeaderCell>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === RateCardSortBy.STATUS_NAME}
        onClick={() => handleSortChange(RateCardSortBy.STATUS_NAME)}>
        Status
      </TableHeaderCellSort>
    </TableHeaderRow>
  );
};

export default memo(RateCardHead);
