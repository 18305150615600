import { memo } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';

import { StyledDialogPaper } from './styles';

const CreditDialog = (props: Omit<DialogProps, 'PaperComponent' | 'elevation'>) => (
  <Dialog {...props} PaperComponent={StyledDialogPaper} PaperProps={{ elevation: 2 }} />
);

export default memo(CreditDialog);
