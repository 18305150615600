import { useEffect, useState } from 'react';

import { PageTitle } from 'src/components/shared/Page';
import ReportFrame from 'src/components/ReportFrame';
import makeRequest from 'src/effects/makeRequest';
import * as apiBrand from 'src/api/brand';

const useHomeTokenRequest = makeRequest(apiBrand.getTokenHome);

export default function DashboardPage() {
  const [token, setToken] = useState<MaybeNull<string>>(null);
  const homeTokenRequest = useHomeTokenRequest(null, true);

  useEffect(() => {
    homeTokenRequest.make();
    homeTokenRequest.onFulfill(setToken);
  }, []);

  return (
    <>
      <PageTitle title={'My dashboard'} />
      <ReportFrame token={token} />
    </>
  );
}
