import './alert-animation.css';

import { MutableRefObject, useCallback, useEffect } from 'react';

const ALERT_SOUND = '/assets/alert.mp3';
const ANIMATION_CLASS_NAME = 'alert-bell-shake';

const audio = new Audio(ALERT_SOUND);

const useAlertNotify = (ref: MutableRefObject<MaybeNull<HTMLDivElement>>) => {
  useEffect(() => {
    const node = ref.current;

    if (!node) return;

    const handleAnimationEnd = () => node.classList.remove(ANIMATION_CLASS_NAME);

    node.addEventListener('animationend', handleAnimationEnd);

    return () => node.removeEventListener('animationend', handleAnimationEnd);
  }, []);

  return useCallback(() => {
    const node = ref.current;

    if (!node || document.hidden) return;

    node.classList.add(ANIMATION_CLASS_NAME);
    audio.play();
  }, []);
};

export default useAlertNotify;
