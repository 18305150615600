import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button, { ButtonProps } from '@mui/material/Button';

import { BrandSchema, IBrandSchema } from 'src/schemas';
import { IBrandStatus, BrandStatus } from 'src/types';
import { FormFields, FormInput, FormSelect, FormText } from 'src/components/shared/Form';

export interface IFormId {
  id: string;
}

export interface IBrandFormProps extends IFormId {
  onSubmit: (brand: IBrandSchema) => void;
}

export const BrandSubmitButton = ({ id, ...props }: IFormId & ButtonProps) => (
  <Button {...props} form={id} type="submit" variant="contained">
    Save
  </Button>
);

export const BrandForm = ({ id, onSubmit }: IBrandFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IBrandSchema>({
    resolver: yupResolver(BrandSchema),
  });

  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <FormFields>
        <FormInput>
          <FormSelect
            name="status"
            label="Status"
            control={control}
            errorMessage={errors?.status?.message}
            options={Object.values<IBrandStatus>(BrandStatus)}
          />
        </FormInput>
        <FormInput>
          <FormText control={control} name="name" label="Name" errorMessage={errors?.name?.message} />
        </FormInput>
        <FormInput>
          <FormText control={control} name="email" label="Email" errorMessage={errors?.email?.message} />
        </FormInput>
      </FormFields>
    </form>
  );
};
