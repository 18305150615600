import styled from '@emotion/styled';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';

import shouldForwardProp from 'src/utils/shouldForwardProp';

export const StyledDialog = styled(Paper)`
  min-width: 800px;
  width: 800px;
`;

export const StyledTitle = styled(DialogTitle)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const StyledContent = styled(DialogContent)`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const StyledFile = styled(Paper, { shouldForwardProp })<{ $failed?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(1, 2)};
  background-color: ${({ theme, $failed }) => ($failed ? theme.palette.error.light : theme.palette.secondary.light)};
`;

export const StyledProgress = styled.div`
  display: flex;
  position: relative;
  flex: 1;
`;

export const StyledUploadProgress = styled(LinearProgress)`
  border-radius: 4px;
  height: 4px;
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  width: 100%;
`;

export const StyledFileIcon = styled(DescriptionOutlinedIcon)`
  padding: 0;
`;

export const StyledDownloadIcon = styled(FileDownloadIcon)`
  cursor: pointer;
  padding: 0;
`;

export const StyledFileDetails = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 100%;
  min-width: 0;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const StyledFileName = styled.h4`
  flex: 1;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: flex;
`;
