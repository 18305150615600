import { memo, useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';

import { DownloadProgress } from 'src/components/InvoiceReconcile';
import makeRequest from 'src/effects/makeRequest';
import * as apiRoot from 'src/api/root';

const useErrorsReportRequest = makeRequest(apiRoot.getInvoiceReportId);

import { useCreditRequestContext } from './CreditRequestContext';
import { useCreditStepsContext } from './CreditStepsContext';
import { makeErrorFilters } from './creditRequestControl';
import requestService from './CreditRequestService';

const DownloadAttachment = () => {
  const { invoiceName } = useCreditRequestContext();
  const { stepControl, creditErrorsSelection } = useCreditStepsContext();
  const errorsReportRequest = useErrorsReportRequest(null);
  const controller = useMemo(() => requestService.getController(invoiceName), [invoiceName]);

  const [progress, setProgress] = useState(() => controller.progress.current);
  const [isGenerating, setGenerating] = useState(() => controller.isGenerating);
  const [isRequesting, setRequesting] = useState(false);

  const handleDownload = () => {
    const filters = makeErrorFilters([...creditErrorsSelection.selection]);

    setRequesting(true);
    errorsReportRequest.cleanup();
    errorsReportRequest.make({ name: invoiceName, type: 'orders', format: 'csv', filters }, false);
    errorsReportRequest.onFulfill(controller.download);
    errorsReportRequest.onFinally(() => setRequesting(false));
  };

  useEffect(() => {
    const unsubscribeController = controller.subscribe(setGenerating);
    const unsubscribeProgress = controller.progress.subscribe(setProgress);

    return () => {
      unsubscribeController();
      unsubscribeProgress();
    };
  }, []);

  useEffect(() => {
    controller.resume();

    return () => controller.pause();
  }, []);

  if (isGenerating) return <DownloadProgress progress={progress} />;

  if (stepControl.isEmailStep) {
    return (
      <Button variant="text" disabled={isRequesting} onClick={handleDownload}>
        {isRequesting ? 'Downloading' : 'Download Attachment'}
      </Button>
    );
  }

  return null;
};

export default memo(DownloadAttachment);
