import { PropsWithChildren } from 'react';
import { EncodedQuery, QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import queryString from 'query-string';

const objectToSearchString = (encodedParams: EncodedQuery) =>
  queryString.stringify(encodedParams, {
    skipEmptyString: true,
    skipNull: true,
  });

const searchStringToObject = queryString.parse;

const options = {
  searchStringToObject,
  objectToSearchString,
};

const QueryProvider = ({ children }: PropsWithChildren) => (
  <QueryParamProvider adapter={ReactRouter6Adapter} options={options}>
    {children}
  </QueryParamProvider>
);

export default QueryProvider;
