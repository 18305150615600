import { memo } from 'react';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';

import { IAnomalyDetails } from 'src/types';
import { CloseIcon } from 'src/components/Icons';
import { Loading } from 'src/components/shared/Page';

import { StyledCloseButton, StyledDialogHeader, StyledDialogPaper } from './styles';
import AnomalyDetails, { IAnomalyDetailsProps } from './AnomalyDetails';

export interface IAnomalyDetailsDialogProps {
  loading: boolean;
  payload: MaybeNull<IAnomalyDetails>;
  open: boolean;
  onClose: () => void;
  anomalyAction: IAnomalyDetailsProps['anomalyAction'];
}

const AnomalyDetailsDialog = ({ open, onClose, loading, payload, anomalyAction }: IAnomalyDetailsDialogProps) => (
  <Dialog
    maxWidth={false}
    open={open}
    onClose={onClose}
    PaperComponent={StyledDialogPaper}
    PaperProps={{ elevation: 2 }}>
    <>
      <StyledDialogHeader>
        <Typography variant="h2">Anomaly Detection</Typography>
        <StyledCloseButton onClick={onClose}>
          <CloseIcon />
        </StyledCloseButton>
      </StyledDialogHeader>
      {loading ? <Loading /> : <AnomalyDetails details={payload} anomalyAction={anomalyAction} />}
    </>
  </Dialog>
);

export default memo(AnomalyDetailsDialog);
