import {
  ISortDirection,
  IInvoice,
  IInvoiceChart,
  ILatestInvoice,
  IInvoicesFilters,
  IInvoiceSummary,
  IInvoiceServices,
  IInvoiceErrorTotal,
  IInvoiceOrders,
  IInvoiceOrdersFilters,
  IInvoiceErrorsFilters,
  ICreditSummary,
  ICreditError,
  ICreditRecipient,
  ICreditRequest,
  ISearchResult,
  ISalesOrderStatus,
  ID,
} from 'src/types';

import request, { serializeQueryJson } from './requestPrivate';

export const getInvoices = (
  filters: object,
  period: Partial<{ from: Maybe<string>; to: Maybe<string> }>,
  sorting?: { by: string; order: ISortDirection },
) => {
  const filtersQuery = JSON.stringify(filters, serializeQueryJson);

  return request.get<IInvoice[]>('/invoice', {
    start_date: period.from,
    end_date: period.to,
    sort_by: sorting?.by,
    sort: sorting?.order,
    filtering_query_json: filtersQuery,
  });
};

export const getInvoicesCharts = () => request.get<IInvoiceChart>('/invoice/chart-data');

export const getLatestInvoice = () => request.get<ILatestInvoice>('/invoice/latest');

export const getInvoicesFilters = () => request.get<IInvoicesFilters>('/invoice/filters');

export const getInvoiceSummary = (name: string) => request.get<IInvoiceSummary>(`/invoice/${encodeURIComponent(name)}`);

export const getInvoiceServices = (name: string) =>
  request.get<IInvoiceServices>(`/invoice/details/${encodeURIComponent(name)}`);

export const getInvoiceErrors = (name: string) =>
  request.get<IInvoiceErrorTotal>(`/invoice/errors/${encodeURIComponent(name)}`, {
    filtering_query_json: JSON.stringify({}),
  });

export const getInvoiceOrders = (
  name: string,
  page: number,
  sorting: { by: string; order: ISortDirection },
  filters: object,
) => {
  const filtersQuery = JSON.stringify(filters, serializeQueryJson);

  return request.get<IInvoiceOrders>(`/invoice/salesorders/${encodeURIComponent(name)}`, {
    filtering_query_json: filtersQuery,
    sort: sorting.order,
    sort_by: sorting.by,
    page: page,
    page_size: 30,
  });
};

export const getInvoiceOrdersFilters = () =>
  request.get<Omit<IInvoiceOrdersFilters, 'ERROR_CODE'>>('/invoice/orders/filters');

export const getInvoiceErrorsFilters = () => request.get<IInvoiceErrorsFilters>('/invoice/errors/filters');

export const getCreditSummary = (name: string) =>
  request.get<ICreditSummary>(`/invoice/credit-requests/${encodeURIComponent(name)}`);

export const getCreditErrors = (name: string) =>
  request.get<ICreditError[]>(`/invoice/credit-requests/errors/${encodeURIComponent(name)}`);

export const getCreditRecipient = (name: string) =>
  request.get<ICreditRecipient>(`/invoice/recipient-info/${encodeURIComponent(name)}`);

export const createCredit = (credit: ICreditRequest, filters: object) => {
  const filtersQuery = JSON.stringify(filters, serializeQueryJson);

  return request.post('/invoice/', null, {
    ...credit,
    filtering_query_json: filtersQuery,
  });
};

export const getSearchResults = (search_text: string) => request.get<ISearchResult>('/invoice/search', { search_text });

export const changeOrderStatus = (id: ID, status: ISalesOrderStatus) =>
  request.put(`/reconciliation/actions/salesorders/${encodeURIComponent(id)}/approval`, null, {
    status,
  });

export const changeOrdersStatus = (name: string, filters: object, status: ISalesOrderStatus) => {
  const filtersQuery = JSON.stringify(filters, serializeQueryJson);

  return request.put(`/reconciliation/actions/salesorders/invoice/${encodeURIComponent(name)}/approval`, null, {
    status,
    filtering_query_json: filtersQuery,
  });
};
