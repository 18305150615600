import { memo, useMemo } from 'react';

import { StyledErrorsCards, InvoiceSummaryCard } from 'src/components/InvoiceReconcile';
import { PieChartCard, IPieChartSegment, createChartColor } from 'src/components/shared/PieChart';
import Message from 'src/components/shared/Message';
import { IInvoiceSummary, IInvoiceSummaryError } from 'src/types';
import currencyFormatter from 'src/utils/currencyFormatter';
import numberFormatter from 'src/utils/numberFormatter';

export interface IInvoiceSummaryProps {
  invoice: Maybe<IInvoiceSummary>;
}

const formatCurrency = currencyFormatter();
const formatNumber = numberFormatter();

const errorsCostToSegments = (data: IInvoiceSummaryError[] = []) =>
  data
    .filter(({ value }) => value > 0)
    .sort((prev, next) => next.value - prev.value)
    .map(({ name, value }, index) => ({
      name,
      value,
      color: createChartColor(index),
      view: formatCurrency(value),
    })) as IPieChartSegment[];

const errorsAmountToSegments = (data: IInvoiceSummaryError[] = []) =>
  data
    .filter(({ value }) => value > 0)
    .sort((prev, next) => next.value - prev.value)
    .map(({ name, value }, index) => ({
      name,
      value,
      color: createChartColor(index),
      view: formatNumber(value),
    })) as IPieChartSegment[];

const InvoiceSummary = ({ invoice }: IInvoiceSummaryProps) => {
  const costSegments = useMemo(
    () => errorsCostToSegments(invoice?.unfavorable_errors_cost.data),
    [invoice?.unfavorable_errors_cost.data],
  );
  const amountSegments = useMemo(
    () => errorsAmountToSegments(invoice?.unfavorable_errors_amount.data),
    [invoice?.unfavorable_errors_amount.data],
  );

  if (!invoice) return <Message>No information about invoice</Message>;

  return (
    <StyledErrorsCards>
      <PieChartCard title={'Errors (#)'} segments={amountSegments} emptyMessage={'No Errors'} />
      <InvoiceSummaryCard invoice={invoice.summary} />
      <PieChartCard title={'Errors ($)'} segments={costSegments} emptyMessage={'No Errors'} />
    </StyledErrorsCards>
  );
};

export default memo(InvoiceSummary);
