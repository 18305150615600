import { ThemedComponent } from '@mui/material/styles';

export const MuiPaper: ThemedComponent<'MuiPaper'> = {
  defaultProps: {
    elevation: 3,
  },
  variants: [
    {
      props: { variant: 'accent' },
      style: ({ theme, ownerState }) => ({
        boxShadow: ownerState.elevation ? `${theme.shadows[ownerState.elevation]} inset` : '',
        borderColor: theme.palette.primary.main,
      }),
    },
    {
      props: { variant: 'inset' },
      style: ({ theme, ownerState }) => ({
        boxShadow: ownerState.elevation ? `${theme.shadows[ownerState.elevation]} inset` : '',
      }),
    },
  ],
  styleOverrides: {
    root: {
      borderWidth: 0,
      borderStyle: 'solid',
      borderColor: 'transparent',
    },
    elevation0: ({ theme }) => ({
      boxShadow: theme.shadows[0],
    }),
    elevation1: ({ theme }) => ({
      boxShadow: theme.shadows[1],
    }),
    elevation2: ({ theme }) => ({
      boxShadow: theme.shadows[2],
    }),
    elevation3: ({ theme }) => ({
      boxShadow: theme.shadows[3],
    }),
  },
};
