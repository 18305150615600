import { PropsWithChildren } from 'react';

import { StyledCard, StyledDescription, StyledErrorTypesHeader } from './styles';

export const ErrorTypesCard = ({ children }: PropsWithChildren) => <StyledCard>{children}</StyledCard>;

export const ErrorTypesHeader = ({ children }: PropsWithChildren) => (
  <StyledErrorTypesHeader>{children}</StyledErrorTypesHeader>
);

export const ErrorTypesDescription = ({ description }: { description: string }) => (
  <StyledDescription>{description}</StyledDescription>
);
