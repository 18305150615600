import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import Button, { ButtonOwnProps } from '@mui/material/Button';

export interface IExportButtonProps {
  isBusy?: boolean;
  onClick?: () => void;
  message?: string;
  busyMessage?: string;
  sx?: ButtonOwnProps['sx'];
}

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(1)};
`;

const ExportButton = ({ sx, isBusy, onClick, message = 'Export', busyMessage = 'Exporting' }: IExportButtonProps) => {
  const handleClick = () => {
    if (isBusy) return;

    onClick?.();
  };

  return (
    <StyledButton sx={sx} variant="text" onClick={handleClick} disabled={isBusy}>
      {isBusy ? (
        <>
          <CircularProgress size="10px" /> {busyMessage}
        </>
      ) : (
        message
      )}
    </StyledButton>
  );
};

export default ExportButton;
