import { IUserListItem } from 'src/types';
import { TableBody } from 'src/components/shared/Table';

import { UserRow } from './UserRow';
import { UserHeaders } from './UserHeaders';

const NoUsers = () => <>You don&apos;t have users.</>;

export interface IUserListProps {
  users: IUserListItem[];
}

export const UserList = ({ users }: IUserListProps) => {
  if (!users.length) return <NoUsers />;

  return (
    <TableBody>
      <UserHeaders />
      <>
        {users.map((user) => (
          <UserRow user={user} key={`users-row-${user.id}`} />
        ))}
      </>
    </TableBody>
  );
};
