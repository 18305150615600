import { memo } from 'react';

import { IRenderAction, TableCell, TableCellAction, TableRow } from 'src/components/shared/Table';
import currencyFormatter from 'src/utils/currencyFormatter';
import { AnomalyType, IAnomaly } from 'src/types';

import AnomalyChip from './AnomalyChip';

export type IAnomalyDetailRowActionProps = Omit<IAnomalyDetailRowProps, 'renderAction'>;
export type IAnomalyDetailRowAction = (props: IAnomalyDetailRowActionProps) => JSX.Element;

export interface IAnomalyDetailRowProps {
  anomaly: IAnomaly;
  renderAction: IRenderAction;
}

const formatCurrency = currencyFormatter();

const AnomalyDetailRow = ({ anomaly, renderAction }: IAnomalyDetailRowProps) => (
  <TableRow>
    <TableCellAction renderAction={renderAction} />
    <TableCell>{anomaly.order_number}</TableCell>
    <TableCell>
      <AnomalyChip active={anomaly.anomaly_type === AnomalyType.ACTUAL_WEIGHT}>
        {anomaly.actual_weight} {anomaly.actual_weight_unit}
      </AnomalyChip>
    </TableCell>
    <TableCell>
      <AnomalyChip active={anomaly.anomaly_type === AnomalyType.DIM_WEIGHT}>
        {anomaly.dim_weight} {anomaly.dim_weight_unit}
      </AnomalyChip>
    </TableCell>
    <TableCell>
      <AnomalyChip active={anomaly.anomaly_type === AnomalyType.ZONE}>{anomaly.zone}</AnomalyChip>
    </TableCell>
    <TableCell>
      {anomaly.distance} {anomaly.distance_unit}
    </TableCell>
    <TableCell>
      <AnomalyChip active={anomaly.anomaly_type === AnomalyType.TOTAL_ACCESSORIALS_COST}>
        {formatCurrency(anomaly.total_accessorials_cost)}
      </AnomalyChip>
    </TableCell>
    <TableCell>
      <AnomalyChip active={anomaly.anomaly_type === AnomalyType.TOTAL_COST}>
        {formatCurrency(anomaly.total_cost)}
      </AnomalyChip>
    </TableCell>
  </TableRow>
);

export default memo(AnomalyDetailRow);
