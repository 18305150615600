import createPalette from '@mui/material/styles/createPalette';
import { alpha } from '@mui/system/colorManipulator';

const options = {
  text: {
    primary: '#100F41',
    secondary: '#656565',
    disabled: '#C6C8CB',
  },
  primary: {
    light: '#FEEFE7',
    main: '#F25E0A',
  },
  secondary: {
    main: '#100F41',
    light: '#EEEEF2',
  },
  error: {
    main: '#AC0A0A',
    light: alpha('#AC0A0A', 0.3),
  },
  warning: {
    main: '#C09B17',
    light: alpha('#C09B17', 0.3),
  },
  success: {
    main: '#228D3B',
    light: alpha('#228D3B', 0.3),
  },
  info: {
    main: '#00B2FF',
    light: alpha('#00B2FF', 0.3),
  },
} as const;

export default createPalette(options);
