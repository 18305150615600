import { useCallback } from 'react';

import { TableHeaderRow, TableHeaderCellSort, TableLeadCell, TableHeaderCell } from 'src/components/shared/Table';
import { ICreditSortBy, CreditSortBy, ISortProps, SortDirection } from 'src/types';

export const CreditRequestsHeaders = ({ sort, onSort }: ISortProps<ICreditSortBy>) => {
  const handleSortChange = useCallback(
    (fieldName: ICreditSortBy) => {
      if (sort.by !== fieldName) {
        onSort({
          by: fieldName,
          order: SortDirection.DESC,
        });
      } else {
        onSort({
          ...sort,
          order: sort.order === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
        });
      }
    },
    [sort, onSort],
  );

  return (
    <TableHeaderRow>
      <TableLeadCell />
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === CreditSortBy.id}
        onClick={() => handleSortChange(CreditSortBy.id)}>
        Credit Request
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === CreditSortBy.invoice_number}
        onClick={() => handleSortChange(CreditSortBy.invoice_number)}>
        Invoice Number
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === CreditSortBy.status}
        onClick={() => handleSortChange(CreditSortBy.status)}>
        Status
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === CreditSortBy.submit_date}
        onClick={() => handleSortChange(CreditSortBy.submit_date)}>
        Submit Date
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === CreditSortBy.errors_count}
        onClick={() => handleSortChange(CreditSortBy.errors_count)}>
        Errors (#)
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === CreditSortBy.errors_dollars}
        onClick={() => handleSortChange(CreditSortBy.errors_dollars)}>
        Errors ($)
      </TableHeaderCellSort>
      <TableHeaderCell>File Link</TableHeaderCell>
    </TableHeaderRow>
  );
};
