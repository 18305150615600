import { InputHTMLAttributes, useEffect, useRef } from 'react';

import { HiddenInput } from './styles';

type IUploadId = string | number;

export interface ITargetInputProps extends InputHTMLAttributes<HTMLInputElement> {
  uploadId?: MaybeNull<IUploadId>;
}

const setInputValue = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value')?.set;

const TargetInput = ({ uploadId, ...props }: ITargetInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const event = new Event('input', { bubbles: true });

    setInputValue?.call(inputRef.current, uploadId ?? '');
    inputRef.current?.dispatchEvent(event);
  }, [uploadId]);

  return <HiddenInput {...props} ref={inputRef} />;
};

export default TargetInput;
