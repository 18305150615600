import styled from '@emotion/styled';

import { IBrand } from 'src/types';
import dateFormatter from 'src/utils/dateFormatter';

import BrandStatus from './BrandStatus';

const StyledFields = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: ${({ theme }) => theme.spacing(1, 2)};
  align-items: center;
`;

const StyledName = styled.span`
  text-align: right;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;

const StyledValue = styled.div``;

const formatDate = dateFormatter();

export interface IBrandProps {
  brand: IBrand;
}

export const BrandDetails = ({ brand }: IBrandProps) => (
  <StyledFields>
    <StyledName>Status:</StyledName>
    <StyledValue>
      <BrandStatus status={brand.status} />
    </StyledValue>
    <StyledName>Created at:</StyledName>
    <StyledValue>{formatDate(brand.created_at)}</StyledValue>
    <StyledName>Name:</StyledName>
    <StyledValue>{brand.name}</StyledValue>
    <StyledName>Email:</StyledName>
    <StyledValue>{brand.email}</StyledValue>
  </StyledFields>
);
