import { useCallback, useEffect, useRef, useState } from 'react';

const useXScrollControl = <Element extends HTMLElement>() => {
  const ref = useRef<MaybeNull<Element>>(null);
  const [factor, setFactor] = useState(0);
  const [active, setActivity] = useState(false);

  const updateScrollFactor = useCallback((element: Element) => {
    const factor = (element.scrollLeft / (element.scrollWidth - element.offsetWidth)) * 100;
    const normalizedFactor = Number.isNaN(factor) ? 0 : factor;

    setFactor(normalizedFactor);
  }, []);

  const checkScroll = useCallback((element: Element) => {
    const isActive = element.scrollWidth > element.offsetWidth;

    setActivity(isActive);
  }, []);

  const [resizeObserver] = useState(
    () =>
      new ResizeObserver(([entry]) => {
        const element = entry.target as Element;

        updateScrollFactor(element);
        checkScroll(element);
      }),
  );

  const handleScroll = useCallback((event: Event) => {
    const element = event.target as Element;

    updateScrollFactor(element);
    checkScroll(element);
  }, []);

  const update = useCallback((factor: number) => {
    const element = ref.current;

    if (!element) return;

    const left = (factor / 100) * (element?.scrollWidth - element?.offsetWidth);

    element.scrollTo({ left });
  }, []);

  const activate = () => {
    const element = ref.current;

    if (!element) return;

    element.addEventListener('scroll', handleScroll, false);
    resizeObserver.observe(element);
  };

  const cleanup = () => {
    const element = ref.current;

    if (!element) return;

    element.removeEventListener('scroll', handleScroll, false);
    resizeObserver.unobserve(element);
    setActivity(false);
  };

  useEffect(() => {
    const element = ref.current;

    if (!element) return;

    return () => {
      element.removeEventListener('scroll', handleScroll, false);
      resizeObserver.unobserve(element);
    };
  }, []);

  return {
    ref,
    active,
    factor,
    update,
    cleanup,
    activate,
  };
};

export default useXScrollControl;
