import { TableFooterCell, TableFooterRow } from 'src/components/shared/Table';
import OrderOperator from 'src/components/shared/OrderOperator';
import currencyFormatter from 'src/utils/currencyFormatter';
import { IInvoiceTotalOrder } from 'src/types';

const formatUSD = currencyFormatter();

export interface IOrderListTotalProps {
  total: IInvoiceTotalOrder;
}

export const OrderListTotal = ({ total }: IOrderListTotalProps) => (
  <TableFooterRow isSticky>
    <TableFooterCell ignoreWidth>Total</TableFooterCell>
    <TableFooterCell />
    <TableFooterCell />
    <TableFooterCell />
    <TableFooterCell />
    <TableFooterCell>{formatUSD(total.expected_rate)}</TableFooterCell>
    <TableFooterCell>
      <OrderOperator operator={total.operator} />
    </TableFooterCell>
    <TableFooterCell>{formatUSD(total.invoiced_rate)}</TableFooterCell>
    <TableFooterCell />
  </TableFooterRow>
);
