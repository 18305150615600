import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button, { ButtonProps } from '@mui/material/Button';

import { ILogisticsProviderSchema, LogisticsProviderSchema } from 'src/schemas';
import { FormFields, FormInput, FormText } from 'src/components/shared/Form';

export interface IFormId {
  id: string;
}

export interface IProviderFormProps extends IFormId {
  onSubmit: (provider: ILogisticsProviderSchema) => void;
}

export const ProviderSubmitButton = ({ id, ...props }: IFormId & ButtonProps) => (
  <Button {...props} form={id} type="submit" variant="contained">
    Save
  </Button>
);

export const ProviderForm = ({ id, onSubmit }: IProviderFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ILogisticsProviderSchema>({
    resolver: yupResolver(LogisticsProviderSchema),
  });

  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <FormFields>
        <FormInput>
          <FormText control={control} name="name" label="3PL Name" errorMessage={errors?.name?.message} />
        </FormInput>
        <FormInput>
          <FormText
            control={control}
            name="description"
            label="3PL Description"
            errorMessage={errors?.description?.message}
          />
        </FormInput>
      </FormFields>
    </form>
  );
};
