import { memo, useMemo, useState } from 'react';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

import { IFilterType, IFiltersValues } from 'src/types';
import { CloseIcon } from 'src/components/Icons';

import { IFilterComponent, IFilterComponentProps } from './types';
import { getFormatter } from './formatter';
import { StyledFilterValueContainer, StyledFilterValue, StyledIconButton } from './styles';

export interface IFilterListItemProps<T extends IFilterType> extends IFilterComponentProps<T> {
  type: T;
  onRemove: () => void;
  Component: IFilterComponent<T>;
}

interface IFilterValueProps {
  label: string;
  value: string;
}

const FilterValue = ({ label, value }: IFilterValueProps) => (
  <StyledFilterValueContainer>
    <StyledFilterValue>
      <Typography variant="h4">{label}:</Typography>&nbsp;<Typography variant="body1">{value}</Typography>
    </StyledFilterValue>
  </StyledFilterValueContainer>
);

const FilterListItem = <T extends IFilterType>({
  type,
  params,
  options,
  onChange,
  onRemove,
  Component,
}: IFilterListItemProps<T>) => {
  const [filterParams, setFilterParams] = useState<IFiltersValues[T]>(params);
  const formatParams = useMemo(() => getFormatter(type), [type]);

  const handleClose = () => {
    if (filterParams) onChange(filterParams);
  };

  return (
    <Select
      value={''}
      displayEmpty
      variant="filled"
      onClose={handleClose}
      inputProps={{
        sx: {
          padding: 0,
        },
      }}
      MenuProps={{
        TransitionProps: {
          onExiting: () => (document.activeElement as Maybe<HTMLElement>)?.blur(),
        },
        MenuListProps: {
          disablePadding: true,
        },
        PaperProps: {
          sx: {
            overflow: 'visible',
          },
        },
      }}
      renderValue={() => <FilterValue label={options.label} value={formatParams(options, params)} />}
      startAdornment={
        <InputAdornment position="start" sx={{ margin: 0 }}>
          <StyledIconButton edge="start" onClick={onRemove}>
            <CloseIcon />
          </StyledIconButton>
        </InputAdornment>
      }>
      <Component params={filterParams} onChange={setFilterParams} options={options} />
    </Select>
  );
};

export default memo(FilterListItem) as typeof FilterListItem;
