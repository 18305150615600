import { ThemedComponent } from '@mui/material/styles';

export const MuiTextField: ThemedComponent<'MuiTextField'> = {
  defaultProps: {
    size: 'medium',
  },
  variants: [
    {
      props: { variant: 'filled' },
      style: ({ theme }) => ({
        gap: theme.spacing(0.5),
      }),
    },
  ],
};
