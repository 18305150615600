import { memo, useState } from 'react';
import styled from '@emotion/styled';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export interface IFiltersProps {
  filter: MaybeNull<string>;
  onFilterChange: (text: MaybeNull<string>) => void;
}

/** @deprecated */
const ListFilter = ({
  id,
  placeholder,
  filter,
  onFilterChange,
}: IFiltersProps & Pick<OutlinedInputProps, 'id' | 'placeholder'>) => {
  const [filterText, setFilterText] = useState(filter ?? '');

  const isFulfilled = filterText !== filter || !filterText;

  const handleEndAdornmentClick = () => {
    if (isFulfilled) onFilterChange(filterText);
    else {
      setFilterText('');
      onFilterChange(null);
    }
  };

  const EndAdornment = (
    <InputAdornment position="end">
      <IconButton edge="end" onClick={handleEndAdornmentClick}>
        {isFulfilled ? <SearchIcon /> : <ClearIcon />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <StyledContainer>
      <FormControl variant="outlined">
        <OutlinedInput
          id={id}
          placeholder={placeholder}
          value={filterText}
          onChange={({ target }) => setFilterText(target.value ?? '')}
          endAdornment={EndAdornment}
          autoComplete="off"
        />
      </FormControl>
    </StyledContainer>
  );
};

export default memo(ListFilter);
