import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';

import shouldForwardProp from 'src/utils/shouldForwardProp';

export const StyledTabbedCard = styled(Paper)`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const StyledTabbedCardTabs = styled.div`
  padding: ${({ theme }) => theme.spacing(0.5, 0)};
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(3)};
`;

export const StyledTabbedCardTab = styled('div', { shouldForwardProp })<{ $active?: boolean }>`
  cursor: pointer;

  transition: ${({ theme }) => theme.transitions.create(['opacity'])};
  opacity: ${({ $active }) => ($active ? 1 : 0.5)};
`;

export const StyledTabbedCardContent = styled.div`
  flex: 1;
`;
