import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ReportType } from 'src/types';
import { PageTitle } from 'src/components/shared/Page';
import { getReportName } from 'src/components/AnalyticReport';
import { ButtonLink } from 'src/components/shared/Buttons';
import ReportFrame from 'src/components/ReportFrame';
import makeRequest from 'src/effects/makeRequest';
import * as apiBrand from 'src/api/brand';

const usReportTokenRequest = makeRequest(apiBrand.getReportToken);

export default function AnalyticReportPage() {
  const { type } = useParams();
  const [token, setToken] = useState<MaybeNull<string>>(null);
  const reportTokenRequest = usReportTokenRequest(null, true);

  useEffect(() => {
    reportTokenRequest.cleanup();
    setToken(null);

    switch (type) {
      case ReportType.INVOICE_SUMMARY: {
        reportTokenRequest.make(ReportType.INVOICE_SUMMARY);
        break;
      }
      case ReportType.INVOICE_SERVICE_DETAIL: {
        reportTokenRequest.make(ReportType.INVOICE_SERVICE_DETAIL);
        break;
      }
      case ReportType.PICK_AND_PACK_SUMMARY: {
        reportTokenRequest.make(ReportType.PICK_AND_PACK_SUMMARY);
        break;
      }
      case ReportType.ERROR_SUMMARY: {
        reportTokenRequest.make(ReportType.ERROR_SUMMARY);
        break;
      }
      case ReportType.ERROR_DETAIL: {
        reportTokenRequest.make(ReportType.ERROR_DETAIL);
        break;
      }
      case ReportType.FAVORABLE_ERRORS: {
        reportTokenRequest.make(ReportType.FAVORABLE_ERRORS);
        break;
      }
    }

    reportTokenRequest.onFulfill(setToken);
  }, [type]);

  return (
    <>
      <PageTitle title={getReportName(type)}>
        <ButtonLink title="Back to all reports" to={'/analytic/reports'} />
      </PageTitle>
      <ReportFrame token={token} />
    </>
  );
}
