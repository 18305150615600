import { SERVER_CONFIG } from 'src/config/server';
import { Publisher } from 'src/libs/Observer';
import { saveAsUrl } from 'src/utils/saveFile';

export class Progress extends Publisher<number> {
  #intervalId: MaybeNull<ReturnType<typeof setTimeout>> = null;
  #isPaused = false;
  #progress = 0;
  #position = 0;
  #timeout = 100;
  #step = 0.01;

  get isPaused() {
    return this.#isPaused;
  }

  get current() {
    return this.#progress;
  }

  #next = () => {
    this.#progress = Math.round((Math.atan((this.#position += this.#step)) / (Math.PI / 2)) * 10000) / 100;
  };

  #move = () => {
    this.#next();
    this.publish(this.#progress);

    this.#intervalId = setTimeout(this.#move, this.#timeout);
  };

  start = () => {
    this.cleanup();
    this.#move();
  };

  pause = () => {
    if (!this.#intervalId) return;

    clearTimeout(this.#intervalId);
    this.#intervalId = null;
    this.#isPaused = true;
  };

  resume() {
    if (!this.#isPaused) return;

    this.#move();
    this.#isPaused = false;
  }

  cleanup = () => {
    if (this.#intervalId) clearTimeout(this.#intervalId);

    this.#intervalId = null;
    this.#progress = 0;
    this.#position = 0;

    this.publish(this.#progress);
  };
}

export class CreditRequestController extends Publisher<boolean> {
  #progress = new Progress();
  #isGenerating = false;

  get progress() {
    return this.#progress;
  }

  get isGenerating() {
    return this.#isGenerating;
  }

  download = (uuid: string) => {
    if (this.#isGenerating) return;

    this.publish((this.#isGenerating = true));
    this.#progress.start();

    const url = `${SERVER_CONFIG.SOCKET_URL}/export/${uuid}/ws`;
    const socket = new WebSocket(url);

    socket.onclose = () => {
      this.publish((this.#isGenerating = false));
      this.#progress.cleanup();
    };
    socket.onmessage = (message: MessageEvent<string>) => {
      socket.close();
      saveAsUrl(message.data);
    };
  };

  pause = () => {
    this.#progress.pause();
  };

  resume = () => {
    this.#progress.resume();
  };
}

export class CreditRequestService {
  #requests = new Map<string, CreditRequestController>();

  getController(invoiceName: string) {
    const controller = this.#requests.get(invoiceName) ?? new CreditRequestController();

    this.#requests.set(invoiceName, controller);

    return controller;
  }
}

export default new CreditRequestService();
