import { forwardRef, memo, useEffect, useMemo, useState } from 'react';

import { DateRangeCalendar, IDateRange, makeDate, makeDateRange } from 'src/components/shared/DateRange';

import { IFilterComponentProps } from '../types';
import { StyledDropdownContainer } from '../styles';

const DATA_FORMAT = 'YYYY-MM-DD';

const FilterDateRange = forwardRef<HTMLDivElement, IFilterComponentProps<'date_range'>>(
  ({ params, options, onChange }, ref) => {
    const [dateRange, setDateRange] = useState<Maybe<IDateRange>>(() => makeDateRange(params?.from, params?.to));

    const maxDate = useMemo(() => makeDate(options.max), [options.max]);
    const minDate = useMemo(() => makeDate(options.min), [options.min]);

    useEffect(() => {
      if (!dateRange) return;

      onChange({ from: dateRange.from.format(DATA_FORMAT), to: dateRange.to.format(DATA_FORMAT) });
    }, [dateRange]);

    return (
      <StyledDropdownContainer $centered ref={ref}>
        <DateRangeCalendar maxDate={maxDate} minDate={minDate} range={dateRange} onRangeChange={setDateRange} />
      </StyledDropdownContainer>
    );
  },
);

FilterDateRange.displayName = 'FilterDateRange';

export default memo(FilterDateRange);
