/* eslint-disable no-useless-escape */

import * as yup from 'yup';

yup.addMethod(yup.string, 'minOfUpper', function (min: number, message: string) {
  return this.test({
    name: 'minOfUpper',
    message: message || `\${label} should contain at least ${min} uppercase character${min > 1 ? 's' : ''}`,
    test: (value) => {
      if (!value) return true;

      return new RegExp(`(?=(.*[A-Z]){${min}})`).test(value);
    },
  });
});

yup.addMethod(yup.string, 'minOfLower', function (min: number, message: string) {
  return this.test({
    name: 'minOfLower',
    message: message || `\${label} should contain at least ${min} lowercase character${min > 1 ? 's' : ''}`,
    test: (value) => {
      if (!value) return true;

      return new RegExp(`(?=(.*[a-z]){${min}})`).test(value);
    },
  });
});

yup.addMethod(yup.string, 'minOfSpecial', function (min: number, message: string) {
  return this.test({
    name: 'minOfSpecial',
    message: message || `\${label} should contain at least ${min} special character${min > 1 ? 's' : ''}`,
    test: (value) => {
      if (!value) return true;
      const numSpecial = (value.match(/[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`]/g) || []).length;

      return numSpecial >= min;
    },
  });
});

yup.addMethod(yup.string, 'minOfNum', function (min: number, message: string) {
  return this.test({
    name: 'minOfNum',
    message: message || `\${label} should contain at least ${min} numeric character${min > 1 ? 's' : ''}`,
    test: (value) => {
      if (!value) return true;

      return new RegExp(`(?=(.*[0-9]){${min}})`, 'g').test(value);
    },
  });
});
