import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';

import * as apiLogistics from 'src/api/logistics';
import makeRequest from 'src/effects/makeRequest';
import { IWarehouseSchema } from 'src/schemas';
import { ButtonLink } from 'src/components/shared/Buttons';
import { PageTitle, TitleButtons, PageContainer, Loading } from 'src/components/shared/Page';
import { WarehouseForm, WarehouseSubmitButton } from 'src/components/LogisticsWarehouseForm';

const WAREHOUSE_FORM_ID = 'warehouse-form';
const useWarehouseRequest = makeRequest(apiLogistics.createWarehouse);
const useLogisticsProviderRequest = makeRequest(apiLogistics.getLogisticsProviders);

export default function ThreePLPage() {
  const navigate = useNavigate();
  const warehouseRequest = useWarehouseRequest(null, false);
  const logisticsProviderRequest = useLogisticsProviderRequest([], true);

  const handleSubmit = (warehouse: IWarehouseSchema) => {
    warehouseRequest.make({
      name: warehouse.name,
      description: warehouse.description,
      logistics_provider_id: warehouse.logistics_provider_id,

      country: warehouse.country,
      city: warehouse.city,
      state: warehouse.state,
      zip_code: warehouse.zip_code,
      address_line_1: warehouse.address_line_1,
      address_line_2: warehouse.address_line_2,
    });
    warehouseRequest.onFulfill(() => navigate('/settings/logistics'));
    warehouseRequest.onReject(() => navigate('/error'));
  };

  useEffect(() => logisticsProviderRequest.make(), []);

  return (
    <>
      <PageTitle title={'Logistics profile'} description={'Set-up your 3PLs, warehouse locations'}>
        <TitleButtons align="left">
          <WarehouseSubmitButton id={WAREHOUSE_FORM_ID} disabled={warehouseRequest.loading} />
          <ButtonLink title="Cancel" to={'/settings/logistics'} variant="outlined" />
        </TitleButtons>
      </PageTitle>
      <PageContainer>
        <Divider />
        {logisticsProviderRequest.loading ? (
          <Loading />
        ) : (
          <WarehouseForm id={WAREHOUSE_FORM_ID} logistics={logisticsProviderRequest.data} onSubmit={handleSubmit} />
        )}
      </PageContainer>
    </>
  );
}
