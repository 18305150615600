import dayjs from 'dayjs';
import * as yup from 'yup';

import { IRateCardDetails, IRateCardEditable, IRateCardOrder, RateCardOrder } from 'src/types';

export const RateCardDetailSchema = yup.object().shape({
  description: yup.string().required().label('Description'),
  type: yup.string().required().label('Type'),
  value: yup.number().required().label('Value'),
});

export const RateCardRateSchema = yup.object().shape({
  effectiveFrom: yup
    .string()
    .required()
    .test('Validate date', '${path} is invalid date', (value) => dayjs(value).isValid())
    .transform((_, value) => dayjs(value).format('YYYY-MM-DD'))
    .label('From'),
  effectiveTo: yup
    .string()
    .required()
    .test('Validate date', '${path} is invalid date', (value) => dayjs(value).isValid())
    .transform((_, value) => dayjs(value).format('YYYY-MM-DD'))
    .label('To'),
  type: yup.string().required(),
  details: yup.array().of(RateCardDetailSchema).required().label('Details'),
});

export const RateCardSchema = yup.object().shape({
  name: yup.string().required().label('Name'),
  logisticsId: yup.number().required().label('3PL'),
  warehouseId: yup.number().required().label('Warehouse'),
  orderType: yup.string().required().oneOf(Object.values<IRateCardOrder>(RateCardOrder)).label('Order Type'),
  fileUploadId: yup.number().label('File not uploaded'),
  rates: yup.array().of(RateCardRateSchema).required().label('Rates'),
});

export type IRateCardSchema = yup.InferType<typeof RateCardSchema>;

export const schemaToRateCard = (schema: IRateCardSchema): IRateCardEditable => ({
  name: schema.name,
  order_type: schema.orderType,
  warehouse_id: schema.warehouseId,
  logistics_provider_id: schema.logisticsId,
  file_upload_id: schema.fileUploadId,
  rates: schema.rates.map((rate) => ({
    rate_type: rate.type,
    effective_to: rate.effectiveTo,
    effective_from: rate.effectiveFrom,
    rate_details: rate.details.map((details) => ({
      data_type: details.type,
      value: details.value,
      description: details.description,
    })),
  })),
});

export const rateCardToSchema = (rateCard: IRateCardDetails): IRateCardSchema => ({
  name: rateCard.name,
  orderType: rateCard.order_type,
  warehouseId: rateCard.warehouse_id.id,
  logisticsId: rateCard.logistics_provider_id.id,
  fileUploadId: rateCard.file_upload_id?.id,
  rates: rateCard.rates.map((rate) => ({
    type: rate.rate_type,
    effectiveTo: dayjs(rate.effective_to).format('M/D/YY'),
    effectiveFrom: dayjs(rate.effective_from).format('M/D/YY'),
    details: rate.rate_details.map((details) => ({
      type: details.data_type,
      value: details.value,
      description: details.description,
    })),
  })),
});

export const getDefaultRateCard = (): NestedPartial<IRateCardSchema> => ({
  rates: [
    {
      type: 'Normal',
      details: [
        {
          description: 'Order Fulfillment Fee',
          type: 'US Dollars',
        },
        {
          description: 'First Pick Fee',
          type: 'US Dollars',
        },
        {
          description: 'Item Insert Fee',
          type: 'US Dollars',
        },
      ],
    },
    {
      type: 'Peak',
      details: [
        {
          description: 'Order Fulfillment Fee',
          type: 'US Dollars',
        },
        {
          description: 'First Pick Fee',
          type: 'US Dollars',
        },
        {
          description: 'Item Insert Fee',
          type: 'US Dollars',
        },
      ],
    },
  ],
});
