import { PropsWithChildren } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';

import { VerifyUserSchema, IVerifyUserSchema } from 'src/schemas';
import { FormFields, FormInput, FormText } from 'src/components/shared/Form';

import { StyledAuthError, StyledForm, StyledFormFooter } from './styles';

export interface IVerifyUserFormProps {
  error?: Maybe<string>;
  loading: boolean;
  onSubmit: (auth: IVerifyUserSchema) => void;
}

export const VerifyUserForm = ({ error, loading, onSubmit }: PropsWithChildren<IVerifyUserFormProps>) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IVerifyUserSchema>({
    resolver: yupResolver(VerifyUserSchema),
  });

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <FormFields>
        <FormInput>
          <FormText
            name="confirmation_code"
            autoComplete="one-time-code"
            label="Code *"
            placeholder="Enter code"
            control={control}
            errorMessage={errors?.confirmation_code?.message}
          />
        </FormInput>
      </FormFields>
      <StyledFormFooter>
        {!!error && <StyledAuthError>{error}</StyledAuthError>}
        <Button variant="contained" type="submit" disabled={loading}>
          Verify
        </Button>
      </StyledFormFooter>
    </StyledForm>
  );
};
