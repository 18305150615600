import styled from '@emotion/styled';
import { Outlet } from 'react-router-dom';

import { Page, PageContainer } from 'src/components/shared/Page';
import { LogoFullIcon } from 'src/components/Icons';

export const StyledHeader = styled.header`
  display: flex;
`;

export default function Public() {
  return (
    <Page>
      <PageContainer>
        <StyledHeader>
          <LogoFullIcon />
        </StyledHeader>
        <Outlet />
      </PageContainer>
    </Page>
  );
}
