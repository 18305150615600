import { cloneElement, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import Popover, { DEFAULT_POPOVER_STATE } from './Popover';

const TruncatePopover = ({ children }: { children: JSX.Element }) => {
  const containerRef = useRef<HTMLElement>();
  const [popover, setPopover] = useState(DEFAULT_POPOVER_STATE);

  useEffect(() => {
    if (!containerRef.current) return;

    const element = containerRef.current;

    const mouseMoveHandler = ({ clientX, clientY, target }: MouseEvent) => {
      const element = target as HTMLElement;
      const truncated = element.offsetWidth < element.scrollWidth;
      const message = element.innerText ?? '';

      if (truncated && message) setPopover({ position: { x: clientX, y: clientY }, message, truncated });
      else setPopover(DEFAULT_POPOVER_STATE);
    };

    const scrollHandler = () => setPopover(DEFAULT_POPOVER_STATE);

    element.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('scroll', scrollHandler, true);

    return () => {
      element.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('scroll', scrollHandler, true);
    };
  }, []);

  if (!children || Array.isArray(children)) return children;

  return (
    <>
      {cloneElement(children, { ref: containerRef })}
      {createPortal(<Popover {...popover} />, document.body)}
    </>
  );
};

export default TruncatePopover;
