import styled from '@emotion/styled';

import { StyledCard as Card } from 'src/components/shared/Card/styles';
import shouldForwardProp from 'src/utils/shouldForwardProp';

export const StyledPiChartCard = styled(Card)`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

export const StyledPiChartChart = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 50%;
`;

export const StyledPiChartCardLegend = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 40%;
  padding: ${({ theme }) => theme.spacing(3, 1.5, 3, 0)};
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledPiChartLegendItem = styled('div', { shouldForwardProp })<{ $color: string; $value: string }>`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(0.5)};
  color: ${({ theme }) => theme.palette.text.primary};
  padding-left: 14px;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};

  &::before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: ${({ $color }) => $color};
    position: absolute;
    left: 0;
    top: 5px;
  }

  &::after {
    content: '${({ $value }) => $value}';
    color: ${({ theme }) => theme.palette.error.main};
    flex-basis: 100%;
  }
`;
