import { memo } from 'react';

import { makeErrorNote, makeInfoNote } from 'src/components/InvoiceReconcile/Export';
import ExportButton from 'src/components/shared/Buttons/ExportButton';
import notification from 'src/services/NotificationService';
import makeRequest from 'src/effects/makeRequest';
import * as apiRoot from 'src/api/root';

import { useReconciliationContext } from './ReconciliationContext';
import { mapErrorsFiltersToExport } from './invoiceErrorsControl';

const useErrorsReportRequest = makeRequest(apiRoot.getInvoiceReportId);

const InvoiceErrorsExport = () => {
  const { invoiceName, errorsFilters } = useReconciliationContext();
  const errorsReportRequest = useErrorsReportRequest(null);

  const handleOrderExport = () => {
    errorsReportRequest.make({
      name: invoiceName,
      type: 'error',
      filters: mapErrorsFiltersToExport(errorsFilters.params),
    });
    errorsReportRequest.onFulfill(() => {
      const infoNote = makeInfoNote();

      notification.publish(infoNote);
    });
    errorsReportRequest.onReject(() => {
      const errorNote = makeErrorNote();

      notification.publish(errorNote);
    });
  };

  return <ExportButton isBusy={errorsReportRequest.loading} onClick={handleOrderExport} />;
};

export default memo(InvoiceErrorsExport);
