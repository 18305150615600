import { PropsWithChildren, createContext, useContext } from 'react';

import { IWarehouse } from 'src/types';
import makeRequest from 'src/effects/makeRequest';
import * as apiLogistics from 'src/api/logistics';

import { useDialogControl } from './logisticsControl';

export interface ILogisticsContext {
  warehousesRequest: ReturnType<typeof useWarehousesRequest<IWarehouse[]>>;
  dialogControl: ReturnType<typeof useDialogControl>;
}

const useWarehousesRequest = makeRequest(apiLogistics.getWarehouses);

export const LogisticsContext = createContext<ILogisticsContext>({} as ILogisticsContext);

export const LogisticsProvider = ({ children }: PropsWithChildren) => {
  const warehousesRequest = useWarehousesRequest([], true);
  const dialogControl = useDialogControl();

  return <LogisticsContext.Provider value={{ warehousesRequest, dialogControl }}>{children}</LogisticsContext.Provider>;
};

export const useLogisticsContext = () => useContext(LogisticsContext);
