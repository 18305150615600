import styled from '@emotion/styled';

const StyledActionPanel = styled.div<{ $noAlign?: boolean; $onlyRightChild?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: ${({ $noAlign }) => ($noAlign ? 'initial' : 'center')};
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(3)};

  ${({ $onlyRightChild }) =>
    $onlyRightChild &&
    `& > :only-child {
        margin-right: auto;
    }`}
`;

export default StyledActionPanel;
