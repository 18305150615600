import { memo, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { Palette } from '@mui/material/styles';

import { IInvoiceStatus, InvoiceStatus } from 'src/types';
import StatusChip from 'src/components/shared/StatusChip';

export interface IInvoiceStatusChipProps {
  status: MaybeNull<IInvoiceStatus>;
}

export const getInvoiceStatusLabel = (status: Maybe<IInvoiceStatus>) => {
  const labels = {
    [InvoiceStatus.REVIEWED]: 'Reviewed',
    [InvoiceStatus.UNREVIEWED]: 'Unreviewed',
    [InvoiceStatus.UNDER_REVIEW]: 'Under review',
    [InvoiceStatus.NO_REVIEW_REQUIRED]: 'No review required',
    [InvoiceStatus.PROCESSING]: 'Processing',
    [InvoiceStatus.LOAD_ERROR]: 'Load Error',
    [InvoiceStatus.RECONCILED]: 'Reconciled',
  };

  return status ? labels[status] ?? 'Unknown' : 'Unknown';
};

export const getInvoiceStatusColor = (status: Maybe<IInvoiceStatus>, palette: Palette) => {
  const colors = {
    [InvoiceStatus.REVIEWED]: palette.success.main,
    [InvoiceStatus.UNDER_REVIEW]: palette.primary.main,
    [InvoiceStatus.PROCESSING]: palette.primary.main,
    [InvoiceStatus.LOAD_ERROR]: palette.error.main,
    [InvoiceStatus.UNREVIEWED]: palette.text.secondary,
    [InvoiceStatus.RECONCILED]: palette.text.secondary,
    [InvoiceStatus.NO_REVIEW_REQUIRED]: palette.text.disabled,
  };

  return status ? colors[status] ?? palette.text.disabled : palette.text.disabled;
};

const InvoiceStatusChip = ({ status }: IInvoiceStatusChipProps) => {
  const { palette } = useTheme();

  const color = useMemo(() => getInvoiceStatusColor(status, palette), [status, palette]);
  const label = useMemo(() => getInvoiceStatusLabel(status), [status]);

  return <StatusChip label={label} color={color} />;
};

export default memo(InvoiceStatusChip);
