import { useEffect, useMemo, useState } from 'react';

import { IUserRole } from 'src/types';
import { authService } from 'src/services/AuthService';

export const useRole = () => {
  const [role, setRole] = useState(authService.role);

  useEffect(
    () =>
      authService.subscribe((action) => {
        if (action === 'update') setRole(authService.role);
      }),
    [],
  );

  return role;
};

export const useAccess = (...roles: IUserRole[]) => {
  const role = useRole();
  const hasAccess = useMemo(() => (roles.length ? roles.includes(role) : true), [...roles, role]);

  return hasAccess;
};
