import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import TargetInput from './TargetInput';
import FileController from './FileController';
import { IUseFileController } from './useFileController';

export interface IFormFileProps<S extends FieldValues> {
  control: Control<S>;
  name: FieldPath<S>;
  label?: string;
  placeholder?: string;
  errorMessage?: Maybe<string>;
  controller: IUseFileController;
}

export const FormFile = <S extends FieldValues>({ controller: fileController, ...props }: IFormFileProps<S>) => (
  <Controller
    control={props.control}
    name={props.name}
    render={({ field }) => (
      <FormControl error={!!props.errorMessage} variant="filled">
        {props.label && <FormLabel>{props.label}</FormLabel>}
        <FileController value={field.value} controller={fileController} />
        <TargetInput
          id={props.name}
          name={props.name}
          value={field.value ?? ''}
          placeholder={props.placeholder}
          onChange={(event) => field.onChange(event)}
          uploadId={fileController.uploadState.id}
        />
        {!!props.errorMessage && <FormHelperText>{props.errorMessage}</FormHelperText>}
      </FormControl>
    )}
  />
);
