/* eslint-disable @typescript-eslint/no-explicit-any */
// INFO: any used due to issue https://github.com/mui/mui-x/issues/9775

import { Dayjs } from 'dayjs';
import { memo, useEffect, useMemo, useState } from 'react';

import { daysToRange } from './utils';
import { StyledDateCalendar } from './styles';
import DateRangeHeader from './DateRangeHeader';
import DateRangeDay from './DateRangeDay';

export type IDateRange = {
  from: Dayjs;
  to: Dayjs;
};

export interface IDateRangeProps {
  range?: Maybe<IDateRange>;
  onDaysChange?: (days: Dayjs[]) => void;
  onRangeChange?: (value: IDateRange) => void;
  maxDate?: Dayjs | undefined;
  minDate?: Dayjs | undefined;
}

const DateRange = ({ maxDate, minDate, range, onRangeChange, onDaysChange }: IDateRangeProps) => {
  const [hoverDay, setHoverDay] = useState<MaybeNull<Dayjs>>(null);
  const [days, setDays] = useState<Dayjs[]>(range ? [range.from, range.to] : []);
  const daysRange = useMemo(() => daysToRange(...(days.length === 2 ? days : [...days, hoverDay])), [days, hoverDay]);

  useEffect(() => {
    setDays(range ? [range.from, range.to] : []);
    setHoverDay(null);
  }, [range]);

  useEffect(() => {
    if (onDaysChange) onDaysChange(days);
    if (days.length !== 2 || !onRangeChange) return;

    const [from, to] = days;

    if (from.isSame(range?.from, 'day') && to.isSame(range?.to, 'day')) return;

    onRangeChange({ from, to });
  }, [days]);

  return (
    <StyledDateCalendar
      showDaysOutsideCurrentMonth
      maxDate={maxDate}
      minDate={minDate}
      slots={{
        day: DateRangeDay as any,
        calendarHeader: DateRangeHeader as any,
      }}
      slotProps={{
        day: ({ day }) =>
          ({
            day,
            days,
            daysRange,
            hoverDay,
            onDaysChange: (days: Dayjs[]) => setDays(daysToRange(...days)),
            onPointerEnter: () => setHoverDay(day),
            onPointerLeave: () => setHoverDay(null),
          }) as any,
        calendarHeader: { range } as any,
      }}
    />
  );
};

export default memo(DateRange);
