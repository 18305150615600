import Typography from '@mui/material/Typography';

import SubmenuLink from './SubmenuLink';
import { StyledSubmenuContainer, StyledSubmenuSegment, StyledSubmenuGroup, StyledSubmenuNav } from './styles';

export const SystemSubmenu = () => (
  <StyledSubmenuContainer>
    <StyledSubmenuSegment>
      <StyledSubmenuGroup>
        <Typography variant="h3">Companies</Typography>
        <StyledSubmenuNav>
          <SubmenuLink to={'/system/companies'}>All companies</SubmenuLink>
          <SubmenuLink to={'/system/company'}>Create company</SubmenuLink>
        </StyledSubmenuNav>
      </StyledSubmenuGroup>
      <StyledSubmenuGroup>
        <Typography variant="h3">Users</Typography>
        <StyledSubmenuNav>
          <SubmenuLink to={'/system/users'}>All users</SubmenuLink>
          <SubmenuLink to={'/system/user'}>Create user</SubmenuLink>
        </StyledSubmenuNav>
      </StyledSubmenuGroup>
    </StyledSubmenuSegment>
  </StyledSubmenuContainer>
);
