import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ButtonBase from '@mui/material/ButtonBase';

export const StyledBlank = styled(Box)`
  position: absolute;
  top: 0;
  height: 100%;
  box-shadow: inset ${({ theme }) => theme.shadows[3]};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  transition: ${({ theme }) => theme.transitions.create(['left', 'width'])};
  background: ${({ theme }) => theme.palette.secondary.light};
`;

export const StyledSlideTabs = styled(Paper)`
  position: relative;
  display: flex;
  flex-direction: row;
`;

export const StyledSlideTab = styled(ButtonBase)`
  position: relative;
  display: flex;
  text-transform: initial;
  height: ${({ theme }) => theme.spacing(5)};
  padding: ${({ theme }) => theme.spacing(1.5)};
  transition: ${({ theme }) => theme.transitions.create(['color'])};

  &[data-active] {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
