import { PropsWithChildren } from 'react';
import Typography from '@mui/material/Typography';

import { StyledCreditHeader } from './styles';

export interface ICreditHeaderProps {
  title: string;
}

const CreditHeader = ({ title, children }: PropsWithChildren<ICreditHeaderProps>) => (
  <StyledCreditHeader>
    <hgroup>
      <Typography variant="h2" sx={{ lineHeight: 0.9 }}>
        {title}
      </Typography>
    </hgroup>
    {children}
  </StyledCreditHeader>
);

export default CreditHeader;
