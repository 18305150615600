import { ID } from './common';

export const AlertType = {
  INVOICE_RECEIVED: 'INVOICE_RECEIVED' as const,
  INVOICE_PROCESSED: 'INVOICE_PROCESSED' as const,
  INVOICE_PROCESSING_FAILED: 'INVOICE_PROCESSING_FAILED' as const,
  CREDIT_REQUEST_READY: 'CREDIT_REQUEST_READY' as const,
  CREDIT_REQUEST_FAILED: 'CREDIT_REQUEST_FAILED' as const,
  EXPORT_REQUEST_READY: 'EXPORT_REQUEST_READY' as const,
  EXPORT_REQUEST_FAILED: 'EXPORT_REQUEST_FAILED' as const,
};

export type IAlertType = ValueOf<typeof AlertType>;

export type IAlertMetaInvoice = {
  invoice_id: string;
};

export type IAlertMetaCreditRequestReady = {
  credit_request_id: ID;
  file_name: string;
};

export type IAlertMetaExportRequestReady = {
  file_name: string;
  invoice_id: ID;
  page_title: string;
};

export type IAlertBase<T extends object> = {
  id: ID;
  sent_at: string;
  created_at: string;
  acknowledged: boolean;
} & T;

export type IAlertRaw =
  | IAlertBase<{
      type: (typeof AlertType)['INVOICE_RECEIVED'];
      meta: IAlertMetaInvoice;
    }>
  | IAlertBase<{
      type: (typeof AlertType)['INVOICE_PROCESSED'];
      meta: IAlertMetaInvoice;
    }>
  | IAlertBase<{
      type: (typeof AlertType)['INVOICE_PROCESSING_FAILED'];
      meta: IAlertMetaInvoice;
    }>
  | IAlertBase<{
      type: (typeof AlertType)['CREDIT_REQUEST_READY'];
      meta: IAlertMetaCreditRequestReady;
    }>
  | IAlertBase<{
      type: (typeof AlertType)['EXPORT_REQUEST_READY'];
      meta: IAlertMetaExportRequestReady;
    }>
  | IAlertBase<{
      type: (typeof AlertType)['CREDIT_REQUEST_FAILED'];
      meta: null;
    }>
  | IAlertBase<{
      type: (typeof AlertType)['EXPORT_REQUEST_FAILED'];
      meta: null;
    }>;

export type IAlertCommon = IAlertRaw & {
  sent_at_date: Date;
  created_at_date: Date;
};

export const AlertActionType = {
  NEW: 'NEW' as const,
  UPDATE: 'UPDATE' as const,
  DELETE: 'DELETE' as const,
};

export type IAlertActionType = ValueOf<typeof AlertActionType>;

export type IAlertAction = {
  action: IAlertActionType;
  alert: IAlertRaw;
};
