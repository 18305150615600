import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

import shouldForwardProp from 'src/utils/shouldForwardProp';

export const StyledSubmenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(4, 1.5)};
`;

export const StyledSubmenuGroup = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledSubmenuNav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledSubmenuLink = styled(NavLink, { shouldForwardProp })<{ $disabled?: boolean }>`
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};

  color: ${({ theme }) => theme.palette.text.secondary};
  transition: ${({ theme }) => theme.transitions.create(['color'])};

  &:hover {
    ${({ theme, $disabled }) => !$disabled && `color: ${theme.palette.primary.main};`}
  }

  & svg {
    height: auto;
    width: auto;
  }

  ${({ theme, $disabled }) => $disabled && `color: ${theme.palette.text.disabled};`}
`;

export const StyledSubmenuSegment = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4.5)};
`;
