import { PropsWithChildren, memo } from 'react';
import styled from '@emotion/styled';

const MessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export interface IMessageProps {
  message: string;
}

const Message = ({ children }: PropsWithChildren) => <MessageContainer>{children}</MessageContainer>;

export default memo(Message);
