import { memo, useEffect } from 'react';

import { Loading } from 'src/components/shared/Page';
import { ReconcileDataType } from 'src/types';

import { useReconciliationContext } from './ReconciliationContext';
import InvoiceOrders from './InvoiceOrders';
import InvoiceErrors from './InvoiceErrors';

const ReconciliationDetails = () => {
  const { invoiceName, reconcileType, errorsRequest, ordersParams, ordersRequest, ordersFiltersRequest } =
    useReconciliationContext();

  useEffect(() => ordersFiltersRequest.make(), []);
  useEffect(() => errorsRequest.make(invoiceName), []);

  useEffect(() => {
    if (!ordersParams.isReady) return;

    ordersRequest.make(invoiceName, ordersParams.page, ordersParams.sorting, ordersParams.filters);
  }, [ordersParams]);

  if (reconcileType.type === ReconcileDataType.ERROR_SUMMARY) return <InvoiceErrors />;

  if (reconcileType.type === ReconcileDataType.ORDERS) return <InvoiceOrders />;

  return <Loading />;
};

export default memo(ReconciliationDetails);
