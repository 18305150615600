import { forwardRef, memo, useEffect, useState } from 'react';
import MenuList from '@mui/material/MenuList';

import { IFilterMultiSelectOption, IFilterMultiSelectValue } from 'src/types';

import { IFilterComponentProps } from '../types';
import { StyledMenuItem, StyledDropdownContainer } from '../styles';

const MultiSelect = forwardRef<HTMLDivElement, IFilterComponentProps<'multi_select'>>(
  ({ params, options, onChange }, ref) => {
    const [selections, setSelections] = useState<IFilterMultiSelectValue>(params ?? []);

    const handleSelect = (key: IFilterMultiSelectOption['key']) => {
      const newSelections = selections.includes(key)
        ? selections.filter((selection) => selection !== key)
        : [...selections, key];

      setSelections(newSelections);
    };

    useEffect(() => onChange(selections as IFilterMultiSelectValue), [selections]);

    return (
      <StyledDropdownContainer ref={ref}>
        <MenuList disablePadding>
          {options.values.map(({ key, value }) => (
            <StyledMenuItem key={key} selected={selections.includes(key)} onClick={() => handleSelect(key)}>
              {value}
            </StyledMenuItem>
          ))}
        </MenuList>
      </StyledDropdownContainer>
    );
  },
);

MultiSelect.displayName = 'MultiSelect';

export default memo(MultiSelect);
