import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';

export const StyledChip = styled.span`
  display: inline-block;
  padding: ${({ theme }) => theme.spacing(0, 1.5)};
  margin: ${({ theme }) => theme.spacing(0, -1.5)};
  color: ${({ theme }) => theme.palette.text.primary};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const StyledDialogPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(3, 2)};
  min-width: 900px;
`;

export const StyledCloseButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 0;

  & > svg {
    width: ${({ theme }) => theme.spacing(3)};
  }
`;

export const StyledDialogHeader = styled.header`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledComparableList = styled(Paper)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(3, 2)};
`;

export const StyledAnomalyTotal = styled.section`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(2)};

  & > span {
    display: flex;
    gap: ${({ theme }) => theme.spacing(0.5)};

    & > * {
      white-space: pre-wrap;
      text-transform: uppercase;
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    }
  }
`;

export const StyledAnomalyDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
`;
