import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Paper from '@mui/material/Paper';

import shouldForwardProp from 'src/utils/shouldForwardProp';

const cssCardRow = css`
  display: flex;
  justify-content: space-between;
`;

export const StyledCard = styled(Paper, { shouldForwardProp })<{ $pointer?: boolean }>`
  display: flex;
  flex-direction: row;
  border-width: 1px;
  gap: ${({ theme }) => theme.spacing(1.2)};
  width: ${({ theme }) => theme.spacing(52.5)};
  min-width: ${({ theme }) => theme.spacing(52.5)};
  max-width: ${({ theme }) => theme.spacing(52.5)};
  min-height: ${({ theme }) => theme.spacing(34.5)};
  padding: ${({ theme }) => theme.spacing(3, 2)};
  cursor: ${({ $pointer }) => ($pointer ? 'pointer' : 'initial')};
`;

export const StyledCardContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.2)};
`;

export const StyledCardRow = styled.div`
  ${cssCardRow}
`;

export const StyledCardHead = styled.header`
  ${cssCardRow}
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const StyledCardFooter = styled.footer`
  ${cssCardRow}
  margin-top: auto;
  padding-top: ${({ theme }) => theme.spacing(2)};
`;
