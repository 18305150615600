import { memo, useCallback } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import { ErrorButton } from 'src/components/shared/Buttons';
import { IRowActionControl } from 'src/components/shared/Table';
import { AnomalyDetailRow, IAnomalyDetailRowActionProps } from 'src/components/AnomalyDetails';

const AnomalyAction = (props: IAnomalyDetailRowActionProps) => {
  const renderAction = useCallback(
    ({ hidePopper }: IRowActionControl) => (
      <ButtonGroup component={Paper} variant="popper">
        <Button disabled>Accept</Button>
        <Button disabled>Reject</Button>
        <Button disabled>Tag</Button>
        <ErrorButton title="Cancel" onClick={hidePopper} />
      </ButtonGroup>
    ),
    [],
  );

  return <AnomalyDetailRow {...props} renderAction={renderAction} />;
};

export default memo(AnomalyAction) as typeof AnomalyAction;
