import { useCallback, useMemo, useReducer } from 'react';

import { ID } from 'src/types';

export type DownloadingStatus = 'downloaded' | 'downloading' | 'failed' | 'initial';
export type DownloadState = {
  id: MaybeNull<ID>;
  name: string;
  status: DownloadingStatus;
  progress: number;
};

export const useFileDownloadState = () => {
  const initialState = useMemo<DownloadState>(
    () => ({
      id: null,
      name: '',
      status: 'initial',
      progress: 0,
    }),
    [],
  );

  const [state, dispatch] = useReducer(
    (state: DownloadState, update: NestedPartial<DownloadState>) => ({ ...state, ...update }),
    initialState,
  );

  const cleanup = useCallback(() => dispatch({ ...initialState }), [state]);

  const start = useCallback(
    (id: ID, name: string) => dispatch({ id, name, status: 'downloading', progress: 0 }),
    [state],
  );

  const updateStatus = useCallback((status: DownloadingStatus) => dispatch({ status }), [state]);

  const updateProgress = useCallback(
    (event: ProgressEvent) => {
      const progress = (event.loaded * 100) / event.total;

      dispatch({ progress });
    },
    [state],
  );

  return {
    state,
    start,
    cleanup,
    updateStatus,
    updateProgress,
  };
};

export type IUseFileDownloadState = typeof useFileDownloadState;
