export const UserRole = {
  IMPLENTIO_ADMIN: 'ROLE_IMPLENTIO_ADMIN' as const,
  CUSTOMER_ADMIN: 'ROLE_CUSTOMER_ADMIN' as const,
  CUSTOMER_USER: 'ROLE_CUSTOMER_USER' as const,
} as const;

export type IUserRole = ValueOf<typeof UserRole>;

export type IUserInfo = {
  id: number;
  created_at: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  email: string;
  intercom_hash: string;
  brand: {
    id: number;
    name: string;
  };
  role: {
    id: number;
    name: IUserRole;
  };
};

export type IAuthToken = {
  refresh: string;
  id: string;
};

export const AuthChallengeType = {
  LOGIN: 'LOGIN' as const,
  SUCCESS: 'SUCCESS' as const,
  VERIFY_EMAIL: 'VERIFY_EMAIL' as const,
  RESET_PASSWORD: 'RESET_PASSWORD' as const,
  FORGOT_PASSWORD: 'FORGOT_PASSWORD' as const,
} as const;

export type IAuthChallengeType = ValueOf<typeof AuthChallengeType>;

export type IAuthPayloadSuccess = {
  refresh_token: string;
  id_token: string;
};

export type IAuthPayloadVerify = {
  email: string;
};

export type IAuthPayloadResetPassword = {
  username: string;
};

export type IAuthChallengeBase<T extends IAuthChallengeType, P> = {
  type: T;
  payload: P;
};

export type IAuthChallengeLogin = IAuthChallengeBase<(typeof AuthChallengeType)['LOGIN'], null>;
export type IAuthChallengeSuccess = IAuthChallengeBase<(typeof AuthChallengeType)['SUCCESS'], IAuthPayloadSuccess>;
export type IAuthChallengeVerifyEmail = IAuthChallengeBase<
  (typeof AuthChallengeType)['VERIFY_EMAIL'],
  IAuthPayloadVerify
>;
export type IAuthChallengeResetPassword = IAuthChallengeBase<
  (typeof AuthChallengeType)['RESET_PASSWORD'],
  IAuthPayloadResetPassword
>;
export type IAuthChallengeForgotPassword = IAuthChallengeBase<(typeof AuthChallengeType)['FORGOT_PASSWORD'], null>;

export type IAuthChallenge =
  | IAuthChallengeLogin
  | IAuthChallengeSuccess
  | IAuthChallengeVerifyEmail
  | IAuthChallengeResetPassword
  | IAuthChallengeForgotPassword;

export type IAuthLogin = {
  username: string;
  password: string;
};

export type IAuthForgotPassword = {
  username: string;
};

export type IAuthResetPassword = {
  username: string;
  confirmation_code: string;
  password: string;
  confirm_password: string;
};

export type IAuthVerifyEmail = {
  username: string;
  confirmation_code: string;
};

export type IAuthError = {
  detail: string;
};
