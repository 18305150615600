import { ChangeEvent, DragEvent, SyntheticEvent, memo } from 'react';
import IconButton from '@mui/material/IconButton';

import { UploadIcon } from 'src/components/Icons';

import { StyledContainer, StyledUploadLabel, StyledActions } from './styles';

export interface IUploadAreaProps {
  onUpload: (file: File) => void;
}

const stopPropagation = (event: SyntheticEvent) => {
  event.preventDefault();
  event.stopPropagation();
};

const UploadArea = ({ onUpload }: IUploadAreaProps) => {
  const handleDrop = (event: DragEvent) => {
    stopPropagation(event);

    const file = event.dataTransfer.files[0];

    if (file) onUpload(file);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    stopPropagation(event);

    const file = event.target?.files?.[0];

    if (file) onUpload(file);
  };

  return (
    <StyledContainer
      onDrop={handleDrop}
      onDragOver={stopPropagation}
      onDragEnter={stopPropagation}
      onDragLeave={stopPropagation}>
      <StyledUploadLabel>Drag and drop to upload or click upload icon</StyledUploadLabel>
      <StyledActions>
        <IconButton sx={{ padding: 0 }} component="label">
          <UploadIcon />
          <input type="file" hidden onChange={handleChange} accept=".pdf" />
        </IconButton>
      </StyledActions>
    </StyledContainer>
  );
};

export default memo(UploadArea);
