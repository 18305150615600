import { memo, useState } from 'react';

import { TabbedCard } from 'src/components/shared/TabbedCard';
import { Loading } from 'src/components/shared/Page';
import { IInvoiceChart } from 'src/types';

import { IChartTab } from './types';
import ChartComponent from './ChartComponent';
import ChartTabPanel from './ChartTabPanel';

export interface IInvoiceChartsProps {
  loading: boolean;
  charts: MaybeNull<IInvoiceChart>;
}

const InvoiceCharts = ({ loading, charts }: IInvoiceChartsProps) => {
  const [tab, setTab] = useState<IChartTab>('stack');

  return (
    <TabbedCard>
      {loading || !charts ? (
        <Loading />
      ) : (
        <>
          <ChartTabPanel tab={tab} onTabChange={setTab} />
          <ChartComponent tab={tab} charts={charts} />
        </>
      )}
    </TabbedCard>
  );
};

export default memo(InvoiceCharts);
