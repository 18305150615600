import { useMemo } from 'react';
import { QueryParamConfig, QueryParamConfigMap, withDefault } from 'use-query-params';

import {
  FilterType,
  IFilters,
  IFilterDateRangeValue,
  IFilterMultiSelectValue,
  IFilterSingleSelectValue,
  IFilterCriterionNumberValue,
} from 'src/types';
import useNamedQueryParams, { makeNamedQueryUtils } from 'src/effects/useNamedQueryParams';

import { CriterionNumberParam, DateRangeParam, MultiSelectParam, SingleSelectParam } from './serializer';

type IQueryConfigParams = {
  [FilterType.DateRange]: MaybeNull<IFilterDateRangeValue>;
  [FilterType.MultiSelect]: MaybeNull<IFilterMultiSelectValue>;
  [FilterType.SingleSelect]: MaybeNull<IFilterSingleSelectValue>;
  [FilterType.CriterionNumber]: MaybeNull<IFilterCriterionNumberValue>;
};

export type IFilterOptions = Record<string, ValueOf<IFilters>>;
export type IFilterConfigs<T extends IFilterOptions> = { [K in keyof T]: Omit<T[K], 'options'> };

const dateRangeFilterParam = (value?: Maybe<IFilterDateRangeValue>) =>
  withDefault<MaybeNull<IFilterDateRangeValue>, MaybeNull<IFilterDateRangeValue>>(DateRangeParam, value ?? null);

const multiSelectFilterParam = (value?: Maybe<IFilterMultiSelectValue>) =>
  withDefault<MaybeNull<IFilterMultiSelectValue>, MaybeNull<IFilterMultiSelectValue>>(MultiSelectParam, value ?? null);

const singleSelectFilterParam = (value?: Maybe<IFilterSingleSelectValue>) =>
  withDefault<MaybeNull<IFilterSingleSelectValue>, MaybeNull<IFilterSingleSelectValue>>(
    SingleSelectParam,
    value ?? null,
  );

const criterionNumberFilterParam = (value?: Maybe<IFilterCriterionNumberValue>) =>
  withDefault<MaybeNull<IFilterCriterionNumberValue>, MaybeNull<IFilterCriterionNumberValue>>(
    CriterionNumberParam,
    value ?? null,
  );

const ORDER_KEY = 'ord' as const;
const orderFilterParam = withDefault<MaybeNull<IFilterMultiSelectValue>, MaybeNull<IFilterMultiSelectValue>>(
  MultiSelectParam,
  [],
);

const makeQueryConfig = <T extends IFilterOptions>(
  config: IFilterConfigs<T>,
): { [K in keyof T]: QueryParamConfig<IQueryConfigParams[T[K]['type']]> } & {
  [ORDER_KEY]: QueryParamConfig<MaybeNull<(keyof T)[]>>;
} => {
  const configEntries = Object.entries(config).map(([key, config]) => {
    switch (config.type) {
      case FilterType.CriterionNumber:
        return [key, criterionNumberFilterParam(config.value)];
      case FilterType.SingleSelect:
        return [key, singleSelectFilterParam(config.value)];
      case FilterType.MultiSelect:
        return [key, multiSelectFilterParam(config.value)];
      case FilterType.DateRange:
        return [key, dateRangeFilterParam(config.value)];
      default:
        return [];
    }
  });

  configEntries.push([ORDER_KEY, orderFilterParam]);

  return Object.fromEntries(configEntries);
};

const stringifyQueryParams = (key: string, value: unknown) => {
  if (key !== ORDER_KEY) return value;
};

export type IQueryParams<T extends IFilterOptions> = {
  [K in keyof T]: IQueryConfigParams[T[K]['type']];
};

export type IQueryAllParams<T extends IFilterOptions> = IQueryParams<T> & {
  [ORDER_KEY]: MaybeNull<(keyof T)[]>;
};

export const useQueryFilter = <T extends IFilterOptions>(name: string, options: MaybeNull<T>) => {
  const config = makeQueryConfig(options ?? {});
  const [query, setQuery] = useNamedQueryParams<QueryParamConfigMap>(name, config);

  const isReady = useMemo(() => !!options, [options]);

  const allParams = query as IQueryAllParams<T>;

  const params = useMemo(() => {
    const params: Partial<typeof allParams> = { ...allParams };

    delete params[ORDER_KEY];

    return params as IQueryParams<T>;
  }, [JSON.stringify(allParams, stringifyQueryParams)]);

  const setParam = <K extends keyof T>(key: K, value: MaybeNull<T[K]['value']>) => setQuery({ [key]: value });

  const removeParam = <K extends keyof T>(key: K) => setQuery({ [key]: null });

  const removeParams = <K extends keyof T>(keys: K[]) => {
    const params = Object.fromEntries(keys.map((key) => [key, null]));

    setQuery(params);
  };

  const order = useMemo(() => (query[ORDER_KEY] ?? []) as (keyof T)[], [query[ORDER_KEY]]);

  const setOrder = (order: (keyof T)[]) => setQuery({ [ORDER_KEY]: order });

  const addToOrder = (key: keyof T) => setQuery({ [ORDER_KEY]: [...new Set([...order, key])] });

  const removeFormOrder = (key: keyof T) => setQuery({ [ORDER_KEY]: order.filter((property) => property !== key) });

  return {
    isReady,
    options,
    params,
    allParams,
    setParam,
    removeParam,
    removeParams,
    order,
    setOrder,
    addToOrder,
    removeFormOrder,
  };
};

export type IQueryFilter<T extends IFilterOptions> = ReturnType<typeof useQueryFilter<T>>;

export const makeQueryFilter = <T extends IFilterOptions>(name: string, config: IFilterConfigs<T>) => {
  const queryConfig = makeQueryConfig(config);
  const queryUtils = makeNamedQueryUtils<QueryParamConfigMap>(name, queryConfig);

  const getSearchString = (params: Partial<IQueryAllParams<T>>) => queryUtils.getSearchString(params) as string;
  const getEncodedParams = (params: Partial<IQueryAllParams<T>>) =>
    queryUtils.getEncodedParams(params) as Partial<{ [K in keyof T]: MaybeNull<string> }>;
  const useFilter = (options: MaybeNull<T>) => useQueryFilter(name, options);

  return {
    getEncodedParams,
    getSearchString,
    useFilter,
  };
};
