interface IPipe<TValue> {
  value(): TValue;
  chain<TNext>(next: (value: TValue) => TNext): IPipe<TNext>;
}

const pipe = <TValue>(value: TValue): IPipe<TValue> => ({
  chain: (next) => pipe(next(value)),
  value: () => value,
});

export default pipe;
