import { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import Chip from '@mui/material/Chip';

import { IBrandStatus, BrandStatus } from 'src/types';

const Status = ({ status }: { status: IBrandStatus }) => {
  const { palette } = useTheme();

  const labels = useMemo(
    () => ({
      [BrandStatus.ACTIVE]: 'Active',
      [BrandStatus.PENDING]: 'Pending',
      [BrandStatus.EXPIRED]: 'Expired',
      [BrandStatus.DELETED]: 'Deleted',
    }),
    [],
  );

  const colors = useMemo(
    () => ({
      [BrandStatus.ACTIVE]: palette.success.light,
      [BrandStatus.PENDING]: palette.info.light,
      [BrandStatus.EXPIRED]: palette.warning.light,
      [BrandStatus.DELETED]: palette.error.light,
    }),
    [],
  );

  const label = labels[status] ?? 'Unknown';
  const color = colors[status] ?? palette.text.disabled;

  return (
    <Chip label={label} sx={(theme) => ({ backgroundColor: color, minWidth: theme.spacing(9) })} component={'span'} />
  );
};

export default Status;
