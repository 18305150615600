import { memo } from 'react';

import { IInvoiceChart } from 'src/types';

import { IChartTab } from './types';
import ChartStack from './ChartStack';
import ChartWaterfall from './ChartWaterfall';

export interface IChartComponentProps {
  tab: IChartTab;
  charts: IInvoiceChart;
}

const ChartComponent = ({ tab, charts }: IChartComponentProps): JSX.Element => {
  switch (tab) {
    case 'stack':
      return <ChartStack data={charts.stack_charts_data} />;
    case 'waterfall':
      return <ChartWaterfall data={charts.waterfall_chart_data} />;
    default:
      return <>Chart not available</>;
  }
};

export default memo(ChartComponent);
