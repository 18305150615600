import { IAnalyticReport, IAnalyticReportsSortBy, ISortProps } from 'src/types';
import { TableBody } from 'src/components/shared/Table';

import { AnalyticReportRow } from './AnalyticReportRow';
import { AnalyticReportHeaders } from './AnalyticReportHeaders';

export interface IAnalyticReportListProps extends ISortProps<IAnalyticReportsSortBy> {
  reports: IAnalyticReport[];
}

export const AnalyticReportList = ({ reports, sort, onSort }: IAnalyticReportListProps) => (
  <TableBody>
    <AnalyticReportHeaders sort={sort} onSort={onSort} />
    <>
      {reports.map((report) => (
        <AnalyticReportRow report={report} key={`report-row-${report.name}`} />
      ))}
    </>
  </TableBody>
);
