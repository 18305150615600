import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';

export const StyledAnomaliesCards = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(3)};
  justify-content: center;
`;

export const StyledTotalStatus = styled(Paper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const StyledTotalStatusValue = styled.span`
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 20px;
`;

export const StyledTotalStatuses = styled.section`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spacing(2)};
`;

export const StyledCanvasContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
`;
