import { ID, ILatestOrder, IOrder } from 'src/types';

import request from './requestPrivate';

export const getLatestOrder = () => request.get<ILatestOrder>('/salesorder/latest');

export const getOrderDetails = (name: string, id: ID) => {
  const requestParams = { invoice_id: name, sales_order_id: id };

  return request.get<IOrder>('/salesorder', requestParams);
};
