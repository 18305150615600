import { memo, useState } from 'react';

import UploadArea from './UploadArea';
import Uploading from './Uploading';
import Downloading from './Downloading';
import { IUseFileController } from './useFileController';

export interface IFileControllerProps {
  controller: IUseFileController;
  value?: string;
}

const FileController = ({ value, controller }: IFileControllerProps) => {
  const { uploadState, downloadState, onFileDelete, onFileUpload } = controller;
  const [file, setFile] = useState<MaybeNull<File>>(null);

  const isUploading = uploadState.status === 'uploading' || uploadState.status === 'failed';
  const isUploaded = uploadState.status === 'uploaded';

  const handleFileUpload = (file: File) => {
    setFile(file);
    onFileUpload(file);
  };

  const handleFileReupload = () => {
    if (file) handleFileUpload(file);
  };

  const handleFileDelete = () => {
    setFile(null);
    onFileDelete();
  };

  const handleFileDownload = () => {
    if (value != null) controller.onFileDownload(value);
  };

  if (isUploading) return <Uploading uploadState={uploadState} canReupload={!!file} onReupload={handleFileReupload} />;
  if (isUploaded) {
    return (
      <Downloading
        downloadState={downloadState}
        uploadState={uploadState}
        onDelete={handleFileDelete}
        onDownload={handleFileDownload}
      />
    );
  }

  return <UploadArea onUpload={handleFileUpload} />;
};

export default memo(FileController);
