import { memo } from 'react';

import { AuthContent, ResetPasswordForm, MessageGreeting } from 'src/components/Auth';
import { IAuthChallengeResetPassword } from 'src/types';
import { IConfirmResetPasswordSchema } from 'src/schemas';
import makeRequest from 'src/effects/makeRequest';
import * as apiAuth from 'src/api/auth';

import { IStepComponent } from './types';

const useForgotPasswordRequest = makeRequest(apiAuth.forgotPassword, { silent: true });
const useResetPasswordRequest = makeRequest(apiAuth.resetPassword, { silent: true });

const ResetPassword: IStepComponent<IAuthChallengeResetPassword> = ({ challenge, onChallenge }) => {
  const forgotPasswordRequest = useForgotPasswordRequest(null, false);
  const resetPasswordRequest = useResetPasswordRequest(null, false);

  const handleResend = () => forgotPasswordRequest.make(challenge.payload);
  const handleSubmit = (auth: IConfirmResetPasswordSchema) => {
    resetPasswordRequest.make({
      ...challenge.payload,
      ...auth,
    });
    resetPasswordRequest.onFulfill(onChallenge);
  };

  return (
    <AuthContent>
      <MessageGreeting title="New Password" description="Enter code and new password." />
      <ResetPasswordForm
        error={resetPasswordRequest.error?.detail}
        loading={forgotPasswordRequest.loading || resetPasswordRequest.loading}
        onSubmit={handleSubmit}
        onResend={handleResend}
      />
    </AuthContent>
  );
};

export default memo(ResetPassword) as typeof ResetPassword;
