import { createEnumParam, withDefault } from 'use-query-params';

import { AnalyticServiceType } from 'src/types';
import { makeNamedQueryParams } from 'src/effects/useNamedQueryParams';

const AnalyticServiceTypeParam = withDefault(
  createEnumParam(Object.values(AnalyticServiceType)),
  AnalyticServiceType.SUMMARY,
);

export const analyticsDetailsQuery = makeNamedQueryParams('as', { type: AnalyticServiceTypeParam });
