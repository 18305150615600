import { memo, useEffect, useRef, useState } from 'react';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { CalendarIcon } from 'src/components/Icons';
import dateFormatter from 'src/utils/dateFormatter';

import { StyledPickerInput } from './styles';
import PickerDateRange, { IPickerDateRangeProps } from './PickerDateRange';

export interface IPickerDateRangeInputProps {
  range?: IPickerDateRangeProps['range'];
  onChange?: IPickerDateRangeProps['onRangeChange'];
  maxDate?: IPickerDateRangeProps['maxDate'];
  minDate?: IPickerDateRangeProps['minDate'];
  periods?: IPickerDateRangeProps['periods'];
}

const formatDate = dateFormatter();

const rangeToView = (range?: IPickerDateRangeProps['range']) => {
  if (!range) return '';

  return `${formatDate(range.from)} - ${formatDate(range.to)}`;
};

const PickerDateRangeInput = ({ range, onChange, maxDate, minDate, periods }: IPickerDateRangeInputProps) => {
  const [rangeView, setRangeView] = useState(() => rangeToView(range));
  const [isOpen, setOpen] = useState(false);
  const popperRef = useRef<MaybeNull<HTMLInputElement>>(null);

  const hidePopper = () => setOpen(false);
  const togglePopper = () => setOpen(!isOpen);

  const handleRangeChange = (range: IPickerDateRangeProps['range']) => {
    hidePopper();
    onChange?.(range);
  };

  useEffect(() => setRangeView(rangeToView(range)), [range]);

  return (
    <ClickAwayListener onClickAway={hidePopper}>
      <div>
        <StyledPickerInput
          ref={popperRef}
          $active={isOpen}
          value={rangeView}
          onClick={togglePopper}
          placeholder={'Choose period'}
          startAdornment={
            <InputAdornment position="start">
              <Typography variant="h5" sx={{ textTransform: 'none' }}>
                Period:
              </Typography>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <CalendarIcon />
            </InputAdornment>
          }
        />
        <Popper
          transition
          placement="bottom-start"
          open={isOpen}
          anchorEl={popperRef.current}
          sx={(theme) => ({ zIndex: theme.zIndex.tooltip, top: '8px!important' })}>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Paper>
                <PickerDateRange
                  range={range}
                  maxDate={maxDate}
                  minDate={minDate}
                  periods={periods}
                  onRangeChange={handleRangeChange}
                  onCancel={hidePopper}
                />
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default memo(PickerDateRangeInput);
