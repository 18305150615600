import { alpha } from '@mui/system/colorManipulator';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

export const StyledTopLabel = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
  width: ${({ theme }) => theme.spacing(5)};
  height: ${({ theme }) => theme.spacing(5)};
  min-width: ${({ theme }) => theme.spacing(5)};
  min-height: ${({ theme }) => theme.spacing(5)};
  border-radius: ${({ theme }) => theme.spacing(5)};
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const StyledDropdown = styled(Paper)`
  display: flex;
  flex-direction: column;
  min-width: 300px;
`;

export const StyledUserLabel = styled(StyledTopLabel)`
  pointer-events: none;
  box-shadow: none;
  cursor: default;
  grid-area: 1 / 1 / 3 / 2;
`;

export const StyledUserName = styled(Typography)`
  grid-area: 1 / 2 / 2 / 3;
`;

export const StyledUserMail = styled(Typography)`
  grid-area: 2 / 2 / 3 / 3;
`;

export const StyledUserInfo = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: repeat(2, auto);
  grid-gap: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const StyledAction = styled(Box)`
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing(1.5)};
  color: ${({ theme }) => theme.palette.text.primary};
  background-color: transparent;

  &:hover {
    background-color: ${({ theme }) => alpha(theme.palette.primary.main, 0.1)};
  }
`;
