import { PropsWithChildren } from 'react';
import Typography from '@mui/material/Typography';

import { StyledInfoRecords, StyledInfoRecord } from './styles';

export interface IInfoRecordProps {
  name: string;
  value: string;
}
export const InfoRecords = ({ children }: PropsWithChildren) => <StyledInfoRecords>{children}</StyledInfoRecords>;

export const InfoRecord = ({ name, value }: IInfoRecordProps) => (
  <StyledInfoRecord>
    <Typography variant="h4">{name}:</Typography>
    <Typography variant="h4" sx={{ color: 'text.secondary' }}>
      {value}
    </Typography>
  </StyledInfoRecord>
);
