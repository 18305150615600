import { memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

import { LogoContentIcon, LogoDecorIcon } from 'src/components/Icons';
import { BOTTOM_SIDEBAR_CONFIG, TOP_SIDEBAR_CONFIG } from 'src/config/sidebar';
import { getAllowedSidebar } from 'src/access';

import { SidebarProvider, useController } from './Context';
import { ISidebarItem, ISidebarIcon, ISidebarSubmenu } from './types';
import {
  StyledSidebarBody,
  StyledSidebarGroup,
  StyledSidebarContainer,
  StyledSidebarTitle,
  StyledSidebarMenuItem,
  StyledButtonComponent,
  StyledLinkComponent,
  StyledSubmenu,
  StyledLogoContainer,
  StyledLogoBody,
  StyledLogoDecor,
  StyledLogoContent,
  StyledDivider,
} from './styles';

const LogoComponent = () => (
  <StyledLogoContainer>
    <StyledLogoBody>
      <StyledLogoDecor>
        <LogoDecorIcon />
      </StyledLogoDecor>
      <StyledLogoContent>
        <LogoContentIcon />
      </StyledLogoContent>
    </StyledLogoBody>
  </StyledLogoContainer>
);

const SidebarMenuItem = ({ icon, isActive }: { icon: ISidebarIcon; isActive: boolean }) => {
  const { title, disabled, Svg } = icon;

  return (
    <StyledSidebarMenuItem $disabled={disabled} $active={isActive}>
      <Svg fontSize={'medium'} />
      <StyledSidebarTitle>{title}</StyledSidebarTitle>
    </StyledSidebarMenuItem>
  );
};

const SidebarItemLink = ({ icon, link }: { icon: ISidebarIcon; link: string }) => {
  const { pathname } = useLocation();
  const { hideSubmenu } = useController();
  const isActive = pathname.startsWith(link);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (icon.disabled) event.preventDefault();
    if (!icon.disabled) hideSubmenu();
  };

  return (
    <StyledLinkComponent to={link} onClick={handleClick} $disabled={icon.disabled}>
      <SidebarMenuItem icon={icon} isActive={isActive} />
    </StyledLinkComponent>
  );
};

const SidebarItemButton = ({ icon, submenu }: { icon: ISidebarIcon; submenu: ISidebarSubmenu }) => {
  const { pathname } = useLocation();
  const { Component, showSubmenu } = useController();
  const isActive = submenu.component === Component;
  const isTargetPage = !!submenu.urlPrefix && pathname.startsWith(submenu.urlPrefix);

  const handleClick = () => showSubmenu(submenu.component);

  return (
    <StyledButtonComponent onClick={handleClick}>
      <SidebarMenuItem icon={icon} isActive={isActive || isTargetPage} />
    </StyledButtonComponent>
  );
};

const SidebarItem = ({ item }: { item: ISidebarItem }) => {
  if (item.link) return <SidebarItemLink icon={item.icon} link={item.link} />;
  if (item.submenu) return <SidebarItemButton icon={item.icon} submenu={item.submenu} />;

  return null;
};

const SidebarComponent = () => {
  const { pathname } = useLocation();
  const { Component, hideSubmenu, isVisible } = useController();

  const handleTransitionEnd = () => !isVisible && hideSubmenu({ hard: true });
  const handleClickAway = () => hideSubmenu();

  useEffect(hideSubmenu, [pathname]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <StyledSidebarContainer>
        <StyledSidebarBody data-active={isVisible}>
          <StyledSidebarGroup>
            <LogoComponent />
            <StyledDivider sx={{ borderBottomWidth: 2 }} />
            {getAllowedSidebar(TOP_SIDEBAR_CONFIG).map((item) => (
              <SidebarItem item={item} key={item.icon.title} />
            ))}
          </StyledSidebarGroup>
          <StyledSidebarGroup>
            {getAllowedSidebar(BOTTOM_SIDEBAR_CONFIG).map((item) => (
              <SidebarItem item={item} key={item.icon.title} />
            ))}
          </StyledSidebarGroup>
        </StyledSidebarBody>
        <StyledSubmenu data-active={isVisible} onTransitionEnd={handleTransitionEnd}>
          {Component && <Component />}
        </StyledSubmenu>
      </StyledSidebarContainer>
    </ClickAwayListener>
  );
};

const Sidebar = () => (
  <SidebarProvider>
    <SidebarComponent />
  </SidebarProvider>
);

export default memo(Sidebar);
