import styled from '@emotion/styled';
import { memo, useState } from 'react';

import { UserRole, useAccess } from 'src/access';
import AlertPanel from 'src/components/AlertPanel';
import GlobalSearch from 'src/components/GlobalSearch';
import { UserInfo } from 'src/components/shared/Page';
import makeRequest from 'src/effects/makeRequest';
import * as apiInvoice from 'src/api/invoice';

const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(2, 3)};
  padding: ${({ theme }) => theme.spacing(4, 3, 2)};
`;

const StyledTitle = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const useSearchRequest = makeRequest(apiInvoice.getSearchResults);

export interface IHeaderProps {
  setTitleRef: (ref: MaybeNull<HTMLElement>) => void;
}

const Header = ({ setTitleRef }: IHeaderProps) => {
  const isActionAvailable = useAccess(UserRole.CUSTOMER_USER, UserRole.CUSTOMER_ADMIN);
  const searchRequest = useSearchRequest(null, false);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleSearchChange = (searchTerm: string) => {
    searchRequest.cleanup();

    if (searchTerm) searchRequest.make(searchTerm);

    setSearchTerm(searchTerm);
  };

  return (
    <StyledHeader>
      <StyledTitle ref={setTitleRef} />
      {isActionAvailable && (
        <GlobalSearch
          placeholder="Search by Invoice or Order"
          tooltip={'Press Enter to Search'}
          loading={searchRequest.loading}
          searchResult={searchRequest.data}
          searchTerm={searchTerm}
          onChange={handleSearchChange}
        />
      )}
      {isActionAvailable && <AlertPanel />}
      <UserInfo />
    </StyledHeader>
  );
};

export default memo(Header);
