import { Fragment, PropsWithChildren, memo } from 'react';

import { StyledChip } from './styles';

export interface IAnomalyChipProps {
  active?: boolean;
}

const AnomalyChip = ({ active, children }: PropsWithChildren<IAnomalyChipProps>) => {
  const Component = active ? StyledChip : Fragment;

  return <Component>{children}</Component>;
};

export default memo(AnomalyChip);
