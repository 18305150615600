import { INotification } from 'src/services/NotificationService';

export const makeErrorNote: () => INotification = () => ({
  variant: 'error',
  message: 'Something went wrong with the report, please try again',
});

export const makeInfoNote: () => INotification = () => ({
  variant: 'info',
  message: 'Your export is being prepared. We will notify you as soon as it is available for download.',
});
