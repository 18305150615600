import { useEffect, useState } from 'react';

import { PageTitle } from 'src/components/shared/Page';
import ReportFrame from 'src/components/ReportFrame';
import makeRequest from 'src/effects/makeRequest';
import * as apiBrand from 'src/api/brand';

const usePerformanceTokenRequest = makeRequest(apiBrand.getTokenPerformance);

export default function PerformancePage() {
  const [token, setToken] = useState<MaybeNull<string>>(null);
  const performanceTokenRequest = usePerformanceTokenRequest(null, true);

  useEffect(() => {
    performanceTokenRequest.make();
    performanceTokenRequest.onFulfill(setToken);
  }, []);

  return (
    <>
      <PageTitle title={'Performance Management'} />
      <ReportFrame token={token} />
    </>
  );
}
