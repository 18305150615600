export class Publisher<Data> {
  #callbacks = new Set<(data: Data) => void>();

  protected publish(data: Data) {
    for (const callback of this.#callbacks) callback(data);
  }

  public subscribe(callback: (data: Data) => void) {
    this.#callbacks.add(callback);

    return () => this.unsubscribe(callback);
  }

  public unsubscribe(callback: (data: Data) => void) {
    this.#callbacks.delete(callback);
  }

  protected cleanup() {
    this.#callbacks.clear();
  }
}

export class Observer<Data> extends Publisher<Data> {
  public publish(data: Data) {
    return super.publish(data);
  }

  public cleanup() {
    return super.cleanup();
  }
}
