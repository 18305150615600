import { PropsWithChildren, createContext, useContext } from 'react';

import { ICredit } from 'src/types';
import makeRequest from 'src/effects/makeRequest';
import * as apiCreditRequest from 'src/api/creditRequest';

import { useCreditDataProcessor, useCreditsFilters, useCreditsSort } from './creditTrackerControl';

export interface ICreditTrackerContext {
  creditsRequest: ReturnType<typeof useCreditsRequest<ICredit[]>>;
  creditsSorting: ReturnType<typeof useCreditsSort>;
  creditsFilters: ReturnType<typeof useCreditsFilters>;
  creditsData: MaybeNull<ICredit[]>;
}

const useCreditsRequest = makeRequest(apiCreditRequest.getCredits);

export const CreditTrackerContext = createContext<ICreditTrackerContext>({} as ICreditTrackerContext);

export const CreditTrackerProvider = ({ children }: PropsWithChildren) => {
  const creditsRequest = useCreditsRequest([], true);
  const creditsSorting = useCreditsSort();
  const creditsFilters = useCreditsFilters(creditsRequest.data);
  const creditsData = useCreditDataProcessor({
    data: creditsRequest.data,
    filterConfig: creditsFilters.options,
    filterParams: creditsFilters.params,
    sortParams: creditsSorting.params,
  });

  return (
    <CreditTrackerContext.Provider
      value={{
        creditsRequest,
        creditsSorting,
        creditsFilters,
        creditsData,
      }}>
      {children}
    </CreditTrackerContext.Provider>
  );
};

export const useCreditTrackerContext = () => useContext(CreditTrackerContext);
