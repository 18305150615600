import { NavLink, NavLinkProps } from 'react-router-dom';
import Button, { ButtonProps } from '@mui/material/Button';

export interface IButtonLinkProps {
  title: string;
}

export const ButtonLink = ({ title, ...props }: ButtonProps<'a'> & NavLinkProps & IButtonLinkProps) => (
  <Button {...props} component={NavLink}>
    {title}
  </Button>
);
