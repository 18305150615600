import { memo, useEffect, useRef } from 'react';
import { useTheme } from '@emotion/react';
import { Chart } from 'chart.js';

import { IInvoiceChartWaterfallColor, IInvoiceChartWaterfallGroup } from 'src/types';
import currencyFormatter from 'src/utils/currencyFormatter';

import { StyledCanvas, StyledCanvasContainer } from './styles';

const formatCurrency = currencyFormatter();
const formatCurrencyCompact = currencyFormatter({ notation: 'compact' });

const formatDataLabel = ({ dataset }: IChartData, dataIndex: number) => {
  const value = dataset.values[dataIndex];

  return formatCurrencyCompact(value);
};

const formatTitleLabel = ({ dataset }: IChartData, dataIndex: number) => {
  const value = dataset.values[dataIndex];

  return formatCurrency(value);
};

const formatTooltipLabel = ({ fullLabels }: IChartData, dataIndex: number) => fullLabels[dataIndex];

const isLabelVisible = ({ dataset }: IChartData, dataIndex: number) => {
  const value = dataset.values[dataIndex];

  return !!value;
};

const makeColor = (label: IInvoiceChartWaterfallColor) => {
  switch (label) {
    case 'BOUND':
      return '#656565';
    case 'NEGATIVE':
      return '#D58484';
    case 'NEGATIVE_TOTAL':
      return '#c42929';
    case 'POSITIVE':
      return '#90C69D';
    case 'POSITIVE_TOTAL':
      return '#228D3B';
  }
};

const makeLabel = (label: string) => label.split('\n').map((phrase) => phrase.trim());

type IChartData = ReturnType<typeof makeChartData>;

const makeChartData = (waterfallData: IInvoiceChartWaterfallGroup[]) => {
  const data = waterfallData.map((item) => [item.start, item.end]);
  const values = waterfallData.map((item) => item.value);
  const maxValue = Math.max(...waterfallData.map((item) => [item.start, item.end]).flat());
  const minValue = Math.min(...waterfallData.map((item) => [item.start, item.end]).flat());
  const labels = waterfallData.map((item) => makeLabel(item.label));
  const fullLabels = labels.map((label) => label.join(' '));
  const backgroundColor = waterfallData.map((item) => makeColor(item.color_label));

  const dataset = {
    data,
    values,
    maxValue,
    minValue,
    backgroundColor,
    borderRadius: 5,
    barPercentage: 1,
    borderSkipped: false,
  };

  const datasets = [dataset];

  return {
    labels,
    dataset,
    datasets,
    fullLabels,
  };
};

export interface IChartWaterfallProps {
  data: IInvoiceChartWaterfallGroup[];
}

const ChartWaterfall = ({ data }: IChartWaterfallProps) => {
  const ref = useRef<MaybeNull<HTMLCanvasElement>>(null);
  const { palette, typography } = useTheme();

  useEffect(() => {
    const context = ref.current?.getContext('2d');

    if (!context) return;

    const chartData = makeChartData(data);

    const chart = new Chart(context, {
      type: 'bar',
      data: chartData,
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: ([{ dataIndex }]) => formatTooltipLabel(chartData, dataIndex),
              label: ({ dataIndex }) => `  ${formatTitleLabel(chartData, dataIndex)}`,
            },
            backgroundColor: palette.secondary.main,
            titleColor: palette.secondary.light,
            bodyColor: palette.secondary.light,
            usePointStyle: true,
            padding: 8,
            bodyFont: {
              family: typography.fontFamily,
              weight: typography.fontWeightRegular as number,
              size: 12,
            },
            titleFont: {
              family: typography.fontFamily,
              weight: typography.fontWeightMedium as number,
              size: 12,
            },
          },
          datalabels: {
            clamp: true,
            anchor: 'end',
            align: 'end',
            labels: {
              value: {
                color: chartData.dataset.backgroundColor,
              },
            },
            font: {
              weight: typography.fontWeightMedium as number,
              size: 12,
            },
            display: ({ dataIndex }) => isLabelVisible(chartData, dataIndex),
            formatter: (_, { dataIndex }) => formatDataLabel(chartData, dataIndex),
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              color: palette.text.primary,
              font: {
                weight: typography.fontWeightRegular as number,
                family: typography.fontFamily,
                size: 12,
              },
              autoSkip: false,
            },
          },
          y: {
            border: {
              display: false,
            },
            ticks: {
              display: false,
              stepSize: (chartData.dataset.maxValue - chartData.dataset.minValue) / 4,
            },
            min: chartData.dataset.minValue,
            max: chartData.dataset.maxValue,
            stacked: false,
            grid: {
              lineWidth: 2,
            },
          },
        },
        layout: {
          padding: {
            left: 0,
            top: 26,
          },
        },
      },
    });

    return () => chart.destroy();
  }, [data, palette, typography]);

  return (
    <StyledCanvasContainer>
      <StyledCanvas ref={ref} width="640" height="90" />
    </StyledCanvasContainer>
  );
};

export default memo(ChartWaterfall);
