import { memo } from 'react';

import { TableHeaderRow, TableHeaderCell, TableLeadCell } from 'src/components/shared/Table';

const AnomalyComparableHead = () => (
  <TableHeaderRow>
    <TableLeadCell />
    <TableHeaderCell preWrap>{'Order \nnumber'}</TableHeaderCell>
    <TableHeaderCell preWrap>{'Actual \nWeight'}</TableHeaderCell>
    <TableHeaderCell preWrap>{'Dimensional \nWeight'}</TableHeaderCell>
    <TableHeaderCell>Zone</TableHeaderCell>
    <TableHeaderCell preWrap>{'Distance \nTraveled'}</TableHeaderCell>
    <TableHeaderCell preWrap>{'Accessorial \nCost'}</TableHeaderCell>
    <TableHeaderCell preWrap>{'Total \nFreight Cost'}</TableHeaderCell>
  </TableHeaderRow>
);

export default memo(AnomalyComparableHead);
