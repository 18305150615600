import { memo } from 'react';

import { StyledGreetingMessage, StyledMessageArrow } from './styles';

export interface IMessageGreetingProps {
  title: string;
  description: string;
}

export const MessageGreeting = ({ title, description }: IMessageGreetingProps) => (
  <StyledGreetingMessage>
    <h1>{title}</h1>
    <p>{description}</p>
    <StyledMessageArrow />
  </StyledGreetingMessage>
);

export default memo(MessageGreeting);
