import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { enUS } from '@mui/x-date-pickers/locales';
import { PropsWithChildren } from 'react';

const { localeText } = enUS.components.MuiLocalizationProvider.defaultProps;

const PickerProvider = ({ children }: PropsWithChildren) => (
  <LocalizationProvider dateAdapter={AdapterDayjs} localeText={localeText}>
    {children}
  </LocalizationProvider>
);

export default PickerProvider;
