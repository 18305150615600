import { Navigate, Outlet, useOutletContext, useNavigate } from 'react-router-dom';
import { memo, useEffect, useState } from 'react';

import { IS_EXPERIMENTAL } from 'src/config/env';
import { authService } from 'src/services/AuthService';
import { PageContent } from 'src/components/shared/Page';
import useAuthenticator from 'src/effects/useAuthenticator';

import Header from './Header';
import FullStory from './FullStory';
import IntercomChat from './IntercomChat';

const Layout = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthenticator();

  const [titleRef, setRef] = useState<MaybeNull<HTMLElement>>(null);

  useEffect(
    () =>
      authService.subscribe((action) => {
        if (action === 'update') navigate('/');
      }),
    [],
  );

  return isAuthenticated ? (
    <PageContent>
      <Header setTitleRef={setRef} />
      <Outlet context={{ titleRef }} />
      {IS_EXPERIMENTAL && <IntercomChat />}
      <FullStory />
    </PageContent>
  ) : (
    <Navigate to={'/login'} />
  );
};

export interface IPrivateLayoutContext {
  titleRef: MaybeNull<HTMLElement>;
}

export const usePrivateContext = () => useOutletContext<IPrivateLayoutContext>();

export default memo(Layout);
