import { memo } from 'react';

import { PickerDateRangeInput, makeDateRange } from 'src/components/shared/DateRange';

export interface IDateRange {
  from?: MaybeNull<Date>;
  to?: MaybeNull<Date>;
}

export interface IInvoiceDateRangeProps {
  range: IDateRange;
  onChange: (range: IDateRange) => void;
}

const InvoiceDateRange = ({ range, onChange }: IInvoiceDateRangeProps) => (
  <PickerDateRangeInput
    range={makeDateRange(range.from, range.to)}
    onChange={(range) =>
      onChange({
        from: range?.from.toDate() ?? null,
        to: range?.to.toDate() ?? null,
      })
    }
  />
);

export default memo(InvoiceDateRange);
