import styled from '@emotion/styled';
import FormControl from '@mui/material/FormControl';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';

export { StyledCard } from '../CreditDialog/styles';

export const StyledTextarea = styled(TextareaAutosize)`
  resize: none;
  border: none;
  outline: none;
  font-size: 12px;
  line-height: 18px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
`;

export const StyledEmailFields = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(1.8)};

  & > * {
    flex: 1;
  }
`;

export const StyledEmailControl = styled(FormControl)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};
`;

export const StyledEmailForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
`;
