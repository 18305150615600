import { memo, useMemo } from 'react';
import FilledInput from '@mui/material/FilledInput';

import dateFormatter from 'src/utils/dateFormatter';

import { IDateRange } from './DateRangeCalendar';

const formatDate = dateFormatter();

export interface IPickerInputsProps {
  range: Maybe<IDateRange>;
}

const PickerInputs = ({ range }: IPickerInputsProps) => {
  const from = useMemo(() => (range?.from ? formatDate(range.from) : ''), [range?.from]);
  const to = useMemo(() => (range?.to ? formatDate(range.to) : ''), [range?.to]);

  return (
    <>
      <FilledInput placeholder="From" value={from} readOnly />
      <FilledInput placeholder="To" value={to} readOnly />
    </>
  );
};

export default memo(PickerInputs);
