import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { StyledFormBlock, StyledHeader, StyledCollapseButton } from './styles';

export interface IFormBlockErrorProps {
  onError: (hasError: boolean) => void;
}

export interface IFormBlockProps {
  title: string;
  content: (onError: IFormBlockErrorProps['onError']) => ReactNode;
}

export const RateCardBlock = ({ title, content, children }: PropsWithChildren<IFormBlockProps>) => {
  const [hasError, setErrorState] = useState(false);
  const [collapseIn, setCollapseState] = useState(true);

  const handleTitleClick = () => {
    if (!hasError) setCollapseState(!collapseIn);
  };

  useEffect(() => {
    if (hasError) setCollapseState(true);
  }, [hasError]);

  return (
    <>
      <Divider />
      <StyledHeader>
        <Typography sx={{ lineHeight: 1 }} variant="h2">
          {title}
        </Typography>
        <StyledCollapseButton variant="text" disabled={hasError} onClick={handleTitleClick} $collapseIn={collapseIn} />
      </StyledHeader>
      <Collapse in={collapseIn}>
        <StyledFormBlock>{content(setErrorState)}</StyledFormBlock>
      </Collapse>
      {children}
    </>
  );
};
