import { PropsWithChildren, forwardRef, memo } from 'react';
import { NavLink } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MenuList from '@mui/material/MenuList';

import { linkToInvoiceDetails } from 'src/components/Invoice';
import { IInvoiceSearchResult, IOrderSearchResult, ISearchResult } from 'src/types';

import { StyledMenuItem, StyledTooltip } from './styles';

interface IBaseResultsProps {
  onResultClick: () => void;
}

interface IBaseResultProps extends IBaseResultsProps {
  link: string;
  name: string;
  description: string;
}

const BaseResult = ({ name, description, link, onResultClick }: IBaseResultProps) => (
  <StyledMenuItem dense component={NavLink} to={link} onClick={onResultClick}>
    <span>{name}</span>
    <span>{` - ${description}`}</span>
  </StyledMenuItem>
);

export interface IInvoicesResultsProps extends IBaseResultsProps {
  invoices: IInvoiceSearchResult[];
}

const InvoicesResults = ({ invoices, onResultClick }: IInvoicesResultsProps) => (
  <>
    {invoices.map(({ invoice_name, meta }) => (
      <BaseResult
        key={`invoice-${invoice_name}`}
        name={`Invoice "${invoice_name}"`}
        description={'Invoices Page'}
        link={linkToInvoiceDetails(invoice_name, meta.services)}
        onResultClick={onResultClick}
      />
    ))}
  </>
);

export interface IOrdersResultsProps extends IBaseResultsProps {
  orders: IOrderSearchResult[];
}

const OrdersResults = ({ orders, onResultClick }: IOrdersResultsProps) => (
  <>
    {orders.map(({ order_number, meta }) => (
      <BaseResult
        key={`order-${order_number}`}
        name={`Order "${order_number}"`}
        description={'Order Detail Page'}
        link={`/invoice/${encodeURIComponent(meta.invoice_name)}/order/${encodeURIComponent(order_number)}`}
        onResultClick={onResultClick}
      />
    ))}
  </>
);

export interface ISearchResultProps {
  loading: boolean;
  result: MaybeNull<ISearchResult>;
}

const SearchResult = forwardRef<HTMLDivElement, PropsWithChildren<ISearchResultProps & IBaseResultsProps>>(
  ({ loading, result, onResultClick }, ref) => {
    if (loading) {
      return (
        <StyledTooltip>
          <Typography variant="h4">Loading</Typography>
        </StyledTooltip>
      );
    }

    const noResults = !result?.invoices.length && !result?.orders.length;

    if (noResults) {
      return (
        <StyledTooltip>
          <Typography variant="h4">No Results</Typography>
        </StyledTooltip>
      );
    }

    return (
      <Paper ref={ref}>
        <MenuList disablePadding>
          <InvoicesResults invoices={result?.invoices ?? []} onResultClick={onResultClick} />
          <OrdersResults orders={result?.orders ?? []} onResultClick={onResultClick} />
        </MenuList>
      </Paper>
    );
  },
);

SearchResult.displayName = 'SearchResult';

export default memo(SearchResult);
