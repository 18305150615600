import Typography from '@mui/material/Typography';

import SubmenuLink from './SubmenuLink';
import { StyledSubmenuContainer, StyledSubmenuSegment, StyledSubmenuGroup, StyledSubmenuNav } from './styles';

export const SettingsSubmenu = () => (
  <StyledSubmenuContainer>
    <StyledSubmenuSegment>
      <StyledSubmenuGroup>
        <Typography variant="h3">User Settings</Typography>
        <StyledSubmenuNav>
          <SubmenuLink disabled to={'/'}>
            User Profile
          </SubmenuLink>
        </StyledSubmenuNav>
      </StyledSubmenuGroup>
      <StyledSubmenuGroup>
        <Typography variant="h3">Company Settings</Typography>
        <StyledSubmenuNav>
          <SubmenuLink disabled to={'/'}>
            Company Profile
          </SubmenuLink>
          <SubmenuLink to={'/settings/users'}>User accounts</SubmenuLink>
          <SubmenuLink disabled to={'/'}>
            Manage tags
          </SubmenuLink>
        </StyledSubmenuNav>
      </StyledSubmenuGroup>
      <StyledSubmenuGroup>
        <Typography variant="h3">Logistics settings</Typography>
        <StyledSubmenuNav>
          <SubmenuLink to={'/settings/logistics'}>Logistics Profile</SubmenuLink>
          <SubmenuLink disabled to={'/'}>
            Bundle Mappings
          </SubmenuLink>
          <SubmenuLink to={'/settings/ratecards'}>Rate Cards - PNP</SubmenuLink>
          <SubmenuLink disabled to={'/'}>
            Rate Cards - Freight
          </SubmenuLink>
          <SubmenuLink disabled to={'/'}>
            Invoice Status
          </SubmenuLink>
          <SubmenuLink disabled to={'/'}>
            Integrations
          </SubmenuLink>
        </StyledSubmenuNav>
      </StyledSubmenuGroup>
    </StyledSubmenuSegment>
  </StyledSubmenuContainer>
);
