import useAuthenticator from 'src/effects/useAuthenticator';

import { StyledAction } from './styles';

const UserActions = () => {
  const { signOut } = useAuthenticator();

  return <StyledAction onClick={signOut}>Log Out</StyledAction>;
};

export default UserActions;
