import { memo, useState } from 'react';

import { TabbedCard } from 'src/components/shared/TabbedCard';
import { IAnomaliesAggregation } from 'src/types';

import { IAnomalyTab } from './types';
import AnomalyTabPanel from './AnomalyTabPanel';
import AnomalyTabComponent from './AnomalyTabComponent';

export interface IAnomalyTabsPanelProps {
  payload: IAnomaliesAggregation;
}

const AnomalyTabsPanel = ({ payload }: IAnomalyTabsPanelProps) => {
  const [tab, setTab] = useState<IAnomalyTab>('status');

  return (
    <TabbedCard sx={{ minWidth: 0, maxWidth: '1100px', flex: 1 }}>
      <AnomalyTabPanel tab={tab} onTabChange={setTab} />
      <AnomalyTabComponent tab={tab} payload={payload} />
    </TabbedCard>
  );
};

export default memo(AnomalyTabsPanel);
