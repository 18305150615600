import { forwardRef, memo, useEffect, useState } from 'react';
import MenuList from '@mui/material/MenuList';

import { IFilterSingleSelectValue } from 'src/types';

import { IFilterComponentProps } from '../types';
import { StyledMenuItem, StyledDropdownContainer } from '../styles';

const SingleSelect = forwardRef<HTMLDivElement, IFilterComponentProps<'single_select'>>(
  ({ params, options, onChange }, ref) => {
    const [selection, setSelection] = useState<MaybeNull<IFilterSingleSelectValue>>(params ?? null);

    useEffect(() => onChange(selection as IFilterSingleSelectValue), [selection]);

    return (
      <StyledDropdownContainer ref={ref}>
        <MenuList disablePadding>
          {options.values.map(({ key, value }) => (
            <StyledMenuItem key={key} selected={key === selection} onClick={() => setSelection(key)}>
              {value}
            </StyledMenuItem>
          ))}
        </MenuList>
      </StyledDropdownContainer>
    );
  },
);

SingleSelect.displayName = 'SingleSelect';

export default memo(SingleSelect);
