import { Dayjs } from 'dayjs';
import { memo } from 'react';
import { PickersDayProps } from '@mui/x-date-pickers';

import { StyledPickersDay } from './styles';
import { isFirstDayInRange, isHighlightedDayInRange as isDayInRange, isLastDayInRange } from './utils';

export interface IDateRangeDayProps extends PickersDayProps<Dayjs> {
  days: Dayjs[];
  daysRange: Dayjs[];
  onDaysChange: (dates: Dayjs[]) => void;
  hoverDay: MaybeNull<Dayjs>;
}

const DateRangeDay = ({ day, days, onDaysChange, daysRange, hoverDay, ...props }: IDateRangeDayProps) => {
  const isHovered = day.isSame(hoverDay);
  const isInRange = isDayInRange(day, daysRange);
  const isFirstInRange = isFirstDayInRange(day, daysRange);
  const isLastInRange = isLastDayInRange(day, daysRange);

  const handleDaySelect = (day: Dayjs) => {
    if (days.length === 1 && days[0].isSame(day, 'day')) return;

    onDaysChange(days.length >= 2 ? [day] : [...days, day]);
  };

  return (
    <StyledPickersDay
      {...props}
      day={day}
      selected={false}
      $inRange={isInRange}
      $first={isFirstInRange}
      $last={isLastInRange}
      $highlight={isHovered || isFirstInRange || isLastInRange}
      onDaySelect={handleDaySelect}
    />
  );
};

export default memo(DateRangeDay) as typeof DateRangeDay;
