import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ButtonLink } from 'src/components/shared/Buttons';
import { RateCardForm, RateCardSubmitButton } from 'src/components/RateCardForm';
import { PageTitle, TitleButtons, PageContainer, Loading } from 'src/components/shared/Page';
import { IRateCardSchema, rateCardToSchema, schemaToRateCard } from 'src/schemas';
import { useFileUploadState } from 'src/effects/useFileUploadState';
import { useFileDownloadState } from 'src/effects/useFileDownloadState';
import { saveAsBlob } from 'src/utils/saveFile';
import makeRequest from 'src/effects/makeRequest';
import * as apiRateCard from 'src/api/rateCard';
import * as apiFile from 'src/api/file';
import * as apiLogistics from 'src/api/logistics';
import { ID } from 'src/types';

const useRateCardRequest = makeRequest(apiRateCard.getRateCard);
const useRateCardUpdateRequest = makeRequest(apiRateCard.updateRateCard);
const useLogisticsRequest = makeRequest(apiLogistics.getLogisticsProviders);
const useWarehousesRequest = makeRequest(apiLogistics.getWarehouses);
const useFileUploadRequest = makeRequest(apiFile.uploadFile);
const useFileDownloadRequest = makeRequest(apiFile.downloadFile);

export default function RateCardEditPage() {
  const { id } = useParams<{ id: string }>();
  const rateCardId = useMemo(() => Number.parseInt(id as string), [id]);

  const [rateCardState, setRateCardState] = useState<MaybeNull<IRateCardSchema>>(null);
  const [warehousesIsReady, setWarehousesIsReady] = useState<boolean>(false);
  const [logisticsIsReady, setLogisticsIsReady] = useState<boolean>(false);

  const rateCardFormId = 'rate-card-form';
  const navigate = useNavigate();

  const logisticsRequest = useLogisticsRequest([]);
  const logistics = logisticsRequest.loading ? null : logisticsRequest.data;
  const askLogistics = () => {
    if (logisticsRequest.loading || logisticsRequest.data) return;

    logisticsRequest.make();
  };

  const warehousesRequest = useWarehousesRequest([]);
  const warehouses = warehousesRequest.loading ? null : warehousesRequest.data;
  const askWarehouses = (logisticsId?: number) => {
    if (!logisticsId) warehousesRequest.cleanup();
    else {
      const isExist = warehousesRequest.data.some((item) => item.logistics_provider_id === logisticsId);

      if (!isExist) warehousesRequest.make(logisticsId);
    }
  };

  const rateCardUpdateRequest = useRateCardUpdateRequest(null);
  const handleSubmit = (rateCard: IRateCardSchema) => {
    rateCardUpdateRequest.make(rateCardId, schemaToRateCard(rateCard));
    rateCardUpdateRequest.onFulfill(() => navigate(`/settings/ratecard/${rateCardId}`));
    rateCardUpdateRequest.onReject(() => navigate('/error'));
  };

  const rateCardRequest = useRateCardRequest(null, true);

  const fileUploadState = useFileUploadState();
  const fileUploadRequest = useFileUploadRequest(null);
  const handleFileUpload = (file: File) => {
    fileUploadState.start(file.name);
    fileUploadRequest.make({ file, onUploadProgress: fileUploadState.updateProgress });
    fileUploadRequest.onReject(() => fileUploadState.updateStatus('failed'));
    fileUploadRequest.onFulfill((id) => {
      fileUploadState.updateId(id);
      fileUploadState.updateStatus('uploaded');
    });
  };

  const fileDownloadState = useFileDownloadState();
  const fileDownloadRequest = useFileDownloadRequest(null);
  const handleFileDownload = (id: ID) => {
    fileDownloadState.start(id, fileUploadState.state.name);
    fileDownloadRequest.make({ id, onDownloadProgress: fileDownloadState.updateProgress });
    fileDownloadRequest.onFulfill((blob) => {
      fileDownloadState.updateStatus('downloaded');
      saveAsBlob(blob, fileUploadState.state.name);
    });
    fileDownloadRequest.onReject(() => fileDownloadState.updateStatus('failed'));
  };

  const handleFileDelete = () => {
    fileUploadState.cleanup();
    fileDownloadState.cleanup();
  };

  useEffect(() => {
    rateCardRequest.make(rateCardId);
    rateCardRequest.onFulfill((rateCard) => {
      const fileInfo = rateCard.file_upload_id;
      const formData = rateCardToSchema(rateCard);

      if (fileInfo) fileUploadState.setUploadedState(fileInfo.id, fileInfo.name);

      logisticsRequest.make();
      logisticsRequest.onFulfill(() => setLogisticsIsReady(true));

      warehousesRequest.make(rateCard.logistics_provider_id.id);
      warehousesRequest.onFulfill(() => setWarehousesIsReady(true));

      setRateCardState(formData);
    });
  }, [rateCardId]);

  return (
    <>
      <PageTitle title={'Edit rate card'} description={'Edit your pick and pack rate card.'}>
        <TitleButtons align="left">
          <RateCardSubmitButton id={rateCardFormId} disabled={rateCardUpdateRequest.loading} />
          <ButtonLink title="Cancel" to={'/settings/ratecards'} variant="outlined" />
        </TitleButtons>
      </PageTitle>
      <PageContainer>
        {!!rateCardState && logisticsIsReady && warehousesIsReady ? (
          <RateCardForm
            id={rateCardFormId}
            logistics={logistics}
            warehouses={warehouses}
            formState={rateCardState}
            askLogistics={askLogistics}
            askWarehouses={askWarehouses}
            uploadState={fileUploadState.state}
            downloadState={fileDownloadState.state}
            onFileDelete={handleFileDelete}
            onFileUpload={handleFileUpload}
            onFileDownload={handleFileDownload}
            onSubmit={handleSubmit}
          />
        ) : (
          <Loading />
        )}
      </PageContainer>
    </>
  );
}
