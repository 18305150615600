import { memo } from 'react';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import { TextareaAutosizeProps } from '@mui/material/TextareaAutosize';

import { StyledCard, StyledEmailControl, StyledTextarea } from './styles';

export interface IEmailMessageProps {
  errorMessage?: Maybe<string>;
}

const EmailMessage = ({ errorMessage, ...props }: TextareaAutosizeProps & IEmailMessageProps) => (
  <StyledEmailControl error={!!errorMessage}>
    <StyledCard>
      <Typography variant="h3">Email Content</Typography>
      <StyledTextarea {...props} />
    </StyledCard>
    {!!errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
  </StyledEmailControl>
);

export default memo(EmailMessage);
