import { SERVER_CONFIG } from 'src/config/server';
import { authService } from 'src/services/AuthService';
import { getPromiseState } from 'src/utils/promiseUtils';
import { IRequestInterceptors, Request } from 'src/libs/Request';

class RequestInterceptors implements IRequestInterceptors {
  #tokenPromise = Promise.resolve();

  requestFulfill: IRequestInterceptors['requestFulfill'] = (_, request) => {
    request.headers.set('Authorization', `Bearer ${request.AUTH_TOKEN ? request.AUTH_TOKEN : authService.token.id}`);

    return request;
  };

  responseReject: IRequestInterceptors['responseReject'] = async (request, error) => {
    if (error?.response?.status !== 401) throw error;

    if (error.config?.TOKEN_INIT_RETRY) {
      authService.cleanup();

      throw error;
    }

    const tokenStatus = await getPromiseState(this.#tokenPromise);

    await (this.#tokenPromise = tokenStatus === 'pending' ? this.#tokenPromise : authService.refresh());

    return request.makeRequest({ ...error.config, TOKEN_INIT_RETRY: true });
  };
}

export type { IRequest } from 'src/libs/Request';
export { serializeQueryJson } from 'src/libs/Request';

export default new Request(SERVER_CONFIG.API_URL, new RequestInterceptors());
