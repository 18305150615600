import { memo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Divider from '@mui/material/Divider';

import { CreditEmailSchema, ICreditEmailSchema } from 'src/schemas';
import { FormText } from 'src/components/shared/Form';

import EmailMessage from './EmailMessage';
import { StyledEmailFields, StyledEmailForm } from './styles';

export interface IEmailFormProps {
  id: string;
  defaultValues: NestedPartial<ICreditEmailSchema>;
  onSubmit: (value: ICreditEmailSchema) => void;
}

const EmailForm = ({ id, defaultValues, onSubmit }: IEmailFormProps) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<ICreditEmailSchema>({
    defaultValues,
    resolver: yupResolver<ICreditEmailSchema>(CreditEmailSchema),
  });

  return (
    <StyledEmailForm id={id} onSubmit={handleSubmit(onSubmit)}>
      <Divider />
      <StyledEmailFields>
        <FormText label="3PL recipient email" control={control} name="email" errorMessage={errors?.email?.message} />
        <FormText label="3PL recipient name" control={control} name="name" errorMessage={errors?.name?.message} />
        <FormText label="Subject" control={control} name="subject" errorMessage={errors?.subject?.message} />
      </StyledEmailFields>
      <Controller
        name="message"
        control={control}
        render={({ field }) => (
          <EmailMessage
            id="message"
            value={field.value ?? ''}
            onChange={field.onChange}
            errorMessage={errors?.message?.message}
          />
        )}
      />
    </StyledEmailForm>
  );
};

export default memo(EmailForm);
