export type IBrand = {
  id: number;
  name: string;
  email: string;
  created_at: string;
  is_active: boolean;
  status: IBrandStatus;

  address1: string;
  address2: string;
  city: string;
  country: string;
  phone: string;
  net_potential_loss: number;
  net_actual_savings: number;
};

export type IBrandListItem = {
  id: number;
  name: string;
  email: string;
  created_at: string;
  status: IBrandStatus;
};

export type INewBrand = Pick<IBrand, 'status' | 'email' | 'name'>;

export const BrandStatus = {
  PENDING: 'PENDING' as const,
  ACTIVE: 'ACTIVE' as const,
  EXPIRED: 'EXPIRED' as const,
  DELETED: 'DELETED' as const,
} as const;

export type IBrandStatus = ValueOf<typeof BrandStatus>;

export const BrandSortBy = {
  BRAND_NAME: 'brand_name' as const,
} as const;

export type IBrandSortBy = ValueOf<typeof BrandSortBy>;
