import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';

import FileList, { IFileListProps } from './FileList';
import { StyledContent, StyledDialog, StyledTitle } from './styles';

export interface IRateCardFilesProps extends IFileListProps {
  onReload: () => void;
}

export default function RateCardFiles({ files, onReload, downloadState, onDownload }: IRateCardFilesProps) {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Dialog PaperComponent={StyledDialog} open={isOpen} onClose={handleClose}>
        <StyledTitle>
          <IconButton onClick={onReload}>
            <ReplayIcon />
          </IconButton>
          Files
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </StyledTitle>
        <StyledContent>
          <FileList files={files} downloadState={downloadState} onDownload={onDownload} />
        </StyledContent>
      </Dialog>
      <Button variant="outlined" onClick={handleOpen}>
        Files
      </Button>
    </>
  );
}
