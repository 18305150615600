import Button, { ButtonProps } from '@mui/material/Button';

export type IErrorButtonProps = Omit<ButtonProps, 'children' | 'sx'> & {
  title: string;
};

export const ErrorButton = ({ title, ...props }: IErrorButtonProps) => (
  <Button {...props} sx={{ color: 'error.main', ':hover': { color: 'error.main' } }}>
    {title}
  </Button>
);
