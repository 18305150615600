import { MouseEvent, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { ReconcileDataType } from 'src/types';
import { ButtonLink } from 'src/components/shared/Buttons';
import LinkNavigation from 'src/components/shared/LinkNavigation';
import ActionPanel from 'src/components/shared/ActionPanel';
import Pagination from 'src/components/shared/Pagination';
import { invoiceDetailsQuery } from 'src/components/Invoice';
import { ReconcileType } from 'src/components/InvoiceReconcile';
import { PageTitle, TitleButtonGroup, TitleButtons, PageContainer, Loading } from 'src/components/shared/Page';
import { CreditRequestButton } from 'src/pages/Invoices/CreditRequest';
import { UserRole, useAccess } from 'src/access';
import * as apiInvoice from 'src/api/invoice';
import makeRequest from 'src/effects/makeRequest';

import InvoiceSummary from './InvoiceSummary';
import ReconciliationDetails from './ReconciliationDetails';
import { ReconciliationProvider, useReconciliationContext } from './ReconciliationContext';

interface ILocationState {
  invoicesNames?: Maybe<string[]>;
  goBack?: boolean;
}

const useInvoiceRequest = makeRequest(apiInvoice.getInvoiceSummary, {
  effect: (invoice) => {
    invoice.unfavorable_errors_amount.data.forEach((row) => (row.name = JSON.parse(row.name).join(', ')));
    invoice.unfavorable_errors_cost.data.forEach((row) => (row.name = JSON.parse(row.name).join(', ')));

    return invoice;
  },
});

const Reconciliation = () => {
  const navigate = useNavigate();
  const { state: locationState }: { state: MaybeNull<ILocationState> } = useLocation();
  const { invoiceName, reconcileType, ordersPagination, ordersRequest } = useReconciliationContext();

  const isCreditAvailable = useAccess(UserRole.CUSTOMER_ADMIN, UserRole.IMPLENTIO_ADMIN);
  const invoiceRequest = useInvoiceRequest(null, true);
  const isCreditable = !!invoiceRequest.data?.summary.unfavorable_errors_count;

  const handleNavigateBack = (event: MouseEvent<HTMLElement>) => {
    if (!locationState?.goBack) return;

    event.preventDefault();
    navigate(-1);
  };

  useEffect(() => {
    if (!invoiceName) return;

    invoiceRequest.cleanup();
    invoiceRequest.make(invoiceName);
  }, [invoiceName]);

  if (!invoiceName) return <Navigate to={'/error'} />;

  return (
    <>
      <PageTitle title={'Reconciliation'}>
        <TitleButtonGroup>
          <TitleButtons align="left">
            <ButtonLink
              variant="text"
              title="Back to invoice detail"
              to={`/invoices/details?${invoiceDetailsQuery.getSearchString({ name: invoiceName })}`}
              onClick={handleNavigateBack}
            />
          </TitleButtons>
          <TitleButtons align="right">
            {locationState?.invoicesNames && (
              <LinkNavigation
                linkId={invoiceName}
                linkIds={locationState.invoicesNames}
                makeLink={(id) => `/invoice/${encodeURIComponent(id)}/reconcile`}
                makeSearch={() => ''}
                title={({ linkId }) => (
                  <>
                    <Typography variant="h4" sx={{ textTransform: 'initial' }}>
                      Invoice:
                    </Typography>
                    <Typography variant="body1">{linkId}</Typography>
                  </>
                )}
              />
            )}
            {isCreditAvailable && <CreditRequestButton name={invoiceName} active={isCreditable} />}
          </TitleButtons>
        </TitleButtonGroup>
      </PageTitle>
      <PageContainer>
        {invoiceRequest.loading ? (
          <Loading />
        ) : (
          <>
            <InvoiceSummary invoice={invoiceRequest.data} />
            <ActionPanel>
              <ReconcileType type={reconcileType.type} onChange={reconcileType.setType} />
              {reconcileType.type === ReconcileDataType.ORDERS && (
                <Pagination
                  loading={ordersRequest.loading}
                  count={ordersRequest.data?.page_info.page_count}
                  page={ordersPagination.page}
                  onChange={ordersPagination.setPage}
                />
              )}
            </ActionPanel>
            <ReconciliationDetails />
          </>
        )}
      </PageContainer>
    </>
  );
};

export default function ReconciliationPage() {
  return (
    <ReconciliationProvider>
      <Reconciliation />
    </ReconciliationProvider>
  );
}
