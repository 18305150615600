import { memo, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import Tooltip from '@mui/material/Tooltip';
import { Palette } from '@mui/material/styles';

import { IInvoiceStatus, InvoiceStatus } from 'src/types';
import { ArrowsRotateCircle, DashCircle, ExclamationCircle, EyeCircle } from 'src/components/Icons';

export interface IInvoiceStatusIconProps {
  status: MaybeNull<IInvoiceStatus>;
}

export const getInvoiceStatusLabel = (status: Maybe<IInvoiceStatus>) => {
  const labels = {
    [InvoiceStatus.REVIEWED]: 'Reviewed',
    [InvoiceStatus.UNREVIEWED]: 'Unreviewed',
    [InvoiceStatus.UNDER_REVIEW]: 'Under review',
    [InvoiceStatus.NO_REVIEW_REQUIRED]: 'No review required',
    [InvoiceStatus.PROCESSING]: 'Processing',
    [InvoiceStatus.LOAD_ERROR]: 'Load error',
    [InvoiceStatus.RECONCILED]: 'Reconciled',
  };

  return status ? labels[status] ?? 'Unknown' : 'Unknown';
};

export const getInvoiceStatusColor = (status: Maybe<IInvoiceStatus>, palette: Palette) => {
  const colors = {
    [InvoiceStatus.REVIEWED]: palette.success.main,
    [InvoiceStatus.UNDER_REVIEW]: palette.primary.main,
    [InvoiceStatus.PROCESSING]: palette.text.secondary,
    [InvoiceStatus.LOAD_ERROR]: palette.error.main,
    [InvoiceStatus.UNREVIEWED]: palette.text.secondary,
    [InvoiceStatus.RECONCILED]: palette.text.secondary,
    [InvoiceStatus.NO_REVIEW_REQUIRED]: palette.text.secondary,
  };

  return status ? colors[status] ?? palette.warning.main : palette.warning.main;
};

export const getInvoiceStatusIcon = (status: Maybe<IInvoiceStatus>) => {
  const icons = {
    [InvoiceStatus.REVIEWED]: EyeCircle,
    [InvoiceStatus.UNDER_REVIEW]: EyeCircle,
    [InvoiceStatus.PROCESSING]: ArrowsRotateCircle,
    [InvoiceStatus.LOAD_ERROR]: ExclamationCircle,
    [InvoiceStatus.UNREVIEWED]: EyeCircle,
    [InvoiceStatus.RECONCILED]: ArrowsRotateCircle,
    [InvoiceStatus.NO_REVIEW_REQUIRED]: DashCircle,
  };

  return status ? icons[status] ?? ExclamationCircle : ExclamationCircle;
};

const SLOT_PROPS = { tooltip: { sx: { marginBottom: '4px !important' } } };

const InvoiceStatusIcon = ({ status }: IInvoiceStatusIconProps) => {
  const { palette } = useTheme();

  const Icon = useMemo(() => getInvoiceStatusIcon(status), [status]);
  const color = useMemo(() => getInvoiceStatusColor(status, palette), [status, palette]);
  const label = useMemo(() => getInvoiceStatusLabel(status), [status]);

  return (
    <Tooltip title={label} placement="top-start" slotProps={SLOT_PROPS}>
      <Icon sx={{ color }} />
    </Tooltip>
  );
};

export default memo(InvoiceStatusIcon);
