import { PropsWithChildren, memo } from 'react';
import styled from '@emotion/styled';

import Sidebar from 'src/components/shared/Sidebar';

const StyledPage = styled.div`
  display: flex;
  overflow: overlay;
  min-height: 100vh;
  max-height: 100vh;
`;

const StyledPageContent = styled.section`
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 1200px;
`;

const StyledPageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(3)};
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const Page = memo(({ children }: PropsWithChildren) => <StyledPage>{children}</StyledPage>);
Page.displayName = 'Page';

export const PageContainer = memo(({ children }: PropsWithChildren) => (
  <StyledPageContainer>{children}</StyledPageContainer>
));
PageContainer.displayName = 'PageContainer';

export const PageContent = memo(({ children }: PropsWithChildren) => (
  <Page>
    <Sidebar />
    <StyledPageContent>{children}</StyledPageContent>
  </Page>
));
PageContent.displayName = 'PageContent';
