import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button, { ButtonProps } from '@mui/material/Button';

import { IWarehouse, ILogisticsProvider, ID } from 'src/types';
import { IRateCardSchema, RateCardSchema, getDefaultRateCard } from 'src/schemas';
import { UploadState } from 'src/effects/useFileUploadState';
import { DownloadState } from 'src/effects/useFileDownloadState';
import { useFileController } from 'src/components/shared/Form';

import { StyledForm } from './styles';
import { RateCardRules } from './RateCardRules';
import { RateCardRates } from './RateCardRates';
import { RateCardGeneral } from './RateCardGeneral';
import { RateCardBlock } from './RateCardBlock';

export interface IFormId {
  id: string;
}

export const RateCardSubmitButton = ({ id, ...props }: IFormId & ButtonProps) => (
  <Button {...props} form={id} type="submit" variant="contained">
    Save
  </Button>
);

export interface IRateCardProps extends IFormId {
  onSubmit: (rateCard: IRateCardSchema) => void;
  formState?: IRateCardSchema;

  logistics: MaybeNull<ILogisticsProvider[]>;
  askLogistics?: () => void;

  warehouses: MaybeNull<IWarehouse[]>;
  askWarehouses?: (logisticsId?: number) => void;

  onFileDelete: () => void;
  onFileUpload: (file: File) => void;
  onFileDownload: (id: ID) => void;

  downloadState: DownloadState;
  uploadState: UploadState;
}

export const RateCardForm = ({
  id,
  onSubmit,
  formState,
  logistics,
  askLogistics,
  warehouses,
  askWarehouses,
  onFileUpload,
  onFileDelete,
  onFileDownload,
  downloadState,
  uploadState,
}: IRateCardProps) => {
  const {
    control,
    formState: { errors },
    watch,
    unregister,
    getFieldState,
    handleSubmit,
  } = useForm<IRateCardSchema>({
    resolver: yupResolver<IRateCardSchema>(RateCardSchema),
    defaultValues: getDefaultRateCard(),
    values: formState,
  });

  const logisticsId = watch('logisticsId');
  const logisticsState = getFieldState('logisticsId');
  const logisticsIsDirty = logisticsState.isDirty;
  const logisticsIsClean = logisticsId == null && !logisticsIsDirty;

  const handleFileDownload = (id: ID) => onFileDownload(id);
  const handleFileUpload = (file: File) => onFileUpload(file);
  const handleFileDelete = () => {
    unregister('fileUploadId');
    onFileDelete();
  };

  const fileController = useFileController({
    uploadState,
    downloadState,
    handleFileDelete,
    handleFileUpload,
    handleFileDownload,
  });

  useEffect(() => askLogistics?.(), []);

  useEffect(() => {
    if (logisticsIsDirty) unregister('warehouseId');
  }, [logisticsIsDirty, logisticsId]);

  useEffect(
    () => askWarehouses?.(logisticsIsClean ? formState?.logisticsId : logisticsId),
    [logisticsIsClean, logisticsId, formState],
  );

  return (
    <StyledForm id={id} onSubmit={handleSubmit(onSubmit)}>
      <RateCardBlock
        title="General Information"
        content={(onError) => (
          <RateCardGeneral onError={onError} control={control} errors={errors} fileController={fileController} />
        )}
      />
      <RateCardBlock
        title="Rate Card Rules"
        content={(onError) => (
          <>
            <RateCardRules
              onError={onError}
              control={control}
              errors={errors}
              watch={watch}
              logistics={logistics}
              warehouses={warehouses}
            />
            <RateCardBlock
              title="Rate Card Form"
              content={(onError) => <RateCardRates onError={onError} control={control} errors={errors} watch={watch} />}
            />
          </>
        )}
      />
    </StyledForm>
  );
};
