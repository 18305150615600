import { memo } from 'react';

import { AuthContent, VerifyUserForm, MessageGreeting } from 'src/components/Auth';
import { IAuthChallengeVerifyEmail } from 'src/types';
import { IVerifyUserSchema } from 'src/schemas';
import makeRequest from 'src/effects/makeRequest';
import * as apiAuth from 'src/api/auth';

import { IStepComponent } from './types';

const useVerifyEmailRequest = makeRequest(apiAuth.verifyEmail, { silent: true });

const VerifyEmail: IStepComponent<IAuthChallengeVerifyEmail> = ({ challenge, onChallenge }) => {
  const verifyEmailRequest = useVerifyEmailRequest(null, false);

  const handleSubmit = (auth: IVerifyUserSchema) => {
    verifyEmailRequest.make({
      username: challenge.payload.email,
      ...auth,
    });
    verifyEmailRequest.onFulfill(onChallenge);
  };

  return (
    <AuthContent>
      <MessageGreeting title="Verify email" description="Please confirm this is your email" />
      <VerifyUserForm
        error={verifyEmailRequest.error?.detail}
        loading={verifyEmailRequest.loading}
        onSubmit={handleSubmit}
      />
    </AuthContent>
  );
};

export default memo(VerifyEmail) as typeof VerifyEmail;
