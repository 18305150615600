import styled from '@emotion/styled';
import Button from '@mui/material/Button';

import shouldForwardProp from 'src/utils/shouldForwardProp';

export const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 180px 1fr;
  max-width: 800px;
  grid-gap: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const StyledHeaderName = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledName = styled(StyledHeaderName)`
  height: ${({ theme }) => theme.spacing(5)};
`;

export const StyledInputGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(3)};

  & > * {
    flex: 1;
  }
`;

export const StyledHeader = styled.header`
  line-height: 1;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(4, 0)};
`;

export const StyledCollapseButton = styled(Button, { shouldForwardProp })<{ $collapseIn?: boolean }>`
  transition: ${({ theme }) => theme.transitions.create(['color'])};
  ${({ theme, $collapseIn }) => $collapseIn && `color: ${theme.palette.error.main};`}

  &::before {
    content: '${({ $collapseIn }) => ($collapseIn ? 'close' : 'edit')}';
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const StyledFormBlock = StyledForm.withComponent('section');
