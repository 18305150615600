import { IInvoiceServiceTotalData } from 'src/types';
import { TableFooterCell, TableFooterRow } from 'src/components/shared/Table';
import currencyFormatter from 'src/utils/currencyFormatter';

const formatUSD = currencyFormatter();

export interface IPickAndPackTotalProps {
  total: IInvoiceServiceTotalData;
}

export const PickAndPackTotal = ({ total }: IPickAndPackTotalProps) => (
  <TableFooterRow>
    <TableFooterCell ignoreWidth>Total</TableFooterCell>
    <TableFooterCell />
    <TableFooterCell />
    <TableFooterCell>{formatUSD(total.logistics_charges)}</TableFooterCell>
  </TableFooterRow>
);
