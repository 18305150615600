import { PropsWithChildren, createContext, useContext } from 'react';

import { IInvoice, IInvoiceServiceDataItem, IInvoiceServices, IInvoicesFilters } from 'src/types';
import { invoicesFilterQuery } from 'src/components/Invoice';
import { IQueryFilter } from 'src/components/shared/QueryFilter';
import useDataSorting from 'src/effects/useDataSorting';
import * as apiInvoice from 'src/api/invoice';
import makeRequest from 'src/effects/makeRequest';

import {
  useInvoiceDetailsControl,
  useInvoicesDateRangeControl,
  usePickAndPackControl,
  useServicesControl,
} from './invoiceDetailsControl';

export interface InvoiceDetailsContext {
  invoicesRequest: ReturnType<typeof useInvoicesRequest<IInvoice[]>>;
  invoicesFiltersRequest: ReturnType<typeof useInvoicesFiltersRequest<MaybeNull<IInvoicesFilters>>>;
  invoiceServicesRequest: ReturnType<typeof useInvoiceServicesRequest<MaybeNull<IInvoiceServices>>>;

  servicesControl: ReturnType<typeof usePickAndPackControl>;
  pickAndPackControl: ReturnType<typeof usePickAndPackControl>;
  invoiceDetailsControl: ReturnType<typeof useInvoiceDetailsControl>;

  invoicesFiltersControl: IQueryFilter<IInvoicesFilters>;
  invoicesDateRangeControl: ReturnType<typeof useInvoicesDateRangeControl>;

  summaryData: MaybeNull<IInvoiceServiceDataItem[]>;
  pickAndPackData: MaybeNull<IInvoiceServiceDataItem[]>;
}

const useInvoicesRequest = makeRequest(apiInvoice.getInvoices);
const useInvoicesFiltersRequest = makeRequest(apiInvoice.getInvoicesFilters);
const useInvoiceServicesRequest = makeRequest(apiInvoice.getInvoiceServices);

export const InvoiceDetailsContext = createContext<InvoiceDetailsContext>({} as InvoiceDetailsContext);

export const InvoiceDetailsProvider = ({ children }: PropsWithChildren) => {
  const invoicesRequest = useInvoicesRequest([], true);
  const invoicesFiltersRequest = useInvoicesFiltersRequest(null);
  const invoiceServicesRequest = useInvoiceServicesRequest(null, true);

  const servicesControl = useServicesControl();
  const pickAndPackControl = usePickAndPackControl();
  const invoiceDetailsControl = useInvoiceDetailsControl();

  const invoicesDateRangeControl = useInvoicesDateRangeControl();
  const invoicesFiltersControl = invoicesFilterQuery.useFilter(invoicesFiltersRequest.data);

  const pickAndPackData = useDataSorting(
    invoiceServicesRequest.data?.pnp_services.data ?? null,
    pickAndPackControl.params,
  );
  const summaryData = useDataSorting(
    invoiceServicesRequest.data?.summary_services.data ?? null,
    servicesControl.params,
  );

  return (
    <InvoiceDetailsContext.Provider
      value={{
        invoicesRequest,
        invoicesFiltersRequest,
        invoiceServicesRequest,

        servicesControl,
        pickAndPackControl,
        invoiceDetailsControl,

        invoicesFiltersControl,
        invoicesDateRangeControl,

        pickAndPackData,
        summaryData,
      }}>
      {children}
    </InvoiceDetailsContext.Provider>
  );
};

export const useInvoiceDetailsContext = () => useContext(InvoiceDetailsContext);
