import { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import { IRateCardRowActionProps, RateCardRow } from 'src/components/RateCardList';
import { IRowActionControl } from 'src/components/shared/Table';
import { ErrorButton } from 'src/components/shared/Buttons';
import { UserRole, useAccess } from 'src/access';

import { useRateCardContext } from './RateCardContext';

const RateCardRowAction = ({ rateCard }: IRateCardRowActionProps) => {
  const [rateCardData, updateRateCardData] = useState(rateCard);
  const isActionAvailable = useAccess(UserRole.CUSTOMER_ADMIN);
  const { approveRequest } = useRateCardContext();

  const navigate = useNavigate();

  const confirmRateCard = () => {
    approveRequest.make(rateCardData.id);
    approveRequest.onFulfill((approve) => {
      updateRateCardData({
        ...rateCardData,
        status: approve.status,
      });
    });
  };

  const editRateCard = () => navigate(`/settings/ratecard/${encodeURIComponent(rateCardData.id)}/edit`);

  const renderAction = ({ hidePopper }: IRowActionControl) => {
    const handleConfirm = () => {
      confirmRateCard();
      hidePopper();
    };

    const handleEdit = () => {
      hidePopper();
      editRateCard();
    };

    if (!isActionAvailable) return null;

    return (
      <ButtonGroup component={Paper} variant="popper">
        <Button onClick={handleConfirm} disabled={approveRequest.loading}>
          Confirm
        </Button>
        <Button onClick={handleEdit}>Edit</Button>
        <ErrorButton title="Cancel" onClick={hidePopper} />
      </ButtonGroup>
    );
  };

  return <RateCardRow rateCard={rateCardData} renderAction={renderAction} />;
};

export default memo(RateCardRowAction) as typeof RateCardRowAction;
