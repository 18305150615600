import { useCallback } from 'react';

import { TableHeaderRow, TableHeaderCellSort, TableLeadCell } from 'src/components/shared/Table';
import { IInvoiceErrorSortBy, ISortProps, InvoiceErrorSortBy, SortDirection } from 'src/types';

export const ErrorListHeader = ({ sort, onSort }: ISortProps<IInvoiceErrorSortBy>) => {
  const handleSortChange = useCallback(
    (fieldName: IInvoiceErrorSortBy) => {
      if (sort.by !== fieldName) {
        onSort({
          by: fieldName,
          order: SortDirection.DESC,
        });
      } else {
        onSort({
          ...sort,
          order: sort.order === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
        });
      }
    },
    [sort, onSort],
  );

  return (
    <TableHeaderRow>
      <TableLeadCell />
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === InvoiceErrorSortBy.error_name}
        onClick={() => handleSortChange(InvoiceErrorSortBy.error_name)}>
        Error type
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === InvoiceErrorSortBy.service}
        onClick={() => handleSortChange(InvoiceErrorSortBy.service)}>
        Services
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === InvoiceErrorSortBy.unfavorable_errors_count}
        onClick={() => handleSortChange(InvoiceErrorSortBy.unfavorable_errors_count)}>
        Errors (#)
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === InvoiceErrorSortBy.unfavorable_errors_dollars}
        onClick={() => handleSortChange(InvoiceErrorSortBy.unfavorable_errors_dollars)}>
        Errors ($)
      </TableHeaderCellSort>
    </TableHeaderRow>
  );
};
