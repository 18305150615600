import styled from '@emotion/styled';

export { StyledDescription, StyledCard } from '../CreditDialog/styles';

export const StyledErrorTypesHeader = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(2)};
`;
