import { memo, useCallback, useMemo, useRef, useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import { ErrorButton } from 'src/components/shared/Buttons';
import { TableCheckbox } from 'src/components/shared/Table';
import makeRequest from 'src/effects/makeRequest';
import * as apiAlert from 'src/api/alert';
import { IAlertCommon } from 'src/types';

import { StyledAlertAcknowledged, StyledAlertItem, StyledAlertContent, StyledAlertInfo } from './styles';
import { AlertLink, AlertTitle, AlertDescription, AlertDateTime } from './AlertUtils';

const useUpdateAlertRequest = makeRequest(apiAlert.acknowledgeAlert, { cancellable: false });

export interface IAlertProps {
  alert: IAlertCommon;
  onUpdate: (alert: IAlertCommon) => void;
  onClose: () => void;
}

const Alert = ({ alert, onUpdate, onClose }: IAlertProps) => {
  const [isOpen, setOpen] = useState(false);
  const popperRef = useRef<MaybeNull<HTMLButtonElement>>(null);
  const containerRef = useRef<MaybeNull<HTMLDivElement>>(null);

  const updateAlertRequest = useUpdateAlertRequest(null, false);

  const Description = useMemo(() => AlertDescription(alert), [alert]);
  const DateTime = useMemo(() => AlertDateTime(alert.sent_at_date), [alert.sent_at_date]);
  const Title = useMemo(() => AlertTitle(alert), []);
  const Link = useMemo(() => AlertLink(alert), [alert]);

  const hidePopper = () => setOpen(false);
  const togglePopper = () => setOpen(!isOpen);

  const updateAcknowledge = useCallback(
    (acknowledged: boolean) => {
      updateAlertRequest.make(alert.id, acknowledged);
      updateAlertRequest.onFulfill(() => onUpdate({ ...alert, acknowledged }));
    },
    [alert],
  );
  const handleLinkClick = () => {
    onClose();
    updateAcknowledge(true);
  };

  return (
    <StyledAlertItem ref={containerRef}>
      <ClickAwayListener onClickAway={hidePopper}>
        <IconButton size="small" onClick={togglePopper} ref={popperRef} sx={{ padding: 0 }} disableRipple>
          <TableCheckbox />
          <Popper
            transition
            placement="bottom-start"
            open={isOpen}
            anchorEl={popperRef.current}
            container={containerRef.current}
            sx={(theme) => ({ zIndex: theme.zIndex.tooltip, top: '8px!important' })}>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps}>
                <ButtonGroup component={Paper} variant="popper">
                  <Button
                    disabled={updateAlertRequest.loading || alert.acknowledged}
                    onClick={() => updateAcknowledge(true)}>
                    Mark as read
                  </Button>
                  <Button
                    disabled={updateAlertRequest.loading || !alert.acknowledged}
                    onClick={() => updateAcknowledge(false)}>
                    Mark as unread
                  </Button>
                  <ErrorButton title="Cancel" onClick={hidePopper} />
                </ButtonGroup>
              </Fade>
            )}
          </Popper>
        </IconButton>
      </ClickAwayListener>
      <StyledAlertContent>
        <StyledAlertInfo $align="left">
          <Title />
          <Description onClick={handleLinkClick} />
        </StyledAlertInfo>
        <StyledAlertInfo $align="right">
          <StyledAlertContent $justify="flex-end">
            <Link onClick={handleLinkClick} />
            <StyledAlertAcknowledged $acknowledged={alert.acknowledged} />
          </StyledAlertContent>
          <StyledAlertContent $justify="flex-end">
            <DateTime />
          </StyledAlertContent>
        </StyledAlertInfo>
      </StyledAlertContent>
    </StyledAlertItem>
  );
};

export default memo(Alert);
