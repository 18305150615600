import { Fragment, memo } from 'react';

import { IFilterType, IFiltersValues } from 'src/types';

import { IFilterOptions, IQueryFilter, IQueryParams } from './useQueryFilter';
import { CriterionNumber, DateRange, MultiSelect, SingleSelect } from './Filter';
import FilterListItem from './FilterListItem';
import { IFilterComponent } from './types';

export interface IFilterListProps<T extends IFilterOptions> {
  params: IQueryParams<T>;
  config: NonNullable<IQueryFilter<T>['options']>;
  order: IQueryFilter<T>['order'];
  onRemove: (key: keyof T) => void;
  onChange: (key: keyof T, value: IFiltersValues[IFilterType]) => void;
}

const FilterList = <T extends IFilterOptions>({ params, config, order, onRemove, onChange }: IFilterListProps<T>) => (
  <>
    {order.map((key) => {
      if (!config[key]) return <Fragment key={`${key.toString()}`} />;

      const { type, options } = config[key];

      if (type === 'criterion_number') {
        return (
          <FilterListItem
            key={`${key.toString()}`}
            type={type}
            options={options}
            params={params[key]}
            onRemove={() => onRemove(key)}
            onChange={(value) => onChange(key, value)}
            Component={CriterionNumber as IFilterComponent<IFilterType>}
          />
        );
      }

      if (type === 'multi_select') {
        return (
          <FilterListItem
            key={`${key.toString()}`}
            type={type}
            options={options}
            params={params[key]}
            onRemove={() => onRemove(key)}
            onChange={(value) => onChange(key, value)}
            Component={MultiSelect as IFilterComponent<IFilterType>}
          />
        );
      }

      if (type === 'single_select') {
        return (
          <FilterListItem
            key={`${key.toString()}`}
            type={type}
            options={options}
            params={params[key]}
            onRemove={() => onRemove(key)}
            onChange={(value) => onChange(key, value)}
            Component={SingleSelect as IFilterComponent<IFilterType>}
          />
        );
      }

      if (type === 'date_range') {
        return (
          <FilterListItem
            key={`${key.toString()}`}
            type={type}
            options={options}
            params={params[key]}
            onRemove={() => onRemove(key)}
            onChange={(value) => onChange(key, value)}
            Component={DateRange as IFilterComponent<IFilterType>}
          />
        );
      }

      return <Fragment key={`${key.toString()}`} />;
    })}
  </>
);

export default memo(FilterList) as typeof FilterList;
