import { useCallback, useMemo, useState } from 'react';

import { CreditStepType, ICreditStepType, ICreditError } from 'src/types';
import currencyFormatter from 'src/utils/currencyFormatter';
import dateFormatter from 'src/utils/dateFormatter';

export type IDialogControl = ReturnType<typeof useDialogControl>;

export const useDialogControl = () => {
  const [isVisible, setVisibility] = useState(false);

  const showDialog = () => setVisibility(true);
  const hideDialog = () => setVisibility(false);

  return {
    isVisible,
    hideDialog,
    showDialog,
  };
};

export type IStepControl = ReturnType<typeof useStepControl>;

export const useStepControl = () => {
  const [activeStep, setActiveStep] = useState<ICreditStepType>(CreditStepType.Errors);

  const goToTags = useCallback(() => setActiveStep(CreditStepType.Tags), []);
  const goToEmail = useCallback(() => setActiveStep(CreditStepType.Email), []);
  const goToErrors = useCallback(() => setActiveStep(CreditStepType.Errors), []);

  const isTagsStep = useMemo(() => activeStep === CreditStepType.Tags, [activeStep]);
  const isEmailStep = useMemo(() => activeStep === CreditStepType.Email, [activeStep]);
  const isErrorsStep = useMemo(() => activeStep === CreditStepType.Errors, [activeStep]);

  return {
    activeStep,
    goToTags,
    goToEmail,
    goToErrors,
    isTagsStep,
    isEmailStep,
    isErrorsStep,
  };
};

export type ICreditErrorSelection = ReturnType<typeof useCreditErrorSelection>;

export const useCreditErrorSelection = () => {
  const [selection, setSelection] = useState(() => new Set<string>());

  return { selection, setSelection };
};

export const useCreditErrorsTotal = (selectId: keyof ICreditError, selection: Set<string>, payload: ICreditError[]) =>
  useMemo(
    () =>
      payload
        .filter((item) => selection.has(item[selectId] as string))
        .reduce(
          (total, item) => {
            total.unfavorable_errors_count += item.unfavorable_errors_count;
            total.unfavorable_errors_dollars += item.unfavorable_errors_dollars;

            return total;
          },
          {
            unfavorable_errors_count: 0,
            unfavorable_errors_dollars: 0,
          },
        ),
    [selection, payload],
  );

interface IEmailTemplateProps {
  senderName: string;
  recipientName: string;
  invoiceName: string;
  periodStart: string;
  periodEnd: string;
  errorsDollars: number;
}

const formatEmailCurrency = currencyFormatter();
const formatEmailDate = dateFormatter();

export const createEmailTemplate = ({
  senderName,
  recipientName,
  invoiceName,
  periodStart,
  periodEnd,
  errorsDollars,
}: IEmailTemplateProps) => `${recipientName}:

We have completed our review of Invoice ${invoiceName} for the service period from ${formatEmailDate(
  periodStart,
)} through ${formatEmailDate(periodEnd)} and are requesting a credit for ${formatEmailCurrency(errorsDollars)}.

During our review, we found the following attached billing errors that we are submitting to you as a request for credit.

Please reach out to me with any questions.

Sincerely,
${senderName}`;

export const makeErrorFilters = (codes: string[]) => ({ STATUS: 'ACCEPT', ERROR_CODE: codes });
