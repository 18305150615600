import { StrictMode } from 'react';

import AuthProvider from 'src/providers/AuthProvider';
import IntercomProvider from 'src/providers/IntercomProvider';
import SnackbarProvider from 'src/providers/SnackbarProvider';
import PickerProvider from 'src/providers/PickerProvider';
import ThemeProvider from 'src/providers/ThemeProvider';
import QueryProvider from 'src/providers/QueryProvider';
import { Router, Routes } from 'src/components/Router';
import { ROUTES_CONFIG } from 'src/config/routes';

const App = () => (
  <StrictMode>
    <ThemeProvider>
      <SnackbarProvider>
        <PickerProvider>
          <AuthProvider>
            <Router>
              <QueryProvider>
                <IntercomProvider>
                  <Routes routes={ROUTES_CONFIG} />
                </IntercomProvider>
              </QueryProvider>
            </Router>
          </AuthProvider>
        </PickerProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </StrictMode>
);

export default App;
