import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import MenuItem from '@mui/material/MenuItem';
import Button, { ButtonProps } from '@mui/material/Button';

import { NewUserSchema, INewUserSchema } from 'src/schemas';
import { UserRole, IUserRole, IBrand } from 'src/types';
import {
  FormAutocomplete,
  FormFields,
  FormInput,
  FormPassword,
  FormSelect,
  FormText,
} from 'src/components/shared/Form';

export interface IFormId {
  id: string;
}

const ROLE_NAMES = {
  [UserRole.IMPLENTIO_ADMIN]: 'Implentio admin',
  [UserRole.CUSTOMER_ADMIN]: 'Customer admin',
  [UserRole.CUSTOMER_USER]: 'Customer user',
};

const getRoleName = (role: IUserRole) => ROLE_NAMES[role];

export interface INewUserFormProps extends IFormId {
  brands: IBrand[];
  onSubmit: (user: INewUserSchema) => void;
}

export const NewUserSubmitButton = ({ id, ...props }: IFormId & ButtonProps) => (
  <Button {...props} form={id} type="submit" variant="contained">
    Create user
  </Button>
);

export const NewUserForm = ({ id, brands, onSubmit }: INewUserFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<INewUserSchema>({
    resolver: yupResolver(NewUserSchema),
  });

  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <FormFields>
        <FormInput>
          <FormText control={control} name="name" label="Name" errorMessage={errors?.name?.message} />
        </FormInput>
        <FormInput>
          <FormText
            control={control}
            name="family_name"
            label="Family name"
            errorMessage={errors?.family_name?.message}
          />
        </FormInput>
        <FormInput>
          <FormText control={control} name="username" label="Email" errorMessage={errors?.username?.message} />
        </FormInput>
        <FormInput>
          <FormPassword name="password" label="Password" control={control} errorMessage={errors?.password?.message} />
        </FormInput>
        <FormInput>
          <FormAutocomplete
            control={control}
            label="Company"
            name="company_id"
            options={brands}
            errorMessage={errors.company_id?.message}
            onChange={(company) => company?.id}
            isOptionsEqual={(prev, next) => prev.id === next.id}
            getLabel={(company) => company.name}
            getValue={(value) => brands.find(({ id }) => id === value) ?? null}
            renderValue={(props, { id, name }) => (
              <MenuItem {...props} key={id}>
                {name}
              </MenuItem>
            )}
          />
        </FormInput>
        <FormInput>
          <FormSelect
            name="role"
            label="Role"
            control={control}
            errorMessage={errors?.role?.message}
            options={Object.values<IUserRole>(UserRole)}
            optionValue={getRoleName}
          />
        </FormInput>
      </FormFields>
    </form>
  );
};
