import {
  IAnomaliesAggregation,
  IAnomalyDetails,
  IAnomalyOrder,
  IAnomalyOrdersFilters,
  ID,
  ISortDirection,
} from 'src/types';

import request, { IRequest } from './requestPrivate';

export const getAnomaliesFilters = (): IRequest<IAnomalyOrdersFilters> => {
  console.log('GET:Anomalies filters');

  return request.mock({
    ANOMALY_STATUS: {
      type: 'multi_select',
      options: {
        label: 'Anomaly status',
        values: [
          {
            key: 'UNREVIEWED',
            value: 'Unreviewed',
          },
          {
            key: 'ACCEPTED',
            value: 'Accepted',
          },
          {
            key: 'REJECTED',
            value: 'Rejected',
          },
        ],
      },
      value: null,
    },
    INVOICE_NAME: {
      type: 'single_select',
      options: {
        label: 'Invoice #',
        values: [
          {
            key: 'I-12311',
            value: 'I-12311',
          },
          {
            key: 'I-12312',
            value: 'I-12312',
          },
          {
            key: 'I-12313',
            value: 'I-12313',
          },
        ],
      },
      value: null,
    },
    ANOMALY_TYPE: {
      type: 'single_select',
      options: {
        label: 'Anomaly type',
        values: [
          {
            key: 'ACTUAL_WEIGHT',
            value: 'Weight',
          },
          {
            key: 'DIM_WEIGHT',
            value: 'Dim Weight',
          },
          {
            key: 'TOTAL_ACCESSORIALS_COST',
            value: 'Accessorials',
          },
          {
            key: 'TOTAL_COST',
            value: 'Freight cost',
          },
          {
            key: 'ZONE',
            value: 'Zone',
          },
        ],
      },
      value: null,
    },
  });
};

export const getAnomalyOrders = (
  sorting: { by: string; order: ISortDirection },
  filters: object,
): IRequest<IAnomalyOrder[]> => {
  console.log('GET:Anomalies', sorting, filters);

  return request.mock([
    {
      anomaly_id: 1,
      order_number: '#121',
      anomaly_status: 'UNREVIEWED',
      ship_date: '2023-09-10T10:10:10.000Z',
      invoice_name: 'I-12311',
      anomaly_name: 'Weight',
    },
    {
      anomaly_id: 2,
      order_number: '#122',
      anomaly_status: 'ACCEPTED',
      ship_date: '2023-10-20T10:10:10.000Z',
      invoice_name: 'I-12312',
      anomaly_name: 'Dim Weight',
    },
    {
      anomaly_id: 3,
      order_number: '#123',
      anomaly_status: 'REJECTED',
      ship_date: '2023-10-30T10:10:10.000Z',
      invoice_name: 'I-12313',
      anomaly_name: 'Zone',
    },
  ]);
};

export const getAnomalyDetails = (id: ID): IRequest<IAnomalyDetails> => {
  console.log('GET:Anomaly details', id);

  return request.mock({
    anomaly: {
      anomaly_id: 1,
      actual_weight: 444,
      actual_weight_unit: 'lbs',
      anomaly_type: 'DIM_WEIGHT',
      dim_weight: 777,
      dim_weight_unit: 'lbs',
      distance: 666,
      distance_unit: 'mi.',
      order_number: '#123',
      total_accessorials_cost: 789456,
      total_cost: 12345,
      zone: 5,
    },
    comparison: [
      {
        actual_weight: 444,
        actual_weight_unit: 'lbs',
        dim_weight: 555,
        dim_weight_unit: 'lbs',
        distance: 666,
        distance_unit: 'mi.',
        order_number: '#121',
        total_accessorials_cost: 789456,
        total_cost: 12345,
        zone: 5,
      },
      {
        actual_weight: 444,
        actual_weight_unit: 'lbs',
        dim_weight: 555,
        dim_weight_unit: 'lbs',
        distance: 666,
        distance_unit: 'mi.',
        order_number: '#122',
        total_accessorials_cost: 789456,
        total_cost: 12345,
        zone: 5,
      },
      {
        actual_weight: 444,
        actual_weight_unit: 'lbs',
        dim_weight: 555,
        dim_weight_unit: 'lbs',
        distance: 666,
        distance_unit: 'mi.',
        order_number: '#124',
        total_accessorials_cost: 789456,
        total_cost: 12345,
        zone: 5,
      },
      {
        actual_weight: 444,
        actual_weight_unit: 'lbs',
        dim_weight: 555,
        dim_weight_unit: 'lbs',
        distance: 666,
        distance_unit: 'mi.',
        order_number: '#125',
        total_accessorials_cost: 789456,
        total_cost: 12345,
        zone: 5,
      },
    ],
    total: {
      anomaly_status: 'ACCEPTED',
      carrier: 'Fedex',
      service_level: 'Fedex Ground',
      sku_count: 11,
    },
  });
};

export const getAnomalies = (): IRequest<IAnomaliesAggregation> => {
  console.log('GET:Anomalies aggregation');

  return request.mock({
    total_type_chart: [
      {
        name: 'Weight',
        value: 1,
      },
      {
        name: 'Dim Weight',
        value: 1,
      },
      {
        name: 'Zone',
        value: 1,
      },
    ],
    total_count_chart: [
      {
        date: '2023-01-01T00:00:00',
        value: 0,
      },
      {
        date: '2023-02-01T00:00:00',
        value: 0,
      },
      {
        date: '2023-03-01T00:00:00',
        value: 0,
      },
      {
        date: '2023-04-01T00:00:00',
        value: 0,
      },
      {
        date: '2023-05-01T00:00:00',
        value: 0,
      },
      {
        date: '2023-06-01T00:00:00',
        value: 0,
      },
      {
        date: '2023-07-01T00:00:00',
        value: 0,
      },
      {
        date: '2023-08-01T00:00:00',
        value: 0,
      },
      {
        date: '2023-09-01T00:00:00',
        value: 1,
      },
      {
        date: '2023-10-01T00:00:00',
        value: 2,
      },
    ],
    total_status: {
      statuses: [
        {
          status: 'ACCEPTED',
          value: 1,
        },
        {
          status: 'REJECTED',
          value: 1,
        },
        {
          status: 'UNREVIEWED',
          value: 1,
        },
      ],
      total: 3,
    },
  });
};
