import { memo, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { Palette } from '@mui/material/styles';

import { AnomalyStatus, IAnomalyStatus } from 'src/types';
import StatusChip from 'src/components/shared/StatusChip';

export interface IAnomalyStatusChipProps {
  status: MaybeNull<IAnomalyStatus>;
}

export const getAnomalyStatusLabel = (status: Maybe<IAnomalyStatus>) => {
  const labels = {
    [AnomalyStatus.ACCEPTED]: 'Accepted',
    [AnomalyStatus.REJECTED]: 'Rejected',
    [AnomalyStatus.UNREVIEWED]: 'Unreviewed',
  };

  return status ? labels[status] ?? 'Unknown' : 'Unknown';
};

export const getAnomalyStatusColor = (status: Maybe<IAnomalyStatus>, palette: Palette) => {
  const colors = {
    [AnomalyStatus.ACCEPTED]: palette.success.main,
    [AnomalyStatus.REJECTED]: palette.error.main,
    [AnomalyStatus.UNREVIEWED]: palette.text.secondary,
  };

  return status ? colors[status] ?? palette.text.disabled : palette.text.disabled;
};

const AnomalyStatusChip = ({ status }: IAnomalyStatusChipProps) => {
  const { palette } = useTheme();

  const color = useMemo(() => getAnomalyStatusColor(status, palette), [status, palette]);
  const label = useMemo(() => getAnomalyStatusLabel(status), [status]);

  return <StatusChip label={label} color={color} />;
};

export default memo(AnomalyStatusChip);
