import * as yup from 'yup';

export const CreditEmailSchema = yup.object().shape({
  name: yup.string().required().label('3PL recipient name'),
  email: yup.string().required().email().label('3PL recipient email'),
  subject: yup.string().required().label('Subject'),
  message: yup.string().required().label('Message'),
});

export type ICreditEmailSchema = yup.InferType<typeof CreditEmailSchema>;
