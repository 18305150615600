import axios, {
  InternalAxiosRequestConfig,
  AxiosInterceptorManager,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosInstance,
  AxiosHeaders,
  AxiosError,
} from 'axios';

export interface IRequestPromise<TSuccess, TError = unknown> extends Promise<TSuccess> {
  then<TSuccessResult = TSuccess, TErrorResult = never>(
    onfulfilled?: Maybe<(value: TSuccess) => TSuccessResult | PromiseLike<TSuccessResult>>,
    onrejected?: Maybe<(reason: TError) => TErrorResult | PromiseLike<TErrorResult>>,
  ): Promise<TSuccessResult | TErrorResult>;

  catch<TResult = never>(
    onrejected?: Maybe<(reason: TError) => TResult | PromiseLike<TResult>>,
  ): Promise<TSuccess | TResult>;
}

export type IResponse<R, E = unknown> = IRequestPromise<Omit<AxiosResponse<R>, 'config' | 'statusText'>, E>;

export type IRequest<R, E = unknown> = {
  make: () => IResponse<R, E>;
  controller: AbortController;
};

export type IRequestConfig = Omit<AxiosRequestConfig, 'method' | 'url' | 'params' | 'data'> & {
  AUTH_TOKEN?: Maybe<string>;
};

type IRequestInterceptor = Parameters<AxiosInterceptorManager<InternalAxiosRequestConfig & IRequestConfig>['use']>;
type IResponseInterceptor = Parameters<AxiosInterceptorManager<AxiosResponse>['use']>;

type IRequestFulfill = IRequestInterceptor[0];
export type IRequestFulfillCallback = (
  request: Request,
  ...args: Parameters<NonNullable<IRequestFulfill>>
) => ReturnType<NonNullable<IRequestFulfill>>;

type IRequestReject = IRequestInterceptor[1];
export type IRequestRejectCallback = (
  request: Request,
  ...args: Parameters<NonNullable<IRequestReject>>
) => ReturnType<NonNullable<IRequestReject>>;

type IResponseFulfill = IResponseInterceptor[0];
export type IResponseFulfillCallback = (
  request: Request,
  ...args: Parameters<NonNullable<IResponseFulfill>>
) => ReturnType<NonNullable<IResponseFulfill>>;

type IResponseReject = IResponseInterceptor[1];
export type IResponseRejectCallback = (
  request: Request,
  ...args: Parameters<NonNullable<IResponseReject>>
) => ReturnType<NonNullable<IResponseReject>>;

export interface IRequestInterceptors {
  requestFulfill?: IRequestFulfillCallback;
  requestReject?: IRequestRejectCallback;
  responseFulfill?: IResponseFulfillCallback;
  responseReject?: IResponseRejectCallback;
}

export class Request {
  #axios: AxiosInstance;

  constructor(
    baseURL: string,
    { requestFulfill, requestReject, responseFulfill, responseReject }: IRequestInterceptors = {},
  ) {
    this.#axios = axios.create({
      baseURL,
      responseType: 'json',
      withCredentials: true,
    });

    this.#axios.interceptors.request.use(
      requestFulfill ? (...args) => requestFulfill(this, ...args) : null,
      requestReject ? (...args) => requestReject(this, ...args) : null,
    );
    this.#axios.interceptors.response.use(
      responseFulfill ? (...args) => responseFulfill(this, ...args) : null,
      responseReject ? (...args) => responseReject(this, ...args) : null,
    );
  }

  public async makeRequest<R, E = unknown>(options: AxiosRequestConfig) {
    try {
      return await this.#axios<R>(options);
    } catch (error) {
      return Promise.reject((error as AxiosError<E>).response?.data);
    }
  }

  public buildRequest<R, E>(options: AxiosRequestConfig): IRequest<R, E> {
    const controller = new AbortController();

    const make = () =>
      this.makeRequest<R, E>({
        ...options,
        signal: controller.signal,
      });

    return {
      make,
      controller,
    };
  }

  public get<R, E = unknown, P = unknown>(url: string, params?: P, config?: IRequestConfig): IRequest<R, E> {
    return this.buildRequest({
      ...config,
      method: 'GET',
      url,
      params,
    });
  }

  public post<R, E = unknown, D = unknown, P = unknown>(
    url: string,
    data?: D,
    params?: P,
    config?: IRequestConfig,
  ): IRequest<R, E> {
    return this.buildRequest({
      ...config,
      method: 'POST',
      url,
      params,
      data,
    });
  }

  public put<R, E = unknown, D = unknown, P = unknown>(
    url: string,
    data?: D,
    params?: P,
    config?: IRequestConfig,
  ): IRequest<R, E> {
    return this.buildRequest({
      ...config,
      method: 'PUT',
      url,
      params,
      data,
    });
  }

  public patch<R, E = unknown, D = unknown, P = unknown>(
    url: string,
    data?: D,
    params?: P,
    config?: IRequestConfig,
  ): IRequest<R, E> {
    return this.buildRequest({
      ...config,
      method: 'PATCH',
      url,
      params,
      data,
    });
  }

  public delete<R, E = unknown, D = unknown, P = unknown>(
    url: string,
    data?: D,
    params?: P,
    config?: IRequestConfig,
  ): IRequest<R, E> {
    return this.buildRequest({
      ...config,
      method: 'DELETE',
      url,
      params,
      data,
    });
  }

  public mock<R, E = unknown>(
    data: R,
    status = 200,
    validateStatus = (status: number) => status >= 200 && status < 300,
  ): IRequest<R, E> {
    const headers = new AxiosHeaders();
    const controller = new AbortController();
    const promosify = (validateStatus(status) ? Promise.resolve : Promise.reject).bind(Promise);

    const make = () =>
      promosify({
        data,
        headers,
        status,
      });

    return { controller, make };
  }
}

export const serializeQueryJson = (_key: string, value: unknown): unknown => {
  switch (true) {
    case Array.isArray(value):
      return (value as unknown[]).filter((value) => value != null);
    case value != null:
      return value;
  }
};
