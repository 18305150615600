import { PropsWithChildren, createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { IInvoiceErrorTotal, IInvoiceOrders, IInvoiceOrdersFilters } from 'src/types';
import makeRequest from 'src/effects/makeRequest';
import * as apiInvoice from 'src/api/invoice';

import { useReconcileType } from './reconciliationControl';
import { useErrorsDataProcessor, useErrorsFilters, useErrorsSorting } from './invoiceErrorsControl';
import { useOrdersPagination, useOrderParams, useOrdersSorting, useOrdersFilters } from './invoiceOrdersControl';

export interface IReconciliationContext {
  invoiceName: string;
  reconcileType: ReturnType<typeof useReconcileType>;

  errorsRequest: ReturnType<typeof useErrorsRequest<MaybeNull<IInvoiceErrorTotal>>>;

  errorsSorting: ReturnType<typeof useErrorsSorting>;
  errorsFilters: ReturnType<typeof useErrorsFilters>;
  errorsData: MaybeNull<IInvoiceErrorTotal>;

  ordersRequest: ReturnType<typeof useOrdersRequest<MaybeNull<IInvoiceOrders>>>;
  ordersFiltersRequest: ReturnType<
    typeof useOrdersFiltersRequest<MaybeNull<Omit<IInvoiceOrdersFilters, 'ERROR_CODE'>>>
  >;

  ordersPagination: ReturnType<typeof useOrdersPagination>;
  ordersSorting: ReturnType<typeof useOrdersSorting>;
  ordersFilters: ReturnType<typeof useOrdersFilters>;
  ordersParams: ReturnType<typeof useOrderParams>;
}

const useErrorsRequest = makeRequest(apiInvoice.getInvoiceErrors);
const useOrdersRequest = makeRequest(apiInvoice.getInvoiceOrders);
const useOrdersFiltersRequest = makeRequest(apiInvoice.getInvoiceOrdersFilters);

export const ReconciliationContext = createContext<IReconciliationContext>({} as IReconciliationContext);

export const ReconciliationProvider = ({ children }: PropsWithChildren) => {
  const { name: invoiceName } = useParams<{ name: string }>();
  const reconcileType = useReconcileType();

  const errorsRequest = useErrorsRequest(null, true);

  const errorsSorting = useErrorsSorting();
  const errorsFilters = useErrorsFilters(errorsRequest.data);
  const errorsData = useErrorsDataProcessor({
    data: errorsRequest.data,
    filterConfig: errorsFilters.options,
    filterParams: errorsFilters.params,
    sortParams: errorsSorting.params,
  });

  const ordersRequest = useOrdersRequest(null, true);
  const ordersFiltersRequest = useOrdersFiltersRequest(null, true);

  const ordersPagination = useOrdersPagination();
  const ordersSorting = useOrdersSorting();
  const ordersFilters = useOrdersFilters(ordersFiltersRequest.data, errorsRequest.data);
  const ordersParams = useOrderParams(ordersPagination, ordersSorting, ordersFilters);

  if (!invoiceName) return null;

  return (
    <ReconciliationContext.Provider
      value={{
        invoiceName,
        reconcileType,

        errorsRequest,

        errorsSorting,
        errorsFilters,
        errorsData,

        ordersRequest,
        ordersFiltersRequest,

        ordersPagination,
        ordersSorting,
        ordersFilters,
        ordersParams,
      }}>
      {children}
    </ReconciliationContext.Provider>
  );
};

export const useReconciliationContext = () => useContext(ReconciliationContext);
