import dayjs from 'dayjs';

import {
  FilterType,
  IFiltersValues,
  IFilterDateRangeValue,
  IFilterMultiSelectValue,
  IFilterSingleSelectValue,
  IFilterCriterionNumberValue,
} from 'src/types';

export type IFilterExpression<D extends object> = (
  key: keyof D,
  filterValue: ValueOf<IFiltersValues>,
) => (data: D[]) => D[];

const singleSelectFilter =
  <D extends object>(key: keyof D, filterValue: IFilterSingleSelectValue) =>
  (data: D[]) =>
    data.filter((item) => item[key] === filterValue);

const criterionNumberFilter =
  <D extends object>(key: keyof D, filterValue: IFilterCriterionNumberValue) =>
  (data: D[]) =>
    data.filter((item) => {
      switch (filterValue.criterion) {
        case 'gt':
          return (item[key] as number) > filterValue.number;
        case 'lt':
          return (item[key] as number) < filterValue.number;
        case 'eq':
          return item[key] == filterValue.number;
        default:
          return false;
      }
    });

const dateRangeFilter =
  <D extends object>(key: keyof D, filterValue: IFilterDateRangeValue) =>
  (data: D[]) =>
    data.filter((item) => {
      const value = dayjs(item[key] as string);
      const from = dayjs(filterValue.from);
      const to = dayjs(filterValue.to);

      return value.isSameOrAfter(from) && value.isSameOrBefore(to);
    });

const multiSelectFilter =
  <D extends object>(key: keyof D, filterValues: IFilterMultiSelectValue) =>
  (data: D[]) =>
    data.filter((item) =>
      filterValues.some((filterValue) => (item[key] as IFilterMultiSelectValue).includes(filterValue)),
    );

const expressions = {
  [FilterType.DateRange]: dateRangeFilter,
  [FilterType.MultiSelect]: multiSelectFilter,
  [FilterType.SingleSelect]: singleSelectFilter,
  [FilterType.CriterionNumber]: criterionNumberFilter,
};

export default expressions;
