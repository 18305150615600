import { memo } from 'react';
import Button from '@mui/material/Button';

import { CreditRequestProvider, ICreditRequestProviderProps, useCreditRequestContext } from './CreditRequestContext';
import CreditRequestDialog from './CreditRequestDialog';

export interface ICreditRequestProps {
  invoiceName: string;
  isActive: boolean;
}

const CreditRequest = () => {
  const { isActive, dialogControl } = useCreditRequestContext();

  return (
    <>
      <Button variant="contained" disabled={!isActive} onClick={dialogControl.showDialog}>
        Request credits
      </Button>
      <CreditRequestDialog />
    </>
  );
};

const CreditRequestButton = (props: ICreditRequestProviderProps) => (
  <CreditRequestProvider {...props}>
    <CreditRequest />
  </CreditRequestProvider>
);

export default memo(CreditRequestButton) as typeof CreditRequestButton;
