import { ThemedComponent } from '@mui/material/styles';

export const MuiFormHelperText: ThemedComponent<'MuiFormHelperText'> = {
  styleOverrides: {
    root: ({ theme }) => ({
      margin: 0,
      fontSize: 12,
      textAlign: 'right',
      color: theme.palette.text.secondary,
      '&.Mui-error': {
        color: theme.palette.error.main,
      },
    }),
  },
};

export const MuiFormControl: ThemedComponent<'MuiFormControl'> = {
  defaultProps: {
    size: 'medium',
  },
  variants: [
    {
      props: { variant: 'filled' },
      style: ({ theme }) => ({
        gap: theme.spacing(0.5),
        '& .MuiFormLabel-root': {
          color: theme.palette.text.primary,
          '&.Mui-error': {
            color: theme.palette.error.main,
          },
          '&.Mui-error.Mui-focused': {
            color: theme.palette.error.main,
          },
          '&.Mui-focused': {
            color: theme.palette.text.primary,
          },
        },
      }),
    },
  ],
};
