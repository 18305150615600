import {
  IAuthChallenge,
  IAuthLogin,
  IAuthForgotPassword,
  IUserInfo,
  IAuthResetPassword,
  IAuthVerifyEmail,
  IAuthError,
} from 'src/types';

import request from './requestPublic';

export const getUserInfo = (token?: string) => request.get<IUserInfo>('/userinfo', null, { AUTH_TOKEN: token });

export const login = (credentials: IAuthLogin) => request.post<IAuthChallenge, IAuthError>('/auth/login', credentials);

export const forgotPassword = (credentials: IAuthForgotPassword) =>
  request.post<IAuthChallenge, IAuthError>('/auth/forgot-password', credentials);

export const resetPassword = (credentials: IAuthResetPassword) =>
  request.post<IAuthChallenge, IAuthError>('/auth/reset-password', credentials);

export const verifyEmail = (credentials: IAuthVerifyEmail) =>
  request.post<IAuthChallenge, IAuthError>('/auth/verify-user', credentials);

export const refreshToken = (refreshToken: string) =>
  request.post<{ id_token: string }>('/token/refresh', { refresh_token: refreshToken });

export const verifyToken = (idToken: string) =>
  request.post<{ valid: boolean }>('/token/verify', { id_token: idToken });

export const invalidateToken = (refreshToken: string) =>
  request.post('/token/invalidate', { refresh_token: refreshToken });
