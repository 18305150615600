import { ReactNode } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Control, Controller, FieldPath, FieldValues, Path, PathValue } from 'react-hook-form';

export interface IFormAutocomplete<S extends FieldValues, O> {
  control: Control<S>;
  name: FieldPath<S>;
  label?: string;
  placeholder?: string;
  errorMessage?: Maybe<string>;
  options: MaybeNull<O[]>;
  getLabel: (option: O) => string;
  getValue: (value: PathValue<S, Path<S>>) => Maybe<O>;
  renderValue: (props: React.HTMLAttributes<HTMLLIElement>, option: O) => Maybe<ReactNode>;
  onChange: (value: MaybeNull<O>) => Maybe<ValueOf<O>>;
  isOptionsEqual: (prev: O, next: O) => boolean;
}

export const FormAutocomplete = <S extends FieldValues, O>(props: IFormAutocomplete<S, O>) => (
  <Controller
    control={props.control}
    name={props.name}
    render={({ field }) => (
      <Autocomplete<O>
        loading={!props.options}
        id={props.name}
        options={props.options ?? []}
        getOptionLabel={props.getLabel}
        onChange={(_, option) => field.onChange(props.onChange(option))}
        isOptionEqualToValue={props.isOptionsEqual}
        value={props.getValue(field.value) ?? null}
        renderOption={props.renderValue}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            label={props.label}
            name={props.name}
            placeholder={props.placeholder}
            error={!!props.errorMessage}
            helperText={props.errorMessage}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off',
            }}
          />
        )}
      />
    )}
  />
);
