import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';
import { alpha } from '@mui/system/colorManipulator';

export const StyledPagination = styled(Paper)`
  display: flex;
  min-height: ${({ theme }) => theme.spacing(5)};
  background-color: ${({ theme }) => theme.palette.secondary.light};
`;

export const StyledForm = styled.form<{ $disabled?: boolean }>`
  flex: 1;
  display: flex;
  min-height: ${({ theme }) => theme.spacing(5)};

  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  transition: ${({ theme }) => theme.transitions.create(['opacity'])};
  ${({ $disabled }) => $disabled && 'cursor: not-allowed;'}

  & > * {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing(1.5)};
    padding: ${({ theme }) => theme.spacing(1.5)};
  }

  & > * + * {
    border-left: 2px solid ${({ theme }) => alpha(theme.palette.text.secondary, 0.1)};
  }
`;

export const StyledInput = styled.input`
  background: transparent;
  border: none;
  text-align: center;

  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  color: ${({ theme }) => theme.palette.primary.main};
  width: ${({ theme }) => theme.spacing(8)};
  max-width: ${({ theme }) => theme.spacing(8)};
  border-top-left-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border-bottom-left-radius: ${({ theme }) => theme.shape.borderRadius}px;

  &:focus-visible {
    outline: none;
    box-shadow: inset ${({ theme }) => theme.shadows[3]};
  }
`;

export const StyledPages = styled.div`
  min-width: 116px;
`;

export const StyledButtons = styled.div``;
