import { TableHeaderRow, TableHeaderCell, TableLeadCell } from 'src/components/shared/Table';

export const LogisticsHeaders = () => (
  <TableHeaderRow>
    <TableLeadCell />
    <TableHeaderCell>3PL Name</TableHeaderCell>
    <TableHeaderCell>Description</TableHeaderCell>
    <TableHeaderCell>Warehouses</TableHeaderCell>
  </TableHeaderRow>
);
