import { useEffect } from 'react';
import Divider from '@mui/material/Divider';

import { ID } from 'src/types';
import { UserRole, useAccess } from 'src/access';
import { LogisticsList, WarehouseDialog } from 'src/components/Logistics';
import { ButtonLink } from 'src/components/shared/Buttons';
import { PageTitle, TitleButtons, PageContainer, Loading } from 'src/components/shared/Page';
import makeRequest from 'src/effects/makeRequest';
import * as apiLogistics from 'src/api/logistics';

import { LogisticsProvider, useLogisticsContext } from './LogisticsContext';

const useLogisticsRequest = makeRequest(apiLogistics.getLogisticsProviders);

const Logistics = () => {
  const { dialogControl, warehousesRequest } = useLogisticsContext();
  const isActionAvailable = useAccess(UserRole.CUSTOMER_ADMIN);
  const logisticsRequest = useLogisticsRequest([], true);

  const handleWarehouseView = (id: ID) => {
    warehousesRequest.cleanup();
    warehousesRequest.make(id);

    dialogControl.showDialog(id);
  };

  useEffect(() => logisticsRequest.make(), []);

  return (
    <>
      <PageTitle title={'Logistics profile'} description={'Set-up your 3PLs, warehouse locations'}>
        <TitleButtons align="left">
          {isActionAvailable && <ButtonLink title="Create 3PL" to={'/settings/logistics/3pl'} variant="contained" />}
          {isActionAvailable && (
            <ButtonLink title="Create Warehouse" to={'/settings/logistics/warehouse'} variant="contained" />
          )}
        </TitleButtons>
      </PageTitle>
      <PageContainer>
        <Divider />
        {logisticsRequest.loading ? (
          <Loading />
        ) : (
          <>
            <LogisticsList payload={logisticsRequest.data} onWarehouseView={handleWarehouseView} />
            <WarehouseDialog
              loading={warehousesRequest.loading}
              payload={warehousesRequest.data}
              open={dialogControl.id != null}
              onClose={dialogControl.hideDialog}
            />
          </>
        )}
      </PageContainer>
    </>
  );
};

export default function LogisticsListPage() {
  return (
    <LogisticsProvider>
      <Logistics />
    </LogisticsProvider>
  );
}
