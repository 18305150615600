import { memo } from 'react';

import { AuthContent, ForgotPasswordForm, MessageGreeting } from 'src/components/Auth';
import { AuthChallengeType, IAuthChallengeForgotPassword } from 'src/types';
import { IForgotPasswordSchema } from 'src/schemas';
import makeRequest from 'src/effects/makeRequest';
import * as apiAuth from 'src/api/auth';

import { IStepComponent } from './types';

const useForgotPasswordRequest = makeRequest(apiAuth.forgotPassword, { silent: true });

const ForgotPassword: IStepComponent<IAuthChallengeForgotPassword> = ({ onChallenge }) => {
  const forgotPasswordRequest = useForgotPasswordRequest(null, false);

  const handleSubmit = (auth: IForgotPasswordSchema) => {
    forgotPasswordRequest.make(auth);
    forgotPasswordRequest.onFulfill(() =>
      onChallenge({
        type: AuthChallengeType.RESET_PASSWORD,
        payload: auth,
      }),
    );
  };

  const handleCancel = () =>
    onChallenge({
      type: AuthChallengeType.LOGIN,
      payload: null,
    });

  return (
    <AuthContent>
      <MessageGreeting title="Reset Password" description="Enter email you want to receive a new password to." />
      <ForgotPasswordForm
        error={forgotPasswordRequest.error?.detail}
        loading={forgotPasswordRequest.loading}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </AuthContent>
  );
};

export default memo(ForgotPassword);
