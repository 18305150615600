import { memo } from 'react';
import { CircularProgress } from '@mui/material';

import { IRateCardFile } from 'src/types';
import { DownloadState } from 'src/effects/useFilesDownloadState';

import File from './File';

export interface IFileListProps {
  files: MaybeNull<IRateCardFile[]>;
  downloadState: DownloadState;
  onDownload: (uploadId: number, filename: string) => void;
}

const FileList = ({ files, downloadState, onDownload }: IFileListProps) => {
  if (!files) return <CircularProgress />;
  if (!files.length) return <>No files</>;

  return (
    <>
      {files.map((file) => (
        <File
          key={file.id}
          file={file}
          state={downloadState[file.id]}
          onDownload={() => onDownload(file.id, file.filename)}
        />
      ))}
    </>
  );
};

export default memo(FileList);
