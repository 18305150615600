import { memo } from 'react';
import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';

export const POSITION_OFFSET = 12;
export const DEFAULT_POPOVER_STATE: IPopoverProps = {
  position: { x: 0, y: 0 },
  truncated: false,
  message: '',
};

export interface IPopoverProps {
  position: { x: number; y: number };
  truncated: boolean;
  message: string;
}

const StyledPopover = styled(Paper)`
  position: fixed;
  pointer-events: none;
  z-index: ${({ theme }) => theme.zIndex.tooltip};
  padding: ${({ theme }) => theme.spacing(0.5, 1)};
`;

const Popover = ({ position, message, truncated }: IPopoverProps) => (
  <StyledPopover
    style={{
      top: position.y + POSITION_OFFSET,
      left: position.x + POSITION_OFFSET,
      display: truncated ? 'block' : 'none',
    }}>
    {message}
  </StyledPopover>
);

export default memo(Popover);
