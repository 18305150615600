import { useEffect } from 'react';
import Divider from '@mui/material/Divider';

import { PageTitle, PageContainer, Loading, TitleButtons } from 'src/components/shared/Page';
import { ButtonLink } from 'src/components/shared/Buttons';
import { UserList } from 'src/components/UserList';
import { UserRole, useAccess } from 'src/access';
import makeRequest from 'src/effects/makeRequest';
import * as apiUser from 'src/api/user';

const useUsersRequest = makeRequest(apiUser.getUsers);

export default function UserListPage() {
  const usersRequest = useUsersRequest([], true);
  const isActionAvailable = useAccess(UserRole.IMPLENTIO_ADMIN);

  useEffect(() => {
    usersRequest.cleanup();
    usersRequest.make();
  }, []);

  return (
    <>
      <PageTitle title={'Implentio users'} description={'Manage all of your users.'}>
        {isActionAvailable && (
          <TitleButtons align="left">
            <ButtonLink title="Add user" variant="contained" to={'/system/user'} />
          </TitleButtons>
        )}
      </PageTitle>
      <PageContainer>
        {usersRequest.loading ? (
          <Loading />
        ) : (
          <>
            <Divider />
            <UserList users={usersRequest.data} />
          </>
        )}
      </PageContainer>
    </>
  );
}
