export const ReportType = {
  HOME: 'home_page',
  SUMMARY: 'summary',
  PERFORMANCE_MANAGEMENT: 'performance_management',
  PICK_AND_PACK: 'pick_and_pack',
  OTHER: 'other',
  INVOICE_SUMMARY: 'invoice_summary',
  INVOICE_SERVICE_DETAIL: 'invoice_service_detail',
  PICK_AND_PACK_SUMMARY: 'pick_and_pack_summary',
  ERROR_SUMMARY: 'error_summary',
  ERROR_DETAIL: 'error_detail',
  FAVORABLE_ERRORS: 'favorable_errors',
} as const;

export type IReportType = ValueOf<typeof ReportType>;
