import { PropsWithChildren } from 'react';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import { theme } from 'src/theme';

const ThemeProvider = ({ children }: PropsWithChildren) => (
  <MuiThemeProvider theme={theme}>
    <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>
  </MuiThemeProvider>
);

export default ThemeProvider;
