import { VariantType, OptionsWithExtraProps } from 'notistack';

import { Observer } from 'src/libs/Observer';

type IMessage = ((close: () => void) => JSX.Element) | JSX.Element | string;

export type INotification = Omit<OptionsWithExtraProps<VariantType>, 'key'> & {
  message: IMessage;
};

type IBaseOptions = Omit<INotification, 'message' | 'variant'>;

class NotificationService extends Observer<INotification> {
  publishError(message: string, options?: IBaseOptions) {
    this.publish({
      ...options,
      variant: 'error',
      message,
    });
  }

  publishInfo(message: string, options?: IBaseOptions) {
    this.publish({
      ...options,
      variant: 'info',
      message,
    });
  }

  publishSuccess(message: string, options?: IBaseOptions) {
    this.publish({
      ...options,
      variant: 'success',
      message,
    });
  }

  publishWarning(message: string, options?: IBaseOptions) {
    this.publish({
      ...options,
      variant: 'warning',
      message,
    });
  }
}

export default new NotificationService();
