import { memo } from 'react';
import Link from '@mui/material/Link';

import { IRenderAction, TableCell, TableCellAction, TableRow } from 'src/components/shared/Table';
import { AnomalyStatusChip } from 'src/components/Status';
import dateFormatter from 'src/utils/dateFormatter';
import { IAnomalyOrder } from 'src/types';

export type IAnomalyOrderRowActionProps = Omit<IAnomalyOrderRowProps, 'renderAction' | 'onAnomalyView'>;
export type IAnomalyOrderRowAction = (props: IAnomalyOrderRowActionProps) => JSX.Element;

export interface IAnomalyOrderRowProps {
  anomaly: IAnomalyOrder;
  onAnomalyView: (anomaly: IAnomalyOrder) => void;
  renderAction: IRenderAction;
}

const formatDate = dateFormatter();

const AnomalyOrderRow = ({ anomaly, onAnomalyView, renderAction }: IAnomalyOrderRowProps) => (
  <TableRow>
    <TableCellAction renderAction={renderAction} />
    <TableCell>{anomaly.order_number}</TableCell>
    <TableCell>
      <AnomalyStatusChip status={anomaly.anomaly_status} />
    </TableCell>
    <TableCell>{formatDate(anomaly.ship_date)}</TableCell>
    <TableCell>{anomaly.invoice_name}</TableCell>
    <TableCell>{anomaly.anomaly_name}</TableCell>
    <TableCell>
      <Link sx={{ cursor: 'pointer' }} onClick={() => onAnomalyView(anomaly)}>
        Click to view
      </Link>
    </TableCell>
  </TableRow>
);

export default memo(AnomalyOrderRow);
