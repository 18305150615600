import styled from '@emotion/styled';

export const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(3)};
`;

export const StyledBlock = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: ${({ theme }) => theme.spacing(1, 5)};
`;

export const StyledGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(2)};

  & > * {
    flex-basis: 120px;
  }
`;

export const StyledFieldName = styled.span`
  text-align: right;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;

export const StyledFieldValue = styled.div``;
