import { NumberParam, createEnumParam, withDefault } from 'use-query-params';

import {
  IInvoiceErrorsFilters,
  IInvoiceOrdersFilters,
  InvoiceErrorSortBy,
  InvoiceErrorsFiltersConfig,
  InvoiceOrderSortBy,
  InvoiceOrdersFiltersConfig,
  ReconcileDataType,
  SortDirection,
} from 'src/types';
import { makeQueryFilter } from 'src/components/shared/QueryFilter';
import { makeNamedQueryParams } from 'src/effects/useNamedQueryParams';

const ReconcileTypeParam = withDefault(
  createEnumParam(Object.values(ReconcileDataType)),
  ReconcileDataType.ERROR_SUMMARY,
);
const SortDirectionParam = withDefault(createEnumParam(Object.values(SortDirection)), SortDirection.DESC);
const InvoiceErrorSortParam = withDefault(
  createEnumParam(Object.values(InvoiceErrorSortBy)),
  InvoiceErrorSortBy.unfavorable_errors_dollars,
);
const InvoiceOrderPageParam = withDefault(NumberParam, 1);
const InvoiceOrderSortParam = withDefault(
  createEnumParam(Object.values(InvoiceOrderSortBy)),
  InvoiceOrderSortBy.SALES_ORDER_NAME,
);

export const reconcileTypeQuery = makeNamedQueryParams('rt', {
  type: ReconcileTypeParam,
});

export const errorsFiltersQuery = makeQueryFilter<IInvoiceErrorsFilters>('ef', InvoiceErrorsFiltersConfig);

export const errorsSortingQuery = makeNamedQueryParams('es', {
  by: InvoiceErrorSortParam,
  order: SortDirectionParam,
});

export const ordersFiltersQuery = makeQueryFilter<IInvoiceOrdersFilters>('of', InvoiceOrdersFiltersConfig);

export const ordersSortingQuery = makeNamedQueryParams('os', {
  by: InvoiceOrderSortParam,
  order: SortDirectionParam,
});

export const ordersPaginationQuery = makeNamedQueryParams('op', {
  page: InvoiceOrderPageParam,
});
