import { memo, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import Chip from '@mui/material/Chip';

import { IOrderOperator, OrderOperator } from 'src/types';

export interface IOrderOperatorProps {
  operator: IOrderOperator;
}

const Operator = ({ operator }: IOrderOperatorProps) => {
  const { palette } = useTheme();

  const colors = useMemo(
    () => ({
      [OrderOperator.LT]: palette.error.light,
      [OrderOperator.GT]: palette.success.light,
      [OrderOperator.EQ]: palette.success.light,
    }),
    [],
  );

  const color = operator ? colors[operator] ?? palette.text.disabled : palette.text.disabled;
  /* Operators can be null when no operator exists (a legit case). Display this as N/A */
  const label = operator ?? 'N/A';

  return <Chip label={label} sx={{ backgroundColor: color }} />;
};

export default memo(Operator);
