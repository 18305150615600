import { ID, ILogisticsProvider, INewLogisticsProvider, INewWarehouse, IWarehouse } from 'src/types';

import request from './requestPrivate';

export const getLogisticsProviders = () => request.get<ILogisticsProvider[]>('/logistics-provider');

export const createLogisticsProvider = (provider: INewLogisticsProvider) =>
  request.post<number>('/logistics-provider', provider);

export const getWarehouses = (id: ID) =>
  request.get<IWarehouse[]>('/warehouse', {
    logistics_provider_id: id,
  });

export const createWarehouse = (warehouse: INewWarehouse) => request.post<number>('/warehouse', warehouse);
