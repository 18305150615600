export type IOrder = {
  type: string;
  order_number: string;
  invoice_name: string;
  order_timeline: IOrderTimeline[];
  pick_and_pack: IOrderPickAndPack;
  order_fulfillment_detail: IOrderFulfillmentDetail;
  total_cost_summary: IOrderCostSummary;
};

export type ILatestOrder = {
  invoice_id: string;
  order_number: string;
};

export const OrderOperator = {
  EQ: '=' as const,
  GT: '>' as const,
  LT: '<' as const,
} as const;

export type IOrderOperator = ValueOf<typeof OrderOperator>;

export type IOrderPickAndPackLine = {
  item: string;
  service: string;
  expected_quantity: number;
  invoiced_quantity: number;
  expected_total_charges: number;
  invoiced_total_charges: number;
  invoiced_rate: number;
  expected_rate: number;
  operator: IOrderOperator;
};

export type IOrderPickAndPackTotal = {
  expected_rate: number;
  invoiced_rate: number;
  expected_quantity: number;
  invoiced_quantity: number;
  operator: IOrderOperator;
};

export type IOrderCostSummary = {
  fulfillment_total: IOrderFulfillmentTotal;
  total: IOrderCostSummaryTotal;
  errors_total: number;
};

export type IOrderFulfillmentTotal = {
  name: string;
  expected_rate: number;
  invoiced_rate: number;
  operator: IOrderOperator;
  error_types: string;
};

export type IOrderCostSummaryTotal = {
  expected_rate: number;
  invoiced_rate: number;
  operator: IOrderOperator;
};

export const OrderPickAndPackErrorStatus = {
  FAIL: 'FAIL' as const,
  PASS: 'PASS' as const,
} as const;

export type IOrderPickAndPackErrorStatus = ValueOf<typeof OrderPickAndPackErrorStatus>;

export type IOrderPickAndPackError = {
  error_name: string;
  status: IOrderPickAndPackErrorStatus;
};

export type IOrderPickAndPack = {
  line_item_details: IOrderPickAndPackLine[];
  error_checks: IOrderPickAndPackError[];
  order_inserts_fees: MaybeNull<IOrderPickAndPackLine[]>;
  total_pick_fees: IOrderPickAndPackTotal;
  total_pick_and_pack: IOrderPickAndPackTotal;
  total_order_level_fees: MaybeNull<IOrderPickAndPackTotal>;
};

export type IOrderFulfillmentDetail = {
  logistics_provider: string;
  warehouse: string;
  rate_card: string;
};

export type IOrderTimeline = {
  date: string;
  event: string;
  days_from_order: number;
};
