import { PropsWithChildren } from 'react';

import StyledActionPanel from './styles';

const ActionPanel = ({
  noAlign,
  onlyRightChild,
  children,
}: PropsWithChildren<{ noAlign?: boolean; onlyRightChild?: boolean }>) => (
  <StyledActionPanel $noAlign={noAlign} $onlyRightChild={onlyRightChild}>
    {children}
  </StyledActionPanel>
);

export default ActionPanel;
