import { IAlertRaw, ID } from 'src/types';

import request, { IRequest } from './requestPrivate';

export const getAlerts = (): IRequest<IAlertRaw[]> => request.get<IAlertRaw[]>('/alert');

export const acknowledgeAlert = (id: ID, acknowledged: boolean) =>
  request.put<boolean>(`/alert/${encodeURIComponent(id)}`, { acknowledged });

export const downloadAlertAttachment = (id: ID) =>
  request.get<Blob>(`/alert/download/${id}`, null, {
    responseType: 'blob',
  });
