import { useEffect, useMemo, useState, useTransition } from 'react';

import pipe from 'src/libs/pipe';
import { sortData } from 'src/effects/useDataSorting';
import { filterData } from 'src/effects/useDataFiltering';
import { IQueryParams } from 'src/components/shared/QueryFilter';
import { creditsFiltersQuery, creditsSortQuery } from 'src/components/CreditRequests';
import { CreditStatus, ICredit, ICreditFilters, ICreditSortBy, ISort } from 'src/types';

export const useCreditsSort = () => {
  const [, startTransition] = useTransition();
  const [params, setParams] = creditsSortQuery.useParams();

  const changeSorting = (sort: ISort<ICreditSortBy>) =>
    startTransition(() => setParams({ by: sort.by, order: sort.order }));

  return {
    params,
    changeSorting,
  };
};

export const useCreditsFilters = (data: MaybeNull<ICredit[]>) => {
  const filterConfig = useMemo(() => makeCreditsFilterConfig(data), [data]);
  const filterQuery = creditsFiltersQuery.useFilter(filterConfig);

  return filterQuery;
};

export interface ICreditDataProcessorParams {
  data: Maybe<ICredit[]>;
  filterConfig: Maybe<ICreditFilters>;
  filterParams: Maybe<IQueryParams<ICreditFilters>>;
  sortParams: ISort<ICreditSortBy>;
}

export const useCreditDataProcessor = ({
  data,
  filterConfig,
  filterParams,
  sortParams,
}: ICreditDataProcessorParams) => {
  const [processedData, setProcessedData] = useState<MaybeNull<ICredit[]>>(null);

  useEffect(() => {
    if (!data) return setProcessedData(null);

    const processedData = pipe(data)
      .chain((data) => filterData(data, filterConfig, filterParams))
      .chain((data) => sortData(data, sortParams))
      .value();

    setProcessedData(processedData);
  }, [data, filterConfig, filterParams, sortParams]);

  return processedData;
};

export const makeCreditsFilterConfig = (data: MaybeNull<ICredit[]>): MaybeNull<ICreditFilters> => {
  if (!data) return null;

  const invoiceNumbers = [...data.reduce((set, { invoice_number }) => set.add(invoice_number), new Set<string>())];
  const invoiceValues = invoiceNumbers.map((value) => ({ key: value, value }));

  const requestNumbers = [
    ...data.reduce((set, { credit_request_number }) => set.add(credit_request_number), new Set<string>()),
  ];
  const requestValues = requestNumbers.map((value) => ({ key: value, value }));

  return {
    credit_request_number: {
      type: 'single_select',
      options: {
        label: 'Credit request',
        values: requestValues,
      },
    },
    invoice_number: {
      type: 'multi_select',
      options: {
        label: 'Invoice number',
        values: invoiceValues,
      },
    },
    errors_dollars: {
      type: 'criterion_number',
      options: {
        label: 'Errors ($)',
        format: 'currency',
        placeholder: 'Errors $',
        criterions: [
          {
            key: 'gt',
            value: 'greater than',
          },
          {
            key: 'lt',
            value: 'less than',
          },
          {
            key: 'eq',
            value: 'equal',
          },
        ],
      },
    },
    errors_count: {
      type: 'criterion_number',
      options: {
        label: 'Errors (#)',
        format: 'number',
        placeholder: 'Errors #',
        criterions: [
          {
            key: 'gt',
            value: 'Greater than',
          },
          {
            key: 'lt',
            value: 'Less than',
          },
          {
            key: 'eq',
            value: 'Equal',
          },
        ],
      },
    },
    submit_date: {
      type: 'date_range',
      options: {
        label: 'Submit date',
      },
    },
    status: {
      type: 'single_select',
      options: {
        label: 'Status',
        values: [
          {
            key: CreditStatus.CANCELLED,
            value: 'Canceled',
          },
          {
            key: CreditStatus.DENIED,
            value: 'Denied',
          },
          {
            key: CreditStatus.PARTIALLY_RECEIVED,
            value: 'Partially received',
          },
          {
            key: CreditStatus.RECEIVED,
            value: 'Received',
          },
          {
            key: CreditStatus.SENT,
            value: 'Sent',
          },
        ],
      },
    },
  };
};
