import { PropsWithChildren, useEffect } from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';

import notificationService from 'src/services/NotificationService';

const createKey = () => `${Math.random().toString(36).substring(2)}.${Date.now().toString(36)}`;

const WithNotificationService = ({ children }: PropsWithChildren) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(
    () =>
      notificationService.subscribe((data) => {
        const key = createKey();
        const message = typeof data.message === 'function' ? data.message(() => closeSnackbar(key)) : data.message;

        enqueueSnackbar({ ...data, message, key });
      }),
    [enqueueSnackbar, closeSnackbar],
  );

  return <>{children}</>;
};

const Provider = ({ children }: PropsWithChildren) => (
  <SnackbarProvider maxSnack={15} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
    <WithNotificationService>{children}</WithNotificationService>
  </SnackbarProvider>
);

export default Provider;
