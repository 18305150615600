import { useCallback, useMemo, useState } from 'react';

import { Observer } from 'src/libs/Observer';
import { IAlertCommon, IAlertRaw } from 'src/types';

export type IAlertTotals = {
  read: number;
  unread: number;
  total: number;
};

const makeCommonAlert = (rawAlert: IAlertRaw): IAlertCommon => ({
  ...rawAlert,
  sent_at_date: new Date(rawAlert.sent_at),
  created_at_date: new Date(rawAlert.created_at),
});

const useAlertsControl = () => {
  const [initialized, setInitialized] = useState(false);
  const [alerts, setAlerts] = useState<IAlertCommon[]>([]);
  const [observer] = useState(() => new Observer<IAlertCommon>());

  const totals = useMemo<IAlertTotals>(
    () =>
      alerts.reduce(
        (totals, alert) => {
          if (alert.acknowledged) totals.read += 1;
          else totals.unread += 1;

          totals.total += 1;

          return totals;
        },
        {
          read: 0,
          unread: 0,
          total: 0,
        },
      ),
    [alerts],
  );

  const addAlert = useCallback(
    (rawAlert: IAlertRaw) =>
      setAlerts((alerts) => {
        const isExist = alerts.some((alert) => alert.id === rawAlert.id);

        if (isExist) return [...alerts];

        const newAlert = makeCommonAlert(rawAlert);
        const index = alerts.findIndex((alert) => +newAlert.sent_at_date >= +alert.sent_at_date);
        const position = index < 0 ? 0 : index;

        alerts.splice(position, 0, newAlert);

        return [...alerts];
      }),
    [],
  );

  const updateAlert = useCallback(
    (rawAlert: Pick<IAlertRaw, 'id'> & Omit<Partial<IAlertRaw>, 'id'>, options?: { silent?: boolean }) =>
      setAlerts((alerts) => {
        const prevIndex = alerts.findIndex((alert) => alert.id === rawAlert.id);

        if (prevIndex < 0) return [...alerts];

        const newAlert = makeCommonAlert({
          ...alerts[prevIndex],
          ...rawAlert,
        } as IAlertRaw);

        alerts[prevIndex] = newAlert;

        if (!options?.silent) observer.publish(newAlert);

        return [...alerts];
      }),
    [],
  );

  const removeAlert = useCallback(
    (rawAlert: IAlertRaw) =>
      setAlerts((alerts) => {
        const index = alerts.findIndex((alert) => alert.id === rawAlert.id);

        if (index < 0) return [...alerts];

        alerts.splice(index, 1);

        return [...alerts];
      }),
    [],
  );

  const initializeAlerts = useCallback(
    (rawAlerts: IAlertRaw[]) =>
      setInitialized((initialized) => {
        if (initialized) return initialized;

        const alerts = rawAlerts.map(makeCommonAlert).sort((prev, next) => +next.sent_at_date - +prev.sent_at_date);

        setAlerts(alerts);

        return true;
      }),
    [],
  );

  return {
    alerts,
    totals,
    observer,
    initialized,
    addAlert,
    removeAlert,
    updateAlert,
    initializeAlerts,
  };
};

export default useAlertsControl;
