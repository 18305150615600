import { useEffect } from 'react';

import { Access, UserRole } from 'src/access';
import { RateCardList } from 'src/components/RateCardList';
import { PageTitle, TitleButtons, PageContainer } from 'src/components/shared/Page';
import { ButtonLink } from 'src/components/shared/Buttons';

import RateCardRowAction from './RateCardRowAction';
import RateCardHeadAction from './RateCardHeadAction';
import { RateCardProvider, useRateCardContext } from './RateCardContext';

const ListPage = () => {
  const { rateCardRequest, rateCardControl } = useRateCardContext();

  useEffect(() => rateCardRequest.make(rateCardControl.params), [rateCardControl.params]);

  return (
    <>
      <PageTitle title={'Rate Cards'} description={'Find your rate card or create a new one.'}>
        <TitleButtons align="left">
          <Access roles={[UserRole.CUSTOMER_ADMIN]}>
            <ButtonLink title="Create" to={'/settings/ratecard'} variant="contained" />
          </Access>
        </TitleButtons>
      </PageTitle>
      <PageContainer>
        <RateCardList
          headAction={RateCardHeadAction}
          rowAction={RateCardRowAction}
          payload={rateCardRequest.data}
          loading={rateCardRequest.loading}
          actions={rateCardControl.actions}
          params={rateCardControl.params}
        />
      </PageContainer>
    </>
  );
};

export default function RateCardListPage() {
  return (
    <RateCardProvider>
      <ListPage />
    </RateCardProvider>
  );
}
