import Link from '@mui/material/Link';

import { ID, ILogisticsProvider } from 'src/types';
import { TableRow, TableCell, TableLeadCell } from 'src/components/shared/Table';

export interface ILogisticsRowProps {
  provider: ILogisticsProvider;
  onWarehouseView: (id: ID) => void;
}

export const LogisticsRow = ({ provider, onWarehouseView }: ILogisticsRowProps) => (
  <TableRow>
    <TableLeadCell />
    <TableCell>{provider.name}</TableCell>
    <TableCell>{provider.description}</TableCell>
    <TableCell>
      <Link sx={{ cursor: 'pointer' }} onClick={() => onWarehouseView(provider.id)}>
        Click to view
      </Link>
    </TableCell>
  </TableRow>
);
