/* eslint-disable react/display-name */

import { HTMLAttributes, createElement, forwardRef } from 'react';
import { alpha } from '@mui/system/colorManipulator';
import { ThemedComponent } from '@mui/material/styles';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper, { PopperProps } from '@mui/material/Popper';
import Grow from '@mui/material/Grow';

import { ArrowBottomFilledIcon } from 'src/components/Icons';

export const MuiAutocomplete: ThemedComponent<'MuiAutocomplete'> = {
  defaultProps: {
    size: 'medium',
    popupIcon: createElement(ArrowBottomFilledIcon),
    ListboxComponent: forwardRef<HTMLUListElement, HTMLAttributes<HTMLElement>>((props, ref) => (
      <MenuList {...props} ref={ref} />
    )),
    PaperComponent: forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>((props, ref) => (
      <Paper {...props} ref={ref} />
    )),
    PopperComponent: ({ children, ...props }: PopperProps) => (
      <Popper
        {...props}
        transition
        placement="bottom-start"
        style={{ zIndex: 1, width: (props.anchorEl as HTMLElement)?.getBoundingClientRect().width ?? 0 }}>
        {({ TransitionProps }) => <Grow {...TransitionProps}>{children as JSX.Element}</Grow>}
      </Popper>
    ),
  },
  styleOverrides: {
    root: ({ theme }) => ({
      '& .MuiFilledInput-root': {
        padding: theme.spacing(0, 4, 0, 1.5),
      },
      '& .MuiFilledInput-root .MuiFilledInput-input': {
        padding: 0,
      },
    }),
    endAdornment: ({ theme }) => ({
      padding: theme.spacing(0.5, 0.5, 0),
      '.Mui-focused &': {
        color: theme.palette.primary.main,
      },
      '.Mui-error &': {
        color: theme.palette.error.main,
      },
      '.Mui-error.Mui-focused &': {
        color: theme.palette.error.main,
      },
    }),
    clearIndicator: ({ theme }) => ({
      padding: theme.spacing(0, 1),
      color: 'inherit',
    }),
    popupIndicator: ({ theme }) => ({
      padding: 0,
      color: 'inherit',
      '& svg': {
        width: theme.spacing(2),
      },
    }),
    listbox: ({ theme }) => ({
      padding: 0,
      '& .MuiAutocomplete-option': {
        justifyContent: 'space-between',
        height: theme.spacing(5),
        padding: theme.spacing(1, 1.5),
      },
      '& .MuiAutocomplete-option.Mui-focused': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
      },
    }),
  },
};
