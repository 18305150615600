import { HTMLAttributes, PropsWithChildren, createContext, useContext } from 'react';

import { IRateCardConfirmationStatus, IRateCardListItem } from 'src/types';
import makeRequest from 'src/effects/makeRequest';
import * as apiRateCard from 'src/api/rateCard';

import { useRateCardControl } from './listControls';

export interface IRateCardContext {
  approveRequest: ReturnType<typeof useApproveRequest<MaybeNull<IRateCardConfirmationStatus>>>;
  approveAllRequest: ReturnType<typeof useApproveAllRequest<MaybeNull<boolean>>>;
  rateCardRequest: ReturnType<typeof useRateCardRequest<IRateCardListItem[]>>;
  rateCardControl: ReturnType<typeof useRateCardControl>;
}

const useRateCardRequest = makeRequest(apiRateCard.getRateCards);
const useApproveRequest = makeRequest(apiRateCard.approveRateCard);
const useApproveAllRequest = makeRequest(apiRateCard.approveRateCards);

export const RateCardContext = createContext<IRateCardContext>({} as IRateCardContext);

export const RateCardProvider = ({ children }: PropsWithChildren<HTMLAttributes<HTMLElement>>) => {
  const approveRequest = useApproveRequest(null);
  const approveAllRequest = useApproveAllRequest(null);
  const rateCardRequest = useRateCardRequest([], true);
  const rateCardControl = useRateCardControl('rate');

  return (
    <RateCardContext.Provider value={{ rateCardRequest, approveRequest, approveAllRequest, rateCardControl }}>
      {children}
    </RateCardContext.Provider>
  );
};

export const useRateCardContext = () => useContext(RateCardContext);
