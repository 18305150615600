import styled from '@emotion/styled';
import { alpha } from '@mui/system/colorManipulator';

import { LoginArrow } from 'src/components/Icons';
import { StyledFields } from 'src/components/shared/Form/styles';

const BRAND_COLOR_PRIMARY = '#AAA6EA';
const BRAND_COLOR_SECONDARY = '#ECEAFA';

export const StyledGreetingMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(2.5)};
  width: 330px;
  min-width: 0;
  color: ${({ theme }) => theme.palette.text.primary};

  h1 {
    font-size: 40px;
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  }

  p {
    font-size: 18px;
    font-weight: ${({ theme }) => theme.typography.fontWeightRegular};

    margin: 0;
    opacity: 0.8;
    max-width: 300px;
  }
`;

export const StyledMessageArrow = styled(LoginArrow)`
  width: 228px;
  align-self: flex-end;
  color: ${BRAND_COLOR_PRIMARY};
`;

export const StyledAuthorizedMessage = styled(StyledGreetingMessage)`
  flex: 1;
  width: auto;
  align-items: center;
  justify-content: center;

  p {
    max-width: fit-content;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  min-width: 496px;
  font-size: 16px;
  background-color: ${BRAND_COLOR_SECONDARY};
  border-radius: ${({ theme }) => theme.spacing(1.5)};
  padding: ${({ theme }) => theme.spacing(3.5, 5)};

  input,
  input:autofill,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  input:-internal-autofill-selected {
    outline: none !important;
    border: none !important;
    background-color: transparent !important;
    -webkit-background-clip: text !important;

    font-family: ${({ theme }) => theme.typography.fontFamily} !important;
    font-weight: ${({ theme }) => theme.typography.fontWeightRegular} !important;
    font-size: 16px !important;
  }
  input::placeholder {
    font-weight: ${({ theme }) => theme.typography.fontWeightRegular} !important;
    color: ${({ theme }) => alpha(theme.palette.text.primary, 0.4)};
  }

  & ${StyledFields} {
    flex: 1;
    padding-bottom: ${({ theme }) => theme.spacing(3)};

    & > * {
      & > * {
        gap: ${({ theme }) => theme.spacing(1)};
      }

      & label {
        line-height: 1.15;
        color: rgb(110, 107, 152);
        font-size: 14px;

        & + * {
          background-color: ${({ theme }) => theme.palette.common.white};
        }
      }
    }
  }
`;

export const StyledFormFooter = styled(StyledFields)`
  padding-bottom: 0;
  gap: ${({ theme }) => theme.spacing(2)};

  & > * {
    flex: 1;
    text-align: center;
  }
`;

export const StyledAuthContent = styled.section`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(13.5)};
`;

export const StyledAuthContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(13.5)};
`;

export const StyledAuthError = styled.p`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  margin: 0;
  padding: ${({ theme }) => theme.spacing(1.5)};
  color: ${({ theme }) => theme.palette.error.main};
  border: 1px solid ${({ theme }) => theme.palette.error.main};
  background-color: ${({ theme }) => theme.palette.error.light};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
`;
