import { IUserListItem } from 'src/types';
import { TableRow, TableCell, TableLeadCell } from 'src/components/shared/Table';
import dateFormatter from 'src/utils/dateFormatter';

import UserStatus from './UserStatus';
import UserRole from './UserRole';

const formatDate = dateFormatter();

export interface IUserRowProps {
  user: IUserListItem;
}

export const UserRow = ({ user }: IUserRowProps) => (
  <TableRow>
    <TableLeadCell />
    <TableCell>
      {user.first_name} {user.last_name}
    </TableCell>
    <TableCell>{user.brand.name}</TableCell>
    <TableCell>{user.email}</TableCell>
    <TableCell>{formatDate(user.created_at)}</TableCell>
    <TableCell>
      <UserRole role={user.role.name} />
    </TableCell>
    <TableCell>
      <UserStatus status={user.is_active} />
    </TableCell>
  </TableRow>
);
