import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import Divider from '@mui/material/Divider';

import shouldForwardProp from 'src/utils/shouldForwardProp';

/* Logo */

export const StyledLogoDecor = styled.div``;

export const StyledLogoContent = styled.div``;

export const StyledLogoBody = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  & > * {
    grid-row-start: 1;
    grid-column-start: 1;
  }

  & svg {
    width: auto;
    height: auto;
  }
`;

export const StyledLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => theme.spacing(0.5, 1.5, 1)};
`;

/* Sidebar */

export const StyledSidebarGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};
  width: 100%;
  overflow: hidden;
`;

export const StyledSidebarContainer = styled.div`
  top: 0;
  z-index: 2;
  position: sticky;
  display: flex;
  width: ${({ theme }) => theme.spacing(9)};
`;

export const StyledSidebarTitle = styled.span`
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  text-align: center;
  white-space: pre;

  opacity: 0;
  transform: translateX(100%);
  transition: ${({ theme }) => theme.transitions.create(['transform', 'opacity'])};
`;

export const StyledSidebarBody = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;
  flex: 0 1 auto;
  background-color: ${({ theme }) => theme.palette.secondary.light};
  gap: ${({ theme }) => theme.spacing(2)};
  align-items: center;
  justify-content: space-between;

  min-width: 100%;
  transition: ${({ theme }) => theme.transitions.create(['min-width'])};

  & ${StyledSidebarGroup}:first-child {
    padding-top: ${({ theme }) => theme.spacing(2.5)};
  }

  & ${StyledSidebarGroup}:last-child {
    padding-bottom: ${({ theme }) => theme.spacing(2.5)};
  }

  & ${StyledSidebarTitle} {
    width: ${({ theme }) => theme.spacing(16)};
  }

  & ${StyledLogoBody} {
    width: ${({ theme }) => theme.spacing(3)};
    transition: ${({ theme }) => theme.transitions.create(['width'])};
  }

  ${StyledLogoDecor} {
    overflow: hidden;
    width: ${({ theme }) => theme.spacing(3)};
    transition: ${({ theme }) => theme.transitions.create(['width'])};
  }

  ${StyledLogoContent} {
    transition: ${({ theme }) => theme.transitions.create(['transform', 'opacity'])};

    opacity: 0;
    transform: translateX(100%);
  }

  &:hover,
  &[data-active='true'] {
    min-width: ${({ theme }) => theme.spacing(19.5)};

    & ${StyledSidebarTitle} {
      opacity: 1;
      transform: translateX(0);
    }

    & ${StyledLogoBody} {
      width: 100%;
    }

    & ${StyledLogoDecor} {
      width: ${({ theme }) => theme.spacing(16.5)};
    }

    & ${StyledLogoContent} {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

export const StyledLinkComponent = styled(NavLink, { shouldForwardProp })<{ $disabled?: boolean }>`
  text-decoration: none;
  padding: ${({ theme }) => theme.spacing(1.5)};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
`;

export const StyledButtonComponent = styled('div', { shouldForwardProp })<{ $disabled?: boolean }>`
  padding: ${({ theme }) => theme.spacing(1.5)};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
`;

export const StyledSidebarMenuItem = styled('div', { shouldForwardProp })<{ $disabled?: boolean; $active?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(1.5)};
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 12px;

  transition: ${({ theme }) => theme.transitions.create(['color'])};

  &:hover {
    ${({ theme, $disabled }) => !$disabled && `color: ${theme.palette.primary.main};`}
  }

  & svg {
    width: ${({ theme }) => theme.spacing(3)};
    transform: scale(1);
    transition: ${({ theme }) => theme.transitions.create(['transform'])};
  }

  &:hover svg {
    transform: scale(0.9);
  }

  ${({ theme, $disabled }) => $disabled && `color: ${theme.palette.text.disabled};`}
  ${({ theme, $active }) => $active && `color: ${theme.palette.primary.main};`}
`;

/* Submenu */

export const StyledSubmenu = styled.section`
  position: relative;
  z-index: 1;
  top: 0;
  height: 100%;
  overflow-y: auto;
  display: flex;
  min-width: ${({ theme }) => theme.spacing(30)};
  background-color: ${({ theme }) => theme.palette.common.white};

  left: ${({ theme }) => theme.spacing(-30)};
  transition: ${({ theme }) => theme.transitions.create(['left'])};

  &[data-active='true'] {
    left: 0;
  }

  & > * {
    flex: 1;
  }
`;
