import dayjs, { Dayjs, ConfigType as DayjsType } from 'dayjs';

export const daysToRange = (...days: Maybe<Dayjs>[]) => {
  const sortedDays = days
    .filter((day): day is Dayjs => day != null)
    .sort((first, last) => (first.isAfter(last) ? 1 : -1));

  if (!sortedDays.length) return [];

  if (sortedDays.length == 1) return sortedDays;

  const first = sortedDays[0];
  const last = sortedDays[sortedDays.length - 1];

  return [first, last];
};

export const isHighlightedDayInRange = (day: Dayjs, range: Dayjs[]) => {
  if (range.length !== 2) return false;

  return day.isBetween(range[0], range[1], 'day', '[]');
};

export const isFirstDayInRange = (day: Dayjs, range: Dayjs[]) => {
  if (!range[0]) return false;

  return day.isSame(range[0], 'day');
};

export const isLastDayInRange = (day: Dayjs, range: Dayjs[]) => {
  if (!range[1]) return false;

  return day.isSame(range[1], 'day');
};

export const makeDate = (date?: DayjsType) => {
  if (!date) return;

  const dateDay = dayjs(date);

  if (!dateDay.isValid()) return;

  return dateDay;
};

export const makeDateRange = (from?: DayjsType, to?: DayjsType) => {
  if (from == null || to == null) return;

  const fromDate = dayjs(from);
  const toDate = dayjs(to);

  if (!fromDate.isValid() || !toDate.isValid()) return;

  return {
    from: fromDate,
    to: toDate,
  };
};
