export interface ICurrencyFormatterConfig {
  locale?: string;
  currency?: string;
  placeholder?: string;
  notation?: 'standard' | 'scientific' | 'engineering' | 'compact';
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

const DEFAULT_CONFIG: ICurrencyFormatterConfig = {
  locale: 'en-US',
  currency: 'USD',
  placeholder: 'N/A',
  notation: 'standard',
  minimumFractionDigits: 2,
  maximumFractionDigits: 3,
};

const currencyFormatter = (config?: ICurrencyFormatterConfig) => {
  const locale = config?.locale ?? DEFAULT_CONFIG.locale;
  const currency = config?.currency ?? DEFAULT_CONFIG.currency;
  const notation = config?.notation ?? DEFAULT_CONFIG.notation;
  const placeholder = config?.placeholder ?? DEFAULT_CONFIG.placeholder;
  const minimumFractionDigits = config?.minimumFractionDigits ?? DEFAULT_CONFIG.minimumFractionDigits;
  const maximumFractionDigits = config?.maximumFractionDigits ?? DEFAULT_CONFIG.maximumFractionDigits;

  const formatter = new Intl.NumberFormat(locale, {
    notation,
    currency,
    minimumFractionDigits,
    maximumFractionDigits,
    style: 'currency',
  });

  return (price: Maybe<number>) => (price != null ? formatter.format(price) : placeholder);
};

export default currencyFormatter;
