import { memo, useTransition } from 'react';

import { ReconcileDataType } from 'src/types';
import ActionPanel from 'src/components/shared/ActionPanel';
import { QueryFilter } from 'src/components/shared/QueryFilter';
import { ErrorList } from 'src/components/InvoiceReconcile/ErrorSummary';

import InvoiceErrorsExport from './InvoiceErrorsExport';
import { useReconciliationContext } from './ReconciliationContext';

const InvoiceErrors = () => {
  const [, startTransition] = useTransition();
  const {
    errorsSorting,
    errorsFilters,
    errorsRequest,
    errorsData,
    reconcileType,
    ordersPagination,
    ordersFilters,
    ordersRequest,
  } = useReconciliationContext();

  const handleErrorTypeClick = (codes: string[]) =>
    startTransition(() => {
      ordersRequest.cleanup();
      ordersPagination.setPage(1);
      ordersFilters.setParam('ERROR_CODE', codes);
      ordersFilters.addToOrder('ERROR_CODE');
      reconcileType.setType(ReconcileDataType.ORDERS);
    });

  return (
    <>
      <ActionPanel noAlign>
        <QueryFilter control={errorsFilters} />
        <InvoiceErrorsExport />
      </ActionPanel>
      <ErrorList
        payload={errorsData}
        loading={errorsRequest.loading}
        sorting={errorsSorting.params}
        changeSorting={errorsSorting.changeSorting}
        onErrorTypeClick={handleErrorTypeClick}
      />
    </>
  );
};

export default memo(InvoiceErrors);
