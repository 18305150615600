import { IInvoiceServiceDataItem } from 'src/types';
import { TableRow, TableCell, TableLeadCell } from 'src/components/shared/Table';
import currencyFormatter from 'src/utils/currencyFormatter';

const formatUSD = currencyFormatter();

export interface IPickAndPackRowProps {
  item: IInvoiceServiceDataItem;
}

export const PickAndPackRow = ({ item }: IPickAndPackRowProps) => (
  <TableRow>
    <TableLeadCell />
    <TableCell size={45}>
      <span>{item.service_name}</span>
    </TableCell>
    <TableCell>{item.audit_depth}</TableCell>
    <TableCell>{formatUSD(item.logistics_charges)}</TableCell>
  </TableRow>
);
