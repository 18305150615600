import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import Link from '@mui/material/Link';

import { IInvoice } from 'src/types';
import dateFormatter from 'src/utils/dateFormatter';
import numberFormatter from 'src/utils/numberFormatter';
import currencyFormatter from 'src/utils/currencyFormatter';
import { TableRow, TableCell } from 'src/components/shared/Table';

import InvoiceStatusIcon from './InvoiceStatusIcon';

const formatDate = dateFormatter();
const formatNumber = numberFormatter();
const formatCurrency = currencyFormatter();

export interface IInvoiceRowProps {
  invoice: IInvoice;
  link: string;
}

const InvoiceRow = ({ invoice, link }: IInvoiceRowProps) => (
  <TableRow>
    <TableCell>
      <InvoiceStatusIcon status={invoice.status} />
    </TableCell>
    <TableCell>
      <Link sx={{ cursor: 'pointer' }} component={NavLink} to={link}>
        {invoice.name}
      </Link>
    </TableCell>
    <TableCell>
      {formatDate(invoice.start_date)} - {formatDate(invoice.end_date)}
    </TableCell>
    <TableCell>{invoice.logistics_provider}</TableCell>
    <TableCell>{invoice.warehouse}</TableCell>
    <TableCell>{invoice.services}</TableCell>
    <TableCell>{formatCurrency(invoice.logistics_charges)}</TableCell>
    <TableCell>{formatNumber(invoice.unfavorable_errors_count)}</TableCell>
    <TableCell>{formatCurrency(invoice.unfavorable_errors_dollars)}</TableCell>
  </TableRow>
);

export default memo(InvoiceRow);
