import { memo, useEffect } from 'react';
import Divider from '@mui/material/Divider';

import { CreditStepType, ICreditStepType } from 'src/types';
import { CreditInfo } from 'src/components/InvoiceReconcile';
import { Loading } from 'src/components/shared/Page';
import Stepper from 'src/components/shared/Stepper';

import { useCreditRequestContext } from './CreditRequestContext';
import { useCreditStepsContext } from './CreditStepsContext';
import StepErrors from './StepErrors';
import StepEmail from './StepEmail';
import StepTags from './StepTags';

const STEPS = [CreditStepType.Errors, /*CreditStepType.Tags,*/ CreditStepType.Email];

const getCurrentStep = (type: ICreditStepType) => {
  switch (type) {
    case CreditStepType.Tags:
      return StepTags;
    case CreditStepType.Email:
      return StepEmail;
    default:
      return StepErrors;
  }
};

const Steps = () => {
  const { invoiceName } = useCreditRequestContext();
  const { stepControl, creditErrorsRequest, creditSummaryRequest, creditRecipientRequest } = useCreditStepsContext();

  const isLoading = creditErrorsRequest.loading || creditSummaryRequest.loading;
  const CurrentStep = getCurrentStep(stepControl.activeStep);

  useEffect(() => creditErrorsRequest.make(invoiceName), []);
  useEffect(() => creditSummaryRequest.make(invoiceName), []);
  useEffect(() => creditRecipientRequest.make(invoiceName), []);

  return (
    <>
      <Stepper steps={STEPS} step={stepControl.activeStep} />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {creditSummaryRequest.data && (
            <>
              <Divider />
              <CreditInfo
                invoiceName={creditSummaryRequest.data.invoice_name}
                invoiceStatus={creditSummaryRequest.data.status}
                periodStart={creditSummaryRequest.data.start_date}
                periodEnd={creditSummaryRequest.data.end_date}
                totalCharges={creditSummaryRequest.data.total_charges}
                totalOrders={creditSummaryRequest.data.total_orders}
                ordersStatus={creditSummaryRequest.data.orders_error_count}
              />
            </>
          )}
          <CurrentStep />
        </>
      )}
    </>
  );
};

export default memo(Steps);
