import { memo, useCallback } from 'react';
import Checkbox from '@mui/material/Checkbox';

import { TableHeaderRow, TableHeaderCellSort, TableLeadCell } from 'src/components/shared/Table';
import { CreditErrorSortBy, ICreditErrorSortBy, ISortProps, SortDirection } from 'src/types';

const ErrorTypesHeader = ({ selected, sort, onSort }: ISortProps<ICreditErrorSortBy> & { selected: boolean }) => {
  const handleSortChange = useCallback(
    (fieldName: ICreditErrorSortBy) => {
      if (sort.by !== fieldName) {
        onSort({
          by: fieldName,
          order: SortDirection.DESC,
        });
      } else {
        onSort({
          ...sort,
          order: sort.order === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
        });
      }
    },
    [sort, onSort],
  );

  return (
    <TableHeaderRow>
      <TableLeadCell>
        <Checkbox id="ALL" checked={selected} disableRipple />
      </TableLeadCell>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === CreditErrorSortBy.error_name}
        onClick={() => handleSortChange(CreditErrorSortBy.error_name)}>
        Error type
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === CreditErrorSortBy.service}
        onClick={() => handleSortChange(CreditErrorSortBy.service)}>
        Services
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === CreditErrorSortBy.unfavorable_errors_count}
        onClick={() => handleSortChange(CreditErrorSortBy.unfavorable_errors_count)}>
        Errors (#)
      </TableHeaderCellSort>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === CreditErrorSortBy.unfavorable_errors_dollars}
        onClick={() => handleSortChange(CreditErrorSortBy.unfavorable_errors_dollars)}>
        Errors ($)
      </TableHeaderCellSort>
    </TableHeaderRow>
  );
};

export default memo(ErrorTypesHeader);
