import { memo } from 'react';
import Typography from '@mui/material/Typography';

import { CardHead } from 'src/components/shared/Card';

import PieChart, { IPieChartSegment } from './PieChart';
import { StyledPiChartCard, StyledPiChartChart, StyledPiChartCardLegend, StyledPiChartLegendItem } from './styles';

export interface IPieChartCardProps {
  title: string;
  emptyMessage: string;
  segments: IPieChartSegment[];
}

const PieChartCard = ({ segments, title, emptyMessage }: IPieChartCardProps) => (
  <StyledPiChartCard>
    <StyledPiChartChart>
      <CardHead name={title} />
      {segments.length ? (
        <PieChart size={100} segments={segments} />
      ) : (
        <Typography variant="h4" sx={{ color: 'text.disabled' }}>
          {emptyMessage}
        </Typography>
      )}
    </StyledPiChartChart>
    <StyledPiChartCardLegend>
      {segments.map((segment) => (
        <StyledPiChartLegendItem key={`pie-chart-legend-${segment.name}`} $color={segment.color} $value={segment.view}>
          {segment.name}
        </StyledPiChartLegendItem>
      ))}
    </StyledPiChartCardLegend>
  </StyledPiChartCard>
);

export default memo(PieChartCard) as typeof PieChartCard;
