import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';

import * as apiLogistics from 'src/api/logistics';
import makeRequest from 'src/effects/makeRequest';
import { ILogisticsProviderSchema } from 'src/schemas';
import { ButtonLink } from 'src/components/shared/Buttons';
import { PageTitle, TitleButtons, PageContainer } from 'src/components/shared/Page';
import { ProviderForm, ProviderSubmitButton } from 'src/components/LogisticsProviderForm';

const PROVIDER_FORM_ID = 'provider-form';
const useLogisticsProviderRequest = makeRequest(apiLogistics.createLogisticsProvider);

export default function ThreePLPage() {
  const navigate = useNavigate();
  const logisticsProviderRequest = useLogisticsProviderRequest(null, false);

  const handleSubmit = (provider: ILogisticsProviderSchema) => {
    logisticsProviderRequest.make({
      name: provider.name,
      description: provider.description,
    });
    logisticsProviderRequest.onFulfill(() => navigate('/settings/logistics'));
    logisticsProviderRequest.onReject(() => navigate('/error'));
  };

  return (
    <>
      <PageTitle title={'Logistics profile'} description={'Set-up your 3PLs, warehouse locations'}>
        <TitleButtons align="left">
          <ProviderSubmitButton id={PROVIDER_FORM_ID} disabled={logisticsProviderRequest.loading} />
          <ButtonLink title="Cancel" to={'/settings/logistics'} variant="outlined" />
        </TitleButtons>
      </PageTitle>
      <PageContainer>
        <Divider />
        <ProviderForm id={PROVIDER_FORM_ID} onSubmit={handleSubmit} />
      </PageContainer>
    </>
  );
}
