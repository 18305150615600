import { ID } from 'src/types';

import request from './requestPrivate';

export const uploadFile = ({
  file,
  onUploadProgress,
}: {
  file: File;
  onUploadProgress: (event: ProgressEvent) => void;
}) => {
  const formData = new FormData();

  formData.append('file', file);

  return request.post<number>('/upload', formData, null, {
    onUploadProgress: ({ event }) => onUploadProgress(event),
  });
};

export const downloadFile = ({
  id,
  onDownloadProgress,
}: {
  id: ID;
  onDownloadProgress: (event: ProgressEvent) => void;
}) =>
  request.get<Blob>(`/download/${id}`, null, {
    responseType: 'blob',
    onDownloadProgress: ({ event }) => onDownloadProgress(event),
  });
