import { createEnumParam, withDefault } from 'use-query-params';

import { SortDirection, AnomalyOrderSortBy, AnomalyOrdersFiltersConfig, IAnomalyOrdersFilters } from 'src/types';
import { makeQueryFilter } from 'src/components/shared/QueryFilter';
import { makeNamedQueryParams } from 'src/effects/useNamedQueryParams';

const SortDirectionParam = withDefault(createEnumParam(Object.values(SortDirection)), SortDirection.DESC);
const AnomalySortParam = withDefault(createEnumParam(Object.values(AnomalyOrderSortBy)), AnomalyOrderSortBy.SHIP_DATE);

export const anomaliesFilterQuery = makeQueryFilter<IAnomalyOrdersFilters>('af', AnomalyOrdersFiltersConfig);

export const anomaliesSortQuery = makeNamedQueryParams('as', {
  by: AnomalySortParam,
  order: SortDirectionParam,
});
