import { StyledSvgIcon } from './styles';
import withIconRef from './withIconRef';

export const UserActiveIconIcon = withIconRef((props, ref) => (
  <StyledSvgIcon {...props} ref={ref} width="20" height="16" viewBox="0 0 20 16">
    <path d="m9.9719 3.7263e-4c0.46252-0.0125 0.87192 0.29063 0.99372 0.7375l2.3125 8.475 0.5532-1.1062c0.3374-0.67812 1.0312-1.1062 1.7874-1.1062h3.3813c0.5531 0 1 0.44688 1 1 0 0.55313-0.4469 1-1 1h-3.3813l-1.7249 3.4468c-0.1844 0.3719-0.5813 0.5875-0.9938 0.5469s-0.7562-0.3312-0.8656-0.7312l-1.9188-7.0344-2.1375 9.9812c-0.09688 0.45-0.48438 0.775-0.94375 0.7907-0.45938 0.0156-0.86875-0.2844-0.99375-0.725l-1.7938-6.275h-3.2469c-0.55312 0-1-0.44687-1-1 0-0.55312 0.44688-1 1-1h3.2469c0.89375 0 1.6781 0.59063 1.9219 1.45l0.69375 2.425 2.1594-10.084c0.09687-0.45 0.49062-0.77813 0.95-0.79063z" />
  </StyledSvgIcon>
));

export const UserInactiveIconIcon = withIconRef((props, ref) => (
  <StyledSvgIcon {...props} ref={ref} width="20" height="16" viewBox="0 0 20 16">
    <path d="m0.83378 0.0020803c-0.24849-0.018093-0.50206 0.082063-0.66784 0.29154-0.26524 0.33517-0.20591 0.82053 0.13083 1.0845l18.438 14.457c0.33674 0.26401 0.82438 0.20494 1.0896-0.13022 0.26525-0.33516 0.20785-0.82053-0.12888-1.0845l-4.9619-3.8892 0.8846-1.7298h3.3822c0.55299 0 0.99981-0.4447 0.99981-0.99514 0-0.55043-0.44681-0.99514-0.99981-0.99514h-3.3822c-0.75605 0-1.4493 0.42527-1.7868 1.1001l-0.55263 1.102-2.3121-8.4334c-0.12188-0.4447-0.53151-0.74713-0.99395-0.73469-0.45929 0.012439-0.85219 0.33936-0.94904 0.78717l-1.0037 4.6355-6.7643-5.3022c-0.12628-0.099-0.2727-0.15241-0.42179-0.16326zm9.2834 5.2458 0.65222 2.3751-0.992-0.77745zm-9.1135 1.7629c-0.55301 0-0.99981 0.44471-0.99981 0.99514 0 0.55044 0.44679 0.99514 0.99981 0.99514h3.2455l1.7926 6.2449c0.12498 0.43846 0.53466 0.73661 0.99395 0.72109 0.45928-0.01553 0.84632-0.33936 0.94318-0.78717l1.0896-5.0554-1.7497-1.3703-0.45499 2.1127-0.69323-2.414c-0.2437-0.85519-1.0279-1.4422-1.9215-1.4422z" />
  </StyledSvgIcon>
));
