import { memo } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ReplayIcon from '@mui/icons-material/Replay';

import { UploadState } from 'src/effects/useFileUploadState';

import { StyledContainer, StyledDescription, UploadProgress } from './styles';

export interface IUploadingProps {
  onReupload: () => void;
  canReupload: boolean;
  uploadState: UploadState;
}

const Uploading = ({ uploadState, canReupload, onReupload }: IUploadingProps) => {
  const isFailed = uploadState.status === 'failed';
  const isUploading = uploadState.status === 'uploading';

  return (
    <StyledContainer $failed={isFailed}>
      <StyledDescription>
        <Typography variant="body1">{uploadState.name}</Typography>
        {isUploading && <UploadProgress variant="determinate" value={uploadState.progress} />}
      </StyledDescription>
      {isFailed && canReupload && (
        <IconButton onClick={onReupload}>
          <ReplayIcon />
        </IconButton>
      )}
    </StyledContainer>
  );
};

export default memo(Uploading);
