import { memo } from 'react';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';

import { IWarehouse } from 'src/types';
import { CloseIcon } from 'src/components/Icons';
import { Loading } from 'src/components/shared/Page';

import { StyledCloseButton, StyledDialogHeader, StyledDialogPaper } from './styles';
import WarehouseList from './WarehouseList';

export interface IAnomalyDetailsDialogProps {
  loading: boolean;
  payload: IWarehouse[];
  open: boolean;
  onClose: () => void;
}

const AnomalyDetailsDialog = ({ open, onClose, loading, payload }: IAnomalyDetailsDialogProps) => (
  <Dialog
    maxWidth={false}
    open={open}
    onClose={onClose}
    PaperComponent={StyledDialogPaper}
    PaperProps={{ elevation: 2 }}>
    <>
      <StyledDialogHeader>
        <Typography variant="h2">Warehouses</Typography>
        <StyledCloseButton onClick={onClose}>
          <CloseIcon />
        </StyledCloseButton>
      </StyledDialogHeader>
      {loading ? <Loading /> : <WarehouseList payload={payload} />}
    </>
  </Dialog>
);

export default memo(AnomalyDetailsDialog);
