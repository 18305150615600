import * as yup from 'yup';

export const LogisticsProviderSchema = yup.object().shape({
  name: yup.string().required().label('Name'),
  description: yup.string().default('').label('Description'),
});

export type ILogisticsProviderSchema = yup.InferType<typeof LogisticsProviderSchema>;

export const WarehouseSchema = yup.object().shape({
  name: yup.string().required().label('Name'),
  logistics_provider_id: yup.number().required().label('3PL'),
  description: yup.string().default('').label('Description'),

  country: yup.string().label('Country'),
  city: yup.string().label('City'),
  state: yup.string().label('State'),
  zip_code: yup.string().label('Zip code'),
  address_line_1: yup.string().label('Address 1'),
  address_line_2: yup.string().label('Address 2'),
});

export type IWarehouseSchema = yup.InferType<typeof WarehouseSchema>;
