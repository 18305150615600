import { memo } from 'react';

import { TableBody } from 'src/components/shared/Table';
import { Loading } from 'src/components/shared/Page';
import Message from 'src/components/shared/Message';
import { IAnomalyOrder, IAnomalyOrderSortBy, ISort } from 'src/types';

import { IAnomalyOrderHeadAction } from './AnomalyOrderHead';
import { IAnomalyOrderRowAction } from './AnomalyOrderRow';

export interface IAnomalyOrderListProps {
  loading: boolean;
  payload: MaybeNull<IAnomalyOrder[]>;
  sorting: ISort<IAnomalyOrderSortBy>;
  onSortChange: (sort: ISort<IAnomalyOrderSortBy>) => void;
  rowAction: IAnomalyOrderRowAction;
  headAction: IAnomalyOrderHeadAction;
}

const AnomalyOrderList = ({
  loading,
  payload,
  sorting,
  onSortChange,
  rowAction: RowAction,
  headAction: HeadAction,
}: IAnomalyOrderListProps) => (
  <>
    <TableBody>
      <HeadAction sort={{ by: sorting.by, order: sorting.order }} onSort={onSortChange} />
      {!loading && payload && (
        <>
          {payload.map((anomaly) => (
            <RowAction key={`anomaly-row-${anomaly.anomaly_id}`} anomaly={anomaly} />
          ))}
        </>
      )}
    </TableBody>
    {loading ? <Loading /> : !payload?.length && <Message>No anomalies</Message>}
  </>
);

export default memo(AnomalyOrderList);
