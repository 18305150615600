import { memo } from 'react';

import {
  ISort,
  ISortDirection,
  IInvoiceDetailDataSortBy,
  IInvoiceServiceDataItem,
  IInvoiceServiceTotalData,
} from 'src/types';
import { Loading } from 'src/components/shared/Page';
import { TableBody } from 'src/components/shared/Table';
import Message from 'src/components/shared/Message';

import { ServicesRow } from './ServicesRow';
import { ServicesHeaders } from './ServicesHeaders';
import { ServicesTotal } from './ServicesTotal';

export type IServicesListParams = {
  by: IInvoiceDetailDataSortBy;
  order: ISortDirection;
};

export interface IServicesListProps {
  loading: boolean;
  params: IServicesListParams;
  data: Maybe<IInvoiceServiceDataItem[]>;
  total: Maybe<IInvoiceServiceTotalData>;
  navServices: string[];
  changeSorting: (sort: ISort<IInvoiceDetailDataSortBy>) => void;
  onServiceTypeClick: (name: string) => void;
}

const ServicesList = ({
  loading,
  params,
  data,
  total,
  navServices,
  changeSorting,
  onServiceTypeClick,
}: IServicesListProps) =>
  loading ? (
    <Loading />
  ) : (
    <TableBody>
      <ServicesHeaders sort={{ by: params.by, order: params.order }} onSort={changeSorting} />
      {!loading && !!data ? (
        <>
          {data.map((item, index) => (
            <ServicesRow
              key={`services-row-${index}`}
              item={item}
              isNavService={navServices.includes(item.service_name)}
              onServiceTypeClick={onServiceTypeClick}
            />
          ))}
          {total && <ServicesTotal total={total} />}
        </>
      ) : (
        <Message>No details for selected invoice</Message>
      )}
    </TableBody>
  );

export default memo(ServicesList);
