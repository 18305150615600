import { ICredit, ICreditStatus, ID } from 'src/types';

import request from './requestPrivate';

export const getCredits = () => request.get<ICredit[]>(`/credit-request`);

export const changeCreditStatus = (id: ID, status: ICreditStatus) =>
  request.put<{ status: ICreditStatus }>(`/credit-request/actions/${encodeURIComponent(id)}`, null, { status });

export const getCreditReportId = (id: ID) => request.post<string>(`/export/credit-request/${encodeURIComponent(id)}`);
