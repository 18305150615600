import Typography from '@mui/material/Typography';

import { InvoiceDataType, ReconcileDataType } from 'src/types';
import { invoiceDetailsQuery } from 'src/components/Invoice';

import SubmenuLink from './SubmenuLink';
import { StyledSubmenuContainer, StyledSubmenuSegment, StyledSubmenuGroup, StyledSubmenuNav } from './styles';

export const InvoicesSubmenu = () => (
  <StyledSubmenuContainer>
    <StyledSubmenuSegment>
      <StyledSubmenuGroup>
        <Typography variant="h3">Invoices</Typography>
        <StyledSubmenuNav>
          <SubmenuLink to={'/invoices'}>All Invoices</SubmenuLink>
          <SubmenuLink
            to={`/invoices/details?${invoiceDetailsQuery.getSearchString({
              type: InvoiceDataType.SUMMARY_SERVICE,
            })}`}>
            Service Level
          </SubmenuLink>
          <SubmenuLink
            to={`/invoices/details?${invoiceDetailsQuery.getSearchString({
              type: InvoiceDataType.PICK_AND_PACK,
            })}`}>
            Pick and Pack
          </SubmenuLink>
          <SubmenuLink disabled to={'/'}>
            Freight
          </SubmenuLink>
        </StyledSubmenuNav>
      </StyledSubmenuGroup>
      <StyledSubmenuGroup>
        <Typography variant="h3">Reconciliation</Typography>
        <StyledSubmenuNav>
          <SubmenuLink to={`/invoice/latest/reconcile?type=${encodeURIComponent(ReconcileDataType.ERROR_SUMMARY)}`}>
            Error Summary
          </SubmenuLink>
          <SubmenuLink to={`/invoice/latest/reconcile?type=${encodeURIComponent(ReconcileDataType.ORDERS)}`}>
            Orders
          </SubmenuLink>
          <SubmenuLink disabled to={'/'}>
            Tagged Orders
          </SubmenuLink>
        </StyledSubmenuNav>
      </StyledSubmenuGroup>
      <StyledSubmenuGroup>
        <Typography variant="h3">Order Details</Typography>
        <StyledSubmenuNav>
          <SubmenuLink to={'/invoice/latest/order'}>Order Details</SubmenuLink>
        </StyledSubmenuNav>
      </StyledSubmenuGroup>
      <StyledSubmenuGroup>
        <Typography variant="h3">Credits</Typography>
        <StyledSubmenuNav>
          <SubmenuLink to={'/invoice/credits/tracker'}>Credit Tracker</SubmenuLink>
        </StyledSubmenuNav>
      </StyledSubmenuGroup>
    </StyledSubmenuSegment>
  </StyledSubmenuContainer>
);
