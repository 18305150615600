import { PropsWithChildren, memo } from 'react';

import { StyledAuthorizedMessage } from './styles';

const MessageAuthorized = ({ children }: PropsWithChildren) => (
  <StyledAuthorizedMessage>
    <h1>You are authenticated</h1>
    <p>To log in as another user, please log out.</p>
    {children}
  </StyledAuthorizedMessage>
);

export default memo(MessageAuthorized);
