import { memo, useMemo } from 'react';

import { AnomalyType, IAnomalyType } from 'src/types';

export interface IAnomalyTypeStatusChipProps {
  type: MaybeNull<IAnomalyType>;
}

export const getAnomalyTypeLabel = (status: Maybe<IAnomalyType>) => {
  const labels = {
    [AnomalyType.ACTUAL_WEIGHT]: 'Weight',
    [AnomalyType.DIM_WEIGHT]: 'Dim Weight',
    [AnomalyType.TOTAL_ACCESSORIALS_COST]: 'Accessorials',
    [AnomalyType.TOTAL_COST]: 'Total cost',
    [AnomalyType.ZONE]: 'Zone',
  };

  return status ? labels[status] ?? 'Unknown' : 'Unknown';
};
const AnomalyTypeStatusChip = ({ type }: IAnomalyTypeStatusChipProps) => {
  const label = useMemo(() => getAnomalyTypeLabel(type), [type]);

  return <>{label}</>;
};

export default memo(AnomalyTypeStatusChip);
