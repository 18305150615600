import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { useRole } from 'src/access';
import { UserRole } from 'src/types';

const useEntryPage = () => {
  const role = useRole();

  return useMemo(() => {
    switch (role) {
      case UserRole.IMPLENTIO_ADMIN:
        return '/system/companies';
      default:
        return '/invoices';
    }
  }, [role]);
};

export const Entry = () => {
  const rootPage = useEntryPage();

  return <Navigate to={rootPage} replace />;
};
