import { IAnalyticReport, ReportType } from 'src/types';

const DEFAULT_REPORT_NAME = 'Reporting';
const REPORT_NAME: Record<string, string> = {
  [ReportType.INVOICE_SUMMARY]: 'Invoice Summary',
  [ReportType.INVOICE_SERVICE_DETAIL]: 'Invoice Service Detail Report',
  [ReportType.PICK_AND_PACK_SUMMARY]: 'Pick And Pack Summary Report',
  [ReportType.ERROR_SUMMARY]: 'Error Summary',
  [ReportType.ERROR_DETAIL]: 'Error Detail Report',
  [ReportType.FAVORABLE_ERRORS]: 'Favorable Errors',
};

export const getReportName = (type?: Maybe<string>): string =>
  (type != null ? REPORT_NAME[type] : DEFAULT_REPORT_NAME) ?? DEFAULT_REPORT_NAME;

export const REPORTS_DATA: IAnalyticReport[] = [
  {
    name: getReportName(ReportType.INVOICE_SUMMARY),
    description: 'Invoice Overview',
    type: 'Invoices',
    reportType: ReportType.INVOICE_SUMMARY,
  },
  {
    name: getReportName(ReportType.INVOICE_SERVICE_DETAIL),
    description: 'Invoice summary with service level detail',
    type: 'Invoices',
    reportType: ReportType.INVOICE_SERVICE_DETAIL,
  },
  {
    name: getReportName(ReportType.PICK_AND_PACK_SUMMARY),
    description: 'Summary report of pick and pack charges',
    type: 'Invoices',
    reportType: ReportType.PICK_AND_PACK_SUMMARY,
  },
  {
    name: getReportName(ReportType.ERROR_SUMMARY),
    description: 'Breakdown by error type',
    type: 'Reconciliations',
    reportType: ReportType.ERROR_SUMMARY,
  },
  {
    name: getReportName(ReportType.ERROR_DETAIL),
    description: 'List of orders by error type',
    type: 'Reconciliations',
    reportType: ReportType.ERROR_DETAIL,
  },
  {
    name: getReportName(ReportType.FAVORABLE_ERRORS),
    description: 'Breakdown by error type (favorable errors only)',
    type: 'Reconciliations',
    reportType: ReportType.FAVORABLE_ERRORS,
  },
];
