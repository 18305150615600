import Link from '@mui/material/Link';

import { IInvoiceServiceDataItem } from 'src/types';
import { TableRow, TableCell, TableLeadCell } from 'src/components/shared/Table';
import currencyFormatter from 'src/utils/currencyFormatter';
import numberFormatter from 'src/utils/numberFormatter';

const formatUSD = currencyFormatter();
const formatNumber = numberFormatter();

export interface IServicesRowProps {
  item: IInvoiceServiceDataItem;
  isNavService: boolean;
  onServiceTypeClick: (name: string) => void;
}

export const ServicesRow = ({ item, isNavService, onServiceTypeClick }: IServicesRowProps) => (
  <TableRow>
    <TableLeadCell />
    <TableCell>
      {isNavService ? (
        <Link sx={{ cursor: 'pointer' }} onClick={() => onServiceTypeClick(item.service_name)}>
          {item.service_name}
        </Link>
      ) : (
        <>{item.service_name}</>
      )}
    </TableCell>
    <TableCell>{item.audit_depth}</TableCell>
    <TableCell>{formatUSD(item.logistics_charges)}</TableCell>
    <TableCell>{formatNumber(item.unfavorable_errors_count)}</TableCell>
    <TableCell>{formatUSD(item.unfavorable_errors_dollars)}</TableCell>
  </TableRow>
);
