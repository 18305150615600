import { memo, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import shouldForwardProp from 'src/utils/shouldForwardProp';
import { getMetabaseLocation } from 'src/config/report';
import { Loading } from 'src/components/shared/Page';

const StyledBlock = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr;

  & > * {
    grid-row-start: 1;
    grid-column-start: 1;
  }
`;

const StyledFrame = styled('iframe', { shouldForwardProp })<{ $active?: boolean }>`
  width: 100%;
  height: 100%;
  border: none;

  transition: ${({ theme }) => theme.transitions.create(['opacity'])};
  opacity: ${({ $active }) => ($active ? 1 : 0)};
`;

export interface IReportFrameProps {
  token: MaybeNull<string>;
}

const ReportFrame = ({ token }: IReportFrameProps) => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => setLoading(true), [token]);

  return (
    <StyledBlock>
      {isLoading && <Loading />}
      <StyledFrame
        $active={!isLoading}
        src={token ? getMetabaseLocation(token) : ''}
        onLoad={() => setLoading(false)}
      />
    </StyledBlock>
  );
};

export default memo(ReportFrame) as typeof ReportFrame;
