import { memo } from 'react';

import { TableBody } from 'src/components/shared/Table';
import Message from 'src/components/shared/Message';
import { ID, ILogisticsProvider } from 'src/types';

import { LogisticsHeaders } from './LogisticsHeaders';
import { LogisticsRow } from './LogisticsRow';

export interface ILogisticsListProps {
  payload: ILogisticsProvider[];
  onWarehouseView: (id: ID) => void;
}

const LogisticsList = ({ payload, onWarehouseView }: ILogisticsListProps) => (
  <>
    <TableBody>
      <LogisticsHeaders />
      {!!payload.length && (
        <>
          {payload.map((provider) => (
            <LogisticsRow key={`logistics-row-${provider.id}`} provider={provider} onWarehouseView={onWarehouseView} />
          ))}
        </>
      )}
    </TableBody>
    {!payload.length && <Message>No logistics providers</Message>}
  </>
);

export default memo(LogisticsList);
