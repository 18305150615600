import { memo } from 'react';

import { IAnomalyComparable } from 'src/types';
import { TableBody } from 'src/components/shared/Table';

import AnomalyComparableHead from './AnomalyComparableHead';
import AnomalyComparableRow from './AnomalyComparableRow';

export interface IAnomalyComparableListProps {
  anomalies: IAnomalyComparable[];
}

const AnomalyComparableList = ({ anomalies }: IAnomalyComparableListProps) => (
  <TableBody>
    <AnomalyComparableHead />
    {anomalies.map((anomaly, index) => (
      <AnomalyComparableRow key={`anomaly-comparable-${index}`} anomaly={anomaly} />
    ))}
  </TableBody>
);

export default memo(AnomalyComparableList);
