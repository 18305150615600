import { useMemo, useTransition } from 'react';

import { ISort, IInvoiceDetailDataSortBy, IInvoiceDataType } from 'src/types';
import { servicesQuery, pickAndPackQuery, invoiceDetailsQuery, invoicesDateRangeQuery } from 'src/components/Invoice';

export const usePickAndPackControl = () => {
  const [, startTransition] = useTransition();
  const [params, setParams] = pickAndPackQuery.useParams();

  const changeSorting = (sort: ISort<IInvoiceDetailDataSortBy>) =>
    startTransition(() => setParams({ by: sort.by, order: sort.order }));

  return {
    params,
    changeSorting,
  };
};

export const useServicesControl = () => {
  const [, startTransition] = useTransition();
  const [params, setParams] = servicesQuery.useParams();

  const changeSorting = (sort: ISort<IInvoiceDetailDataSortBy>) =>
    startTransition(() => setParams({ by: sort.by, order: sort.order }));

  return {
    params,
    changeSorting,
  };
};

export const useInvoiceDetailsControl = () => {
  const [params, setParams] = invoiceDetailsQuery.useParams();

  const type = useMemo<IInvoiceDataType>(() => params.type as IInvoiceDataType, [params.type]);
  const setType = (type: IInvoiceDataType) => setParams({ type });

  const name = useMemo<Maybe<string>>(() => params.name, [params.name]);
  const setName = (name: string) => setParams({ name });

  return {
    type,
    name,
    setType,
    setName,
  };
};

export const useInvoicesDateRangeControl = () => {
  const [dateRange, setDateRange] = invoicesDateRangeQuery.useParams();

  return {
    dateRange,
    setDateRange,
  };
};
