import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { RateCardDetails } from 'src/components/RateCardDetails';
import { PageTitle, TitleButtons, Loading } from 'src/components/shared/Page';
import { ButtonLink } from 'src/components/shared/Buttons';
import RateCardFiles from 'src/components/RateCardFiles';
import { useFilesDownloadState } from 'src/effects/useFilesDownloadState';
import { saveAsBlob } from 'src/utils/saveFile';
import { Access, UserRole } from 'src/access';
import makeRequest from 'src/effects/makeRequest';
import * as apiRateCard from 'src/api/rateCard';
import * as apiFile from 'src/api/file';

const useRateCardRequest = makeRequest(apiRateCard.getRateCard);
const useRateCardFilesRequest = makeRequest(apiRateCard.getRateCardFiles);
const useFileDownloadRequest = makeRequest(apiFile.downloadFile);

export default function RateCardDetailsPage() {
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const rateCardId = useMemo(() => Number.parseInt(id as string), [id]);

  const rateCardRequest = useRateCardRequest(null, true);
  const rateCardFilesRequest = useRateCardFilesRequest([], true);
  const fileDownloadRequest = useFileDownloadRequest(null);
  const filesDownloadState = useFilesDownloadState();

  const rateCardFiles = rateCardFilesRequest.loading ? null : rateCardFilesRequest.data;

  const handleFileDownload = (id: number, fileName: string) => {
    const onDownloadProgress = (event: ProgressEvent) => filesDownloadState.updateProgress(id, event);

    filesDownloadState.start(id);
    fileDownloadRequest.make({ id, onDownloadProgress });
    fileDownloadRequest.onFulfill((blob) => {
      filesDownloadState.updateStatus(id, 'downloaded');
      saveAsBlob(blob, fileName);
    });
    fileDownloadRequest.onReject(() => filesDownloadState.updateStatus(id, 'failed'));
  };

  const handleFilesReload = useCallback(() => {
    fileDownloadRequest.cleanup();
    filesDownloadState.cleanup();

    rateCardFilesRequest.make(rateCardId);
  }, [rateCardId, rateCardFilesRequest]);

  useEffect(() => {
    rateCardRequest.make(rateCardId);
    rateCardFilesRequest.make(rateCardId);
  }, [rateCardId]);
  useEffect(() => {
    if (rateCardRequest.error || rateCardFilesRequest.error) navigate('/error');
  }, [rateCardRequest.error, rateCardFilesRequest.error]);

  return (
    <>
      <PageTitle title={`${rateCardRequest.data?.name ?? ''} rate card`}>
        <TitleButtons align="left">
          <Access roles={[UserRole.CUSTOMER_ADMIN]}>
            <ButtonLink title="Create rate card" to={'/settings/ratecard'} variant="contained" />
          </Access>
          <RateCardFiles
            files={rateCardFiles}
            downloadState={filesDownloadState.state}
            onReload={handleFilesReload}
            onDownload={handleFileDownload}
          />
          <ButtonLink title="Back" to={'/settings/ratecards'} variant="outlined" />
        </TitleButtons>
      </PageTitle>
      {rateCardRequest.loading ? (
        <Loading />
      ) : (
        rateCardRequest.data && <RateCardDetails rateCard={rateCardRequest.data} />
      )}
    </>
  );
}
