import { memo } from 'react';

import { IRateCardFile } from 'src/types';
import { DownloadFileState } from 'src/effects/useFilesDownloadState';

import {
  StyledFile,
  StyledDownloadIcon,
  StyledFileIcon,
  StyledFileDetails,
  StyledFileName,
  StyledProgress,
  StyledUploadProgress,
} from './styles';
import FileStatus from './FileStatus';

export interface IFileProps {
  file: IRateCardFile;
  state: Maybe<DownloadFileState>;
  onDownload: () => void;
}

const File = ({ file, state, onDownload }: IFileProps) => {
  const isDownloading = state?.status === 'downloading';
  const isFailed = state?.status === 'failed';

  return (
    <StyledFile $failed={isFailed}>
      <StyledFileIcon />
      <StyledFileDetails>
        <StyledProgress>
          <StyledFileName>{file.filename}</StyledFileName>
          {isDownloading && <StyledUploadProgress variant="determinate" value={state.progress} />}
        </StyledProgress>
        {!isDownloading && <StyledDownloadIcon onClick={onDownload} />}
        <FileStatus status={file.status} />
      </StyledFileDetails>
    </StyledFile>
  );
};

export default memo(File);
