import { memo, useTransition } from 'react';

import { IFilterType, IFiltersValues } from 'src/types';
import Message from 'src/components/shared/Message';

import { IFilterOptions, IQueryFilter } from './useQueryFilter';
import FilterList from './FilterList';
import AddFilter from './AddFilter';
import { StyledMenuList } from './styles';

export interface IQueryFilterProps<T extends IFilterOptions> {
  control: IQueryFilter<T>;
}

const QueryFilter = <T extends IFilterOptions>({ control }: IQueryFilterProps<T>) => {
  const [, startTransition] = useTransition();

  const handleRemove = (key: keyof T) =>
    startTransition(() => {
      control.removeFormOrder(key);
      control.removeParam(key);
    });

  const handleOrderChange = (keys: (keyof T)[]) =>
    startTransition(() => {
      const outdatedOrder = control.order.filter((option) => !keys.includes(option));

      control.setOrder(keys);
      control.removeParams(outdatedOrder);
    });

  const handleParamChange = (key: keyof T, value: IFiltersValues[IFilterType]) =>
    startTransition(() => {
      control.setParam(key, value);
    });

  if (!control.options) return <Message>Filters are building</Message>;

  return (
    <StyledMenuList>
      <FilterList
        order={control.order}
        params={control.params}
        config={control.options}
        onRemove={handleRemove}
        onChange={handleParamChange}
      />
      <AddFilter order={control.order} config={control.options} onChange={handleOrderChange} />
    </StyledMenuList>
  );
};

export default memo(QueryFilter) as typeof QueryFilter;
