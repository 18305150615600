import { useNavigate } from 'react-router-dom';

import { ButtonLink } from 'src/components/shared/Buttons';
import { RateCardForm, RateCardSubmitButton } from 'src/components/RateCardForm';
import { PageTitle, TitleButtons, PageContainer, Loading } from 'src/components/shared/Page';
import makeRequest from 'src/effects/makeRequest';
import { IRateCardSchema, schemaToRateCard } from 'src/schemas';
import { useFileUploadState } from 'src/effects/useFileUploadState';
import { useFileDownloadState } from 'src/effects/useFileDownloadState';
import { saveAsBlob } from 'src/utils/saveFile';
import * as apiRateCard from 'src/api/rateCard';
import * as apiFile from 'src/api/file';
import * as apiLogistics from 'src/api/logistics';
import { ID } from 'src/types';

const useFileUploadRequest = makeRequest(apiFile.uploadFile);
const useFileDownloadRequest = makeRequest(apiFile.downloadFile);
const useRateCardRequest = makeRequest(apiRateCard.createRateCard);
const useLogisticsRequest = makeRequest(apiLogistics.getLogisticsProviders);
const useWarehousesRequest = makeRequest(apiLogistics.getWarehouses);

export default function RateCardNewPage() {
  const rateCardFormId = 'rate-card-form';
  const navigate = useNavigate();

  const logisticsRequest = useLogisticsRequest([]);
  const logistics = logisticsRequest.loading ? null : logisticsRequest.data;
  const askLogistics = () => logisticsRequest.make();

  const warehousesRequest = useWarehousesRequest([]);
  const warehouses = warehousesRequest.loading ? null : warehousesRequest.data;
  const askWarehouses = (logisticsId?: number) => {
    if (logisticsId) warehousesRequest.make(logisticsId);
    else warehousesRequest.cleanup();
  };

  const rateCardRequest = useRateCardRequest(null);
  const handleSubmit = (rateCard: IRateCardSchema) => {
    rateCardRequest.make(schemaToRateCard(rateCard));
    rateCardRequest.onFulfill((rateCardId) => navigate(`/settings/ratecard/${rateCardId}`));
    rateCardRequest.onReject(() => navigate('/error'));
  };

  const fileUploadState = useFileUploadState();
  const fileUploadRequest = useFileUploadRequest(null);
  const handleFileUpload = (file: File) => {
    fileUploadState.start(file.name);
    fileUploadRequest.make({ file, onUploadProgress: fileUploadState.updateProgress });
    fileUploadRequest.onReject(() => fileUploadState.updateStatus('failed'));
    fileUploadRequest.onFulfill((id) => {
      fileUploadState.updateId(id);
      fileUploadState.updateStatus('uploaded');
    });
  };

  const fileDownloadState = useFileDownloadState();
  const fileDownloadRequest = useFileDownloadRequest(null);
  const handleFileDownload = (id: ID) => {
    fileDownloadState.start(id, fileUploadState.state.name);
    fileDownloadRequest.make({ id, onDownloadProgress: fileDownloadState.updateProgress });
    fileDownloadRequest.onFulfill((blob) => {
      fileDownloadState.updateStatus('downloaded');
      saveAsBlob(blob, fileUploadState.state.name);
    });
    fileDownloadRequest.onReject(() => fileDownloadState.updateStatus('failed'));
  };

  const handleFileDelete = () => {
    fileUploadState.cleanup();
    fileDownloadState.cleanup();
  };

  return (
    <>
      <PageTitle title={'Create rate card'} description={'Create your pick and pack rate card.'}>
        <TitleButtons align="left">
          <RateCardSubmitButton id={rateCardFormId} disabled={rateCardRequest.loading} />
          <ButtonLink title="Cancel" to={'/settings/ratecards'} variant="outlined" />
        </TitleButtons>
      </PageTitle>
      <PageContainer>
        {rateCardRequest.loading ? (
          <Loading />
        ) : (
          <RateCardForm
            id={rateCardFormId}
            logistics={logistics}
            askLogistics={askLogistics}
            warehouses={warehouses}
            askWarehouses={askWarehouses}
            onSubmit={handleSubmit}
            uploadState={fileUploadState.state}
            downloadState={fileDownloadState.state}
            onFileDelete={handleFileDelete}
            onFileUpload={handleFileUpload}
            onFileDownload={handleFileDownload}
          />
        )}
      </PageContainer>
    </>
  );
}
