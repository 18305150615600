import { memo } from 'react';

import { IInvoiceDataType, InvoiceDataType } from 'src/types';
import { SlideTabs, SlideTab } from 'src/components/shared/SlideTabs';

export interface IInvoiceTypeProps {
  type: IInvoiceDataType;
  onChange: (type: IInvoiceDataType) => void;
}

const InvoiceType = ({ type, onChange }: IInvoiceTypeProps) => (
  <SlideTabs value={type} onChange={onChange}>
    <SlideTab value={InvoiceDataType.SUMMARY_SERVICE}>Services</SlideTab>
    <SlideTab value={InvoiceDataType.PICK_AND_PACK}>Pick and Pack</SlideTab>
    <SlideTab disabled value={InvoiceDataType.FREIGHT}>
      Freight
    </SlideTab>
  </SlideTabs>
);

export default memo(InvoiceType);
