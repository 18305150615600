import { memo } from 'react';

import {
  CloseButton,
  CreditActions,
  CreditDescription,
  CreditDialog,
  CreditHeader,
} from 'src/components/InvoiceReconcile';

import Steps from './Steps';
import DownloadAttachment from './DownloadAttachment';
import { IDialogControl } from './creditRequestControl';
import { useCreditRequestContext } from './CreditRequestContext';
import { CreditStepsProvider } from './CreditStepsContext';

export interface ICreditRequestDialogProps {
  control: IDialogControl;
}

const CreditRequestDialog = () => {
  const { dialogControl } = useCreditRequestContext();

  return (
    <CreditDialog open={dialogControl.isVisible} onClose={dialogControl.hideDialog}>
      <CreditStepsProvider>
        <CreditHeader title="Request Credits">
          <CreditActions>
            <DownloadAttachment />
            <CloseButton onClick={dialogControl.hideDialog} />
          </CreditActions>
        </CreditHeader>
        <CreditDescription>
          {
            'Use this wizard to create a custom credit request to send to your 3PL. \n\nOnly orders marked “Accepted” and not previously submitted appear below.'
          }
        </CreditDescription>
        <Steps />
      </CreditStepsProvider>
    </CreditDialog>
  );
};

export default memo(CreditRequestDialog);
