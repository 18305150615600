import { NavLink } from 'react-router-dom';
import Link from '@mui/material/Link';

import { IAnalyticReport } from 'src/types';
import { TableRow, TableCell, TableLeadCell } from 'src/components/shared/Table';

import { getReportName } from './utils';

export interface IAnalyticReportRow {
  report: IAnalyticReport;
}

export const AnalyticReportRow = ({ report }: IAnalyticReportRow) => (
  <TableRow>
    <TableLeadCell />
    <TableCell>
      <Link component={NavLink} to={`/analytic/report/${report.reportType}`}>
        {getReportName(report.reportType)}
      </Link>
    </TableCell>
    <TableCell>{report.description}</TableCell>
    <TableCell>{report.type}</TableCell>
  </TableRow>
);
