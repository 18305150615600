import { memo, useCallback } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import { ErrorButton } from 'src/components/shared/Buttons';
import { IRowActionControl } from 'src/components/shared/Table';
import { AnomalyOrderHeader, IAnomalyOrderHeadActionProps } from 'src/components/AnomalyOrderList';

const FreightHeadAction = (props: IAnomalyOrderHeadActionProps) => {
  const renderAction = useCallback(
    ({ hidePopper }: IRowActionControl) => (
      <ButtonGroup component={Paper} variant="popper">
        <Button disabled>Accept All</Button>
        <Button disabled>Reject All</Button>
        <Button disabled>Mark All As Unreviewed</Button>
        <ErrorButton title="Cancel" onClick={hidePopper} />
      </ButtonGroup>
    ),
    [],
  );

  return <AnomalyOrderHeader {...props} renderAction={renderAction} />;
};

export default memo(FreightHeadAction) as typeof FreightHeadAction;
