import { memo } from 'react';

import { Loading } from 'src/components/shared/Page';
import { IAnomaliesAggregation } from 'src/types';

import AnomalyTypeChart from './AnomalyTypeChart';
import AnomalyTabsPanel from './AnomalyTabsPanel';
import { StyledAnomaliesCards } from './styles';

export interface IAnomalyAggregationProps {
  loading: boolean;
  payload: MaybeNull<IAnomaliesAggregation>;
}

const AnomalyAggregation = ({ loading, payload }: IAnomalyAggregationProps) =>
  loading || !payload ? (
    <Loading />
  ) : (
    <StyledAnomaliesCards>
      <AnomalyTypeChart types={payload.total_type_chart} />
      <AnomalyTabsPanel payload={payload} />
    </StyledAnomaliesCards>
  );

export default memo(AnomalyAggregation);
