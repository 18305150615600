import styled from '@emotion/styled';

export const StyledCanvasContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh / 5);
`;

export const StyledCanvas = styled.canvas`
  position: relative;
`;
