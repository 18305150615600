import dayjs, { Dayjs } from 'dayjs';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export interface IFormDateProps<S extends FieldValues> {
  control: Control<S>;
  name: FieldPath<S>;
  format: string;
  minDate?: string | Dayjs;
  maxDate?: string | Dayjs;
  placeholder?: string;
  errorMessage?: Maybe<string>;
}

export const FormDate = <S extends FieldValues>(props: IFormDateProps<S>) => (
  <Controller
    control={props.control}
    name={props.name}
    render={({ field }) => (
      <DatePicker
        format={props.format}
        minDate={dayjs(props.minDate, props.format)}
        maxDate={dayjs(props.maxDate, props.format)}
        value={field.value ? dayjs(field.value, props.format) : null}
        onChange={(value) => field.onChange(value)}
        slotProps={{
          textField: {
            name: props.name,
            placeholder: props.placeholder,
            error: !!props.errorMessage,
            helperText: props.errorMessage,
            autoComplete: 'off',
            variant: 'filled',
          },
        }}
      />
    )}
  />
);
