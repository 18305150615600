import { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import Chip from '@mui/material/Chip';

import { IRateCardFileStatus, RateCardFileStatus } from 'src/types';

const Status = ({ status }: { status: IRateCardFileStatus }) => {
  const { palette } = useTheme();

  const labels = useMemo(
    () => ({
      [RateCardFileStatus.COMPLETE]: 'Complete',
      [RateCardFileStatus.INGESTING]: 'Inserting',
      [RateCardFileStatus.FAILED]: 'Processing',
      [RateCardFileStatus.LANDING]: 'Landing',
    }),
    [],
  );

  const colors = useMemo(
    () => ({
      [RateCardFileStatus.COMPLETE]: palette.success.light,
      [RateCardFileStatus.INGESTING]: palette.info.light,
      [RateCardFileStatus.FAILED]: palette.error.light,
      [RateCardFileStatus.LANDING]: palette.info.light,
    }),
    [],
  );

  const label = labels[status] ?? 'Unknown';
  const color = colors[status] ?? palette.text.disabled;

  return (
    <Chip
      label={label}
      size="medium"
      sx={(theme) => ({ backgroundColor: color, minWidth: theme.spacing(9) })}
      component={'span'}
    />
  );
};

export default Status;
