import styled from '@emotion/styled';

export const StyledInvoiceGridList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(3)};
`;

export const StyledInvoiceList = styled.div`
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(3)};
  margin: ${({ theme }) => theme.spacing(-1)};
  padding: ${({ theme }) => theme.spacing(1)};

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
