import { ThemedComponent } from '@mui/material/styles';
import { alpha } from '@mui/system/colorManipulator';

export const MuiMenu: ThemedComponent<'MuiMenu'> = {
  styleOverrides: {
    paper: {
      overflow: 'auto!important',
    },
    list: {
      overflow: 'hidden',
    },
  },
};

export const MuiMenuList: ThemedComponent<'MuiMenuList'> = {
  defaultProps: {
    disablePadding: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: 'transparent',
      '& ~ svg': {
        position: 'absolute',
        width: theme.spacing(2),
        right: theme.spacing(1),

        '.Mui-focused &': {
          color: theme.palette.primary.main,
        },
        '.Mui-error &': {
          color: theme.palette.error.main,
        },
        '.Mui-error.Mui-focused &': {
          color: theme.palette.error.main,
        },
      },
    }),
  },
};

export const MuiMenuItem: ThemedComponent<'MuiMenuItem'> = {
  styleOverrides: {
    dense: {
      '&::after': {
        content: 'none',
      },
    },
    root: ({ theme }) => ({
      justifyContent: 'space-between',
      height: theme.spacing(5),
      padding: theme.spacing(1, 1.5),
      '&::after': {
        content: '""',
        display: 'inline-block',
        boxSizing: 'border-box',
        borderRadius: '50%',
        width: theme.spacing(2),
        height: theme.spacing(2),
        marginLeft: theme.spacing(1.5),
        background: theme.palette.common.white,
        border: `.5px solid ${theme.palette.text.secondary}`,
      },
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
      },
      '&.Mui-selected': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        '&::after': {
          border: `${theme.spacing(0.5)} solid ${theme.palette.primary.main}`,
        },
      },
      '&.Mui-focusVisible': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
      },
    }),
  },
};
