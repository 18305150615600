import { useCallback } from 'react';

import { TableHeaderRow, TableHeaderCell, TableHeaderCellSort, TableLeadCell } from 'src/components/shared/Table';
import { IBrandSortBy, BrandSortBy, SortDirection, ISortProps } from 'src/types';

export const BrandHeaders = ({ sort, onSort }: ISortProps<IBrandSortBy>) => {
  const handleSortChange = useCallback(
    (fieldName: IBrandSortBy) => {
      if (sort.by !== fieldName) {
        onSort({
          by: fieldName,
          order: SortDirection.DESC,
        });
      } else {
        onSort({
          ...sort,
          order: sort.order === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
        });
      }
    },
    [sort, onSort],
  );

  return (
    <TableHeaderRow>
      <TableLeadCell />
      <TableHeaderCell>ID</TableHeaderCell>
      <TableHeaderCell>Created at</TableHeaderCell>
      <TableHeaderCellSort
        order={sort.order}
        active={sort.by === BrandSortBy.BRAND_NAME}
        onClick={() => handleSortChange(BrandSortBy.BRAND_NAME)}>
        Name
      </TableHeaderCellSort>
      <TableHeaderCell>Email</TableHeaderCell>
      <TableHeaderCell>Status</TableHeaderCell>
    </TableHeaderRow>
  );
};
