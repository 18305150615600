import { forwardRef, useEffect, useMemo, useRef } from 'react';

import { IInvoice } from 'src/types';
import { InvoiceStatusChip } from 'src/components/Status';
import { CardFooter } from 'src/components/shared/Card';
import { ButtonLink } from 'src/components/shared/Buttons';
import { isInvoiceReconciled, isInvoiceUnreconciled } from 'src/components/Invoice';

import { StyledInvoiceList } from './styles';
import InvoiceCard from './InvoiceCard';

export interface IInvoiceCardsProps {
  invoices: IInvoice[];
  activeName: Maybe<string>;
  onChange: (name: string) => void;
}

const InvoiceCards = forwardRef<HTMLDivElement, IInvoiceCardsProps>(({ invoices, activeName, onChange }, ref) => {
  const invoicesNames = useMemo(() => invoices.filter(isInvoiceReconciled).map(({ name }) => name), [invoices]);

  return (
    <StyledInvoiceList ref={ref}>
      {invoices.map((invoice) => {
        const ref = useRef<MaybeNull<HTMLDivElement>>(null);

        const handleInvoiceClick = ({ target }: React.MouseEvent<HTMLDivElement>) => {
          const name = (target as Maybe<HTMLDivElement>)?.nodeName;
          const isTargetAllowed = name && !['BUTTON', 'A'].includes(name);

          if (isTargetAllowed) onChange(invoice.name);
        };

        useEffect(() => {
          if (activeName === invoice.name) ref.current?.scrollIntoView({ behavior: 'smooth', inline: 'center' });
        }, [activeName]);

        return (
          <InvoiceCard
            ref={ref}
            key={invoice.name}
            invoice={invoice}
            isAccent={activeName === invoice.name}
            onClick={handleInvoiceClick}>
            <CardFooter>
              <ButtonLink
                title="View errors"
                variant="text"
                to={`/invoice/${encodeURIComponent(invoice.name)}/reconcile`}
                state={{ invoicesNames, goBack: true }}
                disabled={isInvoiceUnreconciled(invoice)}
              />
              <InvoiceStatusChip status={invoice.status} />
            </CardFooter>
          </InvoiceCard>
        );
      })}
    </StyledInvoiceList>
  );
});

InvoiceCards.displayName = 'InvoiceCards';

export default InvoiceCards;
