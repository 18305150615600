import dayjs from 'dayjs';
import { QueryParamConfig, encodeDate } from 'use-query-params';

import {
  IFilterCriterionNumberValue,
  IFilterDateRangeValue,
  IFilterMultiSelectValue,
  IFilterSingleSelectValue,
} from 'src/types';

type IValueEncode = string | (string | null)[] | null | undefined;

const DIVIDER = '~';

const encodeDateRange = (value?: Maybe<IFilterDateRangeValue>) => {
  if (value == null) return null;

  const from = value?.from ? encodeDate(dayjs(value.from).toDate()) : null;
  const to = value?.to ? encodeDate(dayjs(value.to).toDate()) : null;

  return `${from}${DIVIDER}${to}`;
};

const decodeDateRange = (input?: IValueEncode) => {
  if (!input) return null;

  const normalizedInput = (input instanceof Array ? input[0] : input) ?? '';

  const [fromString, toString] = normalizedInput.split(DIVIDER);

  return {
    from: fromString ?? '',
    to: toString ?? '',
  };
};

export const DateRangeParam: QueryParamConfig<MaybeNull<IFilterDateRangeValue>, MaybeNull<IFilterDateRangeValue>> = {
  encode: encodeDateRange,
  decode: decodeDateRange,
};

const encodeMultiSelect = (value?: Maybe<IFilterMultiSelectValue>) => {
  if (value == null) return null;

  return (value ?? []).map((param) => encodeURIComponent(param)).join(DIVIDER);
};

const decodeMultiSelect = (input?: IValueEncode) => {
  if (!input) return null;

  const normalizedInput = (input instanceof Array ? input[0] : input) ?? '';

  return normalizedInput.split(DIVIDER).map((param) => {
    const decodedParam = decodeURIComponent(param);
    const normalizedParam = isNaN(Number(decodedParam)) ? decodedParam : +decodedParam;

    return normalizedParam;
  });
};

export const MultiSelectParam: QueryParamConfig<
  MaybeNull<IFilterMultiSelectValue>,
  MaybeNull<IFilterMultiSelectValue>
> = {
  encode: encodeMultiSelect,
  decode: decodeMultiSelect,
};

const encodeCriterionNumber = (value?: Maybe<IFilterCriterionNumberValue>) => {
  if (value == null) return null;

  return `${encodeURIComponent(value.criterion ?? '')}${DIVIDER}${encodeURIComponent(value.number ?? '')}`;
};

const decodeCriterionNumber = (input?: IValueEncode) => {
  if (!input) return null;

  const normalizedInput = input instanceof Array ? input[0] : input;

  if (!normalizedInput) return null;

  const [criterionString, numberString] = normalizedInput.split(DIVIDER);

  return {
    criterion: decodeURIComponent(criterionString) as IFilterCriterionNumberValue['criterion'],
    number: +decodeURIComponent(numberString),
  };
};

export const CriterionNumberParam: QueryParamConfig<
  MaybeNull<IFilterCriterionNumberValue>,
  MaybeNull<IFilterCriterionNumberValue>
> = {
  encode: encodeCriterionNumber,
  decode: decodeCriterionNumber,
};

const encodeSingleSelect = (value?: Maybe<IFilterSingleSelectValue>) => {
  if (value == null) return null;

  return `${encodeURIComponent(value)}`;
};

const decodeSingleSelect = (input?: IValueEncode) => {
  if (!input) return null;

  const normalizedInput = input instanceof Array ? input[0] : input;

  if (!normalizedInput) return null;

  const decodedValue = decodeURIComponent(normalizedInput);
  const normalizedValue = isNaN(Number(decodedValue)) ? decodedValue : +decodedValue;

  return normalizedValue;
};

export const SingleSelectParam: QueryParamConfig<
  MaybeNull<IFilterSingleSelectValue>,
  MaybeNull<IFilterSingleSelectValue>
> = {
  encode: encodeSingleSelect,
  decode: decodeSingleSelect,
};
