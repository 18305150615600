import { useCallback, useMemo, useReducer } from 'react';

import { ID } from 'src/types';

export type UploadingStatus = 'uploaded' | 'uploading' | 'failed' | 'initial';
export type UploadState = {
  id: MaybeNull<ID>;
  name: string;
  status: UploadingStatus;
  progress: number;
};

export const useFileUploadState = () => {
  const initialState = useMemo<UploadState>(
    () => ({
      id: null,
      name: '',
      status: 'initial',
      progress: 0,
    }),
    [],
  );

  const [state, dispatch] = useReducer(
    (state: UploadState, update: NestedPartial<UploadState>) => ({
      ...state,
      ...update,
    }),
    initialState,
  );

  const cleanup = useCallback(() => dispatch({ ...initialState }), [state]);

  const start = useCallback((name: string) => dispatch({ ...initialState, name, status: 'uploading' }), [state]);

  const updateId = useCallback((id: ID) => dispatch({ id }), [state]);

  const updateStatus = useCallback((status: UploadingStatus) => dispatch({ status }), [state]);

  const updateProgress = useCallback(
    (event: ProgressEvent) => {
      const progress = (event.loaded / event.total) * 100;

      dispatch({ progress });
    },
    [state],
  );

  const setUploadedState = useCallback(
    (id: ID, name: string) =>
      dispatch({
        id,
        name,
        status: 'uploaded',
        progress: 100,
      }),
    [state],
  );

  return {
    state,
    start,
    cleanup,
    updateId,
    updateStatus,
    updateProgress,
    setUploadedState,
  };
};

export type IUseFileUploadState = typeof useFileUploadState;
