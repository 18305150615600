import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';

export const StyledDialogPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(3, 2)};
  min-width: 900px;
`;

export const StyledDialogHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledCloseButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 0;

  & > svg {
    width: ${({ theme }) => theme.spacing(3)};
  }
`;
