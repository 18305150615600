import { memo, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import { useTheme } from '@emotion/react';

import { IRateCardStatus, RateCardStatus } from 'src/types';

export interface IStatusProps {
  status: IRateCardStatus;
}

const Status = memo(({ status }: IStatusProps) => {
  const { palette } = useTheme();

  const labels = useMemo(
    () => ({
      [RateCardStatus.EXPIRED]: 'Expired',
      [RateCardStatus.PENDING]: 'Pending',
      [RateCardStatus.APPROVED]: 'Approved',
      [RateCardStatus.IN_USE]: 'In Use',
    }),
    [],
  );

  const colors = useMemo(
    () => ({
      [RateCardStatus.EXPIRED]: palette.error.main,
      [RateCardStatus.PENDING]: palette.info.main,
      [RateCardStatus.APPROVED]: palette.success.main,
      [RateCardStatus.IN_USE]: palette.success.main,
    }),
    [],
  );

  const label = labels[status] ?? 'Unknown';
  const color = colors[status] ?? palette.text.disabled;

  return (
    <Typography variant="h4" sx={{ color, textTransform: 'initial' }}>
      {label}
    </Typography>
  );
});

Status.displayName = 'RateCardStatus';

export default Status;
