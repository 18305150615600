import { StyledSvgIcon } from './styles';
import withIconRef from './withIconRef';

export const ArrowTopFilledIcon = withIconRef((props, ref) => (
  <StyledSvgIcon {...props} ref={ref} width="16" height="10" viewBox="0 0 16 10">
    <path d="m6.8699 0.48809c0.62474-0.65079 1.6393-0.65079 2.264 0l6.3973 6.6641c0.4598 0.47898 0.5948 1.1922 0.3449 1.817-0.2499 0.62476-0.8297 1.0308-1.4794 1.0308l-12.795-0.0052061c-0.64474 0-1.2295-0.40609-1.4794-1.0308-0.2499-0.62476-0.10995-1.338 0.34486-1.817l6.3973-6.6641z" />
  </StyledSvgIcon>
));

export const ArrowBottomFilledIcon = withIconRef((props, ref) => (
  <StyledSvgIcon {...props} ref={ref} width="16" height="10" viewBox="0 0 16 10">
    <path d="m6.8699 9.5119c0.62474 0.65079 1.6393 0.65079 2.264 0l6.3973-6.6641c0.4598-0.47898 0.5948-1.1922 0.3449-1.817-0.2499-0.62476-0.8297-1.0308-1.4794-1.0308l-12.795 0.0052066c-0.64474 0-1.2295 0.40609-1.4794 1.0308-0.2499 0.62476-0.10995 1.338 0.34486 1.817l6.3973 6.6641 5e-3 -0.00521z" />
  </StyledSvgIcon>
));

export const ArrowLeftFilledIcon = withIconRef((props, ref) => (
  <StyledSvgIcon {...props} ref={ref} width="10" height="16" viewBox="0 0 10 16">
    <path d="m0.48809 6.8703c-0.65079 0.62474-0.65079 1.6393 0 2.264l6.6641 6.3973c0.47898 0.4598 1.1922 0.5948 1.817 0.3449 0.62476-0.2499 1.0308-0.8297 1.0308-1.4794l-0.0052-12.795c0-0.64474-0.40609-1.2295-1.0308-1.4794-0.62476-0.2499-1.338-0.10995-1.817 0.34486l-6.6641 6.3973 0.00521 5e-3z" />
  </StyledSvgIcon>
));

export const ArrowRightFilledIcon = withIconRef((props, ref) => (
  <StyledSvgIcon {...props} ref={ref} width="10" height="16" viewBox="0 0 10 16">
    <path d="m9.5119 9.13c0.65079-0.62474 0.65079-1.6393 0-2.264l-6.6641-6.3973c-0.47898-0.4598-1.1922-0.5948-1.817-0.3449-0.62476 0.2499-1.0308 0.8297-1.0308 1.4794l0.0052 12.795c0 0.64474 0.40609 1.2295 1.0308 1.4794 0.62476 0.2499 1.338 0.10995 1.817-0.34486l6.6641-6.3973-0.00521-5e-3z" />
  </StyledSvgIcon>
));
