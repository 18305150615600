import { IInvoiceServiceTotalData } from 'src/types';
import { TableFooterCell, TableFooterRow } from 'src/components/shared/Table';
import currencyFormatter from 'src/utils/currencyFormatter';
import numberFormatter from 'src/utils/numberFormatter';

const formatUSD = currencyFormatter();
const formatNumber = numberFormatter();

export interface IServicesTotalProps {
  total: IInvoiceServiceTotalData;
}

export const ServicesTotal = ({ total }: IServicesTotalProps) => (
  <TableFooterRow>
    <TableFooterCell ignoreWidth>Total</TableFooterCell>
    <TableFooterCell />
    <TableFooterCell />
    <TableFooterCell>{formatUSD(total.logistics_charges)}</TableFooterCell>
    <TableFooterCell>{formatNumber(total.unfavorable_errors_count)}</TableFooterCell>
    <TableFooterCell>{formatUSD(total.unfavorable_errors_dollars)}</TableFooterCell>
  </TableFooterRow>
);
