/* eslint-disable @typescript-eslint/no-explicit-any */
// INFO: upcast to any is needed for internal reasons, public interface is strict

import { useEffect, useState } from 'react';

import { ISort, SortDirection } from 'src/types';

type SortFunction = <Item extends object, Data extends MaybeNull<Item[]>>(
  data: Data,
  config: ISort<keyof Item>,
) => null extends Data ? Data : NonNullable<Data>;

export const sortData: SortFunction = (data, config) => {
  if (!data) return null;

  const factor = config.order === SortDirection.ASC ? 1 : -1;
  const sortedData = [...data].sort((prev, next) => {
    const prevValue = prev[config.by];
    const nextValue = next[config.by];

    return (prevValue !== nextValue ? (prevValue < nextValue ? -1 : 1) : 0) * factor;
  }) as any;

  return sortedData;
};

export const useSortParams = <T>(sort: ISort<T>) => {
  const [params, setParams] = useState(sort);

  return { params, setParams };
};

const useSortedData: SortFunction = (data, config) => {
  const [sortedData, setSortedData] = useState<any>(() => (data ? sortData(data, config) : null));

  useEffect(() => {
    const sortedData = sortData(data, config);

    setSortedData(sortedData);
  }, [data, config]);

  return sortedData;
};

export default useSortedData;
