import { Dayjs } from 'dayjs';
import { memo, useCallback, useEffect, useState } from 'react';

import PickerInputs from './PickerInputs';
import PickerPeriods, { DEFAULT_PERIODS, IDateRangePeriod } from './PickerPeriods';
import DateRange, { IDateRange, IDateRangeProps } from './DateRangeCalendar';
import {
  StyledPicker,
  StyledPickerAside,
  StyledPickerAsideBlock,
  StyledPickerButton,
  StyledPickerCalendar,
  StyledPickerCalendarDates,
} from './styles';

export interface IPickerDateRangeProps extends IDateRangeProps {
  periods?: IDateRangePeriod[];
  onRangeChange?: (value: Maybe<IDateRange>) => void;
  onCancel?: () => void;
}

const PickerDateRange = ({ range, onRangeChange, onCancel, minDate, maxDate, periods }: IPickerDateRangeProps) => {
  const [dateRange, setDateRange] = useState(range);
  const [pickerRange, setPickerRange] = useState(range);
  const [isPickerRange, setIsPickerRange] = useState(false);

  const handlePeriodChange = useCallback((periodRange: Maybe<IDateRange>) => {
    setPickerRange(periodRange);
    setDateRange(periodRange);
  }, []);

  const handleDaysChange = useCallback((days: Dayjs[]) => {
    const isPickerRange = days.length !== 1;

    setIsPickerRange(isPickerRange);
    if (!isPickerRange) setPickerRange(null);
  }, []);

  const handleCancel = () => onCancel?.();
  const handleSetRange = () => onRangeChange?.(pickerRange);

  useEffect(() => setPickerRange(dateRange), [dateRange]);

  return (
    <StyledPicker>
      <StyledPickerAside>
        <StyledPickerAsideBlock>
          <PickerPeriods
            range={pickerRange}
            isRange={isPickerRange}
            onPeriodChange={handlePeriodChange}
            periods={periods ?? DEFAULT_PERIODS}
          />
        </StyledPickerAsideBlock>
        <StyledPickerAsideBlock>
          <StyledPickerButton variant="text" onClick={handleSetRange}>
            Set Date
          </StyledPickerButton>
          <StyledPickerButton variant="text" $warn onClick={handleCancel}>
            Cancel
          </StyledPickerButton>
        </StyledPickerAsideBlock>
      </StyledPickerAside>
      <StyledPickerCalendar>
        <DateRange
          range={dateRange}
          onDaysChange={handleDaysChange}
          onRangeChange={setDateRange}
          minDate={minDate}
          maxDate={maxDate}
        />
        <StyledPickerCalendarDates>
          <PickerInputs range={pickerRange} />
        </StyledPickerCalendarDates>
      </StyledPickerCalendar>
    </StyledPicker>
  );
};

export default memo(PickerDateRange);
