import Typography from '@mui/material/Typography';

import { StyledFreightErrorList, StyledFreightErrorRecord } from './styles';

export const FreightErrors = () => (
  <StyledFreightErrorList>
    <StyledFreightErrorRecord>
      <Typography variant="h4">Wrong rate card</Typography>
      <Typography variant="body1">Coming soon</Typography>
    </StyledFreightErrorRecord>
    <StyledFreightErrorRecord>
      <Typography variant="h4">Wrong weight billed</Typography>
      <Typography variant="body1">Coming soon</Typography>
    </StyledFreightErrorRecord>
    <StyledFreightErrorRecord>
      <Typography variant="h4">Wrong service level/ship method</Typography>
      <Typography variant="body1">Coming soon</Typography>
    </StyledFreightErrorRecord>
    <StyledFreightErrorRecord>
      <Typography variant="h4">DIM weight used inappropriately</Typography>
      <Typography variant="body1">Coming soon</Typography>
    </StyledFreightErrorRecord>
    <StyledFreightErrorRecord>
      <Typography variant="h4">Wrong zone billed</Typography>
      <Typography variant="body1">Coming soon</Typography>
    </StyledFreightErrorRecord>
    <StyledFreightErrorRecord>
      <Typography variant="h4">Order never fulfilled</Typography>
      <Typography variant="body1">Coming soon</Typography>
    </StyledFreightErrorRecord>
    <StyledFreightErrorRecord>
      <Typography variant="h4">Order never delivered (ex, lost)</Typography>
      <Typography variant="body1">Coming soon</Typography>
    </StyledFreightErrorRecord>
    <StyledFreightErrorRecord>
      <Typography variant="h4">No first scan</Typography>
      <Typography variant="body1">Coming soon</Typography>
    </StyledFreightErrorRecord>
    <StyledFreightErrorRecord>
      <Typography variant="h4">Duplicate order</Typography>
      <Typography variant="body1">Coming soon</Typography>
    </StyledFreightErrorRecord>
    <StyledFreightErrorRecord>
      <Typography variant="h4">Unbilled/missing order</Typography>
      <Typography variant="body1">Coming soon</Typography>
    </StyledFreightErrorRecord>
    <StyledFreightErrorRecord>
      <Typography variant="h4">Service level guarantee not met</Typography>
      <Typography variant="body1">Coming soon</Typography>
    </StyledFreightErrorRecord>
    <StyledFreightErrorRecord>
      <Typography variant="h4">Accessorial billing error</Typography>
      <Typography variant="body1">Coming soon</Typography>
    </StyledFreightErrorRecord>
    <StyledFreightErrorRecord>
      <Typography variant="h4">Other</Typography>
      <Typography variant="body1">Coming soon</Typography>
    </StyledFreightErrorRecord>
  </StyledFreightErrorList>
);
