import { IWarehouse } from 'src/types';
import { TableRow, TableCell, TableLeadCell } from 'src/components/shared/Table';

export interface IWarehouseRowProps {
  warehouse: IWarehouse;
}

export const WarehouseRow = ({ warehouse }: IWarehouseRowProps) => (
  <TableRow>
    <TableLeadCell />
    <TableCell>{warehouse.name}</TableCell>
    <TableCell>{warehouse.description}</TableCell>
  </TableRow>
);
