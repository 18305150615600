import { ComponentType, Fragment, memo, Suspense } from 'react';
import { BrowserRouter, Routes as BrowserRoutes, Route } from 'react-router-dom';

export type IRoutes<TRoute extends object = object> = {
  [key: string]: IRoute<TRoute>;
};

export type IRoute<TRoute extends object = object> = {
  element: ComponentType<{ route?: IRoute<TRoute> }>; // TODO: might be replaced with lazy loader
  fallback?: () => JSX.Element;
  caseSensitive?: boolean;
  routes?: IRoutes<TRoute>;
} & {
  [Key in keyof TRoute]: TRoute[Key];
};

const renderRoutes = <TRoute extends object = object>(routes: IRoutes<TRoute>, parent?: IRoute<TRoute>) => {
  const children = Object.entries<IRoute<TRoute>>(routes).map(([path, config]) => {
    const Element = () => {
      const Component = config.element;
      const Fallback = config.fallback ?? parent?.fallback ?? Fragment;

      return (
        <Suspense fallback={<Fallback />}>
          <Component route={config} />
        </Suspense>
      );
    };

    return path ? (
      <Route caseSensitive={config.caseSensitive} path={path} element={<Element />} key={path}>
        {config.routes && renderRoutes(config.routes, { ...config, ...parent })}
      </Route>
    ) : (
      <Route index caseSensitive={config.caseSensitive} element={<Element />} key={'index'} />
    );
  });

  return parent ? <>{children}</> : <BrowserRoutes>{children}</BrowserRoutes>;
};

const RenderRoutes = <TRoute extends object = object>({ routes }: { routes: IRoutes<TRoute> }) =>
  renderRoutes<TRoute>(routes);

export const Router = memo(BrowserRouter);
export const Routes = memo(RenderRoutes);
