import styled from '@emotion/styled';

export const StyledFields = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 450px;
  gap: ${({ theme }) => theme.spacing(2, 5)};
  padding-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const StyledInput = styled.div`
  & > * {
    width: 100%;
  }
`;
