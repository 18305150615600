import createTypography from '@mui/material/styles/createTypography';

import palette from './palette';

const BASE_HEADER = {
  fontFamily: '"Sora", sans-serif',
  fontWeight: 600,
  fontSize: 48,
  textTransform: 'uppercase',
  color: palette.text.primary,
  margin: 0,
  lineHeight: 'normal',
} as const;

const BASE_BODY = {
  color: palette.text.secondary,
  fontFamily: '"Montserrat", sans-serif',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
} as const;

const BASE_WEIGHT = {
  fontWeightThin: 100,
  fontWeightLight: 400,
  fontWeightRegular: 500,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  fontWeightBolder: 800,
} as const;

const options = {
  ...BASE_WEIGHT,
  fontFamily: '"Montserrat", sans-serif',
  fontSize: 12,
  h1: {
    ...BASE_HEADER,
    fontSize: 48,
  },
  h2: {
    ...BASE_HEADER,
    fontSize: 30,
  },
  h3: {
    ...BASE_HEADER,
    fontWeight: BASE_WEIGHT.fontWeightBold,
    fontSize: 16,
  },
  h4: {
    ...BASE_HEADER,
    fontWeight: BASE_WEIGHT.fontWeightBold,
    fontSize: 12,
  },
  h5: {
    ...BASE_HEADER,
    fontWeight: BASE_WEIGHT.fontWeightBold,
    fontSize: 12,
  },
  h6: {
    ...BASE_HEADER,
    fontWeight: BASE_WEIGHT.fontWeightBold,
    fontSize: 12,
  },
  body1: {
    ...BASE_BODY,
    fontSize: 12,
  },
  body2: {
    ...BASE_BODY,
    fontSize: 12,
    color: palette.text.primary,
  },
} as const;

export default createTypography(palette, options);
