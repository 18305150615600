import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { ISearchSchema, SearchSchema } from 'src/schemas';
import { FormText } from 'src/components/shared/Form';
import { ISearchResult } from 'src/types';

import { StyledForm, StyledPopper, StyledTooltip } from './styles';
import SearchResult from './SearchResult';

export interface IGlobalSearchProps {
  loading: boolean;
  searchResult: MaybeNull<ISearchResult>;
  searchTerm: string;
  tooltip: string;
  placeholder: string;
  onChange: (search: string) => void;
}

const GlobalSearch = ({ loading, searchResult, searchTerm, tooltip, placeholder, onChange }: IGlobalSearchProps) => {
  const [resultIsOpen, setResultOpen] = useState(false);
  const [tooltipIsOpen, setTooltipOpen] = useState(false);
  const formRef = useRef<MaybeNull<HTMLFormElement>>(null);
  const { control, watch, reset, handleSubmit } = useForm<ISearchSchema>({
    resolver: yupResolver<ISearchSchema>(SearchSchema),
  });

  const searchInput = watch('search');
  const resultIsPopulated = useMemo(() => !!searchInput && searchInput === searchTerm, [searchInput, searchTerm]);

  const handleFormSubmit = (form: ISearchSchema) => {
    if (form.search === searchTerm) return setResultOpen(true);

    if (form.search) {
      onChange(form.search);
      setResultOpen(true);
    }
  };

  const handleSearchFocus = () => {
    setResultOpen(resultIsPopulated);

    if (!resultIsPopulated && !!searchInput) setTooltipOpen(true);
  };

  const handleResultClick = () => {
    setResultOpen(false);
    reset();
  };

  const handleEndAdornmentClick = () => reset();

  const handleClickAway = () => {
    setResultOpen(false);
    setTooltipOpen(false);
  };

  const StartAdornment = (
    <IconButton type="submit" edge="start" disabled={!searchInput}>
      <SearchIcon />
    </IconButton>
  );

  const EndAdornment = (
    <IconButton type="reset" edge="end" disabled={!searchInput} onClick={handleEndAdornmentClick}>
      <ClearIcon />
    </IconButton>
  );

  useEffect(() => {
    if (!searchInput) onChange('');
  }, [searchInput, onChange]);

  useEffect(() => setResultOpen(false), [searchInput]);

  useEffect(() => {
    if (resultIsPopulated) setResultOpen(true);
  }, [resultIsPopulated]);

  useEffect(() => {
    const searchInputExist = !!searchInput;
    const resultIsEmpty = !loading && !searchResult;
    const isTooltipOpen = searchInputExist && resultIsEmpty;

    setTooltipOpen(isTooltipOpen);
  }, [searchInput, loading, searchResult]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <StyledForm ref={formRef} onSubmit={handleSubmit(handleFormSubmit)}>
        <FormText
          name="search"
          type="search"
          control={control}
          placeholder={placeholder}
          onFocus={handleSearchFocus}
          startAdornment={StartAdornment}
          endAdornment={EndAdornment}
        />
        <StyledPopper open={resultIsOpen} anchorEl={formRef.current} placement="bottom-end">
          <SearchResult loading={loading} result={searchResult} onResultClick={handleResultClick} />
        </StyledPopper>
        <StyledPopper open={tooltipIsOpen} anchorEl={formRef.current} placement="bottom-start">
          <StyledTooltip>
            <Typography variant="body1">{tooltip}</Typography>
          </StyledTooltip>
        </StyledPopper>
      </StyledForm>
    </ClickAwayListener>
  );
};

export default memo(GlobalSearch);
