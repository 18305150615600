import { memo } from 'react';
import Typography from '@mui/material/Typography';

import { TabbedCardTabs, TabbedCardTab } from 'src/components/shared/TabbedCard';

import { IAnomalyTab } from './types';

export interface ITabPanelProps {
  tab: IAnomalyTab;
  onTabChange: (tab: IAnomalyTab) => void;
}

const TabPanel = ({ tab, onTabChange }: ITabPanelProps) => (
  <TabbedCardTabs>
    <TabbedCardTab $active={tab === 'status'} onClick={() => onTabChange('status')}>
      <Typography variant="h3">Anomaly Status</Typography>
    </TabbedCardTab>
    <TabbedCardTab $active={tab === 'count'} onClick={() => onTabChange('count')}>
      <Typography variant="h3">Anomaly Count</Typography>
    </TabbedCardTab>
  </TabbedCardTabs>
);

export default memo(TabPanel);
