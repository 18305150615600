import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';

export interface IFormSelectProps<S extends FieldValues, O extends string | number> {
  control: Control<S>;
  name: FieldPath<S>;
  label?: string;
  placeholder?: string;
  errorMessage?: Maybe<string>;
  options: O[];
  optionValue?: (option: O) => string;
}

export const FormSelect = <S extends FieldValues, O extends string | number>(props: IFormSelectProps<S, O>) => (
  <Controller
    control={props.control}
    name={props.name}
    render={({ field }) => (
      <FormControl error={!!props.errorMessage} variant="filled">
        {props.label && <FormLabel>{props.label}</FormLabel>}
        <Select<O>
          variant="filled"
          id={props.name}
          name={props.name}
          placeholder={props.placeholder}
          value={field.value ?? ''}
          onChange={(event) => field.onChange(event)}>
          {props.options?.map((option) => (
            <MenuItem key={option} id={`${props.name}-${option}`} value={option}>
              {props.optionValue?.(option) ?? option}
            </MenuItem>
          ))}
        </Select>
        {!!props.errorMessage && <FormHelperText>{props.errorMessage}</FormHelperText>}
      </FormControl>
    )}
  />
);
