import { memo } from 'react';

import { ISort, ISortDirection, ICredit, ICreditSortBy } from 'src/types';
import { Loading } from 'src/components/shared/Page';
import { TableBody } from 'src/components/shared/Table';
import Message from 'src/components/shared/Message';

import { ICreditRequestsRowActionProps } from './CreditRequestsRow';
import { CreditRequestsHeaders } from './CreditRequestsHeaders';

export type ICreditRequestsListParams = {
  by: ICreditSortBy;
  order: ISortDirection;
};

export type ICreditRequestsRowAction = (props: ICreditRequestsRowActionProps) => JSX.Element;

export interface ICreditRequestsListProps {
  loading: boolean;
  data: Maybe<ICredit[]>;
  params: ICreditRequestsListParams;
  rowAction: ICreditRequestsRowAction;
  changeSorting: (sort: ISort<ICreditSortBy>) => void;
}

const CreditRequestsList = ({
  loading,
  data,
  params,
  changeSorting,
  rowAction: RowAction,
}: ICreditRequestsListProps) =>
  loading ? (
    <Loading />
  ) : (
    <TableBody>
      <CreditRequestsHeaders sort={{ by: params.by, order: params.order }} onSort={changeSorting} />
      {!loading && !!data ? (
        <>
          {data.map((credit) => (
            <RowAction credit={credit} key={`credit-request-row-${credit.id}`} />
          ))}
        </>
      ) : (
        <Message>No requests</Message>
      )}
    </TableBody>
  );

export default memo(CreditRequestsList);
