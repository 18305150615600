import { ThemedComponent } from '@mui/material/styles';
import Grow from '@mui/material/Grow';

import { ArrowBottomFilledIcon } from 'src/components/Icons';

export const MuiSelect: ThemedComponent<'MuiSelect'> = {
  defaultProps: {
    size: 'medium',
    IconComponent: ArrowBottomFilledIcon,
    MenuProps: {
      elevation: 3,
      TransitionComponent: Grow,
      MenuListProps: {
        disablePadding: true,
      },
    },
  },
  styleOverrides: {
    outlined: ({ theme }) => ({
      '& ~ svg': {
        position: 'absolute',
        width: theme.spacing(2),
        height: theme.spacing(2.5),
        right: theme.spacing(1.5),
      },
    }),
    filled: ({ theme }) => ({
      backgroundColor: 'transparent',
      '& ~ svg': {
        position: 'absolute',
        width: theme.spacing(2),
        height: theme.spacing(2.5),
        right: theme.spacing(1.5),
        '.Mui-focused &': {
          color: theme.palette.primary.main,
        },
        '.Mui-error &': {
          color: theme.palette.error.main,
        },
        '.Mui-error.Mui-focused &': {
          color: theme.palette.error.main,
        },
      },
    }),
  },
};
