import { IS_EXPERIMENTAL } from 'src/config/env';
import { UserRole } from 'src/types';
import { IProtectedSidebarItem } from 'src/access';
import {
  InvoicesSubmenu,
  ReportingSubmenu,
  SettingsSubmenu,
  SystemSubmenu,
  AISubmenu,
} from 'src/components/shared/Submenu';
import {
  AIIcon,
  DashboardIcon,
  InvoiceIcon,
  PerformanceIcon,
  ReportingIcon,
  SettingsIcon,
  SystemIcon,
} from 'src/components/Icons';

export const TOP_SIDEBAR_CONFIG: IProtectedSidebarItem[] = [
  {
    link: '/dashboard',
    icon: {
      title: 'Home',
      Svg: DashboardIcon,
    },
    roles: [UserRole.CUSTOMER_ADMIN, UserRole.CUSTOMER_USER],
  },
  {
    submenu: {
      component: InvoicesSubmenu,
      urlPrefix: '/invoice',
    },
    icon: {
      title: 'Invoices \nand Reconciliation',
      Svg: InvoiceIcon,
    },
    roles: [UserRole.CUSTOMER_ADMIN, UserRole.CUSTOMER_USER],
  },
  IS_EXPERIMENTAL
    ? {
        submenu: {
          component: AISubmenu,
          urlPrefix: '/anomaly',
        },
        icon: {
          title: 'Implentio AI',
          Svg: AIIcon,
        },
        roles: [UserRole.CUSTOMER_ADMIN, UserRole.CUSTOMER_USER],
      }
    : null,
  {
    link: '/performance',
    icon: {
      title: 'Performance \nManagement',
      Svg: PerformanceIcon,
    },
    roles: [UserRole.CUSTOMER_ADMIN, UserRole.CUSTOMER_USER],
  },
  {
    icon: {
      title: 'Reporting \nand Analysis',
      Svg: ReportingIcon,
    },
    submenu: {
      component: ReportingSubmenu,
      urlPrefix: '/analytic',
    },
    roles: [UserRole.CUSTOMER_ADMIN, UserRole.CUSTOMER_USER],
  },
].filter(Boolean) as IProtectedSidebarItem[];

export const BOTTOM_SIDEBAR_CONFIG: IProtectedSidebarItem[] = [
  {
    submenu: {
      component: SystemSubmenu,
      urlPrefix: '/system',
    },
    icon: {
      title: 'System',
      Svg: SystemIcon,
      urlPrefix: '/system',
    },
    roles: [UserRole.IMPLENTIO_ADMIN],
  },
  {
    submenu: {
      component: SettingsSubmenu,
      urlPrefix: '/settings',
    },
    icon: {
      title: 'Settings',
      Svg: SettingsIcon,
    },
    roles: [UserRole.CUSTOMER_ADMIN, UserRole.CUSTOMER_USER],
  },
];
