import { StyledSvgIcon } from './styles';
import withIconRef from './withIconRef';

export const LogoFullIcon = withIconRef((props, ref) => (
  <StyledSvgIcon {...props} ref={ref} width="130" height="24" viewBox="0 0 130 24">
    <path d="m0.072876 23.446v-16.11h3.7403v16.11z" fill="#100f41" />
    <path
      d="m7.6007 23.446v-16.11h5.1544l3.8088 9.0483h0.4333l3.7632-9.0483h5.2456v16.11h-3.6948v-13.749l0.5246 0.04414-4.4017 10.439h-3.5579l-4.4246-10.439 0.5702-0.04414v13.749z"
      fill="#100f41"
    />
    <path
      d="m33.024 18.836v-3.1338h2.9877c0.5775 0 1.0719-0.1104 1.4823-0.3311 0.4257-0.2207 0.7527-0.5297 0.9808-0.9269 0.228-0.412 0.3421-0.8827 0.3421-1.4124 0-0.5591-0.1141-1.0373-0.3421-1.4345-0.2281-0.4119-0.5551-0.7209-0.9808-0.9269-0.4104-0.2207-0.9048-0.331-1.4823-0.331h-2.9877v-3.1338h2.8052c1.414 0 2.6228 0.22805 3.6263 0.68413 1.0186 0.45611 1.7941 1.1034 2.3263 1.9421 0.5474 0.83862 0.821 1.8464 0.821 3.0235v0.353c0 1.1476-0.2736 2.1481-0.821 3.0015-0.5322 0.8385-1.3077 1.4859-2.3263 1.9419-1.0035 0.4561-2.2123 0.6843-3.6263 0.6843zm-3.2843 4.6123v-16.243h3.7404v16.243z"
      fill="#100f41"
    />
    <path d="m44.951 23.446v-16.11h3.7403v16.11zm3.2842 0v-3.1779h6.979v3.1779z" fill="#100f41" />
    <path
      d="m57.67 23.446v-16.11h3.649v16.11zm3.1929 0v-3.0014h7.2754v3.0014zm0-6.6869v-3.0014h6.7509v3.0014zm0-6.4221v-3.0014h7.1158v3.0014z"
      fill="#100f41"
    />
    <path
      d="m71.211 23.446v-16.11h6.135l6.2719 13.175h0.7755l-0.4561 0.3972v-13.572h3.5123v16.11h-6.1808l-6.2719-13.175h-0.7754l0.4562-0.39723v13.572z"
      fill="#100f41"
    />
    <path d="m94.497 23.446v-13.396h3.7404v13.396zm-4.6754-12.954v-3.1559h13.114v3.1559z" fill="#100f41" />
    <path d="m105.33 23.446v-16.11h3.741v16.11z" fill="#100f41" />
    <path
      d="m120.84 23.869c-1.505 0-2.82-0.2501-3.945-0.7502-1.11-0.5149-2.03-1.1844-2.76-2.0083-0.73-0.8387-1.277-1.7435-1.642-2.7146-0.35-0.971-0.525-1.9052-0.525-2.8027v-0.4855c0-1.0004 0.183-1.9862 0.548-2.9573 0.38-0.9857 0.943-1.8758 1.687-2.6703 0.745-0.79449 1.673-1.4271 2.783-1.8979 1.11-0.4855 2.395-0.72826 3.854-0.72826 1.46 0 2.745 0.24276 3.854 0.72826 1.11 0.47082 2.038 1.1034 2.783 1.8979 0.746 0.79448 1.308 1.6846 1.688 2.6703 0.38 0.9711 0.569 1.9569 0.569 2.9573v0.4855c0 0.8975-0.181 1.8317-0.546 2.8027-0.365 0.9711-0.912 1.8759-1.642 2.7146-0.731 0.8239-1.657 1.4934-2.783 2.0083-1.11 0.5001-2.417 0.7502-3.923 0.7502zm0-3.3764c0.791 0 1.498-0.1325 2.121-0.3973 0.639-0.2649 1.179-0.6327 1.619-1.1034 0.456-0.4708 0.799-1.0152 1.027-1.6331 0.227-0.618 0.342-1.2801 0.342-1.9863 0-0.7503-0.122-1.4344-0.365-2.0524-0.227-0.6327-0.571-1.177-1.027-1.6331-0.44-0.4708-0.972-0.8312-1.596-1.0814-0.623-0.2501-1.33-0.3751-2.121-0.3751-0.79 0-1.497 0.125-2.121 0.3751-0.623 0.2502-1.163 0.6106-1.619 1.0814-0.441 0.4561-0.776 1.0004-1.004 1.6331-0.228 0.618-0.342 1.3021-0.342 2.0524 0 0.7062 0.114 1.3683 0.342 1.9863 0.228 0.6179 0.563 1.1623 1.004 1.6331 0.456 0.4707 0.996 0.8385 1.619 1.1034 0.624 0.2648 1.331 0.3973 2.121 0.3973z"
      fill="#100f41"
    />
    <path
      d="m1.8905 0c-1.0038 0-1.8176 0.81126-1.8176 1.812s0.81376 1.812 1.8176 1.812h0.10516c1.0038 0 1.8176-0.81126 1.8176-1.812s-0.81376-1.812-1.8176-1.812z"
      fill="#f25e0a"
    />
    <path d="m7.6007 0v3.624h122.11v-3.624z" fill="#100f41" />
  </StyledSvgIcon>
));

export const LogoDecorIcon = withIconRef((props, ref) => (
  <StyledSvgIcon {...props} ref={ref} width="130" height="24" viewBox="0 0 130 24">
    <path d="m0.072876 23.446v-16.11h3.7403v16.11z" fill="#100f41" />
    <path
      d="m1.8905 0c-1.0038 0-1.8176 0.81126-1.8176 1.812s0.81376 1.812 1.8176 1.812h0.10516c1.0038 0 1.8176-0.81126 1.8176-1.812s-0.81376-1.812-1.8176-1.812z"
      fill="#f25e0a"
    />
    <path d="m7.6007 0v3.624h122.11v-3.624z" fill="#100f41" />
  </StyledSvgIcon>
));

export const LogoContentIcon = withIconRef((props, ref) => (
  <StyledSvgIcon {...props} ref={ref} width="130" height="24" viewBox="0 0 130 24">
    <path
      d="m7.6007 23.446v-16.11h5.1544l3.8088 9.0483h0.4333l3.7632-9.0483h5.2456v16.11h-3.6948v-13.749l0.5246 0.04414-4.4017 10.439h-3.5579l-4.4246-10.439 0.5702-0.04414v13.749z"
      fill="#100f41"
    />
    <path
      d="m33.024 18.836v-3.1338h2.9877c0.5775 0 1.0719-0.1104 1.4823-0.3311 0.4257-0.2207 0.7527-0.5297 0.9808-0.9269 0.228-0.412 0.3421-0.8827 0.3421-1.4124 0-0.5591-0.1141-1.0373-0.3421-1.4345-0.2281-0.4119-0.5551-0.7209-0.9808-0.9269-0.4104-0.2207-0.9048-0.331-1.4823-0.331h-2.9877v-3.1338h2.8052c1.414 0 2.6228 0.22805 3.6263 0.68413 1.0186 0.45611 1.7941 1.1034 2.3263 1.9421 0.5474 0.83862 0.821 1.8464 0.821 3.0235v0.353c0 1.1476-0.2736 2.1481-0.821 3.0015-0.5322 0.8385-1.3077 1.4859-2.3263 1.9419-1.0035 0.4561-2.2123 0.6843-3.6263 0.6843zm-3.2843 4.6123v-16.243h3.7404v16.243z"
      fill="#100f41"
    />
    <path d="m44.951 23.446v-16.11h3.7403v16.11zm3.2842 0v-3.1779h6.979v3.1779z" fill="#100f41" />
    <path
      d="m57.67 23.446v-16.11h3.649v16.11zm3.1929 0v-3.0014h7.2754v3.0014zm0-6.6869v-3.0014h6.7509v3.0014zm0-6.4221v-3.0014h7.1158v3.0014z"
      fill="#100f41"
    />
    <path
      d="m71.211 23.446v-16.11h6.135l6.2719 13.175h0.7755l-0.4561 0.3972v-13.572h3.5123v16.11h-6.1808l-6.2719-13.175h-0.7754l0.4562-0.39723v13.572z"
      fill="#100f41"
    />
    <path d="m94.497 23.446v-13.396h3.7404v13.396zm-4.6754-12.954v-3.1559h13.114v3.1559z" fill="#100f41" />
    <path d="m105.33 23.446v-16.11h3.741v16.11z" fill="#100f41" />
    <path
      d="m120.84 23.869c-1.505 0-2.82-0.2501-3.945-0.7502-1.11-0.5149-2.03-1.1844-2.76-2.0083-0.73-0.8387-1.277-1.7435-1.642-2.7146-0.35-0.971-0.525-1.9052-0.525-2.8027v-0.4855c0-1.0004 0.183-1.9862 0.548-2.9573 0.38-0.9857 0.943-1.8758 1.687-2.6703 0.745-0.79449 1.673-1.4271 2.783-1.8979 1.11-0.4855 2.395-0.72826 3.854-0.72826 1.46 0 2.745 0.24276 3.854 0.72826 1.11 0.47082 2.038 1.1034 2.783 1.8979 0.746 0.79448 1.308 1.6846 1.688 2.6703 0.38 0.9711 0.569 1.9569 0.569 2.9573v0.4855c0 0.8975-0.181 1.8317-0.546 2.8027-0.365 0.9711-0.912 1.8759-1.642 2.7146-0.731 0.8239-1.657 1.4934-2.783 2.0083-1.11 0.5001-2.417 0.7502-3.923 0.7502zm0-3.3764c0.791 0 1.498-0.1325 2.121-0.3973 0.639-0.2649 1.179-0.6327 1.619-1.1034 0.456-0.4708 0.799-1.0152 1.027-1.6331 0.227-0.618 0.342-1.2801 0.342-1.9863 0-0.7503-0.122-1.4344-0.365-2.0524-0.227-0.6327-0.571-1.177-1.027-1.6331-0.44-0.4708-0.972-0.8312-1.596-1.0814-0.623-0.2501-1.33-0.3751-2.121-0.3751-0.79 0-1.497 0.125-2.121 0.3751-0.623 0.2502-1.163 0.6106-1.619 1.0814-0.441 0.4561-0.776 1.0004-1.004 1.6331-0.228 0.618-0.342 1.3021-0.342 2.0524 0 0.7062 0.114 1.3683 0.342 1.9863 0.228 0.6179 0.563 1.1623 1.004 1.6331 0.456 0.4707 0.996 0.8385 1.619 1.1034 0.624 0.2648 1.331 0.3973 2.121 0.3973z"
      fill="#100f41"
    />
  </StyledSvgIcon>
));
