import { memo } from 'react';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { IAnomalyDetails } from 'src/types';
import Message from 'src/components/shared/Message';

import AnomalyDetailList from './AnomalyDetailList';
import AnomalyComparableList from './AnomalyComparableList';
import AnomalyDetailsTotal from './AnomalyDetailsTotal';
import { IAnomalyDetailRowAction } from './AnomalyDetailRow';
import { StyledAnomalyDetails, StyledComparableList } from './styles';

export interface IAnomalyDetailsProps {
  details: MaybeNull<IAnomalyDetails>;
  anomalyAction: IAnomalyDetailRowAction;
}

const AnomalyDetails = ({ details, anomalyAction }: IAnomalyDetailsProps) => (
  <StyledAnomalyDetails>
    {details ? (
      <>
        <AnomalyDetailsTotal total={details.total} />
        <Divider />
        <AnomalyDetailList anomaly={details.anomaly} anomalyAction={anomalyAction} />
        <StyledComparableList variant="inset">
          <Typography variant="h3">Comparable Orders</Typography>
          <AnomalyComparableList anomalies={details.comparison} />
        </StyledComparableList>
      </>
    ) : (
      <Message>No anomalies detected.</Message>
    )}
  </StyledAnomalyDetails>
);

export default memo(AnomalyDetails);
