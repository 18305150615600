import { useCallback, useEffect, useMemo, useState } from 'react';

import { IInvoiceErrorTotal, IInvoiceOrdersFilters } from 'src/types';
import { ordersFiltersQuery, ordersPaginationQuery, ordersSortingQuery } from 'src/components/InvoiceReconcile';

import { makeErrorTypeFilterConfig } from './invoiceErrorsControl';

export const useOrdersPagination = () => {
  const [queryParams, setQueryParams] = ordersPaginationQuery.useParams();

  const setPage = useCallback((page: number) => setQueryParams({ page }), []);

  return {
    page: queryParams.page,
    setPage,
  };
};

export const useOrdersSorting = () => {
  const [params, setSorting] = ordersSortingQuery.useParams();

  return {
    params,
    setSorting,
  };
};

export const useOrdersFilters = (
  mainConfig: MaybeNull<Omit<IInvoiceOrdersFilters, 'ERROR_CODE'>>,
  errorTotal: MaybeNull<IInvoiceErrorTotal>,
) => {
  const filterConfig = useMemo(() => {
    const errorTypeConfig = makeErrorTypeFilterConfig(errorTotal);

    if (!mainConfig || !errorTypeConfig) return null;

    return {
      ...mainConfig,
      ERROR_CODE: errorTypeConfig,
    } as IInvoiceOrdersFilters;
  }, [mainConfig, errorTotal]);

  const filterQuery = ordersFiltersQuery.useFilter(filterConfig);

  return filterQuery;
};

export const useOrderParams = (
  ordersPagination: ReturnType<typeof useOrdersPagination>,
  ordersSorting: ReturnType<typeof useOrdersSorting>,
  ordersFilters: ReturnType<typeof useOrdersFilters>,
) => {
  const [orderParams, setOrderParams] = useState(() => ({
    page: ordersPagination.page,
    sorting: ordersSorting.params,
    filters: ordersFilters.params,
    isReady: ordersFilters.isReady,
  }));

  useEffect(() => {
    if (
      ordersFilters.isReady &&
      ordersPagination.page !== 1 &&
      (orderParams.sorting !== ordersSorting.params || orderParams.filters !== ordersFilters?.params)
    )
      return ordersPagination.setPage(1);

    setOrderParams({
      page: ordersPagination.page,
      sorting: ordersSorting.params,
      filters: ordersFilters.params,
      isReady: ordersFilters.isReady,
    });
  }, [ordersFilters.isReady, ordersPagination.page, ordersSorting.params, ordersFilters.params]);

  return orderParams;
};
