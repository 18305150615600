import { PropsWithChildren, createContext, useContext, useMemo } from 'react';

import { IDialogControl, useDialogControl } from './creditRequestControl';

export interface ICreditRequestContext {
  dialogControl: IDialogControl;
  invoiceName: string;
  isActive: boolean;
}

export interface ICreditRequestProviderProps {
  name: string;
  active: boolean;
}

export const CreditRequestContext = createContext<ICreditRequestContext>({} as ICreditRequestContext);

export const CreditRequestProvider = ({ active, name, children }: PropsWithChildren<ICreditRequestProviderProps>) => {
  const dialogControl = useDialogControl();
  const invoiceName = useMemo(() => name, [name]);
  const isActive = useMemo(() => active, [active]);

  return (
    <CreditRequestContext.Provider
      value={{
        isActive,
        invoiceName,
        dialogControl,
      }}>
      {children}
    </CreditRequestContext.Provider>
  );
};

export const useCreditRequestContext = () => useContext(CreditRequestContext);
