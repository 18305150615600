export interface IPercentFormatterConfig {
  locale?: string;
  placeholder?: string;
  fractionDigits?: number;
}

const DEFAULT_CONFIG = {
  locale: 'en-US',
  placeholder: 'N/A',
  fractionDigits: 2,
};

const percentFormatter = (config?: IPercentFormatterConfig) => {
  const locale = config?.locale ?? DEFAULT_CONFIG.locale;
  const placeholder = config?.placeholder ?? DEFAULT_CONFIG.placeholder;
  const fractionDigits = config?.fractionDigits ?? DEFAULT_CONFIG.fractionDigits;

  const formatter = new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

  return (value: Maybe<number>) => (value != null ? formatter.format(value) : placeholder);
};

export default percentFormatter;
