import { memo } from 'react';

import { IReconcileDataType, ReconcileDataType } from 'src/types';
import { SlideTabs, SlideTab } from 'src/components/shared/SlideTabs';

export interface IReconcileTypeProps {
  type: IReconcileDataType;
  onChange: (type: IReconcileDataType) => void;
}

const ReconcileType = ({ type, onChange }: IReconcileTypeProps) => (
  <SlideTabs value={type} onChange={onChange}>
    <SlideTab value={ReconcileDataType.ERROR_SUMMARY}>{'Error Summary'}</SlideTab>
    <SlideTab value={ReconcileDataType.ORDERS}>{'Orders'}</SlideTab>
    <SlideTab disabled value={ReconcileDataType.TAGGED_ORDERS}>
      {'Tagged orders'}
    </SlideTab>
  </SlideTabs>
);

export default memo(ReconcileType);
