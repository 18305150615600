import { createEnumParam, withDefault } from 'use-query-params';

import { AnalyticReportsSortBy, SortDirection } from 'src/types';
import { makeNamedQueryParams } from 'src/effects/useNamedQueryParams';

const SortDirectionParam = withDefault(createEnumParam(Object.values(SortDirection)), SortDirection.DESC);
const AnalyticReportsSortParam = withDefault(
  createEnumParam(Object.values(AnalyticReportsSortBy)),
  AnalyticReportsSortBy.name,
);

export const analyticsReportsSortQuery = makeNamedQueryParams('ar', {
  by: AnalyticReportsSortParam,
  order: SortDirectionParam,
});
