import Link from '@mui/material/Link';

import { TableRow, TableCell, TableLeadCell } from 'src/components/shared/Table';
import currencyFormatter from 'src/utils/currencyFormatter';
import numberFormatter from 'src/utils/numberFormatter';
import { IInvoiceError } from 'src/types';

const formatUSD = currencyFormatter();
const formatNumber = numberFormatter();

export interface IErrorSummaryRowProps {
  item: IInvoiceError;
  onErrorTypeClick: (codes: string[]) => void;
}

export const ErrorListRow = ({ item, onErrorTypeClick }: IErrorSummaryRowProps) => (
  <TableRow>
    <TableLeadCell />
    <TableCell size={50}>
      <Link sx={{ cursor: 'pointer' }} onClick={() => onErrorTypeClick(item.error_code)}>
        {item.error_name.join(', ')}
      </Link>
    </TableCell>
    <TableCell>{item.service}</TableCell>
    <TableCell>{formatNumber(item.unfavorable_errors_count)}</TableCell>
    <TableCell>{formatUSD(item.unfavorable_errors_dollars)}</TableCell>
  </TableRow>
);
