import { memo } from 'react';

import { TableBody } from 'src/components/shared/Table';
import Message from 'src/components/shared/Message';
import { IWarehouse } from 'src/types';

import { WarehouseHeaders } from './WarehouseHeaders';
import { WarehouseRow } from './WarehouseRow';

export interface IWarehouseListProps {
  payload: IWarehouse[];
}

const WarehouseList = ({ payload }: IWarehouseListProps) =>
  !payload.length ? (
    <Message>No warehouses</Message>
  ) : (
    <TableBody>
      <WarehouseHeaders />
      {!!payload.length && (
        <>
          {payload.map((warehouse) => (
            <WarehouseRow key={`warehouse-row-${warehouse.id}`} warehouse={warehouse} />
          ))}
        </>
      )}
    </TableBody>
  );

export default memo(WarehouseList);
