/* eslint-disable react/display-name */

import dayjs from 'dayjs';
import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Link, { LinkProps } from '@mui/material/Link';

import { AlertType, IAlertCommon } from 'src/types';
import { invoiceDetailsQuery } from 'src/components/Invoice';
import makeRequest from 'src/effects/makeRequest';
import { saveAsBlob } from 'src/utils/saveFile';
import * as apiAlert from 'src/api/alert';

const useAlertDownloadAttachmentRequest = makeRequest(apiAlert.downloadAlertAttachment, { cancellable: false });

export const AlertTitle = (alert: IAlertCommon) => () => {
  switch (alert.type) {
    case AlertType.INVOICE_RECEIVED: {
      return <Typography variant="h4">{'New invoice uploaded'}</Typography>;
    }
    case AlertType.INVOICE_PROCESSED: {
      return <Typography variant="h4">{'New invoice reconciliation ready'}</Typography>;
    }
    case AlertType.INVOICE_PROCESSING_FAILED: {
      return <Typography variant="h4">{'Reconciliation failed'}</Typography>;
    }
    case AlertType.EXPORT_REQUEST_READY:
    case AlertType.CREDIT_REQUEST_READY: {
      return <Typography variant="h4">{'Downloads available'}</Typography>;
    }
    case AlertType.EXPORT_REQUEST_FAILED:
    case AlertType.CREDIT_REQUEST_FAILED: {
      return <Typography variant="h4">{'Download failed'}</Typography>;
    }
    default: {
      return <Typography variant="h4">{'Other'}</Typography>;
    }
  }
};
export interface IAlertDescriptionProps {
  onClick?: LinkProps['onClick'];
}

export const AlertDescription = (alert: IAlertCommon) => (props: IAlertDescriptionProps) => {
  switch (alert.type) {
    case AlertType.INVOICE_RECEIVED: {
      return (
        <Typography variant="body1">{`${alert.meta.invoice_id} was uploaded successfully. You will receive another alert when the invoice is reconciled.`}</Typography>
      );
    }
    case AlertType.INVOICE_PROCESSED: {
      const invoiceName = alert.meta.invoice_id;
      const searchString = invoiceDetailsQuery.getSearchString({ name: invoiceName });
      const to = `/invoices/details?${searchString}`;

      return (
        <Typography variant="body1">
          <Link {...props} component={NavLink} to={to}>
            {invoiceName}
          </Link>{' '}
          was reconciled successfully.
        </Typography>
      );
    }
    case AlertType.INVOICE_PROCESSING_FAILED: {
      return <Typography variant="body1">{`${alert.meta.invoice_id} failed to process.`}</Typography>;
    }
    case AlertType.CREDIT_REQUEST_FAILED: {
      return <Typography variant="body1">Your Credit Request export is failed.</Typography>;
    }
    case AlertType.EXPORT_REQUEST_FAILED: {
      return <Typography variant="body1">Your export is failed.</Typography>;
    }
    case AlertType.CREDIT_REQUEST_READY: {
      return (
        <Typography variant="body1">{`Your Credit Request (${alert.meta.credit_request_id}) is available for download.`}</Typography>
      );
    }
    case AlertType.EXPORT_REQUEST_READY: {
      return (
        <Typography variant="body1">{`Your Reconciled ${alert.meta.page_title} export for Invoice ${alert.meta.invoice_id} is available.`}</Typography>
      );
    }
    default: {
      return <Typography variant="body1">Other</Typography>;
    }
  }
};

export interface IAlertLinkProps {
  onClick?: LinkProps['onClick'];
}

export const AlertLink = (alert: IAlertCommon) => (props: IAlertLinkProps) => {
  switch (alert.type) {
    case AlertType.INVOICE_PROCESSED: {
      const name = alert.meta.invoice_id;
      const searchString = invoiceDetailsQuery.getSearchString({ name });
      const to = `/invoices/details?${searchString}`;

      return (
        <Link {...props} component={NavLink} to={to}>
          {name}
        </Link>
      );
    }
    case AlertType.CREDIT_REQUEST_READY:
    case AlertType.EXPORT_REQUEST_READY: {
      const alertDownloadAttachmentRequest = useAlertDownloadAttachmentRequest(null);

      const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        props.onClick?.(event);
        alertDownloadAttachmentRequest.cleanup();
        alertDownloadAttachmentRequest.make(alert.id);
        alertDownloadAttachmentRequest.onFulfill((blob) => saveAsBlob(blob, alert.meta.file_name));
      };

      return (
        <Link sx={{ cursor: 'pointer' }} onClick={handleClick}>
          Start download
        </Link>
      );
    }
    default: {
      return <Fragment />;
    }
  }
};

export const AlertDateTime = (timestamp: Date) => () => {
  const dateTime = dayjs(timestamp).format('MM.DD.YY    h:mm A');

  return (
    <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="body1">
      {dateTime}
    </Typography>
  );
};
