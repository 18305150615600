/* Sort */

export const SortDirection = {
  ASC: 'asc' as const,
  DESC: 'desc' as const,
} as const;

export type ISortDirection = ValueOf<typeof SortDirection>;

export interface ISort<T> {
  order: ISortDirection;
  by: T;
}

export interface ISortProps<T> {
  sort: ISort<T>;
  onSort: (sort: ISort<T>) => void;
}

export type ID = number | string;

/* Filter */

export const FilterType = {
  SingleSelect: 'single_select' as const,
  MultiSelect: 'multi_select' as const,
  CriterionNumber: 'criterion_number' as const,
  DateRange: 'date_range' as const,
} as const;

export type IFilterType = ValueOf<typeof FilterType>;

export type IFilterCommonOptions = {
  label: string;
};

export type IFilterSingleSelectOption = {
  key: ID;
  value: string;
};
export type IFilterSingleSelectOptions = IFilterCommonOptions & {
  values: IFilterSingleSelectOption[];
};
export type IFilterSingleSelectValue = IFilterSingleSelectOption['key'];

export type IFilterMultiSelectOption = {
  key: ID;
  value: string;
};
export type IFilterMultiSelectOptions = IFilterCommonOptions & {
  values: IFilterMultiSelectOption[];
};
export type IFilterMultiSelectValue = IFilterMultiSelectOption['key'][];

export type IFilterCriterionNumberOption = {
  key: 'gt' | 'lt' | 'eq';
  value: string;
};
export type IFilterCriterionNumberOptions = IFilterCommonOptions & {
  criterions: IFilterCriterionNumberOption[];
  format: 'currency' | 'number';
  placeholder: string;
};
export type IFilterCriterionNumberValue = {
  criterion: IFilterCriterionNumberOption['key'];
  number: number;
};

export type IFilterDateRangeOptions = IFilterCommonOptions & {
  min?: string;
  max?: string;
  format?: string;
  placeholder?: {
    from: string;
    to: string;
  };
};
export type IFilterDateRangeValue = {
  from: string;
  to: string;
};

export type IFiltersOptions = {
  [FilterType.SingleSelect]: IFilterSingleSelectOptions;
  [FilterType.MultiSelect]: IFilterMultiSelectOptions;
  [FilterType.CriterionNumber]: IFilterCriterionNumberOptions;
  [FilterType.DateRange]: IFilterDateRangeOptions;
};

export type IFiltersValues = {
  [FilterType.SingleSelect]: Maybe<IFilterSingleSelectValue>;
  [FilterType.MultiSelect]: Maybe<IFilterMultiSelectValue>;
  [FilterType.CriterionNumber]: Maybe<IFilterCriterionNumberValue>;
  [FilterType.DateRange]: Maybe<IFilterDateRangeValue>;
};

export interface IFilterConfig<T extends IFilterType> {
  type: T;
  value?: IFiltersValues[T];
}

export interface IFilter<T extends IFilterType> extends IFilterConfig<T> {
  options: IFiltersOptions[T];
}

export type IFilters = {
  [FilterType.SingleSelect]: IFilter<'single_select'>;
  [FilterType.MultiSelect]: IFilter<'multi_select'>;
  [FilterType.CriterionNumber]: IFilter<'criterion_number'>;
  [FilterType.DateRange]: IFilter<'date_range'>;
};

export type IOrderSearchResult = {
  order_number: string;
  meta: {
    invoice_name: string;
  };
};

export type IInvoiceSearchResult = {
  invoice_name: string;
  meta: {
    services: string;
  };
};

export type ISearchResult = {
  invoices: IInvoiceSearchResult[];
  orders: IOrderSearchResult[];
};
