import { PropsWithChildren, memo } from 'react';

import { StyledAuthContent, StyledAuthContainer } from './styles';

const AuthContent = ({ children }: PropsWithChildren) => (
  <StyledAuthContent>
    <StyledAuthContainer>{children}</StyledAuthContainer>
  </StyledAuthContent>
);

export default memo(AuthContent);
