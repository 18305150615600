import Typography from '@mui/material/Typography';
import { ButtonBaseProps } from '@mui/material/ButtonBase';

import { StyledSlideTab } from './styles';

export type ISlideTabProps<TValue extends string = string> = ButtonBaseProps & {
  value: TValue;
};

export const SlideTab = ({ value, children, ...props }: ISlideTabProps) => (
  <Typography {...props} component={StyledSlideTab} variant={'body1'} value={value}>
    {children}
  </Typography>
);
