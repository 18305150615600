import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';

import { BellIcon } from 'src/components/Icons';
import shouldForwardProp from 'src/utils/shouldForwardProp';

const AlertAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

/* Alert panel */

export const StyledBellIcon = styled(BellIcon, { shouldForwardProp })<{ $active?: boolean }>`
  cursor: pointer;
  width: ${({ theme }) => theme.spacing(2.5)};
  color: ${({ theme, $active }) => ($active ? theme.palette.primary.main : theme.palette.text.secondary)};
  transition: ${({ theme }) => theme.transitions.create(['color'])};

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const StylesAlertTotal = styled.span`
  white-space: pre-wrap;
  padding: ${({ theme }) => theme.spacing(0.2, 1)};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background-color: ${({ theme }) => theme.palette.primary.light};
`;

export const StyledAlertPanel = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: ${({ theme }) => theme.spacing(5)};
  height: ${({ theme }) => theme.spacing(5)};
  min-width: ${({ theme }) => theme.spacing(5)};
  min-height: ${({ theme }) => theme.spacing(5)};

  & ${StylesAlertTotal} {
    transform: translate(-35%, -25%);
  }

  & > * {
    flex: 1;
    grid-row-start: 1;
    grid-column-start: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
  }
`;

/* Alert dialog */

export const StyledDialog = styled(Paper)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(2)};
  min-width: 800px;

  animation-name: ${AlertAnimation};
  animation-duration: 0.2s;
`;

export const StyledDialogHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledDialogControls = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const StyledCloseButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 0;

  & > svg {
    width: ${({ theme }) => theme.spacing(3)};
  }
`;

/* Alert item */

export const StyledAlertItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.5)};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: ${({ theme }) => theme.spacing(1.5)};
  position: relative;

  animation-name: ${AlertAnimation};
  animation-duration: 0.2s;
`;

export const StyledAlertAcknowledged = styled.span<{ $acknowledged: boolean }>`
  display: inline-block;
  border-radius: 100%;
  width: ${({ theme }) => theme.spacing(2)};
  height: ${({ theme }) => theme.spacing(2)};
  min-width: ${({ theme }) => theme.spacing(2)};
  min-height: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ $acknowledged, theme }) =>
    $acknowledged ? theme.palette.text.secondary : theme.palette.success.main};
  transition: ${({ theme }) => theme.transitions.create(['background-color'])};
`;

export const StyledAlertContent = styled.div<{ $justify?: string }>`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: ${({ $justify }) => $justify ?? 'space-between'};
  gap: ${({ theme }) => theme.spacing(2)};

  & > :only-child {
    margin-left: auto;
  }
`;

export const StyledAlertInfo = styled.span<{ $align?: 'left' | 'right' }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ $align }) => (!$align || $align === 'left' ? 'start' : 'end')};
  gap: ${({ theme }) => theme.spacing(1)};
`;

/* Alert list */

export const StyledAlertList = styled.section`
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin: ${({ theme }) => theme.spacing(0, -2, -2)};
  padding: ${({ theme }) => theme.spacing(0, 2, 4)};

  & > ${StyledAlertItem}:nth-child(2n + 2) {
    background-color: ${({ theme }) => theme.palette.secondary.light};
  }

  & > ${StyledAlertItem}:nth-child(2n + 1) {
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`;
