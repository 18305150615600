import { ThemedComponent } from '@mui/material/styles';

export const MuiInputBase: ThemedComponent<'MuiInputBase'> = {
  defaultProps: {
    size: 'medium',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      height: theme.spacing(5),
    }),
    sizeSmall: ({ theme }) => ({
      height: theme.spacing(4.5),
    }),
  },
};

export const MuiInputLabel: ThemedComponent<'MuiInputLabel'> = {
  variants: [
    {
      props: { variant: 'filled' },
      style: ({ theme }) => ({
        position: 'static',
        height: 'auto',
        transform: 'none',
        maxWidth: '100%',
        color: theme.palette.text.primary,
        '&.Mui-error': {
          color: theme.palette.error.main,
        },
        '&.Mui-error.Mui-focused': {
          color: theme.palette.error.main,
        },
        '&.Mui-focused': {
          color: theme.palette.text.primary,
        },
      }),
    },
  ],
  styleOverrides: {
    root: ({ theme }) => ({
      height: theme.spacing(5),
      '&.Mui-focused': {
        color: theme.palette.primary.main,
      },
    }),
  },
};

export const MuiInputAdornment: ThemedComponent<'MuiInputAdornment'> = {
  variants: [
    {
      props: { variant: 'filled' },
      style: ({ theme }) => ({
        '.Mui-focused &': {
          color: theme.palette.primary.main,
        },
        '.Mui-error &': {
          color: theme.palette.error.main,
        },
        '.Mui-error.Mui-focused &': {
          color: theme.palette.error.main,
        },
        '& .MuiIconButton-root': {
          color: 'inherit',
        },
      }),
    },
    {
      props: { variant: 'outlined' },
      style: ({ theme }) => ({
        '.Mui-focused &': {
          color: theme.palette.primary.main,
        },
        '.Mui-error &': {
          color: theme.palette.error.main,
        },
        '.Mui-error.Mui-focused &': {
          color: theme.palette.error.main,
        },
      }),
    },
  ],
};

export const MuiFilledInput: ThemedComponent<'MuiFilledInput'> = {
  styleOverrides: {
    root: ({ theme }) => ({
      outlineOffset: -1,
      outlineWidth: 1,
      outlineStyle: 'solid',
      outlineColor: 'transparent',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.secondary.light,
      boxShadow: theme.shadows[3],
      color: theme.palette.text.secondary,
      '&::before': {
        content: 'none',
      },
      '&::after': {
        content: 'none',
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
        boxShadow: `inset ${theme.shadows[3]}`,
      },
      '&.Mui-error': {
        outlineColor: theme.palette.error.main,
        color: theme.palette.error.main,
      },
      '&.Mui-error.Mui-focused': {
        outlineColor: theme.palette.error.main,
        color: theme.palette.error.main,
      },
      '&.Mui-focused': {
        outlineColor: theme.palette.primary.main,
        boxShadow: `inset ${theme.shadows[3]}`,
        color: theme.palette.text.secondary,
      },
    }),
    input: ({ theme }) => ({
      padding: theme.spacing(1.5),
    }),
  },
};
