import { memo } from 'react';
import Link from '@mui/material/Link';
import { NavLink } from 'react-router-dom';

import { TableRow, TableCell, TableCellAction, IRenderAction } from 'src/components/shared/Table';
import OrderOperator from 'src/components/shared/OrderOperator';
import { OrderStatusChip } from 'src/components/Status';
import currencyFormatter from 'src/utils/currencyFormatter';
import dateFormatter from 'src/utils/dateFormatter';
import { IInvoiceOrder } from 'src/types';

const formatUSD = currencyFormatter();
const formatDate = dateFormatter();
const formatErrorsDollars = currencyFormatter({ placeholder: '-' });

export type IOrderListRowActionProps = Omit<IOrderListRowProps, 'renderAction'>;

export interface IOrderListRowProps {
  order: IInvoiceOrder;
  ordersNumbers: string[];
  renderAction: IRenderAction;
}

const OrderListRow = ({ order, ordersNumbers, renderAction }: IOrderListRowProps) => (
  <TableRow>
    <TableCellAction renderAction={renderAction} />
    <TableCell>
      <Link
        component={NavLink}
        to={`/invoice/${encodeURIComponent(order.invoice_name)}/order/${encodeURIComponent(order.order_number)}`}
        state={{ ordersNumbers, goBack: true }}>
        {order.order_number}
      </Link>
    </TableCell>
    <TableCell>
      <OrderStatusChip status={order.status} />
    </TableCell>
    <TableCell size={20}>
      <span>{order.error_name.join(', ')}</span>
    </TableCell>
    <TableCell>{formatDate(order.fulfill_date)}</TableCell>
    <TableCell>{formatUSD(order.expected_rate)}</TableCell>
    <TableCell>
      <OrderOperator operator={order.operator} />
    </TableCell>
    <TableCell>{formatUSD(order.invoiced_rate)}</TableCell>
    <TableCell>{formatErrorsDollars(order.display_error_dollars)}</TableCell>
  </TableRow>
);

export default memo(OrderListRow);
