import { Components, Theme } from '@mui/material/styles';

import {
  MuiButton,
  MuiButtonBase,
  MuiButtonGroup,
  MuiIconButton,
  MuiToggleButton,
  MuiToggleButtonGroup,
} from './Button';
import { MuiInputBase, MuiInputLabel, MuiInputAdornment, MuiFilledInput } from './Input';
import { MuiFormControl, MuiFormHelperText } from './Form';
import { MuiLinearProgress } from './Progress';
import { MuiTextField } from './TextField';
import { MuiPaper } from './Paper';
import { MuiSlider } from './Slider';
import { MuiDivider } from './Divider';
import { MuiChip } from './Chip';
import { MuiSelect } from './Select';
import { MuiCheckbox } from './Checkbox';
import { MuiMenu, MuiMenuItem, MuiMenuList } from './Menu';
import { MuiAutocomplete } from './Autocomplete';
import { MuiTypography } from './Typography';
import { MuiTooltip } from './Tooltip';

const components = {
  MuiPaper,
  MuiButton,
  MuiButtonBase,
  MuiIconButton,
  MuiToggleButton,
  MuiToggleButtonGroup,
  MuiButtonGroup,
  MuiInputBase,
  MuiInputLabel,
  MuiFilledInput,
  MuiTextField,
  MuiFormControl,
  MuiFormHelperText,
  MuiInputAdornment,
  MuiLinearProgress,
  MuiSlider,
  MuiDivider,
  MuiChip,
  MuiSelect,
  MuiCheckbox,
  MuiMenu,
  MuiMenuItem,
  MuiMenuList,
  MuiAutocomplete,
  MuiTypography,
  MuiTooltip,
} as Components<Theme>;

export default components;
