import { createEnumParam, withDefault } from 'use-query-params';

import { SortDirection, CreditSortBy, ICreditFilters, CreditFiltersConfig } from 'src/types';
import { makeQueryFilter } from 'src/components/shared/QueryFilter';
import { makeNamedQueryParams } from 'src/effects/useNamedQueryParams';

const SortDirectionParam = withDefault(createEnumParam(Object.values(SortDirection)), SortDirection.DESC);

const CreditSortParam = withDefault(createEnumParam(Object.values(CreditSortBy)), CreditSortBy.id);

export const creditsSortQuery = makeNamedQueryParams('cs', {
  by: CreditSortParam,
  order: SortDirectionParam,
});

export const creditsFiltersQuery = makeQueryFilter<ICreditFilters>('cf', CreditFiltersConfig);
