import { memo } from 'react';

import { IInvoice, IInvoiceSortBy, ISort } from 'src/types';
import { linkToInvoiceDetails } from 'src/components/Invoice';
import { TableBody } from 'src/components/shared/Table';
import { Loading } from 'src/components/shared/Page';
import Message from 'src/components/shared/Message';

import InvoiceHead from './InvoiceHead';
import InvoiceRow from './InvoiceRow';

export interface IInvoiceListProps {
  loading: boolean;
  payload: IInvoice[];
  searchString?: string;
  sorting: ISort<IInvoiceSortBy>;
  onSortChange: (sort: ISort<IInvoiceSortBy>) => void;
}

const InvoiceList = ({ payload, loading, searchString, sorting, onSortChange }: IInvoiceListProps) => (
  <>
    <TableBody>
      <InvoiceHead sort={sorting} onSort={onSortChange} />
      {!loading && payload && (
        <>
          {payload.map((invoice) => (
            <InvoiceRow
              key={`invoice-row-${invoice.name}`}
              invoice={invoice}
              link={linkToInvoiceDetails(invoice.name, invoice.services, searchString)}
            />
          ))}
        </>
      )}
    </TableBody>
    {loading ? <Loading /> : !payload?.length && <Message>No invoices</Message>}
  </>
);

export default memo(InvoiceList);
