import { memo, useCallback, useState } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import { IOrderListRowActionProps, OrderListRow } from 'src/components/InvoiceReconcile/Order';
import { IRowActionControl } from 'src/components/shared/Table';
import { ErrorButton } from 'src/components/shared/Buttons';
import { ISalesOrderStatus, SalesOrderStatus } from 'src/types';
import makeRequest from 'src/effects/makeRequest';
import * as apiInvoice from 'src/api/invoice';

const ACCEPT_STATUSES = [
  SalesOrderStatus.UNREVIEWED,
  SalesOrderStatus.REJECT,
  SalesOrderStatus.NO_REVIEW_REQUIRED,
] as Partial<ISalesOrderStatus>[];

const REJECT_STATUSES = [
  SalesOrderStatus.ACCEPT,
  SalesOrderStatus.UNREVIEWED,
  SalesOrderStatus.NO_REVIEW_REQUIRED,
] as Partial<ISalesOrderStatus>[];

const UNREVIEWED_STATUSES = [SalesOrderStatus.ACCEPT, SalesOrderStatus.REJECT] as Partial<ISalesOrderStatus>[];

const useChangeOrderStatusRequest = makeRequest(apiInvoice.changeOrderStatus);

const InvoiceOrdersRowAction = ({ order, ...orderRestProps }: IOrderListRowActionProps) => {
  const [orderData, updateOrderData] = useState(order);
  const changeOrderStatusRequest = useChangeOrderStatusRequest(null);

  const renderAction = useCallback(
    ({ hidePopper }: IRowActionControl) => {
      const isDisabled = orderData.status === SalesOrderStatus.NO_REVIEW_REQUIRED || changeOrderStatusRequest.loading;

      const processStatusUpdate = (status: ISalesOrderStatus) => {
        changeOrderStatusRequest.make(orderData.order_number, status);
        changeOrderStatusRequest.onFulfill(() => updateOrderData({ ...orderData, status }));
        hidePopper();
      };

      return (
        <ButtonGroup component={Paper} variant="popper">
          {ACCEPT_STATUSES.includes(orderData.status) && (
            <Button onClick={() => processStatusUpdate(SalesOrderStatus.ACCEPT)} disabled={isDisabled}>
              Accept
            </Button>
          )}
          {REJECT_STATUSES.includes(orderData.status) && (
            <Button onClick={() => processStatusUpdate(SalesOrderStatus.REJECT)} disabled={isDisabled}>
              Reject
            </Button>
          )}
          {UNREVIEWED_STATUSES.includes(orderData.status) && (
            <Button onClick={() => processStatusUpdate(SalesOrderStatus.UNREVIEWED)} disabled={isDisabled}>
              Mark as Unreviewed
            </Button>
          )}
          <ErrorButton title="Cancel" onClick={hidePopper} />
        </ButtonGroup>
      );
    },
    [orderData, changeOrderStatusRequest.loading],
  );

  return <OrderListRow {...orderRestProps} order={orderData} renderAction={renderAction} />;
};

export default memo(InvoiceOrdersRowAction) as typeof InvoiceOrdersRowAction;
