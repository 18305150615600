import { memo, useCallback, useState } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import { ErrorButton } from 'src/components/shared/Buttons';
import { IRowActionControl } from 'src/components/shared/Table';
import { AnomalyOrderRow, IAnomalyOrderRowActionProps } from 'src/components/AnomalyOrderList';

import { useFreightContext } from './FreightContext';

const FreightRowAction = ({ anomaly }: IAnomalyOrderRowActionProps) => {
  const [anomalyData, updateAnomalyData] = useState(anomaly);
  const { dialogControl } = useFreightContext();

  const handleAnomalyView = useCallback(
    () =>
      dialogControl.showDialog(anomalyData.order_number, (newData) =>
        updateAnomalyData({ ...anomalyData, ...newData }),
      ),
    [anomalyData],
  );

  const renderAction = useCallback(
    ({ hidePopper }: IRowActionControl) => (
      <ButtonGroup component={Paper} variant="popper">
        <Button disabled>Accept</Button>
        <Button disabled>Reject</Button>
        <Button disabled>Tag</Button>
        <ErrorButton title="Cancel" onClick={hidePopper} />
      </ButtonGroup>
    ),
    [],
  );

  return <AnomalyOrderRow anomaly={anomalyData} renderAction={renderAction} onAnomalyView={handleAnomalyView} />;
};

export default memo(FreightRowAction) as typeof FreightRowAction;
