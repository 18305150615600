import { Dayjs } from 'dayjs';
import { ComponentType, forwardRef, memo, useEffect, useMemo } from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { PickersCalendarHeaderProps } from '@mui/x-date-pickers';

import { ArrowLeftFilledIcon, ArrowRightFilledIcon } from 'src/components/Icons';

import { StyledDateCalendarHeader } from './styles';
import { IDateRange } from './DateRangeCalendar';

export interface IDateRangeHeaderProps extends PickersCalendarHeaderProps<Dayjs> {
  range?: Maybe<IDateRange>;
}

const DateRangeHeader = forwardRef<HTMLDivElement, IDateRangeHeaderProps>(
  ({ range, currentMonth, onMonthChange }, ref) => {
    const monthView = useMemo(() => currentMonth.format('MMMM YYYY'), [currentMonth]);

    const moveToPrevMonth = () => onMonthChange(currentMonth.subtract(1, 'month'), 'right');
    const moveToNextMonth = () => onMonthChange(currentMonth.add(1, 'month'), 'left');

    useEffect(() => {
      if (!range) return;
      if (currentMonth.isAfter(range.to, 'month')) onMonthChange(range.to, 'right');
      if (currentMonth.isBefore(range.from, 'month')) onMonthChange(range.from, 'left');
    }, [range]);

    return (
      <StyledDateCalendarHeader ref={ref}>
        <IconButton onClick={moveToPrevMonth}>
          <ArrowLeftFilledIcon />
        </IconButton>
        <Typography variant="h4">{monthView}</Typography>
        <IconButton onClick={moveToNextMonth}>
          <ArrowRightFilledIcon />
        </IconButton>
      </StyledDateCalendarHeader>
    );
  },
);

DateRangeHeader.displayName = 'DateRangeHeader';

export default memo(DateRangeHeader) as ComponentType<IDateRangeHeaderProps>;
