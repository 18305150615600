import { useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';

import { IInvoice } from 'src/types';
import Message from 'src/components/shared/Message';
import ActionPanel from 'src/components/shared/ActionPanel';
import { ButtonLink } from 'src/components/shared/Buttons';
import InvoiceDateRange from 'src/components/InvoiceDateRange';
import { InvoiceCards, InvoiceType } from 'src/components/InvoiceDetails';
import { PageTitle, PageContainer, Loading, TitleButtons } from 'src/components/shared/Page';
import { InvoiceSlider, isInvoiceUnreconciled, invoicesDateRangeQuery } from 'src/components/Invoice';
import { QueryFilter } from 'src/components/shared/QueryFilter';
import useXScrollControl from 'src/effects/useXScrollControl';

import InvoiceDetails from './InvoiceDetails';
import { InvoiceDetailsProvider, useInvoiceDetailsContext } from './InvoiceDetailsContext';

const InvoicesDetails = () => {
  const {
    invoiceDetailsControl,
    invoicesDateRangeControl,
    invoicesFiltersControl,
    invoicesRequest,
    invoicesFiltersRequest,
  } = useInvoiceDetailsContext();

  const scrollControl = useXScrollControl<HTMLDivElement>();

  const invoicesMap = useMemo(
    () => new Map(invoicesRequest.data.map((invoice) => [invoice.name, invoice])),
    [invoicesRequest.data],
  );
  const activeInvoice = useMemo<Maybe<IInvoice>>(
    () => (invoiceDetailsControl.name ? invoicesMap.get(invoiceDetailsControl.name) : null),
    [invoicesMap, invoiceDetailsControl.name],
  );

  useEffect(() => invoicesFiltersRequest.make(), []);

  useEffect(() => {
    if (!invoicesFiltersControl.isReady) return;
    invoicesRequest.make(
      invoicesFiltersControl.params,
      invoicesDateRangeQuery.getEncodedParams(invoicesDateRangeControl.dateRange),
    );
  }, [invoicesDateRangeControl.dateRange, invoicesFiltersControl.isReady, invoicesFiltersControl.params]);

  useEffect(() => {
    if (invoicesRequest.loading) return;

    const name = activeInvoice?.name ?? invoicesRequest.data[0]?.name ?? null;

    invoiceDetailsControl.setName(name);
  }, [activeInvoice, invoicesRequest.data, invoicesRequest.loading]);

  useEffect(() => {
    const scrollIsReady = !invoicesRequest.loading && !!invoicesRequest.data.length;

    if (scrollIsReady) scrollControl.activate();
    else scrollControl.cleanup();

    return () => scrollControl.cleanup();
  }, [invoicesRequest.loading, invoicesRequest.data]);

  return (
    <>
      <PageTitle title={'Invoices'} description={'Sorting: most recent first (based on end date).'}>
        <ActionPanel noAlign>
          <QueryFilter control={invoicesFiltersControl} />
          <TitleButtons align="right">
            <InvoiceDateRange
              range={invoicesDateRangeControl.dateRange}
              onChange={invoicesDateRangeControl.setDateRange}
            />
            <ButtonLink
              title="View errors"
              variant="contained"
              disabled={isInvoiceUnreconciled(activeInvoice)}
              to={`/invoice/${encodeURIComponent(invoiceDetailsControl.name as string)}/reconcile`}
            />
            <ButtonLink title="All Invoices" variant="outlined" to={'/invoices'} />
          </TitleButtons>
        </ActionPanel>
      </PageTitle>
      <PageContainer>
        {invoicesRequest.loading ? (
          <Loading />
        ) : (
          <>
            {invoicesRequest.data.length ? (
              <>
                <InvoiceCards
                  ref={scrollControl.ref}
                  activeName={invoiceDetailsControl.name}
                  onChange={invoiceDetailsControl.setName}
                  invoices={invoicesRequest.data}
                />
                <ActionPanel onlyRightChild>
                  {invoiceDetailsControl.name && (
                    <InvoiceType type={invoiceDetailsControl.type} onChange={invoiceDetailsControl.setType} />
                  )}
                  <InvoiceSlider
                    value={scrollControl.factor}
                    disabled={!scrollControl.active}
                    onChange={(_, value) => scrollControl.update(value as number)}
                  />
                  <Button variant="text" disabled>
                    Upload invoice
                  </Button>
                </ActionPanel>
                {invoiceDetailsControl.name ? <InvoiceDetails /> : <Message>Select invoice to see details</Message>}
              </>
            ) : (
              <Message>No invoices for selected period</Message>
            )}
          </>
        )}
      </PageContainer>
    </>
  );
};

export default function InvoicesDetailsPage() {
  return (
    <InvoiceDetailsProvider>
      <InvoicesDetails />
    </InvoiceDetailsProvider>
  );
}
