import { SERVER_CONFIG } from 'src/config/server';
import { IRequestInterceptors, Request } from 'src/libs/Request';

class RequestInterceptors implements IRequestInterceptors {
  requestFulfill: IRequestInterceptors['requestFulfill'] = (_, request) => {
    if (request.AUTH_TOKEN) request.headers.set('Authorization', `Bearer ${request.AUTH_TOKEN}`);

    return request;
  };
}

export type { IRequest } from 'src/libs/Request';
export { serializeQueryJson } from 'src/libs/Request';

export default new Request(SERVER_CONFIG.API_URL, new RequestInterceptors());
